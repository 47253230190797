import React from 'react';
import { CommentWithReviewComments } from '../../../../types/types';
import StarRating from '../StarRating/StarRating';
import Button from '../../button/Button/Button';
import { renderPinDropLabel, seekComment, usePinDropContext } from '../../../../contexts/PinDropContext';

interface Props {
  commentWithReviewComments: CommentWithReviewComments;
  submitterView?: boolean;
  teacherView?: boolean;
}

function ReviewDialogue({ commentWithReviewComments, submitterView = false, teacherView = false }: Props): JSX.Element {
  const { pinDropDispatch, pinDropContextState } = usePinDropContext();

  let reviewCommentNum = 0;
  return (
    <>
      {Object.keys(commentWithReviewComments.reviewCommentsMap).map((reviewCommentId) => {
        const reviewComments = commentWithReviewComments.reviewCommentsMap[reviewCommentId];
        const submitterFeedback = commentWithReviewComments.submitterFeedbackMap?.hasOwnProperty(reviewCommentId)
          ? commentWithReviewComments.submitterFeedbackMap[reviewCommentId]
          : null;

        return (
          <React.Fragment key={reviewCommentId}>
            <div className={`review-dialogue ${teacherView ? 'teacher-view' : ''}`}>
              {reviewComments.map((reviewComment, i) => {
                reviewCommentNum++;
                const customAttributes = { 'review-comment-num': `${reviewCommentNum}` };
                return (
                  <div
                    className="review-comment-dialogue-wrapper"
                    {...customAttributes}
                    key={reviewComment.reviewCommentId}
                  >
                    <blockquote
                      className={`review-comment ${submitterView ? 'received' : 'sent'}`}
                      style={{ animationDelay: `0.${reviewCommentNum - 1}s` }}
                    >
                      {reviewComment.comment}
                      {i === reviewComments.length - 1 && submitterFeedback ? (
                        <div className="reaction">
                          <StarRating score={submitterFeedback.feedbackRating ?? 0} max={5} starSize={16} />
                        </div>
                      ) : null}
                    </blockquote>
                    <div className="ctrls">
                      {reviewComment.pinDrop && !teacherView ? (
                        <Button
                          className="pin-drop"
                          classOverride
                          onClick={(e) => {
                            const storedNum =
                              (e.target as Element).parentElement?.parentElement?.getAttribute('review-comment-num') ??
                              '0';
                            pinDropDispatch(seekComment(reviewComment.commentId, parseInt(storedNum)));
                          }}
                          tooltip={
                            reviewComment.pinDrop.altText
                              ? `"${reviewComment.pinDrop.altText}"`
                              : reviewComment.pinDrop.pageNum != -1
                              ? 'View Pin'
                              : reviewComment.pinDrop.timestamp != -1
                              ? 'Play From Timestamp'
                              : undefined
                          }
                          disabled={reviewComment.pinDrop.pageNum === -1 && reviewComment.pinDrop.timestamp === -1}
                          ariaLabel={
                            reviewComment.pinDrop.altText
                              ? `Comment Pin Description: "${reviewComment.pinDrop.altText}"`
                              : undefined
                          }
                        >
                          {renderPinDropLabel(
                            reviewComment.commentId,
                            reviewCommentNum,
                            reviewComment.pinDrop,
                            pinDropContextState,
                          )}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={`feedback-dialogue ${teacherView ? 'teacher-view' : ''}`}>
              {submitterFeedback ? (
                <div className="feedback-comment-dialogue-wrapper" key={submitterFeedback.commentId}>
                  <blockquote
                    className={`feedback-comment ${submitterView ? 'sent' : 'received'}`}
                    style={{ animationDelay: `0.${reviewCommentNum}s` }}
                  >
                    {submitterFeedback.feedbackComment}
                  </blockquote>
                </div>
              ) : null}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default ReviewDialogue;
