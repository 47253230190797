import moment from 'moment';
import { schoolAdminGetCourses } from '../../utils/requests';
import QueryTable, {  Column } from '../core/display/QueryTable/QueryTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Course, PageQuery } from '../../types/types';
import { adminGetCourses } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function AdminCoursesMenu(): JSX.Element {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(true);
  const [query, setQuery] = useState<PageQuery<Course> | null>(null);

  const parseNum = (val: string, cb: (num: number) => void) => cb(Math.min(Math.max(parseInt(val), 1), 999));

  useEffect(() => adminGetCourses(limit, page - 1, active, setQuery), [limit, page, active]);

  return (
    <div id="admin-courses" className="page">
      <h1>Courses</h1>
      <div className="ctrls-row">
        <div className="input-wrapper">
          <label htmlFor="limit">Results Per Page:</label>
          <input
            id="limit"
            name="limit"
            type="number"
            placeholder="# / Page"
            min={1}
            value={limit}
            onChange={(e) => parseNum(e.target.value, setLimit)}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="page">Page Number:</label>
          <input
            id="page"
            name="page"
            type="number"
            placeholder="Page #"
            min={1}
            value={page}
            onChange={(e) => parseNum(e.target.value, setPage)}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="active">Active Only:</label>
          <input
            id="active"
            name="active"
            type="checkbox"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </div>
      </div>

      {query ? (
        <>
          <p className="results-num">
            Query contains <b>{query.totalResults} total results</b>
          </p>
          <CourseTable courses={query.results} defaultPageSize={limit} />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}

interface CourseTableProps {
  courses: Course[];
  defaultPageSize: number;
}

function CourseTable({ courses, defaultPageSize }: CourseTableProps): JSX.Element {

  const tableColumns = useMemo<Column<Course>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'courseName',
        Cell: (course: Course) => course.courseName,
        className: 'bold',
        customSort: 'courseName',
      },

      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: (course: Course) => course.user?.sortableName,
        customSort: 'createdBy',
      },
      { Header: 'Semester', accessor: 'semester', Cell: (course: Course) => course.semester, customSort: 'semester' },
      { Header: 'Year', accessor: 'year', Cell: (course: Course) => course.year, customSort: 'year' },
      {
        Header: 'Discipline',
        accessor: 'discipline',
        Cell: (course: Course) => course.discipline,
      },

      {
        Header: 'University',
        accessor: 'university',
        Cell: (course: Course) => course.university,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (course: Course) => moment(course.createdAt).format('MMM D, YYYY'),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (course: Course) => course.status,
      },
      {
        Header: 'Student Purchase',
        accessor: 'studentPurchaseEnabled',
        Cell: (course: Course) => (course.studentPurchaseEnabled ? 'Yes' : 'No'),
        className: 'center-align',
      },
      {
        Header: 'LTI Context ID',
        accessor: 'ltiContextId',
        Cell: (course: Course) => (course.ltiContextId ? course.ltiContextId : 'None'),
        customSort: 'ltiContextId',
      },
      {
        Header: 'Students',
        accessor: 'rosterStudentCount',
        Cell: (course: Course) => course.rosterStudentCount,
        className: 'center-align',
      },
      {
        Header: 'Assignments',
        accessor: 'assignmentCount',
        Cell: (course: Course) => course.assignmentCount,
        className: 'center-align',
      },
    ],
    [],
  );
  return (
    <div className="page home-page" id="course-libary">
      <h1 className="sr-only">Courses</h1>
      <QueryTable
        title="Courses"
      columns={tableColumns}
      queryRequest={schoolAdminGetCourses}
        institution={true}
        date={true}
        zeroAssignment={true}
        enrollmentDate={true}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          tableClassName: 'home-body',
        }}
      />
    </div>
  );
}

export default AdminCoursesMenu;
