import { PayloadAction } from '@reduxjs/toolkit';
import { CommentData, CommentTable } from '../types/types';
import { SET_COMMENT_TABLE, UPDATE_COMMENT_TABLE } from '../utils/constants';
import _ from 'lodash';

const commentTableReducer = (
  state: CommentTable = {},
  action: PayloadAction<CommentTable | CommentData>,
): CommentTable => {
  switch (action.type) {
    case SET_COMMENT_TABLE:
      const setPayload = action.payload as CommentTable;
      return setPayload;
    case UPDATE_COMMENT_TABLE:
      const newState = _.cloneDeep(state);
      const updatePayload = action.payload as CommentData;
      if (newState[updatePayload.commentId] == null) newState[updatePayload.commentId] = {};
      if (newState[updatePayload.commentId][updatePayload.commentNumber] == null)
        newState[updatePayload.commentId][updatePayload.commentNumber] = { comment: '', pinDrop: null };
      newState[updatePayload.commentId][updatePayload.commentNumber].comment = updatePayload.comment;
      newState[updatePayload.commentId][updatePayload.commentNumber].pinDrop = updatePayload.pinDrop;
      return newState;
    default:
      return state;
  }
};

export default commentTableReducer;
