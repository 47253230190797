import React, { useState } from 'react';
import { confirmSubmission, postFileSubmission, updateFileSubmission } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import OptionButton from '../core/button/OptionButton/OptionButton';
import FileDropZone from '../core/input/FileDropZone/FileDropZone';
import Submission from '../core/display/Submission/Submission';
import { State } from './SubmissionPage';
import { Assignment } from '../../types/types';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

interface Props {
  allowEdit: boolean;
  assignmentId: string;
  assignment: Assignment;
  asyncEnabled: boolean;
  canReset?: boolean;
  fileName?: string;
  onDelete: () => void;
  requestSequenceBreak: (arg0: number) => void;
  requestStateChangeInSequence: (arg0: number) => void;
  returnToDashboard: () => void;
  setSubmissionIdAndCleanLocalstorage: (arg0: string) => void;
  submissionId: string;
  submissionState: number;
  submitterId: string;
}

function FileMenu({
  allowEdit,
  assignmentId,
  fileName,
  onDelete,
  requestSequenceBreak,
  requestStateChangeInSequence,
  returnToDashboard,
  setSubmissionIdAndCleanLocalstorage,
  submissionId,
  submissionState,
  submitterId,
}: Props): JSX.Element {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  switch (submissionState) {
    case State.SUBMIT:
      return (
        <div className="submission-menu" key="file-submit" id="submit-menu" tabIndex={-1}>
          <h1>Upload a File</h1>
          <p>Select the file you would like to submit for the assignment.</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              // Post file to server
              const formData = new FormData();
              if (file) formData.append('submission', file);
              if (submissionId === '')
                postFileSubmission(
                  assignmentId,
                  submitterId,
                  formData,
                  setSubmissionIdAndCleanLocalstorage,
                  () => {
                    setLoading(false);
                    requestStateChangeInSequence(State.CONFIRM);
                  },
                  () => {
                    setLoading(false);
                    return false;
                  },
                );
              else
                updateFileSubmission(
                  submissionId,
                  formData,
                  setSubmissionIdAndCleanLocalstorage,
                  () => {
                    setLoading(false);
                    requestStateChangeInSequence(State.CONFIRM);
                  },
                  () => {
                    setLoading(false);
                    return false;
                  },
                );
            }}
          >
            <FileDropZone onChange={setFile} required={true} />
            <div className="buttons-wrapper">
              <Button
                variant="rad alt"
                type="button"
                onClick={() => {
                  requestSequenceBreak(State.CHOOSE);
                }}
              >
                Back
              </Button>
              <Button variant="rad" type="submit">
                Continue
              </Button>
            </div>
          </form>
          {loading ? <LoadingSpinner /> : null}
        </div>
      );
    case State.CONFIRM:
      return (
        <div className="submission-menu" key="file-confirm" id="confirm-menu" tabIndex={-1}>
          <h1>Confirm &amp; Submit</h1>
          <p>Please check that your submission appears correctly and then press Confirm &amp; Submit.</p>
          <Submission
            key="file-confirm"
            submissionId={submissionId}
            flexDirection="column-reverse"
            pdfMenu={(submissionSrc) => (
              <div className="view-ctrls">
                <Button
                  variant="rad alt"
                  type="button"
                  onClick={() => {
                    requestSequenceBreak(State.SUBMIT);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="rad"
                  onClick={() => {
                    confirmSubmission(submissionId);
                    requestStateChangeInSequence(State.FINISH);
                  }}
                >
                  Confirm &amp; Submit
                </Button>
                {submissionSrc ? (
                  <OptionButton
                    id="download-sub-btn"
                    href={submissionSrc}
                    label="Open submission"
                    iconCode="open_in_new"
                  />
                ) : null}
              </div>
            )}
            miscFileMenu={(submissionSrc) => (
              <div className="view-ctrls">
                <Button
                  variant="rad alt"
                  type="button"
                  onClick={() => {
                    requestSequenceBreak(State.SUBMIT);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="rad"
                  onClick={() => {
                    confirmSubmission(submissionId);
                    requestStateChangeInSequence(State.FINISH);
                  }}
                >
                  Confirm &amp; Submit
                </Button>
                {submissionSrc ? (
                  <OptionButton
                    id="download-sub-btn"
                    href={submissionSrc}
                    download={fileName}
                    label="Download submission"
                    iconCode="download"
                  />
                ) : null}
              </div>
            )}
          />
        </div>
      );
    case State.FINISH:
      return (
        <div className="submission-menu" key="file-finish" id="finish-menu" tabIndex={-1}>
          <h1>Submission Successful</h1>
          <p>Your assignment has been successfully submitted.</p>
          <Button variant="rad" onClick={returnToDashboard}>
            Done
          </Button>
        </div>
      );
    case State.VIEW:
      return (
        <div className="submission-menu" key="file-view" id="view-menu" tabIndex={-1}>
          <h1>View Submission</h1>
          <p>
            Below is your current submission for your assignment.{' '}
            {allowEdit ? 'Here you may access, replace, or delete your submission.' : ''}
          </p>
          <Submission
            key="file-view"
            submissionId={submissionId}
            flexDirection={'column-reverse'}
            pdfMenu={(submissionSrc, fileName) => (
              <div className="view-ctrls flex-row">
                {submissionSrc ? (
                  <OptionButton
                    id="download-sub-btn"
                    href={submissionSrc}
                    download={fileName}
                    label="Download submission"
                    iconCode="download"
                  />
                ) : null}
                {allowEdit ? (
                  <>
                    <OptionButton
                      id="delete-sub-btn"
                      onClick={() => onDelete()}
                      label="Delete submission"
                      iconCode="delete"
                    />
                    <Button
                      variant="rad"
                      onClick={() => {
                        requestSequenceBreak(State.SUBMIT);
                      }}
                    >
                      Replace
                    </Button>
                  </>
                ) : null}
              </div>
            )}
            miscFileMenu={(submissionSrc, fileName) => (
              <div className="view-ctrls flex-row">
                {submissionSrc ? (
                  <OptionButton
                    id="download-sub-btn"
                    href={submissionSrc}
                    download={fileName}
                    label="Download submission"
                    iconCode="download"
                  />
                ) : null}
                {allowEdit ? (
                  <>
                    <OptionButton
                      id="delete-sub-btn"
                      onClick={() => onDelete()}
                      label="Delete submission"
                      iconCode="delete"
                    />
                    <Button
                      variant="rad"
                      onClick={() => {
                        requestSequenceBreak(State.SUBMIT);
                      }}
                    >
                      Replace
                    </Button>
                  </>
                ) : null}
              </div>
            )}
          />
        </div>
      );
    default:
      return <></>;
  }
}

export default FileMenu;
