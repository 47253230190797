import React, { useMemo } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import JumpButton from '../../button/JumpButton';

type Category = {
  id: string;
  href: string;
  name: string;
};

interface Props {
  categories: Category[];
  categoryToComponentMap: { [index: string]: React.ReactNode };
  id: string;
  root?: string;
  title?: string;
}

function CategoryMenu({ categories, categoryToComponentMap, id, root, title }: Props): JSX.Element {
  const location = useLocation();

  const currentCategory = useMemo(
    () => categories.find((category) => location.pathname === `${root ?? ''}${category.href}`),
    [categories, location.pathname, root],
  );

  let heading = title ?? '';
  if (title && currentCategory) heading += ': ';
  heading += currentCategory?.name ?? '';
  return (
    <div className="category-menu">
      <h1>{heading}</h1>

      <div className="menu-body">
        <JumpButton type="focus" targetId={`category-menu-content-${id}`} asLink invisible>
          Skip to menu
        </JumpButton>
        <div className="links" role="navigation" aria-label="Profile links">
          <ul>
            {categories.map((category) => {
              const isCurrent = category.id === currentCategory?.id;
              return (
                <li key={category.id} className={isCurrent ? 'selected' : undefined}>
                  <Link to={`${root ?? ''}${category.href}`} aria-current={isCurrent}>
                    {category.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        <div id={`category-menu-content-${id}`} className="content" role="region" tabIndex={-1}>
          <Routes>
            {categories.map((category) => (
              <Route key={category.id} path={category.href} element={categoryToComponentMap[category.id]} />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default CategoryMenu;
