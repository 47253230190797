import React, { useEffect, useRef, useState, useCallback } from 'react';

interface TabListProps {
  children: React.ReactNode;
  label: string;
  mini?: boolean;
  mini2?: boolean;
  onTabChange?: (tabId: string) => void;
  startingTabId?: string;
  tabs: React.ReactNode;
}

function TabList({
  children,
  label,
  mini = false,
  mini2 = false,
  onTabChange = () => undefined,
  startingTabId,
  tabs,
}: TabListProps): JSX.Element {
  const [initComplete, setInitComplete] = useState(false);

  const containerEl = useRef<HTMLDivElement>(null);
  const tablistEl = useRef<HTMLDivElement>(null);

  const selectTab = useCallback(
    (tabEl: HTMLElement, init = false) => {
      tabEl.setAttribute('tabindex', '0');
      tabEl.setAttribute('aria-selected', 'true');
      if (!init) tabEl.focus();

      const panelId = tabEl.getAttribute('aria-controls');
      const panelEl = document.getElementById(panelId || '');
      if (panelEl) {
        panelEl.removeAttribute('hidden');
      }

      onTabChange(tabEl.getAttribute('id') ?? '');
    },
    [onTabChange],
  );

  const clear = () => {
    if (tablistEl.current) {
      const tabs = tablistEl.current.childNodes;
      tabs.forEach((tab) => {
        (tab as HTMLElement).setAttribute('tabindex', '-1');
        (tab as HTMLElement).setAttribute('aria-selected', 'false');
      });
    }

    if (containerEl.current) {
      const tabPanels = containerEl.current.querySelectorAll('.tabpanel');
      tabPanels.forEach((tabPanel) => {
        if (!tabPanel.hasAttribute('hidden')) tabPanel.setAttribute('hidden', '');
      });
    }
  };

  useEffect(() => {
    if (!initComplete && tablistEl.current) {
      const firstTab = startingTabId
        ? document.getElementById(startingTabId)
        : (tablistEl.current.childNodes[0] as HTMLElement);
      if (firstTab) selectTab(firstTab, true);
      setInitComplete(true);
    }
  }, [initComplete, selectTab, startingTabId]);

  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.getAttribute('role') === 'tab') {
      clear();
      selectTab(target);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLElement;
    const parent = target.parentNode;
    if (parent) {
      let newTab: HTMLElement | null = null;
      switch (e.key) {
        case 'ArrowLeft':
          newTab = (target.previousElementSibling || parent.lastChild) as HTMLElement;
          break;
        case 'ArrowRight':
          newTab = (target.nextElementSibling || parent.firstChild) as HTMLElement;
          break;
        case 'Home':
          newTab = parent.firstChild as HTMLElement;
          break;
        case 'End':
          newTab = parent.lastChild as HTMLElement;
      }

      if (newTab) {
        e.preventDefault();
        clear();
        selectTab(newTab);
      }
    }
  };

  return (
    <div className={`tabs ${mini ? `tabs-mini` : ''} ${mini2 ? `tabs-mini-2` : ''}`} ref={containerEl}>
      <div
        className="tablist mininav"
        role="tablist"
        aria-label={label}
        ref={tablistEl}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {tabs}
      </div>
      {children}
    </div>
  );
}

interface TabProps {
  children: React.ReactNode;
  controls: string;
  id: string;
}

function Tab({ children, controls, id }: TabProps): JSX.Element {
  return (
    <div className="tab" tabIndex={-1} role="tab" aria-selected="false" aria-controls={controls} id={id}>
      {children}
    </div>
  );
}

interface TabPanelProps {
  children: React.ReactNode;
  id: string;
  labeledBy: string;
}

function TabPanel({ children, id, labeledBy }: TabPanelProps): JSX.Element {
  return (
    <div className="tabpanel" role="tabpanel" id={id} aria-labelledby={labeledBy} hidden={true}>
      {children}
    </div>
  );
}

TabList.Tab = Tab;
TabList.TabPanel = TabPanel;

export default TabList;
