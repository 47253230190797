import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RootState } from '../../store';
import DashboardStatsBar from '../core/layout/DashboardStatsBar/DashboardStatsBar';
import MiniNav from '../core/layout/MiniNav/MiniNav';
import AllAssignments from './AllAssignments';
import AssignmentTemplates from './AssignmentTemplates';

function AssignmentLibrary(): JSX.Element {
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className="page home-page" id="assignment-libary">
      {user.admin || user.schoolAdmin ? (
        <Routes>
          {['/all', '/templates'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <>
                  <DashboardStatsBar type="ASSIGNMENT" />
                  <MiniNav id="assignment-library-nav">
                    <MiniNav.Link to="/assignments/all">Assignments</MiniNav.Link>
                    <MiniNav.Link to="/assignments/templates">Templates</MiniNav.Link>
                  </MiniNav>
                </>
              }
            />
          ))}
        </Routes>
      ) : null}
      <Routes>
        <Route index element={<Navigate to="/assignments/all" />} />
        <Route path="/all" element={<AllAssignments />} />
        <Route path="/templates" element={<AssignmentTemplates />} />
      </Routes>
    </div>
  );
}

export default AssignmentLibrary;
