import React from 'react';
import { useState, useEffect } from 'react';
import Form from '../core/input/Form/Form';
import TextBox from '../core/input/TextBox/TextBox';
import Button from '../core/button/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommentTable } from '../../actions';
import { RootState } from '../../store';
import RichReader from '../core/display/RichReader';
import CommentTutorial from '../tutorial/CommentTutorial';
import { usePinDropContext } from '../../contexts/PinDropContext';

interface Props {
  commentId: string;
  commentName: string;
  commentPrompt: string;
  maximumComments: number;
  minimumComments: number;
  onInvalid?: () => void;
  saveReview: () => void;
}

function FormCommentPage({
  commentId,
  commentName,
  commentPrompt,
  maximumComments,
  minimumComments,
  onInvalid,
  saveReview,
}: Props): JSX.Element {
  // State:
  const [commentBoxes, setCommentBoxes] = useState<JSX.Element[]>([]);
  const [numCommentsShown, setNumCommentsShown] = useState(minimumComments);

  // Redux:
  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);

  // PinDrop Context:
  const { pinDropContextState } = usePinDropContext();

  // Find last filled comment to determine how many comment boxes to show initially
  useEffect(() => {
    let lastFilledCommentIndex = 1;
    for (let i = 1; i <= maximumComments; i++) {
      if (commentTable[commentId] && commentTable[commentId][i] && commentTable[commentId][i].comment !== '')
        lastFilledCommentIndex = i;
    }

    if (lastFilledCommentIndex > minimumComments) setNumCommentsShown(lastFilledCommentIndex);
  }, [commentId, commentTable, maximumComments, minimumComments]);

  useEffect(() => {
    /**
     * The event handler for changes on the comment's text area
     * @param {object} e Event
     */
    const handleChange = (e: React.ChangeEvent, commentNumber: number) => {
      const comment = (e.target as HTMLInputElement).value;
      const pinDrop =
        commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber)
          ? commentTable[commentId][commentNumber].pinDrop
          : null;
      dispatch(
        updateCommentTable({
          commentId,
          commentNumber,
          comment,
          pinDrop,
        }),
      );

      // Save review
      saveReview();
    };

    const handlePinDelete = (commentNumber: number) => {
      const comment =
        commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber)
          ? commentTable[commentId][commentNumber].comment
          : '';
      dispatch(
        updateCommentTable({
          commentId,
          commentNumber,
          comment,
          pinDrop: null,
        }),
      );

      // Save review
      saveReview();
    };

    // Generate comment boxes
    const newCommentBoxes = [];
    for (let i = 1; i <= numCommentsShown; i++) {
      // Get initial comment, if any
      let initComment = '';
      let pinDrop = null;
      if (commentTable[commentId] && commentTable[commentId][i]) {
        initComment = commentTable[commentId][i].comment;
        pinDrop = commentTable[commentId][i].pinDrop;
      }
      // Determine if comment is required
      const required = i <= minimumComments;
      newCommentBoxes.push(
        <TextBox
          key={`${commentId}-${i}`}
          commentId={commentId}
          commentNumber={i}
          labelText={`Comment ${i} on ${commentName}`}
          required={required}
          defaultValue={initComment}
          onChange={(e) => handleChange(e, i)}
          onBlur={(e) => handleChange(e, i)}
          onInvalid={onInvalid}
          onPinDelete={() => handlePinDelete(i)}
          pinDrop={pinDrop}
          review
        />,
      );
      setCommentBoxes(newCommentBoxes);
    }
  }, [commentId, commentName, commentTable, dispatch, numCommentsShown, minimumComments, onInvalid, saveReview]);

  return (
    <>
      <Form.Header>
        <Form.Title>{commentName}</Form.Title>
        <Form.Description size="sm">
          <RichReader content={commentPrompt} />
        </Form.Description>
      </Form.Header>
      <Form.Body>
        {commentBoxes}
        {numCommentsShown < maximumComments ? (
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setNumCommentsShown((prevNum) => prevNum + 1);
            }}
          >
            + Additional Comment
          </Button>
        ) : null}
      </Form.Body>
      <CommentTutorial pinningEnabled={pinDropContextState.type != null} />
    </>
  );
}

export default FormCommentPage;
