import React, { useEffect, useState } from 'react';
import { AssignmentProgress, GroupFormationResult, Result } from '../../types/types';
import { getGroupFormationResults, getMyGradeResults } from '../../utils/requests';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAssignment, selectUser } from '../../store/selectors';
import Avatar from '../core/display/Avatar/Avatar';
import { getBackgroundColorFromScore } from '../../utils/functions';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import Icon from '../core/display/Icon';
import AlertBar from '../core/display/AlertBar';
import GroupFormationResults from './GroupFormationResults';

interface Props {
  assignmentProgress: AssignmentProgress;
}

function StudentGroupFormationResults({ assignmentProgress }: Props): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const assignment = useSelector(selectAssignment);

  const [results, setResults] = useState<GroupFormationResult[]>([]);

  useEffect(() => {
    getGroupFormationResults(assignmentId, setResults, () => {
      setResults([]);
      return true;
    });
  }, [assignmentId]);

  if (assignment?.groupFormationEnabled && assignmentProgress.status.hasResult) {
    return (
      <>
        {!assignment?.hideGradeResults ? <GradeResults assignmentProgress={assignmentProgress} /> : null}
        {!assignment?.anonymousGroups ? <GroupResults assignmentProgress={assignmentProgress} /> : null}
        {!assignment?.hideGroupFormationResults ? <GroupFormationResults results={results} /> : null}
      </>
    );
  }
  return <></>;
}

function GradeResults({ assignmentProgress }: { assignmentProgress: AssignmentProgress }): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [result, setResult] = useState<Result | null>(null);

  useEffect(() => {
    getMyGradeResults(assignmentId, setResult);
  }, [assignmentId]);

  if (result)
    return (
      <div className="grade-results results-section">
        <h2>Overall Grade</h2>
        <div className="overall-wrapper">
          <div
            className="overall-grade"
            style={{ backgroundColor: getBackgroundColorFromScore(result.grade.overallGrade) }}
          >
            <span>
              {Math.round(result.grade.overallGrade)}
              <span className="percent">%</span>
            </span>
          </div>
          <div className="completion-wrapper">
            Group Formation Survey:{' '}
            {assignmentProgress.groupFormationResponseComplete ? (
              <>
                Complete <Icon className="complete" code="done" ariaHidden />
              </>
            ) : (
              <>
                Incomplete <Icon className="incomplete" code="close" ariaHidden />
              </>
            )}
          </div>
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

interface GroupProps {
  assignmentProgress: AssignmentProgress;
}

function GroupResults({ assignmentProgress }: GroupProps): JSX.Element {
  const user = useSelector(selectUser);

  const myGroup = assignmentProgress.group;

  return (
    <div className="group-results results-section">
      {myGroup ? (
        <>
          <h2>Your Group</h2>
          <div id="my-group">
            <div id="group-container">
              <ul aria-label="My Group Members">
                {myGroup.groupMembers
                  .sort((a, b) => {
                    if (a.user.userId === user.userId) return -1;
                    if (b.user.userId === user.userId) return 1;
                    return (a.user.name || '').localeCompare(b.user.name || '');
                  })
                  .map((member) => {
                    const currUser = member.user;
                    const isSelf = currUser.userId === user.userId;

                    return (
                      <li key={`member-${member.groupMemberId}`}>
                        <div className="member-entry">
                          <Avatar user={currUser} size={32} />

                          <span className="entry-name">
                            {isSelf ? (
                              <span>
                                <b>{currUser.name}</b>
                                <span className="badge">You</span>
                              </span>
                            ) : (
                              <span>{currUser.name}</span>
                            )}
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <AlertBar>You have not been assigned to a group. Please contact your instructor.</AlertBar>
      )}
    </div>
  );
}

export default StudentGroupFormationResults;
