import React, { useCallback, useState } from 'react';
import { Assignment, AssignmentProgress, AssignmentProgressStatus, PhaseCode } from '../../../types/types';
import TabList from '../../core/layout/TabList/TabList';
import ProgressRing from '../../core/display/Progress/ProgressRing';
import Icon from '../../core/display/Icon';
import Button from '../../core/button/Button/Button';
import { getCurrentPhaseForUser, getTimeRemainingForDate } from '../../../utils/functions';
import moment from 'moment';
import ReviewMenu from './ReviewMenu';
import FeedbackMenu from './FeedbackMenu';
import AssignmentPhaseIcon from '../../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import SubmitMenu from './SubmitMenu';
import EvaluateMenu from './EvaluateMenu';

interface Props {
  assignment: Assignment;
  userProgress: AssignmentProgress;
}

export type PhaseMenuDetails = {
  complete: boolean;
  active: boolean;
  locked: boolean;
  deadline: string;
};

type PhaseMenuDetailsMap = Record<PhaseCode, PhaseMenuDetails | undefined> | Record<string, never>;

function PhaseMenu(props: Props): JSX.Element {
  const { assignment, userProgress } = props;
  const { status } = userProgress;

  const currentPhase = getCurrentPhaseForUser(assignment, userProgress.status);
  const startingTabId = currentPhase !== 'async' ? currentPhase : undefined;

  const [phaseDetailsMap, setPhaseDetailsMap] = useState<PhaseMenuDetailsMap>({});

  const setPhaseDetails = useCallback(
    (phase: PhaseCode, details: PhaseMenuDetails) =>
      setPhaseDetailsMap((prevMap) => {
        const newMap = { ...prevMap };
        newMap[phase] = details;
        return newMap;
      }),
    [],
  );

  const sharedProps = { ...props, setPhaseDetails };
  return (
    <div id="phase-menu">
      <TabList
        label="Phase Menu"
        startingTabId={startingTabId}
        tabs={
          <>
            {status.showSubmission ? (
              <TabList.Tab id="submit" controls="submit-tab">
                <TabContent phase="submit" phaseDetailsMap={phaseDetailsMap} />
              </TabList.Tab>
            ) : null}
            {status.showReviewing ? (
              <TabList.Tab id="review" controls="review-tab">
                <TabContent phase="review" phaseDetailsMap={phaseDetailsMap} />
              </TabList.Tab>
            ) : null}
            {status.showFeedback ? (
              <TabList.Tab id="feedback" controls="feedback-tab">
                <TabContent phase="feedback" phaseDetailsMap={phaseDetailsMap} />
              </TabList.Tab>
            ) : null}
            {status.showEvaluations ? (
              <TabList.Tab id="evaluate" controls="evaluate-tab">
                <TabContent phase="evaluate" phaseDetailsMap={phaseDetailsMap} />
              </TabList.Tab>
            ) : null}
          </>
        }
      >
        {status.showSubmission ? (
          <TabList.TabPanel id="submit-tab" labeledBy="submit">
            <SubmitMenu {...sharedProps} />
          </TabList.TabPanel>
        ) : null}
        {status.showReviewing ? (
          <TabList.TabPanel id="review-tab" labeledBy="review">
            <ReviewMenu {...sharedProps} />
          </TabList.TabPanel>
        ) : null}
        {status.showFeedback ? (
          <TabList.TabPanel id="feedback-tab" labeledBy="feedback">
            <FeedbackMenu {...sharedProps} />
          </TabList.TabPanel>
        ) : null}
        {status.showEvaluations ? (
          <TabList.TabPanel id="evaluate-tab" labeledBy="evaluate">
            <EvaluateMenu {...sharedProps} />
          </TabList.TabPanel>
        ) : null}
      </TabList>
    </div>
  );
}

function TabContent({
  phase,
  phaseDetailsMap,
}: {
  phase: PhaseCode;
  phaseDetailsMap: PhaseMenuDetailsMap;
}): JSX.Element {
  const details = phaseDetailsMap[phase];

  return (
    <div className="tab-content">
      {details ? (
        <>
          {details.locked ? <div className="status-icon locked">lock</div> : null}
          {details.active ? <div className="status-icon active">Active</div> : null}
          {!(details.locked || details.active) ? (
            details.complete ? (
              <div className="status-icon complete">done</div>
            ) : (
              <div className="status-icon incomplete">close</div>
            )
          ) : null}
        </>
      ) : null}

      <AssignmentPhaseIcon phase={phase} size={24} />
      <div>
        <div>{phase[0].toLocaleUpperCase() + phase.slice(1)}</div>
        <div className="timer">
          {details && details.active
            ? getTimeRemainingForDate(details?.deadline ?? '')
                .slice(0, 2)
                .join(', ')
            : null}
        </div>
      </div>
    </div>
  );
}

interface SubMenuProps {
  description: React.ReactNode;
  children: React.ReactNode;
}

function SubMenu({ description, children }: SubMenuProps): JSX.Element {
  return (
    <>
      <p className="description">{description}</p>
      <div className="content">{children}</div>
    </>
  );
}

interface StatusProps {
  phaseGoal: string;
  dueDate: string;
  tasksCompleted?: number;
  tasksToComplete?: number;
  complete?: boolean;
  iconCode: string;
}

function Status({ phaseGoal, dueDate, tasksCompleted, tasksToComplete, complete, iconCode }: StatusProps): JSX.Element {
  return (
    <div className="status-container">
      <p>{phaseGoal}</p>
      {tasksCompleted !== undefined && tasksToComplete !== undefined ? (
        <div className="progress">
          <ProgressRing
            label={`${tasksCompleted}/${tasksToComplete}`}
            radius={32}
            progress={(100 * tasksCompleted) / Math.max(tasksToComplete, 1)}
            strokeWidth={4}
            fontStyle={{ fontSize: '24px' }}
          />
          {tasksToComplete < 5 ? (
            <div className="complete-icons">
              {Array.from({ length: tasksToComplete }, (_, i) => i + 1).map((reviewNum) => (
                <Icon
                  key={`review-${reviewNum}`}
                  code={iconCode}
                  className={reviewNum <= tasksCompleted ? 'fill' : 'empty'}
                  ariaHidden
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
      {complete !== undefined ? (
        <div className="progress binary">
          <ProgressRing
            label={complete ? 'done' : 'hourglass_empty'}
            radius={32}
            progress={complete ? 100 : 0}
            strokeWidth={4}
            fontStyle={{ fontSize: '30px', color: complete ? '#38b934' : '#395061' }}
          />
        </div>
      ) : null}
      <p>
        <span>
          <b>Due:</b> {moment(dueDate).format('M/D/YY, h:mm A')}
        </span>
        {moment().isBefore(dueDate) ? (
          <span>
            <b>{getTimeRemainingForDate(dueDate).slice(0, 2).join(', ')} remaining</b>
          </span>
        ) : null}
      </p>
    </div>
  );
}

interface TaskListProps {
  children: React.ReactNode;
  phase: PhaseCode;
  status: AssignmentProgressStatus;
  locked?: boolean;
  unlockDate?: string;
}

function TaskList({ children, phase, status, locked, unlockDate }: TaskListProps): JSX.Element {
  return (
    <div className="task-container">
      {locked ? (
        <div className="locked-overlay">
          <Icon code="lock" label="Locked" />
          Unlocks {moment(unlockDate).format('M/D/YY, h:mm A')}
        </div>
      ) : (
        <>
          {phase === 'feedback' && status.missedReviewing ? (
            <div className="locked-overlay">Missed reviewing - Cannot provide feedback</div>
          ) : null}

          {phase === 'review' && status.missedReviewing ? <div className="locked-overlay">Missed reviewing</div> : null}

          {phase !== 'submit' && status.missedSubmitting ? (
            status.canLateSubmit ? (
              <div className="locked-overlay">Must submit late to continue</div>
            ) : (
              <div className="locked-overlay">Missed submission - Cannot continue assignment</div>
            )
          ) : null}

          {children}
        </>
      )}
    </div>
  );
}

interface TaskEntryProps {
  name: string;
  buttonText: string;
  complete?: boolean;
  incomplete?: boolean;
  disabled?: boolean;
  href?: string;
}

function TaskEntry({ name, buttonText, complete, incomplete, disabled, href }: TaskEntryProps): JSX.Element {
  const completionStatus = complete ? 'complete' : incomplete ? 'incomplete' : '';
  return (
    <div className={`task-entry ${completionStatus}`}>
      <div className="name">{name}</div>
      <Button variant={complete ? 'alt' : undefined} href={href} route={href !== undefined} disabled={disabled}>
        {buttonText}
      </Button>
    </div>
  );
}

PhaseMenu.SubMenu = SubMenu;
PhaseMenu.Status = Status;
PhaseMenu.TaskList = TaskList;
PhaseMenu.TaskEntry = TaskEntry;

export default PhaseMenu;
