import React, { useState, useRef, useEffect, useCallback } from 'react';
import SimpleBar from 'simplebar-react';
import { openModal, useModalContext } from '../../../../contexts/ModalContext';
import { Tag } from '../../../../types/types';
import { formDataToObjectParsed, handleKeySelect } from '../../../../utils/functions';
import { createTag, getAvailableTags } from '../../../../utils/requests';
import Icon from '../../display/Icon';

interface Props {
  id: string;
  defaultTags?: Tag[];
  onChange?: (arg0: Tag[]) => void;
}

function TagsInput({ id, defaultTags, onChange }: Props): JSX.Element {
  const [tags, setTags] = useState<Tag[]>(defaultTags ? defaultTags : []);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [inputFocus, setInputFocus] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const mainEl = useRef<HTMLDivElement>(null);
  const textInputEl = useRef<HTMLInputElement>(null);

  const { modalDispatch } = useModalContext();

  useEffect(() => getAvailableTags(setAvailableTags), []);

  const handleClick = (e: React.MouseEvent) => {
    const elem = e.target as HTMLElement;
    if (elem.tagName === 'BUTTON' && elem.classList.contains('remove-tag')) {
      const nextElem = elem.nextSibling as HTMLElement;
      if (nextElem) {
        const tag = nextElem.innerHTML;
        removeTag(tag);
      }
    } else if (elem === mainEl.current && textInputEl.current) {
      textInputEl.current.focus();
    }
  };

  const addTag = (tag: Tag) => setTags((prevTags) => [...prevTags.filter((t) => t.tagId !== tag.tagId), tag]);

  const removeTag = (tagId: string) => {
    setTags((prevTags) => {
      const newTags = [...prevTags];
      const index = newTags.findIndex((tag) => tag.tagId === tagId);
      newTags.splice(index, 1);
      return newTags;
    });
  };

  useEffect(() => {
    if (onChange) onChange(tags);
  }, [tags, onChange]);

  const openCreateTagModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'New Tag',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Create',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              content: string;
            };
            createTag(data.content, addTag);
          },
          children: (
            <>
              <label htmlFor={`create-for-${id}`} className="sr-only">
                Tag Name
              </label>
              <input id={`create-for-${id}`} type="text" name="content" placeholder="Tag Name" required />
            </>
          ),
        }),
      ),
    [id, modalDispatch],
  );

  const searching = searchTerm !== '';
  return (
    <div ref={mainEl} className="tags-input" onClick={handleClick}>
      {tags.map((tag, i) => (
        <div key={tag.tagId} id={`tag-${i}`} className="tag neumorphic-lite">
          <button className="remove-tag" type="button" aria-label={`Delete tag '${tag}'`}>
            <Icon code="close" />
          </button>
          <p aria-hidden>{tag.content}</p>
        </div>
      ))}
      <div className="input-wrapper">
        <input
          ref={textInputEl}
          id={id}
          type="text"
          placeholder="Add a tag..."
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          autoComplete="off"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          aria-autocomplete="list"
        />
        <div
          className={`search-results ${inputFocus ? undefined : 'sr-only'}`}
          role="listbox"
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          aria-label="Available Tags"
        >
          <SimpleBar className="option-scroller">
            <div
              className="option"
              role="option"
              tabIndex={0}
              onClick={openCreateTagModal}
              onKeyDown={(e) => handleKeySelect(e, openCreateTagModal)}
            >
              Create new tag +
            </div>
            {availableTags
              .filter((tag) => !searching || tag.content.indexOf(searchTerm) !== -1)
              .map((tag) => (
                <div
                  className="option"
                  key={tag.tagId}
                  role="option"
                  tabIndex={0}
                  onClick={() => addTag(tag)}
                  onKeyDown={(e) => handleKeySelect(e, () => addTag(tag))}
                >
                  {searching
                    ? tag.content.split(new RegExp('(' + searchTerm + ')', 'g')).map((str, i) => (
                        <span key={`${i}-${str}`} className={str === searchTerm ? 'highlight' : undefined}>
                          {str}
                        </span>
                      ))
                    : tag.content}
                </div>
              ))}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default TagsInput;
