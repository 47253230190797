import * as Sentry from '@sentry/react';

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === 'SET_USER') {
      return {
        ...action.payload,
        username: 'redacted',
        email: 'redacted',
        firstName: 'redacted',
        lastName: 'redacted',
        name: 'redacted',
        sortableName: 'redacted',
      };
    }

    return action;
  },
  stateTransformer: (state) => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      user: {
        ...state.user,
        username: 'redacted',
        email: 'redacted',
        firstName: 'redacted',
        lastName: 'redacted',
        name: 'redacted',
        sortableName: 'redacted',
      },
    };

    return transformedState;
  },
});
