import React from 'react';
import TabList from '../core/layout/TabList/TabList';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../store/selectors';
import GraderPermission from './permissions/GraderPermission';
import TAsPermission from './permissions/TAsPermission';

function CourseUserPermission(): JSX.Element {
  const course = useSelector(selectCourse);

  return (
    <div>
      <div className="course-card-banner-wrapper">
        <div className="course-card-long">
          {course ? (
            <section className="card-main-section">
              <p>
                <i>
                  {course.semester} {course.year}
                </i>
              </p>
              <h1>{course.courseName}</h1>
              <p>{course.discipline}</p>
              <p>
                <i>{course.label}</i>
              </p>
            </section>
          ) : null}
        </div>
      </div>
      <div className="permission-container">
        <TabList
          label="permission-tabs"
          tabs={
            <>
              <TabList.Tab id="TA" controls="TA-tab">
                TAs
              </TabList.Tab>
              <TabList.Tab id="grader" controls="grader-tab">
                Graders
              </TabList.Tab>
            </>
          }
        >
          <TabList.TabPanel id="TA-tab" labeledBy="TA">
            <TAsPermission />
          </TabList.TabPanel>
          <TabList.TabPanel id="grader-tab" labeledBy="grader">
            <GraderPermission />
          </TabList.TabPanel>
        </TabList>
      </div>
    </div>
  );
}

export default CourseUserPermission;
