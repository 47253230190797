import moment from 'moment';
import React from 'react';
import { SubmissionInfo } from '../../../types/types';
import { convertUTCDateToLocalDate } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';

interface Props {
  submissions: SubmissionInfo[];
}

function SubmissionHistory({ submissions }: Props): JSX.Element {
  //sort the submissions from latest to oldest
  submissions.sort(function (a, b) {
    return moment(b.updatedAt).isBefore(moment(a.updatedAt)) ? -1 : 1;
  });

  return (
    <div className="submission-history">
      <div className="panel-sm">
        <h2 className="title">Submission History</h2>
        <table className="nice-table">
          <thead>
            <tr>
              <th>Date Submitted</th>
              <th className="type-cell">Type</th>
              <th className="type-cell">Deleted</th>
              <th className="type-cell">Last Edited Time</th>
              <th className="type-cell">Download File</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <tr key={submission.submissionId}>
                <td>{convertUTCDateToLocalDate(submission.createdAt)}</td>
                <td className="type-cell">{submission.submissionType.toLowerCase()}</td>
                <td className="deleted-cell">{submission.deleted ? 'True' : 'False'}</td>
                <td>{convertUTCDateToLocalDate(submission.updatedAt)}</td>
                <td>
                  {submission.submissionType === 'FILE' ? (
                    <Button
                      variant="alt xs low"
                      href={submission.fileUrl as string}
                      download={submission.fileName as string}
                    >
                      Download
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SubmissionHistory;
