import React from 'react';

function FinishPage(): JSX.Element {
  return (
    <div className="sub-section">
      <p>{`Your assignment is ready. Click 'Finish' below to finalize your settings.`}</p>
      <p>{`You may return to any of the previous pages to review your assignment's settings before finalizing.`}</p>
      <p>{`After the assignment is created, you can continue to edit the settings.`}</p>
    </div>
  );
}

export default FinishPage;
