import React from 'react';
import Button from '../../core/button/Button/Button';
import ReviewUsersList from './ReviewUsersList';
import Icon from '../../core/display/Icon';
import { useParams } from 'react-router';
import { ReviewManagementMenuProps } from './ReviewManagement';
import Dropdown from '../../core/button/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../../store/selectors';
import { assignUniqueReviewsForGroupMembers } from '../../../utils/requests';

function AssignedReviewsMenu({
  changeToMenu,
  progress,
  reviewUsers,
  updateData,
}: ReviewManagementMenuProps): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const returnPath = `/course/${courseId}/assignment/${assignmentId}/dashboard`;

  const assignment = useSelector(selectAssignment);

  return (
    <div className="page" id="review-management">
      <div id="existing-groups">
        <h1>Reviewer Management</h1>
        <Button className="close-btn button-mini" classOverride href={returnPath} route>
          <Icon code="close" />
        </Button>
        <Dropdown
          className="peer-button  button-rad button-low"
          buttonContent="Options"
          ariaLabel="Options"
          iconCode="arrow_drop_down"
          align="left"
          top="100%"
          minWidth="200px"
        >
          {assignment?.groupsEnabled ? (
            <Dropdown.Link
              onClick={() => assignUniqueReviewsForGroupMembers(assignment.assignmentId ?? '', updateData)}
            >
              Assign Each Group Member to a Unique Submission
            </Dropdown.Link>
          ) : null}

          <Dropdown.Link onClick={() => changeToMenu('COPY')}>Copy Assigned Reviews From Assignment</Dropdown.Link>
          <Dropdown.Link onClick={() => changeToMenu('UPLOAD')}>Upload Assigned Reviews As CSV</Dropdown.Link>
          <Dropdown.Link onClick={() => changeToMenu('DEANONYMIZE')}>Assign De-anonymized Submissions</Dropdown.Link>
          <Dropdown.Link onClick={() => changeToMenu('DELETE_ALL')}>Delete All Reviews</Dropdown.Link>
        </Dropdown>

        <ReviewUsersList reviewUsers={reviewUsers} progress={progress} updateData={updateData} />
      </div>
    </div>
  );
}

export default AssignedReviewsMenu;
