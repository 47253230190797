import React from 'react';
import { CompositeDecorator, EditorState, Modifier, ContentState, ContentBlock } from 'draft-js';

const Link = ({
  entityKey,
  contentState,
  children,
}: {
  entityKey: string;
  contentState: ContentState;
  children: React.ReactNode;
}): JSX.Element => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

export const createLinkDecorator = (): CompositeDecorator =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

export const onAddLink = (
  editorState: EditorState,
  onChange: (arg0: EditorState) => void,
  linkUrl: string,
  linkText: string,
): void => {
  let selection = editorState.getSelection();

  const entityKey = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', {
      url: linkUrl,
    })
    .getLastCreatedEntityKey();

  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    linkText,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );
  let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');

  // insert a blank space after link
  selection = newEditorState.getSelection().merge({
    anchorOffset: selection.get('anchorOffset') + linkText.length,
    focusOffset: selection.get('anchorOffset') + linkText.length,
  });
  newEditorState = EditorState.acceptSelection(newEditorState, selection);
  contentState = Modifier.insertText(
    newEditorState.getCurrentContent(),
    selection,
    ' ',
    newEditorState.getCurrentInlineStyle(),
    undefined,
  );
  onChange(EditorState.push(newEditorState, contentState, 'insert-characters'));
};
