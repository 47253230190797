import { createContext, useContext } from 'react';
import { ModalProps } from '../components/core/display/Modal/Modal';
import { OPEN_MODAL, CLOSE_MODAL } from '../utils/constants';

type OpenAction = {
  type: string;
  payload: ModalProps;
};

type CloseAction = {
  type: string;
  payload: null;
};

type Context = {
  modal: ModalProps | null;
  modalDispatch: React.Dispatch<OpenAction | CloseAction>;
};

export const ModalContext = createContext<Context>({} as Context);

const initState = null;

export const modalReducer = (
  state: ModalProps | null = initState,
  action: OpenAction | CloseAction,
): ModalProps | null => {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload;
    case CLOSE_MODAL:
      return null;
    default:
      return state;
  }
};

export const useModalContext = (): Context => {
  return useContext(ModalContext);
};

export const openModal = (modal: ModalProps): OpenAction => {
  return {
    type: OPEN_MODAL,
    payload: modal,
  };
};

export const closeModal = (): CloseAction => {
  return {
    type: CLOSE_MODAL,
    payload: null,
  };
};
