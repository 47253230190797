import React from 'react';
import { PhaseCardProps } from './StudentDashboardOld';
import { ProgressStats } from '../../../types/types';
import moment from 'moment';
import Button from '../../core/button/Button/Button';
import PhaseCard from './PhaseCard';

interface ReflectionCardProps extends PhaseCardProps {
  userId: string;
  progressStats: ProgressStats;
}

function ReflectionCard({ assignment, rootPath, userProgress, progressStats }: ReflectionCardProps): JSX.Element {
  const { instructorGradedOnly } = assignment;
  const { status, instructorReviewReceived } = userProgress;
  const { reflectionPhase } = progressStats;
  const missedClass = !status.hasReflected ? ' fail' : '';
  const completeClass = status.hasReflected ? ' pass' : '';

  const getNoReviewsReceivedText = () => {
    if (
      userProgress.reviewsReceived === 0 &&
      (assignment.asyncEnabled ||
        (assignment.submissionDeadline !== null && moment().isAfter(assignment.submissionDeadline)))
    ) {
      if (status.hasSubmitted) {
        if (instructorGradedOnly) {
          return !instructorReviewReceived ? <p>Awaiting instructor review</p> : null;
        } else {
          return (
            <p>
              Reflect action unavailable <br /> (No reviews received)
            </p>
          );
        }
      } else {
        return (
          <p>
            Reflect action unavailable <br /> {status.canLateSubmit ? '(Submission required)' : '(Missed submission)'}
          </p>
        );
      }
    }
  };

  const getNoEvaluationsReceived = () => {
    if (
      userProgress.countEvaluationsReceived === 0 &&
      (assignment.asyncEnabled ||
        (assignment.peerEvaluationDeadline !== null && moment().isAfter(assignment.peerEvaluationDeadline)))
    ) {
      return (
        <p>
          Reflect action unavailable <br /> (No evaluations received)
        </p>
      );
    }
  };

  const getReflectionButtonAndText = () => {
    if ((!reflectionPhase || assignment.asyncEnabled) && userProgress.reflection && userProgress.reflection.complete) {
      return (
        <>
          <p className={`completed-text${missedClass}${completeClass}`}>Completed:</p>
          <Button variant="alt" disabled>
            Reflection Complete
          </Button>
        </>
      );
    }

    if (reflectionPhase && status.canReflect) {
      const reflection = userProgress.reflection;

      if (reflection) {
        if (reflection.complete) {
          return (
            <>
              <p className={`completed-text${missedClass}${completeClass}`}>Completed</p>
              <Button
                disabled={!reflectionPhase}
                variant="alt"
                href={`${rootPath}/reflection/${reflection.reflectionId}`}
                route
              >
                Edit Reflection
              </Button>
            </>
          );
        } else {
          return (
            <Button disabled={!reflectionPhase} href={`${rootPath}/reflection/${reflection.reflectionId}`} route>
              Continue Reflection
            </Button>
          );
        }
      } else {
        return (
          <Button disabled={!reflectionPhase} href={`${rootPath}/reflection/new`} route>
            Start Reflection
          </Button>
        );
      }
    }

    if (assignment.asyncEnabled && !status.canReflect && userProgress.reflection == null) {
      return (
        <p>
          Reflect action unavailable <br />
          until reviews are received
        </p>
      );
    }
  };

  const getMissedReflectionText = () => {
    if (userProgress.reviewsReceived > 0) {
      if (
        progressStats.complete &&
        (userProgress.reflection == null || (userProgress.reflection != null && !userProgress.reflection.complete))
      ) {
        return (
          <>
            <p>Missed Reflect Phase</p>
            <Button variant="alt" disabled>
              Incomplete Reflection
            </Button>
          </>
        );
      }
    }
  };

  return (
    <PhaseCard type="REFLECT" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      {assignment.peerEvaluationOnly ? getNoEvaluationsReceived() : getNoReviewsReceivedText()}
      {getReflectionButtonAndText()}
      {getMissedReflectionText()}
    </PhaseCard>
  );
}

export default ReflectionCard;
