import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubmissionInfo } from '../../../types/types';
import { getUserSubmissionHistory } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import ReviewSubmission from '../../core/display/Submission/ReviewSubmission';
import { StudentDetailsTabProps } from './StudentDetailsPage';
import SubmissionHistory from './SubmissionHistory';
import { storageAvailable } from '../../../utils/functions';

function SubmissionDetails({ assignmentProgress, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { courseId, assignmentId, userId } = useParams() as { courseId: string; assignmentId: string; userId: string };

  const [submissionHistory, setSubmissionHistory] = useState<SubmissionInfo[] | null>(null);

  useEffect(() => {
    getUserSubmissionHistory(assignmentId, userId, setSubmissionHistory);
  }, [updateKey, assignmentId, userId]);

  const setLocalStorage = () => {
    if (storageAvailable('localStorage')) window.localStorage.setItem('submitterId', assignmentProgress.user.userId);
  };

  if (assignmentProgress.submissionInfo)
    return (
      <>
        {submissionHistory && submissionHistory.length > 0 ? (
          <SubmissionHistory submissions={submissionHistory} />
        ) : null}
        <ReviewSubmission submitter={assignmentProgress.user.name} submissionInfo={assignmentProgress.submissionInfo} />
      </>
    );
  return (
    <div className="panel-sm panel-white no-progress-message">
      <h2 className="title">No Progress Yet</h2>
      <div className="submission-for-student">
        <Button href={`/course/${courseId}/assignment/${assignmentId}/submission`} route onClick={setLocalStorage}>
          Submit on behalf of {assignmentProgress.user.name}
        </Button>
      </div>
    </div>
  );
}

export default SubmissionDetails;
