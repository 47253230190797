import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { selectCourse } from '../../store/selectors';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import AssignmentChooseWorkflow from './AssignmentChooseWorkflow';
import AssignmentCopyList from './AssignmentCopyList';
import AssignmentInitialSetup from './AssignmentInitialSetup';
import AssignmentMenu from './AssignmentMenu';
import AssignmentWizardController from './AssignmentWizardController';

function AssignmentSetupController(): JSX.Element {
  const course = useSelector(selectCourse);

  if (course)
    return (
      <Routes>
        <Route path={`/menu`} element={<AssignmentMenu />} />
        <Route path={`/workflows`} element={<AssignmentChooseWorkflow course={course} />} />
        <Route path={`/start`} element={<AssignmentInitialSetup course={course} />} />
        <Route path={`/copy-list`} element={<AssignmentCopyList />} />
        <Route path={`/wizard/:type/:assignmentId?`} element={<AssignmentWizardController />} />
      </Routes>
    );
  return <LoadingSpinner />;
}

export default AssignmentSetupController;
