import React, { useState, useMemo, useCallback, ChangeEvent, useEffect } from 'react';
import { InstitutionStats } from '../../types/types';
import { getInstitutionStats } from '../../utils/requests';
import moment from 'moment';
import Table from '../core/display/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

function InstitutionStatistics(): JSX.Element {
  const [startDate, setStartDate] = useState<null | string>(null);
  const [endDate, setEndDate] = useState<null | string>(null);
  const [institutionStatsList, setInstitutionStatsList] = useState<InstitutionStats[]>([]);

  const handleStartDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  const handleEndDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEndDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  useEffect(() => {
    let searchFromDateTime = startDate ? startDate : '2000-01-01 00:00:00';
    let searchToDateTime = endDate ? endDate : '2050-12-30 00:00:00';
    getInstitutionStats(searchFromDateTime, searchToDateTime, setInstitutionStatsList);
  }, [startDate, endDate]);

  return (
    <div id="admin-statics" className="page">
      <div id="course-search">
        <div>
          <label id="startDateText">Start Date:</label>
          <input
            className="startDate"
            type="date"
            value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
            onChange={handleStartDateChange}
          />

          <label id="endDateText">End Date:</label>
          <input
            className="endDate"
            type="date"
            value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <div id="stat-table">
        <InstitutionStatsTable institutionStatsList={institutionStatsList} />
      </div>
    </div>
  );
}

interface CourseTableProps {
  institutionStatsList: InstitutionStats[];
}

function InstitutionStatsTable({ institutionStatsList }: CourseTableProps): JSX.Element {
  const tableColumns = useMemo<ColumnDef<InstitutionStats>[]>(
    () => [
      { header: 'Institution', accessorKey: 'institution', meta:{className: 'left-align' }},
      { header: 'SubmissionCount', accessorKey: 'submissionCount', meta:{className: 'left-align' }},
      { header: 'ReviewCount', accessorKey: 'reviewCount', meta:{className: 'left-align' }},
      { header: 'FeedbackCount', accessorKey: 'feedbackCount', meta:{className: 'left-align' }},
      { header: 'EvalCount', accessorKey: 'evalCount', meta:{className: 'left-align' }},
      { header: 'ReflectionCount', accessorKey: 'reflectionCount', meta:{className: 'left-align' }},
    ],
    [],
  );

  return (
    <Table
      columns={tableColumns}
      data={institutionStatsList}
      sortBy="courseName"
      id="admin-institution-stats-table"
      noWrapper
      title="Institution Statistics"
    />
  );
}

export default InstitutionStatistics;
