import React, { useMemo, useState } from 'react';
import { AverageRating, PageQuery, PageQueryParams } from '../../types/types';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';
import { ErrorObj, getCourseTagAverages } from '../../utils/requests';

interface Props {
  courseId: string;
}

function IndividualCourseTags({ courseId }: Props): JSX.Element {
  const [updateKey, setUpdateKey] = useState(0);
  const queryRequest = useMemo(() => {
    if (courseId !== null)
      return (
        pageQueryParams: PageQueryParams,
        successCb: (arg0: PageQuery<AverageRating>) => void
      ) => getCourseTagAverages(courseId, pageQueryParams, successCb);
    return () => undefined;
  }, [courseId]);

  const columns = useMemo<Column<AverageRating>[]>(
    () => [
      {
        Header: 'Tag',
        accessor: 'tag',
        Cell: (avg: AverageRating) => avg.tag?.content,
        className: 'name',
        customSort: 'content',
      },
      {
        Header: 'Average Mastery',
        accessor: 'averageRating',
        Cell: (avg: AverageRating) => `${Math.round(avg.averageRating)}%`,
        Style: (avg: AverageRating) => {
          let barColor = '#2e74a3';
          if (avg.averageRating >= 80) barColor = '#298547';
          else if (avg.averageRating < 60) barColor = '#A32E2E';
          return {
            backgroundImage: `linear-gradient(to right, ${barColor} ${avg.averageRating}%, rgba(255, 255, 255, 0) ${avg.averageRating}%)`,
          };
        },
        className: 'score',
        notSortable: true,
      },
    ],
    [],
  );
  return (
    <>
      <h1 className="sr-only">Tags</h1>
      <QueryTable
        columns={columns}
        queryRequest={queryRequest}
        updateKey={updateKey}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsWrapperClassName: 'col-2',
          tableClassName: 'home-body bar-chart',
        }}
        title="Tags"
      />
    </>
  );
}

export default IndividualCourseTags;
