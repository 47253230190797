import moment from 'moment';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Tag } from '../../types/types';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';

interface Props {
  onEdit?: (rating: Tag) => void;
  tag: Tag;
}

function TagDisplay({ onEdit, tag }: Props): JSX.Element {
  const handleEdit = useCallback(() => (onEdit ? onEdit(tag) : undefined), [onEdit, tag]);

  const numPrompts = tag.linkedComments.length + tag.linkedRatings.length;

  return (
    <div className="prompt-display">
      <Icon className="icon" code="local_offer" style={{ backgroundColor: '#9C9C9C' }} ariaHidden />
      <div className="ctrls">
        {onEdit ? (
          <Button className="edit-btn" classOverride onClick={handleEdit}>
            <Icon code="edit" ariaHidden />
            <span>edit</span>
          </Button>
        ) : null}
      </div>
      <h2 className="prompt-name">
        <span className="title">{tag.content}</span>
        <span className="badge" style={{ boxShadow: `0 0 0 2px #9C9C9C` }}>
          Tag
        </span>
      </h2>
      <p>
        Created by <b>{tag.peerceptivCreated ? 'Peerceptiv' : tag.user.name}</b> on{' '}
        <b>{moment(tag.createdAt).format('MMM D, YYYY')}</b>
      </p>
      {numPrompts > 0 ? (
        <>
          <p>
            Attached to <b>{numPrompts}</b> prompts:
          </p>
          <p>
            {tag.linkedComments.map((comment, i) => (
              <React.Fragment key={comment.commentId}>
                <Link to={`/rubrics/comment/${comment.commentId}`}>{comment.commentName}</Link>
                {i < tag.linkedComments.length - 1 ? `, ` : null}
              </React.Fragment>
            ))}
            {tag.linkedRatings.map((rating, i) => (
              <React.Fragment key={rating.ratingId}>
                <Link to={`/rubrics/rating/${rating.ratingId}`}>{rating.name}</Link>
                {i < tag.linkedRatings.length - 1 ? `, ` : null}
              </React.Fragment>
            ))}
          </p>
        </>
      ) : (
        <p>No prompts are attached to this tag</p>
      )}
    </div>
  );
}

export default TagDisplay;
