import React, { useCallback, useEffect, useState } from 'react';
import { RubricTemplate, SharedRubric } from '../../types/types';
import { shareRubricTemplate, unshareRubricTemplate } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import AlertBar from '../core/display/AlertBar';
import Avatar from '../core/display/Avatar/Avatar';

interface Props {
  rubricTemplate: RubricTemplate;
  updateData: () => void;
}

function ShareRubricTemplateMenu({ rubricTemplate, updateData }: Props): JSX.Element {
  const [sharedRubrics, setSharedRubrics] = useState<SharedRubric[]>(rubricTemplate.sharing);
  const [email, setEmail] = useState('');
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [emailAlreadyShared, setEmailAlreadyShared] = useState(false);

  useEffect(() => {
    setEmailNotFound(false);
    setEmailAlreadyShared(false);
  }, [email]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const emailAlreadyShared = sharedRubrics.filter((sharedRubric) => sharedRubric.user.email === email).length > 0;

      if (emailAlreadyShared) setEmailAlreadyShared(true);
      else {
        setEmailNotFound(false);
        shareRubricTemplate(
          rubricTemplate.rubricTemplateId,
          email,
          (newRubricTemplate: RubricTemplate) => {
            setSharedRubrics(newRubricTemplate.sharing);
            updateData();
            setEmail('');
            setEmailNotFound(false);
          },
          (error) => {
            if (error.response?.status === 404) {
              setEmailNotFound(true);
              return true;
            }
            return false;
          },
        );
      }
    },
    [sharedRubrics, rubricTemplate.rubricTemplateId, email, updateData],
  );

  return (
    <div id="sharing-menu">
      <p>
        Rubric Template: <b>{rubricTemplate.name}</b>
      </p>

      {emailNotFound ? <AlertBar>Email not found. Please try again.</AlertBar> : null}
      {emailAlreadyShared ? <AlertBar>Already shared with this email.</AlertBar> : null}

      <form onSubmit={handleSubmit}>
        <label htmlFor="email" className="sr-only">
          Email of user to share purchase
        </label>
        <input
          id="email"
          type="email"
          placeholder="Enter user via email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="rad">Share</Button>
      </form>

      {sharedRubrics.length > 0 ? (
        <>
          <h2>Shared with:</h2>
          <table>
            <thead className="sr-only">
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {sharedRubrics.map((sharedRubric) => (
                <tr key={sharedRubric.sharedRubricId}>
                  <td>
                    <Avatar user={sharedRubric.user} />
                  </td>
                  <td>
                    <b>{sharedRubric.user.name}</b>
                  </td>
                  <td>
                    <Button
                      variant="alt sm low"
                      onClick={() =>
                        unshareRubricTemplate(
                          rubricTemplate.rubricTemplateId,
                          sharedRubric.sharedRubricId,
                          (newRubric) => {
                            setSharedRubrics(newRubric.sharing);
                            updateData();
                          },
                        )
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
}

export default ShareRubricTemplateMenu;
