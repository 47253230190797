import React, { useMemo } from 'react';
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { animated, useTransition, to } from '@react-spring/web';

// data and types
export type PieDatum = { label: string; usage: number; color: string };

// accessor functions
const usage = (d: PieDatum) => d.usage;

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

export type Props = {
  data: PieDatum[];
  donutThickness?: number;
  width: number;
  height: number;
  labelMain?: string;
  labelSub?: string;
  margin?: typeof defaultMargin;
};

function DonutChart({
  data,
  donutThickness = 50,
  width,
  height,
  labelMain,
  labelSub,
  margin = defaultMargin,
}: Props): JSX.Element {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  // color scales
  const getColor = useMemo(
    () =>
      scaleOrdinal({
        domain: data.map((d) => d.label),
        range: data.map((d) => d.color),
      }),
    [data],
  );

  return (
    <svg width={width} height={height}>
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={data}
          pieValue={usage}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={2}
          padAngle={0.02}
        >
          {(pie) => (
            <AnimatedPie<PieDatum>
              {...pie}
              getKey={(arc) => arc.data.label}
              getColor={(arc) => getColor(arc.data.label)}
            />
          )}
        </Pie>
        {labelMain ? (
          <text
            fill="#2D4B62"
            dy={`${height / 800}em`}
            fontSize={height / 4}
            fontWeight="bold"
            textAnchor="middle"
            pointerEvents="none"
          >
            {labelMain}
          </text>
        ) : null}
        {labelSub ? (
          <text
            fill="#8394A1"
            dy={`${height / 60}em`}
            fontSize={12}
            fontWeight="bold"
            textAnchor="middle"
            pointerEvents="none"
          >
            {labelSub}
          </text>
        ) : null}
      </Group>
    </svg>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  delay?: number;
};

function AnimatedPie<Datum>({ arcs, path, getKey, getColor }: AnimatedPieProps<Datum>) {
  const fromLeaveTransition = ({ endAngle }: PieArcDatum<Datum>) => ({
    // enter from 360° if end angle is > 180°
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
  });
  const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<Datum>) => ({
    startAngle,
    endAngle,
    opacity: 1,
  });
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: fromLeaveTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: fromLeaveTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => (
    <g key={key}>
      <animated.path
        // compute interpolated path d attribute from intermediate angle values
        d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
          path({
            ...arc,
            startAngle,
            endAngle,
          }),
        )}
        fill={getColor(arc)}
      />
    </g>
  ));
}

export default DonutChart;
