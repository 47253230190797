import React from 'react';
import moment from 'moment';
import Button from '../../core/button/Button/Button';
import PhaseCard from './PhaseCard';
import { PhaseCardProps } from './StudentDashboardOld';

function SubmitCard({ assignment, rootPath, userProgress }: PhaseCardProps): JSX.Element {
  const { status, submissionInfo } = userProgress;

  const getSubmitStatusText = () => {
    if (!status.canSubmit && status.hasSubmitted) return 'Submitted';
    if (status.canSubmit && status.hasSubmitted) return 'Resubmit';
    if (status.missedSubmitting && !status.canLateSubmit) return 'Missed Submission';
    if (status.missedSubmitting && status.canLateSubmit) return 'Submit Late';
    if (assignment.groupsEnabled) return 'Group Submit';
    return 'Submit';
  };

  const getSubmissionTime = () => {
    if (status.hasSubmitted && userProgress.submissionInfo)
      return <p>Submitted: {moment.utc(userProgress.submissionInfo.createdAt).local().format('M/D/YY, h:mm:ss A')}</p>;
    return null;
  };

  const submitLinkDisabled =
    status.hasSubmitted ||
    (!status.canSubmit && !status.canLateSubmit) ||
    (assignment.groupsEnabled && !status.inGroup);

  return (
    <PhaseCard type="SUBMIT" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      <Button
        variant={submitLinkDisabled ? 'alt' : undefined}
        disabled={submitLinkDisabled}
        href={
          !submitLinkDisabled
            ? submissionInfo
              ? `${rootPath}/submission/${submissionInfo.submissionId}`
              : `${rootPath}/submission`
            : undefined
        }
        route
      >
        {getSubmitStatusText()}
      </Button>
      {status.hasSubmitted && submissionInfo && !submissionInfo.presentation ? (
        <Button variant="alt" href={`${rootPath}/submission/${submissionInfo.submissionId}`} route>
          View Submission
        </Button>
      ) : null}
      {getSubmissionTime()}
    </PhaseCard>
  );
}

export default SubmitCard;
