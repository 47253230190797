import React from 'react';
import Icon from '../../display/Icon';

interface Props {
  currPage: number;
  pageCount: number;
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (arg0: number) => void;
  uniqueLabel?: string;
}

function Pagination({ currPage, pageCount, nextPage, prevPage, goToPage, uniqueLabel }: Props): JSX.Element {
  const canPrevPage = currPage > 0;
  const canNextPage = currPage < pageCount - 1;

  const proximalPages = [currPage - 1, currPage, currPage + 1];

  const paginationStruct = {
    ellipsisLeft: [] as number[],
    proximals: [] as number[],
    ellipsisRight: [] as number[],
  };

  for (let i = 1; i < pageCount - 1; i++) {
    if (!proximalPages.includes(i)) {
      if (paginationStruct.proximals.length < 1) paginationStruct.ellipsisLeft.push(i);
      else paginationStruct.ellipsisRight.push(i);
    } else {
      paginationStruct.proximals.push(i);
    }
  }

  if (paginationStruct.ellipsisLeft.length === 1) {
    const lonerNum = paginationStruct.ellipsisLeft.pop();
    if (lonerNum) paginationStruct.proximals.push(lonerNum);
  }
  if (paginationStruct.ellipsisRight.length === 1) {
    const lonerNum = paginationStruct.ellipsisRight.pop();
    if (lonerNum) paginationStruct.proximals.push(lonerNum);
  }
  paginationStruct.proximals.sort((a, b) => {
    return a - b;
  });

  const buttons = [];

  const createButton = (btnNum: number) => {
    const isCurrent = currPage === btnNum;
    return (
      <button
        key={`btn-${btnNum}`}
        className={btnNum === currPage ? 'selected' : undefined}
        onClick={!isCurrent ? () => goToPage(btnNum) : undefined}
        aria-label={`Page ${btnNum + 1}`}
        tabIndex={0}
        aria-current={isCurrent}
        aria-disabled={isCurrent}
      >
        {btnNum + 1}
      </button>
    );
  };

  buttons.push(
    <button
      key={`prevBtn`}
      onClick={canPrevPage ? prevPage : undefined}
      aria-disabled={!canPrevPage}
      aria-label="Previous Page"
    >
      <Icon code="chevron_left" ariaHidden />
    </button>,
  );

  buttons.push(createButton(0));

  if (paginationStruct.ellipsisLeft.length > 0) {
    const { ellipsisLeft } = paginationStruct;
    buttons.push(
      <select
        key="ellipsisBtnLeft"
        value="..."
        onChange={(e) => goToPage(Number(e.target.value))}
        aria-label={`Select pages ${ellipsisLeft[0] + 1} through ${ellipsisLeft[ellipsisLeft.length - 1] + 1}`}
      >
        <option value="..." disabled>
          ...
        </option>
        {paginationStruct.ellipsisLeft.map((pageNum) => (
          <option key={`select-${pageNum}`} value={pageNum} aria-label={`Page ${pageNum + 1}`}>
            {pageNum + 1}
          </option>
        ))}
      </select>,
    );
  }

  paginationStruct.proximals.map((pageNum) => buttons.push(createButton(pageNum)));

  if (paginationStruct.ellipsisRight.length > 0) {
    const { ellipsisRight } = paginationStruct;
    buttons.push(
      <select
        key="ellipsisBtnRight"
        value="..."
        onChange={(e) => goToPage(Number(e.target.value))}
        aria-label={`Select pages ${ellipsisRight[0] + 1} through ${ellipsisRight[ellipsisRight.length - 1] + 1}`}
      >
        <option value="..." disabled>
          ...
        </option>
        {ellipsisRight.map((pageNum) => (
          <option key={`select-${pageNum}`} value={pageNum} aria-label={`Page ${pageNum + 1}`}>
            {pageNum + 1}
          </option>
        ))}
      </select>,
    );
  }

  if (pageCount > 1) buttons.push(createButton(pageCount - 1));

  buttons.push(
    <button
      key={`nextBtn`}
      onClick={canNextPage ? nextPage : undefined}
      aria-disabled={!canNextPage}
      aria-label="Next Page"
    >
      <Icon code="chevron_right" ariaHidden />
    </button>,
  );

  return (
    <nav
      role="navigation"
      className="peer-pagination"
      aria-label={`Pagination${uniqueLabel ? ' for ' + uniqueLabel : ''}`}
    >
      <ul>
        {buttons.map((button, i) => (
          <li key={`li-${i}`}>{button}</li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
