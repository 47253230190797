import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import AlertBar from '../core/display/AlertBar';
import AssignmentPhaseIcon from '../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import TutorialModal from './TutorialModal';

interface Props {
  buttonMode?: boolean;
  buttonText?: string;
}

function StudentAssignmentTutorial({ buttonMode = false, buttonText = '' }: Props): JSX.Element {
  const user = useSelector((state: RootState) => state.user);
  const userId = user.actingAs ? 'null' : user.userId;
  const courseId = user.actingAs ? 'null' : user.courseRoleCourseId;

  return (
    <TutorialModal
      id={`student-assignment-tutorial-for-${userId}-in-${courseId}`}
      heading="Peerceptiv Assignments"
      checkToDismiss={!buttonMode}
      buttonMode={buttonMode}
      buttonText={buttonText}
    >
      <div id="student-assignment-tutorial">
        <p>
          Peerceptiv assignments follow a research-based workflow designed to improve your submission and build
          collaboration skills. On most assignments you&apos;ll participate in three steps:
        </p>
        <ol>
          <li>
            <AssignmentPhaseIcon phase="submit" />
            Upload your submission
          </li>
          <li>
            <AssignmentPhaseIcon phase="review" />
            Review your peers&apos; submissions
          </li>
          <li>
            <AssignmentPhaseIcon phase="feedback" />
            Provide Feedback to the peers who reviewed your work
          </li>
        </ol>
        <AlertBar backgroundColor="#ffefd2">
          Your overall grade is based on completing all of your required steps thoughtfully and accurately.
        </AlertBar>
      </div>
    </TutorialModal>
  );
}

export default StudentAssignmentTutorial;
