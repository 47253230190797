import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  createCourse,
  updateCourse,
  copyCourse,
  createCourseTemplate,
  getContractForUser,
  getPotentialCoursesToShareSeatsWith,
  shareSeatWithCourse,
} from '../../utils/requests';
import { deepLinkCallback, formDataToObject } from '../../utils/functions';
import CourseDashboard from './CourseDashboard';
import CourseForm from './CourseForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CopyCourseList from './CopyCourseList';
import NewCourseMenu from './NewCourseMenu';
import MiniNav from '../core/layout/MiniNav/MiniNav';
import CourseTemplates from './CourseTemplates';
import DashboardStatsBar from '../core/layout/DashboardStatsBar/DashboardStatsBar';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import CourseCard from './CourseCard';
import { Contract, Course } from '../../types/types';

interface Props {
  courseId?: string;
  courseName?: string;
  discipline?: string;
  university?: string;
  timeZone?: string;
  label?: string | null;
  studentPurchaseEnabled?: 'true' | 'false';
  asyncEnabled?: 'true' | 'false';
  noSeats?: boolean;
  path: '/course/dashboard/*' | '/course/templates/*';
  contractId?: string;
}

function CourseLibrary({
  courseId,
  courseName,
  discipline,
  university,
  timeZone,
  label,
  studentPurchaseEnabled,
  asyncEnabled,
  noSeats,
  path,
  contractId,
}: Props): JSX.Element {
  const [updateKey, setUpdateKey] = useState(0);

  const user = useSelector((state: RootState) => state.user);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();
  const [courseToShareSeatsWith, setCourseToShareSeatWith] = useState<Course[]>([]);
  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  useEffect(() => {
    if (user.role !== 'STUDENT') {
      getContractForUser(setContracts);
    }
    if (user.role === 'TEACHER') {
      getPotentialCoursesToShareSeatsWith(user.userId, setCourseToShareSeatWith);
    }
  }, [updateKey, user.role, user.userId]);

  const openShareSeatModal = useCallback(
    (course: Course) => {
      modalDispatch(
        openModal({
          heading: 'Seat Sharing',
          label: `You are added to ${course.courseName} as an instructor. Do you want to share your seats with this course?`,
          closeButton: true,
          buttonText: 'Share',
          onConfirm: () =>
            shareSeatWithCourse(course.courseId ? course.courseId : '', () => {
              modalDispatch(
                openModal({
                  heading: 'Share Seats',
                  label: `You have successfully share your seats with ${course.courseName}.`,
                  inputType: 'none',
                  buttonText: 'Continue',
                  cancelHide: true,
                }),
              );
            }),
        }),
      );
    },
    [modalDispatch],
  );

  useEffect(() => {
    if (courseToShareSeatsWith.length > 0) {
      for (let i = 0; i < courseToShareSeatsWith.length; i++) {
        if (courseToShareSeatsWith[i].courseId != null) {
          openShareSeatModal(courseToShareSeatsWith[i]);
        }
      }
    }
  }, [courseToShareSeatsWith, openShareSeatModal]);

  const openNewCourseModal = useCallback(
    (course: Course) =>
      modalDispatch(
        openModal({
          heading: 'Course Created',
          cancelHide: true,
          closeButton: true,
          buttonText: 'Open Course',
          onConfirm: () => navigate(`/course/${course.courseId}/assignments`),
          children: (
            <>
              <p>Successfully created the following course:</p>
              <CourseCard
                name={course.courseName}
                discipline={course.discipline}
                semester={course.semester}
                year={course.year}
                label={course.label}
                readOnly
              />
            </>
          ),
        }),
      ),
    [modalDispatch, navigate],
  );

  const handleNewCourse = useCallback(
    (formData: FormData, callback: () => void) =>
      createCourse(
        formDataToObject(formData),
        (course) => {
          updateData();
          navigate('/course/dashboard');
          requestAnimationFrame(() => openNewCourseModal(course));
        },
        callback,
      ),
    [updateData, navigate, openNewCourseModal],
  );

  const handleNewCourseTemplate = useCallback(
    (formData: FormData, callback: () => void) =>
      createCourseTemplate(
        { ...formDataToObject(formData), template: true },
        () => {
          updateData();
          navigate('/course/templates');
        },
        callback,
      ),
    [updateData, navigate],
  );

  const handleEditCourse = useCallback(
    (formData: FormData) => {
      if (courseId)
        updateCourse(courseId, formDataToObject(formData), () => {
          updateData();
          navigate('/course/dashboard');
        });
    },
    [courseId, updateData, navigate],
  );

  const handleEditCourseTemplate = useCallback(
    (formData: FormData) => {
      if (courseId)
        updateCourse(courseId, { ...formDataToObject(formData), template: true }, () => {
          updateData();
          navigate('/course/templates');
        });
    },
    [courseId, updateData, navigate],
  );

  const handleCopyCourse = useCallback(
    (formData: FormData, callback: () => void) =>
      courseId
        ? copyCourse(
            courseId,
            formDataToObject(formData),
            (data) => {
              updateData();
              deepLinkCallback(data);
              navigate('/course/dashboard');
              requestAnimationFrame(() => openNewCourseModal(data));
            },
            callback,
          )
        : undefined,
    [courseId, updateData, navigate, openNewCourseModal],
  );

  const initProps = {
    initCourseName: courseName,
    initDiscipline: discipline,
    initUniversity: university,
    initTimeZone: timeZone,
    initLabel: label ? label : undefined,
    initStudentPurchaseEnabled: studentPurchaseEnabled,
    initAsyncEnabled: asyncEnabled,
    initContracts: contracts,
    initContractId: contractId,
  };

  return (
    <div className="page home-page" id="course-page">
      {user.admin || user.schoolAdmin || user.role === 'TEACHER' ? (
        <Routes>
          <Route
            index
            element={
              <>
                {user.admin || user.schoolAdmin ? <DashboardStatsBar type="COURSE" /> : null}
                <MiniNav id="courses-nav">
                  <MiniNav.Link to="/course/dashboard">Courses</MiniNav.Link>
                  <MiniNav.Link to="/course/templates">Templates</MiniNav.Link>
                </MiniNav>
              </>
            }
          />
        </Routes>
      ) : null}

      {path === '/course/dashboard/*' ? (
        <Routes>
          <Route
            index
            element={<CourseDashboard updateData={updateData} noSeats={noSeats} user={user} updateKey={updateKey} />}
          />
          <Route
            path="/new"
            element={<CourseForm type="NEW" title="New Course" {...initProps} onSubmit={handleNewCourse} />}
          />
          <Route
            path="/edit"
            element={<CourseForm type="EDIT" title="Edit Course" {...initProps} onSubmit={handleEditCourse} />}
          />
          <Route
            path="/copy"
            element={<CourseForm type="COPY" title="Copy Course" {...initProps} onSubmit={handleCopyCourse} />}
          />
          <Route path="/course-copy-list" element={<CopyCourseList />} />
          <Route path="/new-course-menu" element={<NewCourseMenu />} />
        </Routes>
      ) : null}

      {path === '/course/templates/*' ? (
        <Routes>
          <Route index element={<CourseTemplates />} />
          <Route
            path="/new"
            element={<CourseForm type="NEW" title="New Course Template" onSubmit={handleNewCourseTemplate} />}
          />
          <Route
            path="/edit"
            element={
              <CourseForm type="EDIT" title="Edit Course Template" {...initProps} onSubmit={handleEditCourseTemplate} />
            }
          />
          <Route path="/course-copy-list" element={<CopyCourseList templateMode />} />
        </Routes>
      ) : null}
    </div>
  );
}

export default CourseLibrary;
