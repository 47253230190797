import React, { useEffect, useState } from 'react';

interface Props {
  defaultValue?: number;
  legendText: string;
  numStars: number;
  onChange?: (score: number) => void;
  ratingDescriptions?: string[];
  required?: boolean;
}

function StarRatingInput({
  defaultValue,
  legendText,
  numStars,
  onChange = () => undefined,
  ratingDescriptions,
  required = false,
}: Props): JSX.Element {
  const [rating, setRating] = useState(defaultValue ?? 0);
  const [hoveredRating, setHoveredRating] = useState(0);

  useEffect(() => {
    if (rating > 0) onChange(rating);
  }, [onChange, rating]);

  const ratingDescriptionIndex = hoveredRating > 0 ? hoveredRating : rating > 0 ? rating : -1;
  const currDescription =
    ratingDescriptions && ratingDescriptions.length === numStars && ratingDescriptionIndex > 0
      ? ratingDescriptions[ratingDescriptionIndex - 1]
      : null;
  return (
    <fieldset className="star-rating-input" onChange={(e) => setRating(parseInt((e.target as HTMLInputElement).value))}>
      <legend>{legendText}</legend>
      <div className="row-container">
        <span className="fraction" style={rating === 0 ? { opacity: 0.3 } : undefined}>
          {rating > 0 ? rating : '—'}/{numStars}
        </span>
        <div className="stars-wrapper">
          {[...Array(numStars).keys()].map((i) => {
            const value = i + 1;
            return (
              <div
                className={`star ${(i < rating && hoveredRating === 0) || hoveredRating === value ? 'filled' : ''} ${
                  i < hoveredRating ? 'hover-filled' : ''
                }`}
                key={`star-input-${i}`}
              >
                <input
                  type="radio"
                  name="rating"
                  value={value}
                  defaultChecked={rating === value}
                  id={`star-${i}`}
                  required={required}
                  onMouseOver={(e) => setHoveredRating(parseInt((e.target as HTMLInputElement).value))}
                  onFocus={(e) => setHoveredRating(parseInt((e.target as HTMLInputElement).value))}
                  onMouseOut={() => setHoveredRating(0)}
                  onBlur={() => setHoveredRating(0)}
                />
                <label htmlFor={`star-${i}`}>
                  <span className="sr-only">
                    {value} star. Description: &quot;{currDescription}&quot;
                  </span>
                </label>
              </div>
            );
          })}
        </div>
        {currDescription ? <p>{currDescription}</p> : null}
      </div>
    </fieldset>
  );
}

export default StarRatingInput;
