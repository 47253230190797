import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { User } from '../../types/types';
import { addUsersToGroup, getUngroupedUsers } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Avatar from '../core/display/Avatar/Avatar';
import Icon from '../core/display/Icon';
import { openModal, useModalContext } from '../../contexts/ModalContext';

interface Props {
  assignmentId: string;
  circles?: boolean;
}

function TeacherGroupsMemberAdd({ assignmentId, circles }: Props): JSX.Element {
  const { groupId } = useParams() as { groupId: string };

  const groupType = circles ? 'Circle' : 'Group';
  const groupListType = circles ? 'circle-list' : 'group-list';

  const [usersSelected, setUsersSelected] = useState<User[]>([]);
  const [ungroupedUsers, setUngroupedUsers] = useState<User[]>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const basePath = useMemo(() => {
    const matches = location.pathname.match(circles ? /(\S+\/circles)/g : /(\S+\/groups)/g);
    return (matches || [''])[0];
  }, [location, circles]);

  useEffect(() => {
    getUngroupedUsers(assignmentId, setUngroupedUsers);
  }, [assignmentId]);

  const selectUser = useCallback((user: User) => {
    setUsersSelected((prevSelectedUsers) => {
      const newSelectedUsers = [...prevSelectedUsers, user];
      return newSelectedUsers;
    });
  }, []);

  const deselectUser = useCallback((user: User) => {
    setUsersSelected((prevSelectedUsers) => prevSelectedUsers.filter((currUser) => currUser.userId !== user.userId));
  }, []);

  const addUsers = useCallback(() => {
    const userIds = usersSelected.map((user) => user.userId);
    addUsersToGroup(
      assignmentId,
      groupId,
      userIds,
      () => {
        const queryParams = new URLSearchParams({
          defaultId: groupId,
        });
        navigate(`${basePath}/${groupListType}?${queryParams.toString()}`);
      },
      (error) => {
        if (error.response?.status === 400) {
          modalDispatch(
            openModal({
              heading: 'Cannot Add Students',
              label: 'This would exceed the group size limit. You can change this limit in the group permissions menu.',
              inputType: 'none',
              buttonText: 'Okay',
              cancelHide: true,
              padding: '3rem',
              maxWidth: '480px',
            }),
          );
          return true;
        }
        return false;
      },
    );
  }, [usersSelected, assignmentId, groupId, navigate, basePath, groupListType, modalDispatch]);

  return (
    <div id="add-members-interface">
      <h1>Add Members</h1>
      <p>Select students to add:</p>
      <div id="selected-heading">
        <button className="button-mini exit-btn" onClick={() => window.history.back()}>
          <Icon code="close" label="Cancel" />
        </button>
        <h2>{usersSelected.length} Selected</h2>
      </div>
      <p id="selected-names">
        {usersSelected
          .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
          .map((user, i) => {
            const includeComma = i < usersSelected.length - 1;
            return <span key={`user-${i}`}>{`${user.name}${includeComma ? ', ' : ''}`}</span>;
          })}
      </p>
      <Button id="confirm-btn" variant="rad" onClick={addUsers}>
        Add to {groupType}
      </Button>
      {ungroupedUsers.length > 0 ? (
        <div id="user-list" role="list" aria-label="Students without groups">
          {ungroupedUsers
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
            .map((user, i) => (
              <UserEntry key={`user-${i}`} user={user} selectUser={selectUser} deselectUser={deselectUser} />
            ))}
        </div>
      ) : (
        <p>No available students. All students are already in groups.</p>
      )}
    </div>
  );
}

interface UserEntryProps {
  deselectUser: (user: User) => void;
  selectUser: (user: User) => void;
  user: User;
}

function UserEntry({ deselectUser, selectUser, user }: UserEntryProps): JSX.Element {
  return (
    <div className="user-entry" role="listitem">
      <input
        type="checkbox"
        id={`user-${user.userId}`}
        name="new_members"
        value={user.userId}
        onChange={(e) => {
          if (e.target.checked) selectUser(user);
          else deselectUser(user);
        }}
      />
      <label htmlFor={`user-${user.userId}`}>
        <span className="avatar-wrapper" role="presentation">
          <Avatar user={user} size={40} />
        </span>
        <span className="name">{user.name}</span>
        <Icon className="check-indicator" code="check_circle" ariaHidden />
      </label>
    </div>
  );
}

export default TeacherGroupsMemberAdd;
