import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import NotificationsMenu from '../../core/layout/Navbar/NotificationsMenu';

function NotificationsPage(): JSX.Element {
  useEffect(() => setPageTitle('Notifications'), []);

  const navigate = useNavigate();

  return (
    <div className="page" id="notifications-page">
      <div className="notifs-container">
        <div className="heading">
          <h1>Notifications</h1>
          <Button className="close button-mini" classOverride onClick={() => navigate(-1)}>
            <Icon code="close" />
          </Button>
        </div>
        <NotificationsMenu dedicatedPage limit={20} />
      </div>
    </div>
  );
}

export default NotificationsPage;
