import React from 'react';
import { Link } from 'react-router-dom';
import { Notification as NotificationType } from '../../../../types/types';
import { timeDiff } from '../../../../utils/functions';
import Button from '../../button/Button/Button';
import Icon from '../Icon';

interface Props extends NotificationType {
  markAsRead: () => void;
  dismiss?: () => void;
}

function Notification({
  notificationId,
  title,
  text,
  seen,
  target,
  timestamp,
  notificationActions,
  markAsRead,
  dismiss,
}: Props): JSX.Element {
  const isExternalLink = target && target.indexOf('http') === 0;

  const targetProps = (() => {
    if (target) {
      if (isExternalLink) return { href: target, target: '_blank', rel: 'noreferrer' };
      return { to: target };
    }
    return {};
  })();

  const BaseTag = (target ? (isExternalLink ? 'a' : Link) : 'div') as keyof JSX.IntrinsicElements;

  return (
    <BaseTag
      id={`notification-${notificationId}`}
      className={`notification ${seen ? '' : 'unread'} ${dismiss ? 'dismissable' : null}`}
      {...targetProps}
      onClick={markAsRead}
    >
      <div className="graphic">
        <Icon code={seen ? 'notifications' : 'notifications_active'} ariaHidden />
      </div>
      <div className="body">
        <p className="title">{title}</p>
        <p className="description">
          {timeDiff(timestamp)} ago{' '}
          {text ? (
            <>
              <span className="dot">•</span> {text}
            </>
          ) : null}
        </p>
        {notificationActions.length > 0 ? (
          <div className="actions">
            {notificationActions.map((action, i) => (
              <Button
                key={action.notificationActionId}
                variant={`${i % 2 === 1 ? 'alt-white' : ''} low xs`}
                href={action.target}
                route={action.target.indexOf('http') !== 0}
                onClick={(e) => {
                  markAsRead();
                  e.stopPropagation();
                }}
              >
                {action.title}
              </Button>
            ))}
          </div>
        ) : null}
        {dismiss ? (
          <Button
            className="close button-mini"
            classOverride
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const elem = document.getElementById(`notification-${notificationId}`);
              elem?.classList.add('dismissed');
              setTimeout(dismiss, 500);
            }}
            tooltip="Dismiss"
          >
            <Icon code="close" label="Dismiss notification" />
          </Button>
        ) : null}
      </div>
    </BaseTag>
  );
}

export default Notification;
