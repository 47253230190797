import { combineReducers } from 'redux';
import { CLEAR_REVIEW_DATA } from '../utils/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import commentTableReducer from './commentTableReducer';
import ratingTableReducer from './ratingTableReducer';
import previousTablesReducer from './previousTablesReducer';
import saveTimestampReducer from './saveTimestampReducer';
import feedbackTableReducer from './feedbackTableReducer';
import userReducer from './userReducer';
import assignmentReducer from './assignmentReducer';
import courseReducer from './courseReducer';
import updateKeyReducer from './updateKeyReducer';
import errorsReducer from './errorsReducer';

const appReducer = combineReducers({
  course: courseReducer,
  assignment: assignmentReducer,
  commentTable: commentTableReducer,
  ratingTable: ratingTableReducer,
  previousTables: previousTablesReducer,
  saveTimestamp: saveTimestampReducer,
  feedbackTable: feedbackTableReducer,
  updateKey: updateKeyReducer,
  user: userReducer,
  errors: errorsReducer,
});

const rootReducer = (state: RootState | undefined, action: PayloadAction<RootState>): RootState => {
  if (action.type === CLEAR_REVIEW_DATA)
    state = {
      ...(state as RootState),
      commentTable: {},
      feedbackTable: {},
      previousTables: {},
      ratingTable: {},
      saveTimestamp: '',
      errors: [],
    };

  return appReducer(state, action);
};

export default rootReducer;
