import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApprovedDomain, SeatConsumption } from '../../../types/types';
import Table from '../../core/display/Table/Table';
import { formatDate } from '../../../utils/functions';
import { getApprovedDomainSeatConsumption,getApprovedDomain } from '../../../utils/requests';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  consumerName: string;
  consumerEmail: string;
  usedAt: string;
  courseName: string;
};

function DomainSeatTracking(): JSX.Element {
  const { approvedDomainId } = useParams() as { approvedDomainId: string };
  const [approvedDomain, setApprovedDomain] = useState<ApprovedDomain>();
  const [seatConsumptions, setSeatConsumptions] = useState<SeatConsumption[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  useEffect(() => {
    getApprovedDomainSeatConsumption(approvedDomainId, setSeatConsumptions);
    getApprovedDomain(approvedDomainId, setApprovedDomain);
  }, [approvedDomainId]);

  useMemo(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Name', accessorKey: 'consumerName', meta:{className: 'left-align' }},
      { header: 'Email', accessorKey: 'consumerEmail', meta:{className: 'left-align' }},
      { header: 'Course Name', accessorKey: 'courseName', meta:{className: 'left-align' }},
      { header: 'Used At', accessorKey: 'usedAt', meta:{className: 'left-align' }},
    ];

    const dataTable: TableData[] = [];
    seatConsumptions.forEach((consumption: SeatConsumption) => {
      const newRow: TableData = {
        consumerName: consumption.consumer?.sortableName,
        consumerEmail: consumption.consumer?.email,
        usedAt: formatDate(consumption.usedAt),
        courseName: consumption.courseName
      };
      dataTable.push(newRow);
    });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [seatConsumptions]);

  return (
    <div className="contract-seat-tracking">
      <div className="seat-tracking-title">
        <h2> Domain {approvedDomain ? approvedDomain.domain : ''} Seats Tracking</h2>
      </div>
      <div>
        <Table
          columns={tableColumns}
          data={tableData}
          sortBy="name"
          headingLevel={1}
          noWrapper
          id="seat-tracking-card"
          title="Domain Seat Tracking"
        />
      </div>
    </div>
  );
}

export default DomainSeatTracking;
