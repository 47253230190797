import React, { useState } from 'react';
import { Assignment } from '../../types/types';
import { confirmSubmission, postUrlSubmission, updateUrlSubmission } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import OptionButton from '../core/button/OptionButton/OptionButton';
import Icon from '../core/display/Icon';
import Submission from '../core/display/Submission/Submission';
import { State } from './SubmissionPage';

interface Props {
  allowEdit: boolean;
  assignmentId: string;
  assignment: Assignment;
  asyncEnabled: boolean;
  canReset?: boolean;
  onDelete: () => void;
  requestSequenceBreak: (arg0: number) => void;
  requestStateChangeInSequence: (arg0: number) => void;
  returnToDashboard: () => void;
  setSubmissionIdAndCleanLocalstorage: (arg0: string) => void;
  submissionId: string;
  submissionState: number;
  submitterId: string;
}

function LinkMenu({
  allowEdit,
  assignmentId,
  onDelete,
  requestSequenceBreak,
  requestStateChangeInSequence,
  returnToDashboard,
  setSubmissionIdAndCleanLocalstorage,
  submissionId,
  submitterId,
  submissionState,
}: Props): JSX.Element {
  const [url, setUrl] = useState('');

  switch (submissionState) {
    case State.SUBMIT:
      return (
        <div className="submission-menu" key="link-submit" id="submit-menu" tabIndex={-1}>
          <h1>Submit a Link</h1>
          <p>
            Enter the link you would like to submit for the assignment.
            <br />
            If the content of your link requires permissions to access, please check that your sharing settings allow
            this content to be seen by others via the link.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // Post file to server
              const formData = new FormData();
              formData.append('url', url);
              if (submissionId === '')
                postUrlSubmission(assignmentId, submitterId, formData, setSubmissionIdAndCleanLocalstorage, () => {
                  requestStateChangeInSequence(State.CONFIRM);
                });
              else
                updateUrlSubmission(submissionId, formData, setSubmissionIdAndCleanLocalstorage, () => {
                  requestStateChangeInSequence(State.CONFIRM);
                });
            }}
          >
            <div className="link-input-wrapper flex-row align-center">
              <Icon code="link" ariaHidden />
              <input
                type="url"
                placeholder="Paste your link here"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                required={true}
              />
            </div>
            <div className="buttons-wrapper">
              <Button
                variant="rad alt"
                type="button"
                onClick={() => {
                  requestSequenceBreak(State.CHOOSE);
                }}
              >
                Back
              </Button>
              <Button variant="rad" type="submit">
                Continue
              </Button>
            </div>
          </form>
        </div>
      );
    case State.CONFIRM:
      return (
        <div className="submission-menu" key="link-confirm" id="confirm-menu" tabIndex={-1}>
          <h1>Confirm &amp; Submit</h1>
          <p>Please check that your submission appears correctly and then press Confirm &amp; Submit.</p>
          <Submission
            key="link-confirm"
            submissionId={submissionId}
            flexDirection="column-reverse"
            linkMenu={() => (
              <div className="view-ctrls">
                <Button
                  variant="rad alt"
                  type="button"
                  onClick={() => {
                    requestSequenceBreak(State.SUBMIT);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="rad"
                  onClick={() => {
                    confirmSubmission(submissionId);
                    requestStateChangeInSequence(State.FINISH);
                  }}
                >
                  Confirm &amp; Submit
                </Button>
              </div>
            )}
          />
        </div>
      );
    case State.FINISH:
      return (
        <div className="submission-menu" key="link-finish" id="finish-menu" tabIndex={-1}>
          <h1>Submission Successful</h1>
          <p>Your assignment has been successfully submitted.</p>
          <Button variant="rad" onClick={returnToDashboard}>
            Done
          </Button>
        </div>
      );
    case State.VIEW:
      return (
        <div className="submission-menu" key="link-view" id="view-menu" tabIndex={-1}>
          <h1>View Submission</h1>
          <p>
            Below is your current submission for your assignment.{' '}
            {allowEdit ? 'Here you may access, replace, or delete your submission.' : ''}
          </p>
          <Submission
            key="link-view"
            submissionId={submissionId}
            flexDirection="column-reverse"
            linkMenu={(url) => (
              <div className="view-ctrls flex-row">
                <OptionButton id="open-sub-btn" href={url} label="Open link" iconCode="open_in_new" />
                {allowEdit ? (
                  <>
                    <OptionButton
                      id="delete-sub-btn"
                      onClick={() => onDelete()}
                      label="Delete submission"
                      iconCode="delete"
                    />
                    <Button
                      variant="rad"
                      onClick={() => {
                        requestSequenceBreak(State.SUBMIT);
                      }}
                    >
                      Replace
                    </Button>
                  </>
                ) : null}
              </div>
            )}
          />
        </div>
      );
    default:
      return <></>;
  }
}

export default LinkMenu;
