'use strict';

import pdfjs, { GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf.js';

// Temporary fix for Safari issues
if ('at' in Array.prototype) {
  if (typeof window !== 'undefined' && 'Worker' in window) {
    GlobalWorkerOptions.workerPort = new Worker(new URL('pdfjs-dist/legacy/build/pdf.worker.js', import.meta.url));
  }
} else {
  pdfjs = undefined;
}

export default pdfjs;
