import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../actions';
import { RootState } from '../../../store';
import { editUser } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import AlertBar from '../../core/display/AlertBar';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { setPageTitle } from '../../../utils/functions';

type EmailPreferenceType = 'allEmails' | 'importantEmails' | 'requiredEmails';

function EmailPreferences(): JSX.Element {
  useEffect(() => setPageTitle('Profile: Email Preferences'), []);

  const user = useSelector((state: RootState) => state.user);

  const formEl = useRef<HTMLFormElement>(null);

  const [emailPreference, setEmailPreferences] = useState<EmailPreferenceType>(
    user.sendAllNotifications && user.sendDeadlineNotifications
      ? 'allEmails'
      : !user.sendAllNotifications && user.sendDeadlineNotifications
      ? 'importantEmails'
      : 'requiredEmails',
  );
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      if (user)
        editUser(
          user.userId,
          {
            sendAllNotifications: emailPreference === 'allEmails',
            sendDeadlineNotifications: emailPreference === 'importantEmails' || emailPreference === 'allEmails',
          },
          (user) => {
            dispatch(setUser(user));
            setLoading(false);
            setSaved(true);
          },
        );
    },
    [user, emailPreference, dispatch],
  );

  const changesMade =
    (user.sendAllNotifications && user.sendDeadlineNotifications && emailPreference !== 'allEmails') ||
    (!user.sendAllNotifications && user.sendDeadlineNotifications && emailPreference !== 'importantEmails') ||
    (!user.sendAllNotifications && !user.sendDeadlineNotifications && emailPreference !== 'requiredEmails');

  if (user)
    return (
      <form id="email-preferences-form" ref={formEl} onSubmit={handleSubmit}>
        {saved ? (
          <AlertBar iconCode="done" backgroundColor="#a2ff9e">
            Email preferences have been saved
          </AlertBar>
        ) : null}

        <div className="radio-wrapper">
          <input
            id="allEmails"
            type="radio"
            name="emailPreferences"
            value="allEmails"
            checked={emailPreference === 'allEmails'}
            onChange={(e) => setEmailPreferences(e.target.value as EmailPreferenceType)}
          />
          <label htmlFor="allEmails">All Emails</label>
        </div>
        <div className="radio-wrapper">
          <input
            id="importantEmails"
            type="radio"
            name="emailPreferences"
            value="importantEmails"
            checked={emailPreference === 'importantEmails'}
            onChange={(e) => setEmailPreferences(e.target.value as EmailPreferenceType)}
          />
          <label htmlFor="importantEmails">Important Assignment Notifications</label>
        </div>
        <div className="radio-wrapper">
          <input
            id="requiredEmails"
            type="radio"
            name="emailPreferences"
            value="requiredEmails"
            checked={emailPreference === 'requiredEmails'}
            onChange={(e) => setEmailPreferences(e.target.value as EmailPreferenceType)}
          />
          <label htmlFor="requiredEmails">Required Notifications Only</label>
        </div>
        <Button type="submit" disabled={!changesMade}>
          Save Changes
        </Button>
        {loading ? <LoadingSpinner /> : null}
      </form>
    );
  return <LoadingSpinner />;
}

export default EmailPreferences;
