import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

interface Props {
  url: string;
}

function YouTubePlayer({ url }: Props): JSX.Element {
  const [embedId, setEmbedId] = useState<string | null>(null);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => setInvalid(!validateYouTubeUrl(url, setEmbedId)), [url]);

  if (!invalid && embedId)
    return (
      <YouTube
        videoId={embedId}
        opts={{
          height: '390',
          width: '640',
        }}
      />
    );
  return <>Invalid URL</>;
}

export const validateYouTubeUrl = (url: string, embedIdCb?: (embedId: string) => void) => {
  if (url === '') return false;

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length == 11) {
    embedIdCb && embedIdCb(match[2]);
    return true;
  }
  return false;
};

export default YouTubePlayer;
