import React from 'react';
import Button from '../../core/button/Button/Button';
import PhaseCard from './PhaseCard';
import { PhaseCardProps } from './StudentDashboardOld';

function GroupFormationCard({ assignment, rootPath, userProgress }: PhaseCardProps): JSX.Element {
  const { groupFormationResponseComplete } = userProgress;
  const isSurveyOpen = !(groupFormationResponseComplete || assignment.status === 'COMPLETE');
  return (
    <PhaseCard type="GROUP_FORMATION" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      <Button
        variant={groupFormationResponseComplete ? 'alt' : undefined}
        disabled={!isSurveyOpen}
        href={isSurveyOpen ? `${rootPath}/groups/formation` : undefined}
        route={isSurveyOpen}
      >
        Group Survey {groupFormationResponseComplete ? 'Complete' : isSurveyOpen ? '' : 'Missed'}
      </Button>
    </PhaseCard>
  );
}

export default GroupFormationCard;
