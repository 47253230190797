import React, { useEffect, useState } from 'react';
import { ReviewManagementMenuProps } from './ReviewManagement';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import { Assignment } from '../../../types/types';
import { copyAssignReviews, getCourseAssignments } from '../../../utils/requests';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';

function CopyAssignedReviewsMenu({ changeToMenu, updateData }: ReviewManagementMenuProps): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [assignments, setAssignments] = useState<Assignment[] | null>(null);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => getCourseAssignments(courseId, setAssignments), [courseId]);

  if (assignments) {
    return (
      <div className="page" id="review-management">
        <div id="copy-assigned-reviews">
          <h1>Copy Assigned Reviews</h1>
          <Button className="close-btn button-mini" classOverride onClick={() => changeToMenu('MAIN')}>
            <Icon code="close" />
          </Button>
          <select
            aria-label="Table Page Size"
            value={selectedAssignmentId}
            onChange={(e) => setSelectedAssignmentId(e.target.value)}
          >
            <option value="" disabled>
              Select an assignment to copy from
            </option>
            {assignments
              .filter((assignment) => assignment.assignmentId !== assignmentId)
              .sort((a, b) => a.assignmentName.localeCompare(b.assignmentName))
              .map((assignment) => (
                <option key={assignment.assignmentId} value={assignment.assignmentId}>
                  {assignment.assignmentName}
                </option>
              ))}
          </select>
          <Button
            onClick={() => {
              setLoading(true);
              copyAssignReviews(assignmentId, selectedAssignmentId, () => {
                updateData();
                changeToMenu('MAIN');
              });
            }}
            disabled={selectedAssignmentId === ''}
          >
            Confirm Copy
          </Button>
          {loading ? <LoadingSpinner /> : null}
        </div>
      </div>
    );
  }

  return <LoadingSpinner />;
}

export default CopyAssignedReviewsMenu;
