import React from 'react';
import { useEffect, useState } from 'react';
import pdfobject from 'pdfobject';
import JumpButton from '../button/JumpButton';

interface Props {
  display?: boolean;
  fileName: string;
  id: string;
  setPdfSupported: (arg0: boolean) => void;
  src: string;
}

function PDF({ display = true, fileName, id, setPdfSupported, src }: Props): JSX.Element {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Inform parent of browser PDF support
    setPdfSupported(pdfobject.supportsPDFs);
  }, [setPdfSupported]);

  useEffect(() => {
    if (src && id) pdfobject.embed(src, '#' + id);
  }, [src, id]);

  useEffect(() => {
    if (id) {
      const pdfWrapper = document.getElementById(id);
      if (pdfWrapper) {
        for (let i = 0; i < pdfWrapper.children.length; i++) {
          const node = pdfWrapper.children[i];
          if (node.className === 'pdfobject') {
            // Attach set title attribute to embed tag
            node.setAttribute('title', 'Submission Document (PDF)');
            node.setAttribute('tabindex', '-1');
            // Attach on-load event listener to embed
            const handleLoad = () => {
              setLoaded(true);
            };
            node.addEventListener('load', handleLoad);
            return () => node.removeEventListener('load', handleLoad);
          }
        }
      }
    }
  });

  return (
    <>
      <p className="sr-pdf-alert sr-only" role="alert">
        You are being presented with a PDF. You can{' '}
        <a href={src} download={fileName} target="_blank" rel="noreferrer">
          download the document as a PDF here. <br />
        </a>
        Need software to view a PDF?{' '}
        <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">
          Download Adobe Acrobat Reader here.
        </a>
      </p>
      <JumpButton invisible id={`pre-pdf-btn-${id}`} targetId={`post-pdf-btn-${id}`} type="focus">
        Skip to after PDF
      </JumpButton>
      {src && id ? (
        <div
          className="pdf-wrapper"
          id={id}
          role="document"
          style={{
            opacity: loaded ? 1 : 0,
            display: display ? 'block' : 'none',
          }}
        />
      ) : null}
      <JumpButton invisible id={`post-pdf-btn-${id}`} targetId={`pre-pdf-btn-${id}`} type="focus">
        Skip to before PDF
      </JumpButton>
    </>
  );
}

export default PDF;
