import React, { useState, useMemo, useCallback, ChangeEvent, useEffect } from 'react';
import { AdminStatistics, Course } from '../../types/types';
import { getAdminStatics } from '../../utils/requests';
import moment from 'moment';
import Table from '../core/display/Table/Table';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';

function CourseStats(): JSX.Element {
  const [startDate, setStartDate] = useState<null | string>(null);
  const [endDate, setEndDate] = useState<null | string>(null);
  const [adminStatistics, setAdminStatistics] = useState<AdminStatistics | undefined>(undefined);

  const handleStartDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  const handleEndDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEndDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  useEffect(() => {
    let searchFromDateTime = startDate ? startDate : '2000-01-01 00:00:00';
    let searchToDateTime = endDate ? endDate : '2050-12-30 00:00:00';
    getAdminStatics(searchFromDateTime, searchToDateTime, setAdminStatistics);
  }, [startDate, endDate]);

  return (
    <div id="admin-statics" className="page">
      <div id="course-search">
        <div>
          <label htmlFor="startDate">Start Date:</label>
          <input
            id="startDate"
            type="date"
            value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
            onChange={handleStartDateChange}
          />

          <label htmlFor="endDateText">End Date:</label>
          <input
            id="endDate"
            type="date"
            value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <div id="summary">
        From <b>{startDate ? moment(startDate).format('YYYY-MM-DD') : '2000-01-01'}</b> to{' '}
        <b>{endDate ? moment(endDate).format('YYYY-MM-DD') : '2050-12-30'}</b>, there are totally{' '}
        <b>{adminStatistics?.studentCount?? 0}</b> students and <b>{adminStatistics?.instructorCount?? 0}</b>{' '}
        instructors in <b>{adminStatistics?.courseCount?? 0}</b> courses.
      </div>
      <div id="stat-table">
        <StatsTable courses={adminStatistics?.courses ?? []} />
      </div>
    </div>
  );
}

interface CourseTableProps {
  courses: Course[];
}

function StatsTable({ courses }: CourseTableProps): JSX.Element {
  const navigate = useNavigate();
  const tableColumns = useMemo<ColumnDef<Course>[]>(
    () => [
      { header: 'Name', accessorKey: 'courseName', meta:{className: 'left-align' }},
      { header: 'University', accessorKey: 'university',meta:{className: 'left-align' }},
      { header: 'Created By', accessorKey: (row: { user: { name: string; }; }) => row.user?.name , meta:{className: 'left-align' }},
      { header: 'Discipline', accessorKey: 'discipline', meta:{className: 'left-align' }},
      { header: 'Students', accessorKey: 'rosterStudentCount', meta:{className: 'left-align' }},
      { header: 'Submissions', accessorKey: 'submissions', meta:{className: 'left-align' }},
      { header: 'Reviews', accessorKey: 'reviews', meta:{className: 'left-align' }},
      { header: 'Feedbacks', accessorKey: 'feedbacks', meta:{className: 'left-align' }},
    ],
    [],
  );

  return (
    <Table
      columns={tableColumns}
      data={courses}
      sortBy="courseName"
      id="admin-course-table"
      noWrapper
      informOfRow={(row) => navigate(`/course/${row.original.courseId}/assignments`)}
      title="Admin Courses"
    />
  );
}

export default CourseStats;
