import React, { useState } from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';

interface Props {
  deadlineSetting: 'NONE' | 'END' | 'PHASES';
  oneDeadline: boolean;
  setDeadlineSetting: React.Dispatch<React.SetStateAction<'NONE' | 'END' | 'PHASES'>>;
}

function DeadlineSetup({ deadlineSetting, oneDeadline, setDeadlineSetting }: Props): JSX.Element {
  const [deadlinesExist, setDeadlinesExist] = useState<'true' | 'false'>(deadlineSetting === 'NONE' ? 'false' : 'true');

  return (
    <>
      <Form.Header>
        <Form.Title>Deadlines</Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="row-inputs">
          <legend>
            Should there be {oneDeadline ? 'a deadline' : 'deadlines?'}
            <HelpTag emphasize>
              Choosing &apos;No&apos; means the assignment is open indefinitely. Choosing &apos;Yes&apos; allows you to
              set a final deadline or phase deadlines.
            </HelpTag>
          </legend>
          <div className="rad-radio-btn">
            <input
              id="noDeadlines"
              type="radio"
              name="deadlinesExist"
              value="false"
              checked={deadlinesExist === 'false'}
              onChange={(e) => {
                setDeadlinesExist(e.target.value as 'false');
                setDeadlineSetting('NONE');
              }}
            />
            <label htmlFor="noDeadlines">No</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="teamEvalOn"
              type="radio"
              name="deadlinesExist"
              value="true"
              checked={deadlinesExist === 'true'}
              onChange={(e) => {
                setDeadlinesExist(e.target.value as 'true');
                if (oneDeadline) setDeadlineSetting('END');
              }}
            />
            <label htmlFor="teamEvalOn">Yes</label>
          </div>
        </fieldset>
        {deadlinesExist === 'true' && !oneDeadline ? (
          <fieldset className="col-inputs">
            <legend>Which deadlines?</legend>
            <div className="rad-radio-btn">
              <input
                id="endDeadline"
                type="radio"
                name="deadlineType"
                value="END"
                checked={deadlineSetting === 'END'}
                onChange={(e) => setDeadlineSetting(e.target.value as 'END')}
              />
              <label htmlFor="endDeadline">One End-of-Assignment Deadline</label>
            </div>
            <div className="rad-radio-btn">
              <input
                id="phaseDeadline"
                type="radio"
                name="deadlineType"
                value="PHASES"
                checked={deadlineSetting === 'PHASES'}
                onChange={(e) => setDeadlineSetting(e.target.value as 'PHASES')}
              />
              <label htmlFor="phaseDeadline">Deadlines for Each Assignment Phase</label>
            </div>
          </fieldset>
        ) : null}
      </Form.Body>
    </>
  );
}

export default DeadlineSetup;
