import React from 'react';
import Form from '../../core/input/Form/Form';
import HelpTag from '../../core/display/HelpTag';

interface Props {
  liveMode: boolean;
  setLiveMode: React.Dispatch<React.SetStateAction<boolean>>;
}

function LiveModeSetup({ liveMode, setLiveMode }: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>
          Live Presentations
          <HelpTag type="modal" heading="Peerceptiv Live" margin="0 1rem" emphasize>
            <p>
              By choosing Live Presentations, submissions will be de-anonymized in the review list. Students will be
              able to see all the submitters and pick the one they want to review or review all of them.
            </p>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="row-inputs">
          <legend>Use Live Presentations?</legend>
          <div className="rad-radio-btn">
            <input
              id="liveModeOn"
              type="radio"
              name="liveMode"
              value="true"
              checked={liveMode}
              onChange={(e) => setLiveMode(e.target.value === 'true')}
            />
            <label htmlFor="liveModeOn">Yes</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="liveModeOff"
              type="radio"
              name="liveMode"
              value="false"
              checked={!liveMode}
              onChange={(e) => setLiveMode(e.target.value === 'true')}
            />
            <label htmlFor="liveModeOff">No</label>
          </div>
        </fieldset>
      </Form.Body>
    </>
  );
}

export default LiveModeSetup;
