import React, { useState, useRef, useEffect } from 'react';
import { outsideClick } from '../../../../utils/functions';
import _ from 'lodash';
import Icon from '../../display/Icon';

const MAX_ROWS = 10;
const MIN_ROWS = 2;

interface Props {
  descriptionLabel?: string;
  rows?: number;
  onChange: (arg0: string[]) => void;
  defaultList?: string[];
  orderLabel?: string;
}

function ListEditor({
  descriptionLabel = 'Description',
  rows = 4,
  onChange,
  defaultList,
  orderLabel = 'Order',
}: Props): JSX.Element {
  const [list, setList] = useState<string[]>(
    defaultList && defaultList.length >= MIN_ROWS ? defaultList : Array(rows).fill(''),
  );
  const [selectedId, setSelectedId] = useState(-1);

  const uniqueId = useRef(_.uniqueId());
  const tbodyEl = useRef<HTMLTableSectionElement>(null);
  const orderInputEl = useRef<HTMLInputElement>(null);
  const descInputEl = useRef<HTMLTextAreaElement>(null);
  const addBtnEl = useRef<HTMLButtonElement>(null);
  const removeBtnEl = useRef<HTMLButtonElement>(null);
  const upBtnEl = useRef<HTMLButtonElement>(null);
  const downBtnEl = useRef<HTMLButtonElement>(null);

  const getRowId = (elem: ChildNode | null) => {
    let i = 0;
    let row = elem;
    while ((row = row ? row.previousSibling : null) != null) i++;
    return i;
  };

  const deselect = () => {
    setSelectedId(-1);
  };

  const handleRowSelect = (e: React.MouseEvent | React.FocusEvent) => {
    let element = e.target as Element;
    if (element && element.tagName === 'INPUT' && element.parentElement) element = element.parentElement;

    if (element) {
      // Select row
      const parent = element.parentElement;
      if (parent && parent.tagName === 'TR' && parent.id) {
        setSelectedId(list.length - getRowId(parent) - 1);
      }

      // Focus the element that was clicked
      if (element.tagName === 'TD') {
        if (element.classList.contains('order')) {
          requestAnimationFrame(() => {
            if (orderInputEl.current) orderInputEl.current.focus();
          });
        } else if (element.classList.contains('description')) {
          requestAnimationFrame(() => {
            if (descInputEl.current) descInputEl.current.focus();
          });
        }
      }
    }
  };

  const handleDescriptionEdit = (e: React.ChangeEvent) => {
    const targetElem = e.target as HTMLInputElement;
    if (targetElem) {
      const newDescription = targetElem.value.trim();
      setList((prevList) => {
        const newList = [...prevList];
        newList[selectedId] = newDescription;
        return newList;
      });
    }
  };

  const swap = (a: number, b: number) => {
    setList((prevList) => {
      const newList = _.cloneDeep(prevList);
      const temp = newList[a];
      newList[a] = newList[b];
      newList[b] = temp;
      return newList;
    });
  };

  const add = () => {
    setList((prevList) => {
      const newList = [...prevList];
      if (newList.length + 1 <= MAX_ROWS) {
        newList.push('');
        setSelectedId(newList.length - 1);
        requestAnimationFrame(() => {
          if (descInputEl.current) descInputEl.current.focus();
        });
      }
      return newList;
    });
  };

  const remove = () => {
    setList((prevList) => {
      if (prevList.length - 1 >= MIN_ROWS) return prevList.slice(0, prevList.length - 1);
      return prevList;
    });
  };

  const moveUp = () => {
    if (selectedId > -1 && selectedId < list.length - 1) {
      swap(selectedId, selectedId + 1);
      setSelectedId((prevId) => prevId + 1);
    }
  };

  const moveDown = () => {
    if (selectedId > 0 && selectedId < list.length) {
      swap(selectedId, selectedId - 1);
      setSelectedId((prevId) => prevId - 1);
    }
  };

  const handleDescriptionHotkeys = (e: React.KeyboardEvent) => {
    const focusNewText = () => {
      requestAnimationFrame(() => {
        const textElem = tbodyEl.current?.querySelector('textarea');
        if (textElem) textElem.focus();
      });
    };

    if (e.key === 'Tab') {
      if ((e.shiftKey && selectedId === list.length - 1) || (!e.shiftKey && selectedId === 0))
        requestAnimationFrame(deselect);
    } else {
      switch (e.key) {
        case 'ArrowUp':
          moveUp();
          focusNewText();
          break;
        case 'ArrowDown':
          moveDown();
          focusNewText();
      }
    }
  };

  /**
   * Create event listener for deselecting from the editor
   */
  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (
        tbodyEl.current &&
        addBtnEl.current &&
        removeBtnEl.current &&
        upBtnEl.current &&
        downBtnEl.current &&
        outsideClick(e, [tbodyEl.current, addBtnEl.current, removeBtnEl.current, upBtnEl.current, downBtnEl.current])
      ) {
        deselect();
      }
    };
    window.addEventListener('mousedown', handleMouseDown);
    return () => window.removeEventListener('mousedown', handleMouseDown);
  }, []);

  /**
   * Call the onChange handler if the list changes
   */
  useEffect(() => {
    if (onChange) onChange(list);
  }, [list, onChange]);

  return (
    <div className="list-editor">
      <div className="list-table-wrapper">
        <table className="list-levels">
          <thead>
            <tr>
              <th>{orderLabel}</th>
              <th>{descriptionLabel}</th>
            </tr>
          </thead>
          <tbody ref={tbodyEl} onMouseDown={handleRowSelect} onFocus={handleRowSelect}>
            {list
              .slice(0)
              .reverse()
              .map((entry, i) => {
                const realIndex = list.length - i - 1;
                return (
                  <tr
                    key={`entry-${realIndex}`}
                    id={`entry-${realIndex}`}
                    className={realIndex === selectedId ? 'selected' : ''}
                  >
                    <td className="order">{realIndex + 1}</td>
                    <td
                      className={'description' + (entry === '' ? ' empty' : '')}
                      tabIndex={realIndex === selectedId ? -1 : 0}
                    >
                      {realIndex === selectedId ? (
                        <>
                          <label className="sr-only" htmlFor={`description-${uniqueId}`}>
                            {descriptionLabel} for {orderLabel} {realIndex + 1}
                          </label>
                          <textarea
                            id={`description-${uniqueId}`}
                            ref={realIndex === selectedId ? descInputEl : null}
                            defaultValue={entry}
                            placeholder="Description"
                            onChange={handleDescriptionEdit}
                            onKeyDown={handleDescriptionHotkeys}
                          />
                        </>
                      ) : entry !== '' ? (
                        entry
                      ) : (
                        'Click to add a description'
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="list-ctrls">
        <label htmlFor="num-rows">Rows:</label>
        <input id="num-rows" type="number" value={list.length} disabled />
        <button ref={addBtnEl} onClick={add} type="button">
          <Icon code="add" />
        </button>
        <button ref={removeBtnEl} onClick={remove} type="button">
          <Icon code="remove" />
        </button>
        <button ref={upBtnEl} onClick={moveUp} type="button" tabIndex={-1}>
          <Icon code="expand_less" label="Move up" />
        </button>
        <button ref={downBtnEl} onClick={moveDown} type="button" tabIndex={-1}>
          <Icon code="expand_more" label="Move down" />
        </button>
      </div>
    </div>
  );
}

export default ListEditor;
