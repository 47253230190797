import React, { useCallback, useEffect, useState } from 'react';
import { CourseRatingAverage } from '../../types/types';
import { getCourseRatingAverage } from '../../utils/requests';
import Table from '../core/display/Table/Table';
import { formatDate } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';

interface Props {
  courseId: string;
  setCourseName: React.Dispatch<React.SetStateAction<string>>;
}

type TableData = {
  ratingName: string;
  createdBy?: string;
  createdAt?: string;
  ratingAverage: string;
  ratingId: string;
  courseId: string;
};

function IndividualCourseRatings({ courseId, setCourseName }: Props): JSX.Element {
  const [courseRatingAverages, setCourseRatingAverages] = useState<CourseRatingAverage[] | null>(null);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (courseId) {
      getCourseRatingAverage(courseId, setCourseRatingAverages);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseRatingAverages) {
      setCourseName(courseRatingAverages[0].course.courseName);
    }
  }, [courseRatingAverages, setCourseName]);

  const handleRowSelect = useCallback(
    (courseId: string, ratingId: string) => {
      navigate(`/rubrics/course/${courseId}/rating/${ratingId}`);
    },
    [navigate],
  );

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Rating Name', accessorKey: 'ratingName', meta:{className: 'left-align' }},
    ];
    columns.push({ header: 'Created By', meta:{className: 'left-align' }});
    columns.push({ header: 'Created At', meta:{className: 'left-align' }});
    columns.push({ header: 'Average', meta:{className: 'left-align' }});
    setTableColumns(columns);
  }, []);

  useEffect(() => {
    if (courseRatingAverages) {
      const dataTable: TableData[] = [];

      courseRatingAverages.forEach((courseRatingAverage) => {
        const newRow: TableData = {
          ratingName: courseRatingAverage.rating.name,
          createdBy: courseRatingAverage.rating.user?.name,
          createdAt: courseRatingAverage.rating.createdAt ? formatDate(courseRatingAverage.rating.createdAt) : 'N/A',
          ratingAverage: courseRatingAverage.ratingAverage.toFixed(1),
          courseId: courseRatingAverage.course.courseId ? courseRatingAverage.course.courseId : '',
          ratingId: courseRatingAverage.rating.ratingId ? courseRatingAverage.rating.ratingId : '',
        };
        dataTable.push(newRow);
      });
      setTableData(dataTable);
    }
  }, [courseRatingAverages]);

  return (
    <>
      <Table
        columns={tableColumns}
        data={tableData}
        sortBy="ratingName"
        headingLevel={1}
        noWrapper={true}
        informOfRow={(row) => handleRowSelect(row.original.courseId, row.original.ratingId)}
        title="Course Ratings"
      />
    </>
  );
}

export default IndividualCourseRatings;
