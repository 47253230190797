import React, { useState } from 'react';
import { EditorState, DraftDecorator, CompositeDecorator } from 'draft-js';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createImagePlugin from '@draft-js-plugins/image';
import MultiDecorator from 'draft-js-multidecorators';
import { validateAndParseRichInput } from '../editor/RichEditor/RichEditor';
import ImageComponent from '../editor/RichEditor/ImageComponent';

const resizeablePlugin = createResizeablePlugin({
  horizontal: 'absolute',
  vertical: 'absolute',
  initialWidth: 'auto',
});

const imageDecorators = composeDecorators(resizeablePlugin.decorator);

const linkPlugin = createLinkPlugin({ linkTarget: '_blank' });
const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
const imagePlugin = createImagePlugin({ decorator: imageDecorators, imageComponent: ImageComponent });

const plugins = [linkPlugin, linkifyPlugin, imagePlugin, resizeablePlugin];

function getPluginDecoratorArray() {
  let decorators = [] as DraftDecorator[];
  let plugin;
  // check each plugin that will be used in the editor for decorators
  // (retrieve listOfPlugins however makes sense in your code)
  for (plugin of plugins) {
    if (plugin.decorators !== null && plugin.decorators !== undefined) {
      // if the plugin has any decorators, add them to a list of all decorators from all plugins
      decorators = decorators.concat(plugin.decorators as DraftDecorator[]);
    }
  }
  return decorators;
}

function myFunctionForGrabbingAllPluginDecorators() {
  // I can't quite remember why I had to wrap things in this exact way, but found that I ran into
  // errors if I did not both have a MultiDecorator and a CompositeDecorator wrapping
  // This MultiDecorator can now be used as shown in my previous post.
  return new MultiDecorator([new CompositeDecorator(getPluginDecoratorArray())]);
}

interface Props {
  content: string;
}

function RichReader({ content }: Props): JSX.Element {
  const [validContent, setValidContent] = useState(true);
  const decorators = myFunctionForGrabbingAllPluginDecorators();
  const [editorState, setEditorState] = useState(() => {
    if (content) {
      try {
        const contentState = validateAndParseRichInput(content);
        if (contentState) return EditorState.createWithContent(contentState, decorators);
        else {
          setValidContent(false);
          return EditorState.createEmpty();
        }
      } catch (error) {
        setValidContent(false);
      }
    } else return EditorState.createEmpty();
  });

  return (
    <div className="rich-read-only">
      {validContent ? (
        <Editor editorState={editorState} onChange={setEditorState} plugins={plugins} readOnly={true} />
      ) : (
        <p>{content}</p>
      )}
    </div>
  );
}

export default RichReader;
