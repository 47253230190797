import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useModalContext } from '../../contexts/ModalContext';
import { Reflection } from '../../types/types';
import { newReflection } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function NewReflectionPage(): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const requestCreateNewReflection = useCallback(() => {
    newReflection(assignmentId, (reflectionPost: Reflection) => {
      setLoading(false);
      navigate(`/course/${courseId}/assignment/${assignmentId}/reflection/${reflectionPost.reflectionId}`, {
        replace: true,
      });
    });
    setLoading(true);
  }, [assignmentId, courseId, navigate]);

  useEffect(() => {
    if (!init) {
      requestCreateNewReflection();
      setInit(true);
    }
  }, [init, navigate, modalDispatch, requestCreateNewReflection]);

  return loading ? <LoadingSpinner /> : <></>;
}

export default NewReflectionPage;
