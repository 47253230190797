import React, { useCallback } from 'react';
import { closeModal, useModalContext } from '../../../../contexts/ModalContext';
import Modal, { ModalProps } from './Modal';

interface Props {
  modal: ModalProps | null;
}

function ModalDisplay({ modal }: Props): JSX.Element {
  const { modalDispatch } = useModalContext();

  const close = useCallback(() => modalDispatch(closeModal()), [modalDispatch]);

  if (modal === null) return <></>;

  return (
    <Modal {...modal} close={close}>
      {modal.children}
    </Modal>
  );
}

export default ModalDisplay;
