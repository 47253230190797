import { RootState } from '.';
import { Assignment, Course, User } from '../types/types';

export const selectCourse = (state: RootState): Course | null => state.course;

export const selectAssignment = (state: RootState): Assignment | null => state.assignment;

export const selectUser = (state: RootState): User => state.user;

export const selectUpdateKey = (state: RootState): number => state.updateKey;
