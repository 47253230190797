import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Assignment, Course } from '../../types/types';
import { setPageTitle } from '../../utils/functions';
import { getStandardWorkflows } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import RichReader from '../core/display/RichReader';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import AssignmentDiagram from './AssignmentDiagram';

interface Props {
  course: Course;
}

function AssignmentChooseWorkflow({ course }: Props): JSX.Element {
  useEffect(() => setPageTitle('Choose a Workflow'), []);

  const [workflows, setWorkflows] = useState<Assignment[] | null>(null);
  const [selectedId, setSelectedId] = useState('');
  const [selectedWorkflow, setSelectedWorkflow] = useState<Assignment | null>(null);

  const navigate = useNavigate();

  useEffect(
    () =>
      getStandardWorkflows(course.asyncEnabled, (workflowData) => {
        setWorkflows(workflowData);
        if (workflowData.length > 0) setSelectedId(workflowData[0].assignmentId ?? '');
      }),
    [course],
  );

  useEffect(() => {
    if (workflows) {
      const found = workflows.find((wf) => wf.assignmentId === selectedId);
      if (found) setSelectedWorkflow(found);
    }
  }, [workflows, selectedId]);

  const handleSelect = useCallback(
    (workflow: Assignment) => {
      navigate(`/course/${course.courseId}/assignment/setup/wizard/workflow/${workflow.assignmentId}`);
    },
    [course, navigate],
  );

  if (workflows !== null)
    return (
      <div id="workflow-select-page" className="page">
        <div className="workflow-menu-container">
          <div className="workflows-menu">
            {workflows.length > 0 ? (
              <>
                <label htmlFor="workflow-select">
                  <h1>Choose a Standard Workflow</h1>
                </label>
                <select id="workflow-select" value={selectedId} onChange={(e) => setSelectedId(e.target.value)}>
                  <option value="" disabled>
                    Choose a workflow
                  </option>
                  {workflows.map((workflow) => (
                    <option key={workflow.assignmentId} value={workflow.assignmentId}>
                      {workflow.assignmentName}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <p>No workflows available. Please try again later.</p>
            )}
          </div>
          <div className="workflows-menu workflow-preview" role="status">
            <h2>Workflow Preview</h2>
            {selectedWorkflow ? (
              <div key={selectedWorkflow.assignmentId} id="selected-workflow-desc">
                <RichReader content={selectedWorkflow.assignmentDescription} />
                <AssignmentDiagram assignment={selectedWorkflow} scale={0.75} />
                <Button id="use-workflow-btn" variant="rad green" onClick={() => handleSelect(selectedWorkflow)}>
                  Use Workflow
                </Button>
              </div>
            ) : (
              <p>Please select a workflow.</p>
            )}
          </div>
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

export default AssignmentChooseWorkflow;
