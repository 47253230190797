import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';
import { AssignmentProgress, CommentWithReviewComments, ReviewByItem } from '../../types/types';
import AssignmentPhaseIcon from '../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import ReviewsChart from '../core/display/Graph/ReviewsChart';
import TargetGraph from '../core/display/Graph/TargetGraph';
import StarRating from '../core/display/StarRating/StarRating';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import FeedbackResult from './FeedbackResult';

interface Props {
  assignmentProgress: AssignmentProgress;
  reviewItems: ReviewByItem[];
}

function StudentReviewResults({ reviewItems }: Props): JSX.Element {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const assignment = useSelector(selectAssignment);

  const currReviewItem = useMemo(
    () => (selectedItemIndex < reviewItems.length ? reviewItems[selectedItemIndex] : null),
    [reviewItems, selectedItemIndex],
  );
  const currComments = useMemo(
    () => (currReviewItem ? currReviewItem.comments.filter((comment) => comment.submitterFeedbackMap !== null) : []),
    [currReviewItem],
  );

  if (assignment && currReviewItem)
    return (
      <div className="review-results">
        <h2>Reviewing</h2>
        <p>
          In the <AssignmentPhaseIcon size={24} phase="review" /> <b>Review Phase</b>, you were tasked in rating your
          peers&apos; submissions according to the rubric and provided constructive comments. Your reviewing grade is
          determined by the quality of those ratings (<b>Accuracy</b>) and comments (<b>Helpfulness</b>).
        </p>
        <p>
          First, select a submission and see how you performed reviewing it. We&apos;ve ranked the submissions according
          to how you and your peers rated their quality in conjunction with how <i>Peerceptiv&apos;s</i> algorithm
          processed this data.
        </p>

        <fieldset className="submission-choice">
          <legend>Select a Submission:</legend>
          {reviewItems.map((item, i) => (
            <div key={`rad-${item.submissionId}`} className="rad-radio-btn">
              <input
                id={`submission-rad-${i}`}
                type="radio"
                name="submissionIndex"
                value={i}
                checked={selectedItemIndex === i}
                onChange={(e) => setSelectedItemIndex(parseInt(e.target.value))}
              />
              <label htmlFor={`submission-rad-${i}`}>Submission #{i + 1}</label>
            </div>
          ))}
        </fieldset>

        <AccuracySection currReviewItem={currReviewItem} />

        {assignment.feedbackEnabled ? <FeedbackSection comments={currComments} /> : null}
      </div>
    );
  else if (currReviewItem === null)
    return (
      <div className="review-results">
        <div className="no-results panel-sm">
          <h2 className="title">No Review Results</h2>
          <p>You did not complete any reviews.</p>
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

interface AccuracySectionProps {
  currReviewItem: ReviewByItem;
}

function AccuracySection({ currReviewItem }: AccuracySectionProps): JSX.Element {
  const [filterList, setFilterList] = useState<string[]>([]);
  const [currHoveredRating, setCurrHoveredRating] = useState<string | null>(null);

  const metricProperty = useMemo(
    () => (filterList.includes('Relative Analysis') ? 'rankingAccuracy' : 'distanceFromMean'),
    [filterList],
  );

  return (
    <>
      <h2>Accuracy</h2>
      <p>
        <b>Accuracy</b> measures how closely your ratings track with peer and instructor ratings, and how the
        submissions are ranked overall. This tests your <b>critical analysis</b> skill, or how well you can judge the
        submission on each criteria.
      </p>

      <section>
        <div className="accuracy-card">
          <FilterTab label="Choose a skill metric:" setFilterList={setFilterList}>
            <FilterTab.Button id="btn-active" type="radio" name="accuracy-metric" defaultChecked={true}>
              Relative Analysis
            </FilterTab.Button>
            <FilterTab.Button id="btn-archived" type="radio" name="accuracy-metric">
              Precision
            </FilterTab.Button>
          </FilterTab>
          {metricProperty === 'rankingAccuracy' ? (
            <p className="definition">
              <b>Relative Analysis</b> measures your ability to distinguish quality across different submissions.
            </p>
          ) : (
            <p className="definition">
              <b>Precision</b> measures how close your rating was to the <i>Peerceptiv</i> calculated rating score.
            </p>
          )}

          <div className="accuracy-display">
            <TargetGraph
              radius={80}
              data={currReviewItem.ratings.map((rating) => ({
                datum: 1 - rating[metricProperty],
                style:
                  currHoveredRating === null
                    ? undefined
                    : rating.rating.ratingId === currHoveredRating
                    ? { width: '16px', height: '16px', transform: 'translate(-4px, -4px)' }
                    : { opacity: 0.2 },
              }))}
              yDomain={[0, 1]}
            />

            <table className="nice-table">
              <thead>
                <tr>
                  <th className="name">Rating Prompt</th>
                  <th className="score">Accuracy</th>
                </tr>
              </thead>
              <tbody
                onMouseOver={(e) => setCurrHoveredRating((e.target as HTMLElement).getAttribute('data-rating-id'))}
                onMouseLeave={() => setCurrHoveredRating(null)}
              >
                {currReviewItem.ratings
                  .sort((a, b) => b[metricProperty] - a[metricProperty])
                  .map((rating) => (
                    <tr key={`row-${rating.rating.ratingId}`} data-rating-id={rating.rating.ratingId}>
                      <td className="name">{rating.rating.name}</td>
                      <td className="score">{Math.round(rating[metricProperty] * 100)}%</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <p className="caption">Hover over a prompt so see it on the graph</p>
        </div>
      </section>
    </>
  );
}

interface FeedbackSectionProps {
  comments: CommentWithReviewComments[];
}

function FeedbackSection({ comments }: FeedbackSectionProps): JSX.Element {
  const getFirstReviewComments = useCallback((commentWithReviewComments: CommentWithReviewComments) => {
    const reviewIds = Object.keys(commentWithReviewComments.reviewCommentsMap);
    if (reviewIds.length === 0) return null;
    return commentWithReviewComments.reviewCommentsMap[reviewIds[0]];
  }, []);

  const getFirstFeedback = useCallback((commentWithReviewComments: CommentWithReviewComments) => {
    const reviewIds = Object.keys(commentWithReviewComments.submitterFeedbackMap);
    if (reviewIds.length === 0) return null;
    return commentWithReviewComments.submitterFeedbackMap[reviewIds[0]];
  }, []);

  const getFirstFeedbackRating = useCallback(
    (commentWithReviewComments: CommentWithReviewComments): number => {
      return getFirstFeedback(commentWithReviewComments)?.feedbackRating ?? 0;
    },
    [getFirstFeedback],
  );

  const avgHelpfulnessScore = useMemo(
    () => comments.map((comment) => getFirstFeedbackRating(comment)).reduce((a, b) => a + b, 0) / comments.length,
    [comments, getFirstFeedbackRating],
  );

  const sortedComments = useMemo(
    () => comments.sort((a, b) => (getFirstFeedbackRating(a) < getFirstFeedbackRating(b) ? 1 : -1)),
    [comments, getFirstFeedbackRating],
  );
  const topComments = useMemo(
    () => sortedComments.filter((comment) => getFirstFeedbackRating(comment) > 3),
    [getFirstFeedbackRating, sortedComments],
  );
  const bottomComments = useMemo(
    () => sortedComments.filter((comment) => getFirstFeedbackRating(comment) <= 3),
    [getFirstFeedbackRating, sortedComments],
  );

  const mapCommentToFeedbackResult = (comment: CommentWithReviewComments) => {
    const comments = getFirstReviewComments(comment);
    const feedback = getFirstFeedback(comment);
    if (comments && feedback)
      return (
        <FeedbackResult
          key={`feedback-${comment.comment.commentId}`}
          commentPrompt={comment.comment.commentName}
          feedbackComment={feedback.feedbackComment}
          feedbackRating={feedback.feedbackRating}
          reviewComment={comments.map((reviewComment) => reviewComment.comment).join(' ')}
        />
      );
    return null;
  };

  return (
    <>
      <h2>Helpfulness</h2>
      <p>
        <b>Helpfulness</b> is calculated based on the feedback ratings your comments received from your peers.
      </p>

      {comments.length > 0 ? (
        <section>
          <div className="helpfulness-card">
            <h3>Feedback Ratings</h3>
            <StarRating score={avgHelpfulnessScore} max={5} starSize={25} showScore />
            <ReviewsChart
              width={160}
              height={108}
              bins={[1, 2, 3, 4, 5]}
              data={sortedComments.map((comment) => getFirstFeedbackRating(comment))}
            />
          </div>
        </section>
      ) : (
        <p>
          <i>You received no feedback on your comments for this submission.</i>
        </p>
      )}

      {topComments.length > 0 ? (
        <section>
          <h3>Here are your best comments:</h3>
          {topComments.map(mapCommentToFeedbackResult)}
        </section>
      ) : null}

      {bottomComments.length > 0 ? (
        <section>
          <h3>These comments could use some work:</h3>
          <a
            className="resource-link"
            href="https://peerceptiv.zendesk.com/hc/en-us/articles/6518241328659"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about leaving helpful comments »
          </a>
          {bottomComments.map(mapCommentToFeedbackResult)}
        </section>
      ) : null}
    </>
  );
  return <></>;
}

export default StudentReviewResults;
