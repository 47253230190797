import { PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types/types';
import { CLEAR_COURSE_USER, CLEAR_USER, SET_USER } from '../utils/constants';

const userReducer = (
  state = {
    userId: '',
    purchasingEnabled: false,
    email: '',
    pseudonym: '',
    role: 'STUDENT',
    admin: false,
    enabled: false,
    sortableName: '',
    accountNonLocked: false,
    accountNonExpired: false,
    activeAccount: false,
    credentialsNonExpired: false,
    forcePasswordChange: false,
    username: '',
    authorities: [],
    groupSyncEnabled: false,
    lastLoginTime: null,
    courseRole: null,
    courseRoleCourseId: null,
    courseStudentPurchaseRequired: null,
    createdAt: '',
    schoolAdmin: false,
    sendAllNotifications: false,
    sendDeadlineNotifications: false,
    videoStreamingEnabled: false,
    institutionId: '',
    ltiUser: false,
  } as User,
  action: PayloadAction<User>,
): User => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case CLEAR_USER:
      return action.payload;
    case CLEAR_COURSE_USER:
      return { ...state, courseRole: null, courseRoleCourseId: null };
    default:
      return state;
  }
};

export default userReducer;
