import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../actions';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import { UserRegistration } from '../../types/types';
import { formDataToObject, setPageTitle } from '../../utils/functions';
import { register } from '../../utils/requests';
import SingleForm from '../core/input/Form/SingleForm';
import PasswordInput from './PasswordInput';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
const { Row, Col, FormGroup, InputGroup } = SingleForm;

const USER_EXISTS_ERROR = 409;

function RegisterPage(): JSX.Element {
  useEffect(() => setPageTitle('Account Registration'), []);

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [userExistsFail, setUserExistsFail] = useState(false);
  const [registrationFail, setRegistrationFail] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user.userId !== '' && user.activeAccount) navigate('/course/dashboard');
  }, [navigate, user.activeAccount, user.userId]);

  const handleSubmit = useCallback(
    (formData: FormData, callback: () => void) => {
      const dataObject = formDataToObject(formData) as UserRegistration & { tosAccept?: string };
      delete dataObject.tosAccept;
      const registrationData = dataObject as UserRegistration;
      if (registrationData.password === registrationData.repeatPassword) {
        setPasswordMismatch(false);
        setUserExistsFail(false);
        setRegistrationFail(false);
        register(
          registrationData,
          (user) => {
            dispatch(setUser(user));
            const queryParams = new URLSearchParams({
              username: registrationData.username,
            });
            navigate(`/verify-email?${queryParams.toString()}`);
          },
          (error) => {
            callback();
            if (error.response?.status === USER_EXISTS_ERROR) {
              setUserExistsFail(true);
              return true;
            }
            setRegistrationFail(true);
            return true;
          },
        );
      } else {
        setPasswordMismatch(true);
      }
    },
    [dispatch, navigate],
  );

  if (user.userId !== '' && user.activeAccount) return <></>;
  return (
    <div className="page register-row">
      <img src={logoSrc} width="256" alt="Peerceptiv logo" />
      <SingleForm
        className="registration-form"
        title="Account Registration"
        submitText="Create Account"
        onSubmit={handleSubmit}
        backButton
      >
        {passwordMismatch ? (
          <p className="invalid-log" role="alert">
            Passwords do not match. Please try again.
          </p>
        ) : null}
        {userExistsFail ? (
          <p className="invalid-log" role="alert">
            User already exists.
          </p>
        ) : null}
        {registrationFail ? (
          <p className="invalid-log" role="alert">
            Registration failed. Please try again.
          </p>
        ) : null}
        <Row>
          <Col>
            <FormGroup>
              <InputGroup iconCode="face" flexDirection="row">
                <label className="sr-only" htmlFor="firstName">
                  First name:
                </label>
                <input type="text" id="firstName" name="firstName" placeholder="First name" required={true} />
                <label className="sr-only" htmlFor="lastName">
                  Last name:
                </label>
                <input type="text" id="lastName" name="lastName" placeholder="Last name" required={true} />
              </InputGroup>
              <InputGroup iconCode="email" flexDirection="column">
                <label className="sr-only" htmlFor="username">
                  Email:
                </label>
                <input type="email" id="username" name="username" placeholder="Email" required={true} />
              </InputGroup>
              <InputGroup iconCode="password" flexDirection="column">
                <PasswordInput passwordMismatch={passwordMismatch} setPasswordMismatch={setPasswordMismatch} />
              </InputGroup>
              <InputGroup iconCode="school" flexDirection="row">
                <label className="sr-only" htmlFor="role">
                  Role
                </label>
                <span id="i-am-a">I am a </span>
                <select name="role" id="role" required={true}>
                  <option value="STUDENT">Student</option>
                  <option value="TEACHER">Instructor</option>
                </select>
              </InputGroup>
              <InputGroup iconCode="history_edu" flexDirection="row">
                <input type="checkbox" id="tosAccept" name="tosAccept" required={true} />
                <label htmlFor="tosAccept">
                  I agree to the{' '}
                  <a href="https://peerceptiv.com/terms-of-service/" target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                </label>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </SingleForm>
    </div>
  );
}

export default RegisterPage;
