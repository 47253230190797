import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Assignment, AsyncResult, Grade, LineItem, Review, SubmissionInfo, User } from '../../../types/types';
import { formDataToObject } from '../../../utils/functions';
import {
  waiveLatePenalty,
  postOverrideGrade,
  postOverrideAsyncGrade,
  actAs,
  createInstructorReview,
  deleteReview,
  deleteAsyncResult,
  forceAsyncResult,
  deleteSubmission,
  getLineItemForAssignment,
  forceResendLTIResultForStudent,
} from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import ConfirmButton from '../../core/button/ConfirmButton';
import ProgressRing from '../../core/display/Progress/ProgressRing';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { closeModal, openModal, useModalContext } from '../../../contexts/ModalContext';
import Icon from '../../core/display/Icon';
import AlertBar from '../../core/display/AlertBar';
import { StudentDetailsTabProps } from '../details/StudentDetailsPage';

function ExpandedStudentRow({ assignmentProgress, updateData, accessPermission }: StudentDetailsTabProps): JSX.Element {
  const { courseId, assignmentId, userId } = useParams() as { courseId: string; assignmentId: string; userId: string };
  const dashboardUrl = `/course/${courseId}/assignment/${assignmentId}/dashboard`;

  const [asyncResult, setAsyncResult] = useState<AsyncResult | null>(null);
  const [gradeResult, setGradeResult] = useState<Grade | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [submissionInfo, setSubmissionInfo] = useState<SubmissionInfo | null>(null);
  const [lineItem, setLineItem] = useState<LineItem | null>(null);
  const [isLate, setIsLate] = useState(false);
  const [override, setOverride] = useState(false);
  const { modalDispatch } = useModalContext();
  const location = useLocation();

  const close = useCallback(() => {
    modalDispatch(closeModal());
  }, [modalDispatch]);

  useEffect(() => close(), [location, close]);

  useEffect(() => {
    if (assignmentProgress) {
      const { result, user, submissionInfo } = assignmentProgress;
      setUser(user);
      setSubmissionInfo(submissionInfo);
      setAsyncResult(result?.asyncResult ?? null);
      setGradeResult(result?.grade ?? null);
      setIsLate(result?.lateSubmission ?? false);
      setOverride(result?.override ?? false);
    }
  }, [assignmentProgress]);

  useEffect(() => {
    getLineItemForAssignment(courseId, assignmentId, setLineItem);
  }, [assignmentId, courseId]);

  const handleForceResendLTIResult = useCallback(() => {
    modalDispatch(
      openModal({
        heading: 'Force Send LTI Results',
        label: `Are you sure you want to force resend the result of this assignment for ${user?.sortableName} to the gradebook?`,
        onConfirm: () => {
          forceResendLTIResultForStudent(
            assignmentId,
            user? user.userId: ' ',
            () => {
              modalDispatch(
                openModal({
                  heading: 'Result Resend',
                  label: 'Assignment result have been sent to your gradebook.',
                  buttonText: 'Continue',
                  cancelHide: true,
                }),
              );
            },
          );
        },
      }),
    );
  }, [assignmentId, modalDispatch, user]);

  let grade = -1;
  if (asyncResult) grade = asyncResult.generatedGrade;
  else if (gradeResult) grade = gradeResult.overallGrade;

  const { asyncEnabled, completionGrading } = assignmentProgress.assignment;

  if (user && assignmentProgress)
    return (
      <div className="expanded-student-row">
        {grade > -1 && accessPermission?.viewStudentGradePermission? (
          <div className="action-section">
            {completionGrading ? (
              <div className="completion-status">
                <Icon
                  code="check_circle"
                  color="green"
                  className="icon"
                  label="Complete"
                  aria-hidden="true"
                  aria-label="Completion Icon"
                />
                Completed
              </div>
            ) : (
              <>
                <div className="grade-display">
                  <ProgressRing size="sm" progress={Math.round(grade)} radius={30} strokeWidth={8} padding={4} />
                  Overall
                </div>
                {override ? (
                  <p id="override-text">
                    <Icon code="gavel" ariaHidden /> Overridden by Instructor
                  </p>
                ) : null}
                <OverrideButton
                  assignment={assignmentProgress.assignment}
                  assignmentId={assignmentId}
                  userId={userId}
                  asyncResult={asyncResult || undefined}
                  gradeResult={gradeResult || undefined}
                  setGradeResult={setGradeResult}
                  setAsyncResult={setAsyncResult}
                  updateData={updateData}
                />
                {lineItem && user && user.userId? (
                  <Button variant="rad sm low" type="button" onClick={handleForceResendLTIResult}>
                    Force Resend LTI Result
                  </Button>
                ) : null}
              </>
            )}
            {asyncEnabled ? (
              <Button
                variant="alt rad sm low"
                onClick={() => {
                  modalDispatch(
                    openModal({
                      heading: 'Deleted Results?',
                      buttonText: 'Delete',
                      onConfirm: () => deleteAsyncResult(assignmentId, userId, updateData),
                    }),
                  );
                }}
              >
                Delete Result
              </Button>
            ) : null}
          </div>
        ) : asyncEnabled ? (
          <div className="action-section">
            <Button
              variant="rad sm low"
              onClick={() =>
                forceAsyncResult(assignmentId, userId, () => {
                  updateData();
                  modalDispatch(
                    openModal({
                      heading: 'Results Pending',
                      label:
                        'This result has successfully been set to calculate the next time grades run. This can take up to an hour. You can cause this to happen sooner by recalculating grades from the results page.',
                      noCancel: true,
                      maxWidth: '512px',
                    }),
                  );
                })
              }
            >
              Force Result
            </Button>
          </div>
        ) : null}
        {submissionInfo ? (
          <div className="action-section">
            <div className="instructor-actions">
              <SubmissionButton
                submissionInfo={submissionInfo}
                courseId={courseId}
                assignmentId={assignmentId}
                updateData={updateData}
              />
            </div>
            {isLate && gradeResult && gradeResult.submissionLatePenalty > 0 ? (
              <div className="instructor-actions">
                <WaiveButton submissionInfo={submissionInfo} setIsLate={setIsLate} updateData={updateData} />
              </div>
            ) : (
              ''
            )}
            <div className="instructor-actions">
              <InstructorReviewButton
                assignment={assignmentProgress.assignment}
                instructorReview={assignmentProgress.instructorReviewReceived}
                submissionInfo={submissionInfo}
                updateData={updateData}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {accessPermission?.actAsStudentPermission ? (
          <div className="action-section">
            <Button
              variant="rad sm low"
              onClick={() => {
                actAs(courseId, userId, dashboardUrl, () => {
                  window.location.replace(dashboardUrl);
                });
              }}
              disabled={!user.activeAccount}
              tooltip={!user.activeAccount ? 'Account requires activation' : undefined}
            >
              Act As
            </Button>
          </div>
        ) : null}
        {assignmentProgress.assignment.studentExportEnabled && assignmentProgress.exportUrl ? (
          <div className="action-section">
            <Button
              variant="rad sm low"
              href={assignmentProgress.exportUrl}
              tooltip={'Download student result export package.'}
              download={''}
            >
              Student Result Export
            </Button>
          </div>
        ) : null}
      </div>
    );
  return <LoadingSpinner />;
}

interface SubmissionButtonProps {
  assignmentId: string;
  courseId: string;
  submissionInfo: SubmissionInfo;
  updateData: () => void;
}

function SubmissionButton({ assignmentId, courseId, submissionInfo, updateData }: SubmissionButtonProps): JSX.Element {
  const { submissionId, presentation } = submissionInfo;

  const { modalDispatch } = useModalContext();

  const requestDeleteSubmission = useCallback(
    () =>
      deleteSubmission(submissionId, () => {
        updateData();
        modalDispatch(
          openModal({
            heading: 'Submission Deleted',
            inputType: 'none',
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
      }),
    [submissionId, updateData, modalDispatch],
  );

  return (
    <div>
      <>
        {!presentation ? (
          <Button
            variant="rad sm low"
            disabled={submissionId === ''}
            href={`/course/${courseId}/assignment/${assignmentId}/submission/${submissionId}`}
            route
          >
            Manage Submission
          </Button>
        ) : (
          <Button variant="rad sm low" disabled>
            Manage Submission
          </Button>
        )}

        <Button
          classOverride
          className="button-mini delete"
          onClick={() => {
            modalDispatch(
              openModal({
                heading: 'Delete Submission?',
                label: 'Are you sure you want to delete this submission?',
                buttonText: 'Delete',
                onConfirm: requestDeleteSubmission,
                maxWidth: '600px',
                children: (
                  <AlertBar>Deleting submissions will cause deletion of any reviews on said submission.</AlertBar>
                ),
              }),
            );
          }}
          tooltip="Delete submission"
        >
          <Icon code="delete" ariaHidden />
        </Button>
      </>
    </div>
  );
}

interface WaiveButtonProps {
  setIsLate: React.Dispatch<React.SetStateAction<boolean>>;
  submissionInfo: SubmissionInfo;
  updateData: () => void;
}

function WaiveButton({ setIsLate, submissionInfo, updateData }: WaiveButtonProps): JSX.Element {
  const { submissionId } = submissionInfo;

  return (
    <ConfirmButton
      modalProps={{
        heading: 'Waive Late Penalty',
        label: "Are you sure you want to waive the late penalty for this student's submission?",
        buttonText: 'Waive',
        onConfirm: () =>
          waiveLatePenalty(submissionId, () => {
            updateData();
            setIsLate(false);
          }),
      }}
    >
      <Button variant="rad sm low">Waive Late Penalty</Button>
    </ConfirmButton>
  );
}

interface InstructorReviewButtonProps {
  assignment: Assignment;
  updateData: () => void;
  instructorReview: Review | null;
  submissionInfo: SubmissionInfo;
}

function InstructorReviewButton({
  assignment,
  updateData,
  instructorReview,
  submissionInfo,
}: InstructorReviewButtonProps): JSX.Element {
  const { courseId, assignmentId, reviewId, submissionId } = { ...assignment, ...instructorReview, ...submissionInfo };
  const reviewPathname = `/course/${courseId}/assignment/${assignmentId}/review/${reviewId}`;

  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();

  const requestInstructorReview = useCallback(() => {
    createInstructorReview(assignmentId, submissionId, (review) => {
      navigate(`/course/${courseId}/assignment/${assignmentId}/review/${review.reviewId}`);
    });
  }, [assignmentId, submissionId, navigate, courseId]);

  const requestDeleteReview = useCallback(() => {
    if (reviewId)
      deleteReview(reviewId, () => {
        updateData();
        modalDispatch(
          openModal({
            heading: 'Instructor Review Deleted',
            inputType: 'none',
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
      });
  }, [reviewId, updateData, modalDispatch]);

  if (instructorReview === null) {
    return (
      <Button variant="rad sm low" onClick={requestInstructorReview}>
        Write Review
      </Button>
    );
  }
  return (
    <>
      <Button variant="rad sm low" href={reviewPathname} route>
        Edit Review
      </Button>
      <Button
        classOverride
        className="button-mini delete"
        onClick={() => {
          modalDispatch(
            openModal({
              heading: 'Delete Instructor Review?',
              label: 'Are you sure you want to delete your review for this submission?',
              inputType: 'none',
              buttonText: 'Delete',
              onConfirm: requestDeleteReview,
            }),
          );
        }}
      >
        <Icon code="delete" label="Delete review" />
      </Button>
    </>
  );
}

interface OverrideButtonProps {
  assignment: Assignment;
  assignmentId: string;
  asyncResult?: AsyncResult;
  gradeResult?: Grade;
  setAsyncResult: React.Dispatch<React.SetStateAction<AsyncResult | null>>;
  setGradeResult: React.Dispatch<React.SetStateAction<Grade | null>>;
  updateData: () => void;
  userId: string;
}

function OverrideButton({
  assignment,
  assignmentId,
  asyncResult,
  gradeResult,
  setAsyncResult,
  setGradeResult,
  updateData,
  userId,
}: OverrideButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  return (
    <>
      <Button
        variant="rad sm low"
        type="button"
        onClick={() =>
          modalDispatch(
            openModal({
              heading: 'Override Grades',
              label: "Enter the changes below to override this student's grades:",
              buttonText: 'Override',
              onSubmit: (formData) => {
                const grade = formDataToObject(formData);
                if (gradeResult)
                  postOverrideGrade(assignmentId, userId, grade, (resData) => {
                    const newGrade = resData.grade as Grade;
                    setGradeResult({
                      accuracyGrade: newGrade.accuracyGrade,
                      helpfulnessGrade: newGrade.helpfulnessGrade,
                      submissionGrade: newGrade.submissionGrade,
                      reviewingGrade: newGrade.reviewingGrade,
                      taskGrade: newGrade.taskGrade,
                      taskGradeWithoutBonus: newGrade.taskGradeWithoutBonus,
                      peerEvaluationGrade: newGrade.peerEvaluationGrade,
                      overallGrade: newGrade.overallGrade,
                      submissionLatePenalty: newGrade.submissionLatePenalty,
                      bonusPoints: newGrade.bonusPoints,
                    });
                    updateData();
                  });
                if (asyncResult)
                  postOverrideAsyncGrade(assignmentId, userId, grade, (resData) => {
                    const newGrade = resData.asyncResult as AsyncResult;
                    setAsyncResult({
                      taskCheck: newGrade.taskCheck,
                      reviewCheck: newGrade.reviewCheck,
                      averageScore: newGrade.averageScore,
                      generatedGrade: newGrade.generatedGrade,
                    });
                    updateData();
                  });
              },
              children: <OverrideMenu assignment={assignment} asyncResult={asyncResult} gradeResult={gradeResult} />,
            }),
          )
        }
      >
        Override
      </Button>
    </>
  );
}

interface OverrideMenuProps {
  assignment: Assignment;
  asyncResult?: AsyncResult;
  gradeResult?: Grade;
}

function OverrideMenu({ assignment, asyncResult, gradeResult }: OverrideMenuProps): JSX.Element {
  const { submissionGrade, reviewingGrade, taskGradeWithoutBonus, peerEvaluationGrade, bonusPoints } = gradeResult
    ? gradeResult
    : { submissionGrade: -1, reviewingGrade: -1, taskGradeWithoutBonus: -1, peerEvaluationGrade: -1, bonusPoints: -1 };
  const { generatedGrade } = asyncResult ? asyncResult : { generatedGrade: -1 };

  const [newReviewingGrade, setNewReviewingGrade] = useState<number>(reviewingGrade);
  const [newTaskGrade, setNewTaskGrade] = useState<number>(taskGradeWithoutBonus);
  const [newPeerEvalGrade, setNewPeerEvalGrade] = useState<number>(peerEvaluationGrade);
  const [newGeneratedGrade, setNewGeneratedGrade] = useState<number>(generatedGrade);
  const [newBonusPoints, setNewBonusPoints] = useState<number>(bonusPoints);

  const [newSubmissionGrade, setNewSubmissionGrade] = useState<number>(submissionGrade);
  useEffect(() => {
    setNewSubmissionGrade((prevGrade) => (prevGrade < 0 ? 0 : prevGrade));
  }, [newSubmissionGrade]);

  const handleChange = (e: React.ChangeEvent, cb: React.Dispatch<React.SetStateAction<number>>) => {
    const target = e.target as HTMLInputElement;
    if (target) {
      const numberValue = parseFloat(target.value);
      if (isNaN(numberValue)) cb(0);
      else cb(numberValue);
    }
  };

  return (
    <div id="override-input-wrapper">
      {gradeResult ? (
        <>
          {!assignment.instructorUpload && !assignment.peerEvaluationOnly ? (
            <div className="override-section">
              <label htmlFor="submissionGrade">
                <b>Submission Grade</b>
              </label>
              <input
                id="submissionGrade"
                type="number"
                name="submissionGrade"
                value={newSubmissionGrade}
                onChange={(e) => {
                  handleChange(e, setNewSubmissionGrade);
                }}
                required={true}
                min={0}
                max={100}
                step={0.01}
              />
              <GradeConversionDisplay grade1={gradeResult.submissionGrade} grade2={newSubmissionGrade} />
            </div>
          ) : null}

          {!assignment.instructorGradedOnly && !assignment.peerEvaluationOnly ? (
            <>
              <div className="override-section">
                <label htmlFor="reviewingGrade">
                  <b>Reviewing Grade</b>
                </label>
                <input
                  id="reviewingGrade"
                  type="number"
                  name="reviewingGrade"
                  value={newReviewingGrade}
                  onChange={(e) => {
                    handleChange(e, setNewReviewingGrade);
                  }}
                  required={true}
                  min={0}
                  max={100}
                  step={0.01}
                />
                <GradeConversionDisplay grade1={gradeResult.reviewingGrade} grade2={newReviewingGrade} />
              </div>
              <div className="override-section">
                <label htmlFor="taskGrade">
                  <b>Task Grade</b>
                </label>
                <input
                  id="taskGrade"
                  type="number"
                  name="taskGrade"
                  value={newTaskGrade}
                  onChange={(e) => {
                    handleChange(e, setNewTaskGrade);
                  }}
                  required={true}
                  min={0}
                  max={100}
                  step={0.01}
                />
                <GradeConversionDisplay grade1={gradeResult.taskGradeWithoutBonus} grade2={newTaskGrade} />
              </div>
            </>
          ) : null}

          {assignment.peerEvaluationEnabled ? (
            <div className="override-section">
              <label htmlFor="peerEvaluationGrade">
                <b>Team Member Evaluation Grade</b>
              </label>
              <input
                id="peerEvaluationGrade"
                type="number"
                name="peerEvaluationGrade"
                value={newPeerEvalGrade}
                onChange={(e) => {
                  handleChange(e, setNewPeerEvalGrade);
                }}
                required={true}
                min={0}
                max={100}
                step={0.01}
              />
              <GradeConversionDisplay grade1={gradeResult.peerEvaluationGrade} grade2={newPeerEvalGrade} />
            </div>
          ) : null}

          {assignment.allowBonusReviews ? (
            <div className="override-section">
              <label htmlFor="bonusPoints">
                <b>Bonus Points</b>
              </label>
              <input
                id="bonusPoints"
                type="number"
                name="bonusPoints"
                value={newBonusPoints}
                onChange={(e) => {
                  handleChange(e, setNewBonusPoints);
                }}
                required={true}
                min={0}
                max={100}
                step={0.01}
              />
              <GradeConversionDisplay grade1={gradeResult.bonusPoints} grade2={newBonusPoints} />
            </div>
          ) : null}
        </>
      ) : (
        ''
      )}
      {asyncResult ? (
        <>
          <div className="override-section">
            <label htmlFor="generatedGrade">
              <b>Grade</b>
            </label>
            <input
              id="generatedGrade"
              type="number"
              name="generatedGrade"
              value={newGeneratedGrade}
              onChange={(e) => {
                handleChange(e, setNewGeneratedGrade);
              }}
              required={true}
              min={0}
              max={100}
              step={0.01}
            />
            <GradeConversionDisplay grade1={asyncResult.generatedGrade} grade2={newGeneratedGrade} />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

interface GradeConversionDisplayProps {
  grade2: number;
  grade1: number;
}

function GradeConversionDisplay({ grade1, grade2 }: GradeConversionDisplayProps): JSX.Element {
  return (
    <div className="grade-conversion-display">
      {grade1 < 0 ? (
        <span className="pending">Pending</span>
      ) : (
        <ProgressRing size="sm" progress={Math.round(grade1)} radius={30} strokeWidth={8} padding={4} />
      )}
      <Icon code="arrow_forward" label="To" />
      <ProgressRing size="sm" progress={Math.round(grade2)} radius={30} strokeWidth={8} padding={4} />
    </div>
  );
}

export default ExpandedStudentRow;
