import React, { useMemo } from 'react';
import { Rating, Rubric } from '../../types/types';
import Form from '../core/input/Form/Form';
import EvalCommentsPreview from './EvalCommentsPreview';
import EvalScoresTable from './EvalScoresTable';

interface Props {
  commentIdToCommentMap: { [index: string]: string };
  rubric: Rubric;
  ratingIdToScoreMap: { [index: string]: number };
}

function EvalFormReviewPage({ commentIdToCommentMap, rubric, ratingIdToScoreMap }: Props): JSX.Element {
  const rubricRatings = useMemo(() => rubric.filter((item) => item.hasOwnProperty('ratingId')) as Rating[], [rubric]);

  return (
    <>
      <Form.Header>
        <Form.Title>Review Evaluation</Form.Title>
      </Form.Header>
      <Form.Body>
        {rubricRatings.length > 0 ? (
          <EvalScoresTable rubricRatings={rubricRatings} ratingIdToScoreMap={ratingIdToScoreMap} />
        ) : null}
        <EvalCommentsPreview commentIdToCommentMap={commentIdToCommentMap} />
      </Form.Body>
    </>
  );
}

export default EvalFormReviewPage;
