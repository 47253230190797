import React from 'react';
import TabList from '../core/layout/TabList/TabList';
import CourseSectionManagement from './CourseSectionManagement';

function CourseSectionPage(): JSX.Element {
  return (
    <div className="course-section-container">
      <TabList
        label="coursection-tab"
        tabs={
          <>
            <TabList.Tab id="section" controls="section-tab">
              {' '}
              Sections
            </TabList.Tab>
          </>
        }
      >
        <TabList.TabPanel id="section-tab" labeledBy="section">
          <CourseSectionManagement />
        </TabList.TabPanel>
      </TabList>
    </div>
  );
}

export default CourseSectionPage;
