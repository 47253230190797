import { PayloadAction } from '@reduxjs/toolkit';
import { NEW_UPDATE_KEY } from '../utils/constants';

const updateKeyReducer = (state = 0 as number, action: PayloadAction<number>): number => {
  switch (action.type) {
    case NEW_UPDATE_KEY:
      return action.payload;
    default:
      return state;
  }
};

export default updateKeyReducer;
