import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { deleteSubmission, getStudentAssignmentProgress, getSubmissionInfo } from '../../utils/requests';
import Breadcrumbs from '../core/layout/Breadcrumbs/Breadcrumbs';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import ChooseMenu from './ChooseMenu';
import FileMenu from './FileMenu';
import LinkMenu from './LinkMenu';
import TextMenu from './TextMenu';
import { AssignmentProgress } from '../../types/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../core/button/Button/Button';
import { UUID_REGEX } from '../../utils/constants';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setPageTitle, storageAvailable } from '../../utils/functions';
import { selectAssignment } from '../../store/selectors';
import InClassPresentationMenu from './InClassPresentationMenu';

export const Type = {
  FILE: 'file_submission',
  LINK: 'link_submission',
  TEXT: 'text_submission',
  PRESENTATION: 'presentation_submission',
};

export const State = {
  CHOOSE: 0,
  SUBMIT: 1,
  CONFIRM: 2,
  FINISH: 3,
  VIEW: 4,
};

function SubmissionPage(): JSX.Element {
  useEffect(() => setPageTitle('Submission'), []);

  const params = useParams() as { courseId: string; assignmentId: string; submissionId?: string };
  const courseId = useMemo(() => params.courseId, [params]);
  const assignmentId = useMemo(() => params.assignmentId, [params]);
  const initSubmissionId = useMemo(() => (params.submissionId ? params.submissionId : ''), [params]);
  const basePath = `/course/${courseId}/assignment/${assignmentId}`;

  const [assignmentProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const [submissionId, setSubmissionId] = useState('');
  const [submissionType, setSubmissionType] = useState<string | null>(null);
  const [submissionState, setSubmissionState] = useState<number>(State.CHOOSE);
  const [isNewSubmission, setIsNewSubmission] = useState(true);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [textSubmission, setTextSubmission] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const [presentation, setPresentation] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const user = useSelector((state: RootState) => state.user);
  const assignment = useSelector(selectAssignment);
  const setSubmissionIdAndCleanLocalstorage = (submissionId: string) => {
    setSubmissionId(submissionId);
    if (storageAvailable('localStorage')) window.localStorage.clear();
  };
  const submitterId = storageAvailable('localStorage')
    ? localStorage.getItem('submitterId') ?? user.userId
    : user.userId;

  const allowEdit = useMemo(() => {
    if (assignment && assignment.progressStats && assignmentProgress)
      if (assignment.instructorUpload) return !assignment.progressStats.published;
      else
        return assignment.asyncEnabled ? assignmentProgress.status.canReset : assignment.progressStats.submissionPhase;
    return false;
  }, [assignment, assignmentProgress]);

  useEffect(() => getStudentAssignmentProgress(assignmentId, setAssignmentProgress), [assignmentId]);

  /**
   * Menu children will not be changing sequence directly. Instead they will
   * be making requests to change state through the following two callback
   * functions that operate as Finite State Machines
   */

  // Handles regular state sequence:
  // CHOOSE -> SUBMIT -> CONFIRM -> FINISH -> VIEW
  const requestStateChangeInSequence = useCallback((newState: number) => {
    setSubmissionState((prevState) => {
      switch (newState) {
        case State.CHOOSE:
          break;
        case State.SUBMIT:
          if (prevState === State.CHOOSE) return newState;
          break;
        case State.CONFIRM:
          if (prevState === State.SUBMIT) return newState;
          break;
        case State.FINISH:
          if (prevState === State.CONFIRM) return newState;
          break;
        case State.VIEW:
          if (prevState === State.FINISH) return newState;
          break;
        default:
          break;
      }
      return prevState;
    });
  }, []);

  const openDeleteModal = useCallback(
    (state?: number) =>
      modalDispatch(
        openModal({
          heading: 'Delete Submission',
          label:
            'Are you sure you want to delete your submission? This action cannot be undone. After deletion, you will need to resubmit your assignment.',
          buttonText: 'Delete',
          onConfirm: () => {
            deleteSubmission(submissionId, () => {
              setSubmissionId('');
              if (state === undefined || state === State.CHOOSE) setSubmissionType(null);
              setSubmissionState(state ?? State.CHOOSE);
              setUrl(undefined);
              setFileName(undefined);
              setTextSubmission(undefined);
              setPresentation(false);

              const regex = new RegExp(`\/course\/${UUID_REGEX}\/assignment\/${UUID_REGEX}\/submission`, 'g');
              const found = location.pathname.match(regex);
              if (found && found.length > 0) {
                const rootSubmissionPath = found[0];
                navigate(rootSubmissionPath);
              } else {
                navigate('/');
              }

              modalDispatch(
                openModal({
                  heading: 'Submission Deleted',
                  label: 'Submission successfully deleted. You may now resubmit your assignment',
                  buttonText: 'Okay',
                  cancelHide: true,
                }),
              );
            });
          },
        }),
      ),
    [location.pathname, modalDispatch, navigate, submissionId],
  );

  // Handles any out-of-sequence (see above) state changes
  const requestSequenceBreak = useCallback(
    (newState: number) => {
      setSubmissionState((prevState) => {
        switch (newState) {
          case State.CHOOSE:
            if (prevState !== State.FINISH) {
              if (submissionId !== '') {
                openDeleteModal(State.CHOOSE);
                break;
              }
              setSubmissionType(null);
              return newState;
            }
            break;
          case State.SUBMIT:
            if (prevState === State.CONFIRM) {
              if (submissionId !== '') {
                openDeleteModal(State.SUBMIT);
                break;
              }
              return newState;
            }
            if (prevState === State.VIEW) return newState;
            break;
          case State.CONFIRM:
            break;
          case State.FINISH:
            break;
          case State.VIEW:
            break;
          default:
            break;
        }
        return prevState;
      });
    },
    [openDeleteModal, submissionId],
  );

  const getMenuFromType = (type: string | null) => {
    if (assignment)
      switch (type) {
        case Type.FILE:
          return (
            <FileMenu
              assignmentId={assignmentId}
              assignment={assignment}
              asyncEnabled={assignment.asyncEnabled === true}
              fileName={fileName}
              requestStateChangeInSequence={requestStateChangeInSequence}
              requestSequenceBreak={requestSequenceBreak}
              returnToDashboard={returnToDashboard}
              setSubmissionIdAndCleanLocalstorage={setSubmissionIdAndCleanLocalstorage}
              submissionId={submissionId}
              submissionState={submissionState}
              canReset={assignmentProgress?.status.canReset}
              allowEdit={allowEdit}
              submitterId={submitterId}
              onDelete={openDeleteModal}
            />
          );
        case Type.LINK:
          return (
            <LinkMenu
              assignmentId={assignmentId}
              assignment={assignment}
              asyncEnabled={assignment.asyncEnabled === true}
              requestStateChangeInSequence={requestStateChangeInSequence}
              requestSequenceBreak={requestSequenceBreak}
              returnToDashboard={returnToDashboard}
              submissionId={submissionId}
              setSubmissionIdAndCleanLocalstorage={setSubmissionIdAndCleanLocalstorage}
              submissionState={submissionState}
              canReset={assignmentProgress?.status.canReset}
              allowEdit={allowEdit}
              submitterId={submitterId}
              onDelete={openDeleteModal}
            />
          );
        case Type.TEXT:
          return (
            <TextMenu
              assignmentId={assignmentId}
              assignment={assignment}
              asyncEnabled={assignment.asyncEnabled === true}
              textSubmission={textSubmission}
              requestStateChangeInSequence={requestStateChangeInSequence}
              requestSequenceBreak={requestSequenceBreak}
              returnToDashboard={returnToDashboard}
              submissionId={submissionId}
              setSubmissionIdAndCleanLocalstorage={setSubmissionIdAndCleanLocalstorage}
              submissionState={submissionState}
              canReset={assignmentProgress?.status.canReset}
              allowEdit={allowEdit}
              submitterId={submitterId}
              onDelete={openDeleteModal}
            />
          );
        case Type.PRESENTATION:
          return (
            <InClassPresentationMenu
              assignmentId={assignmentId}
              assignment={assignment}
              asyncEnabled={assignment.asyncEnabled === true}
              requestStateChangeInSequence={requestStateChangeInSequence}
              requestSequenceBreak={requestSequenceBreak}
              returnToDashboard={returnToDashboard}
              submissionId={submissionId}
              setSubmissionIdAndCleanLocalstorage={setSubmissionIdAndCleanLocalstorage}
              submissionState={submissionState}
              allowEdit={allowEdit}
              submitterId={submitterId}
              onDelete={openDeleteModal}
            />
          );
        default:
          return (
            <ChooseMenu
              allowFileType={assignment ? assignment.allowFileSubmissions : false}
              allowLinkType={assignment ? assignment.allowUrlSubmissions : false}
              allowTextType={assignment ? assignment.allowTextSubmissions : false}
              assignment={assignment}
              requestStateChangeInSequence={requestStateChangeInSequence}
              setSubmissionType={setSubmissionType}
            />
          );
      }
    return null;
  };

  const getBreadcrumbsFromState = () => (
    <Breadcrumbs
      isReturn={submissionState === State.VIEW}
      onReturn={() => navigate(-1)}
      unavailable={submissionState === State.FINISH}
    >
      <Breadcrumbs.Item onClick={() => requestSequenceBreak(State.CHOOSE)} selected={submissionState === State.CHOOSE}>
        Choose Type
      </Breadcrumbs.Item>
      <Breadcrumbs.Item onClick={() => requestSequenceBreak(State.SUBMIT)} selected={submissionState === State.SUBMIT}>
        Submit
      </Breadcrumbs.Item>
      <Breadcrumbs.Item selected={submissionState === State.CONFIRM}>Confirm</Breadcrumbs.Item>
      {submissionState === State.FINISH ? <Breadcrumbs.Item selected={true}>Complete</Breadcrumbs.Item> : null}
    </Breadcrumbs>
  );

  const returnToDashboard = useCallback(() => {
    const currPath = location.pathname;
    const dashboardPath = currPath.substring(0, currPath.indexOf('submission')) + 'dashboard';
    navigate(dashboardPath);
  }, [location, navigate]);

  // Get Submission Status, retrieving submission ID if any
  useEffect(() => {
    if (initSubmissionId !== '') setIsNewSubmission(false);
    setSubmissionId(initSubmissionId);
  }, [initSubmissionId, assignmentId]);

  // Handle submission ID
  useEffect(() => {
    if (submissionId === '') {
      setLoaded(true);
    } else {
      getSubmissionInfo(submissionId, (submissionInfo) => {
        setUrl(submissionInfo.url ?? undefined);
        setFileName(submissionInfo.fileName ?? undefined);
        setTextSubmission(submissionInfo.textSubmission ?? undefined);
        setPresentation(submissionInfo.presentation);
        if (submissionInfo.confirmed) setSubmissionState(State.VIEW);
        else setSubmissionState(State.CONFIRM);
      });
    }
  }, [submissionId, submissionType, isNewSubmission]);

  // Handle Submission Info
  useEffect(() => {
    if (submissionId !== '') {
      if (fileName) setSubmissionType(Type.FILE);
      else if (url) setSubmissionType(Type.LINK);
      else if (textSubmission) setSubmissionType(Type.TEXT);
      else if (presentation) setSubmissionType(Type.PRESENTATION);
      const loadResult = url != null || fileName != null || textSubmission != null || presentation;
      if (loadResult !== null) setLoaded(loadResult);
    }
  }, [submissionId, url, fileName, textSubmission, presentation]);

  useEffect(() => {
    requestAnimationFrame(() => {
      switch (submissionState) {
        case State.SUBMIT:
          document.getElementById('submit-menu')?.focus();
          break;
        case State.CONFIRM:
          document.getElementById('confirm-menu')?.focus();
          break;
        case State.FINISH:
          document.getElementById('finish-menu')?.focus();
          break;
        case State.VIEW:
          document.getElementById('view-menu')?.focus();
          break;
        default:
          break;
      }
    });
  }, [submissionState, loaded]);

  if (
    assignment &&
    assignmentProgress &&
    user.courseRole === 'STUDENT' &&
    assignment.groupsEnabled === true &&
    assignmentProgress.status.inGroup === false
  ) {
    return (
      <div className="page submission-row">
        <div id="groups-warning">
          <h1>You must be in a group to submit</h1>
          <Button href={`${basePath}/groups`} route>
            Go to Groups
          </Button>
        </div>
      </div>
    );
  }

  if (loaded)
    return (
      <div className="page submission-row">
        <div className="submission-container fadeIn">
          <div className="breadcrumb-wrapper">{getBreadcrumbsFromState()}</div>
          {getMenuFromType(submissionType)}
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

export default SubmissionPage;
