import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Purchase } from '../../../types/types';
import { getMyPurchaseHistory, getMySharedPurchases } from '../../../utils/requests';
import { Navigate, Route, Routes } from 'react-router';
import CategoryMenu from '../../core/layout/CategoryMenu/CategoryMenu';
import PurchaseHistory from './PurchaseHistory';
import PasswordChangePage from './PasswordChangePage';
import ProfileDetails from './ProfileDetails';
import EmailPreferences from './EmailPreferences';
import AccessibilityOptions from './AccessibilityOptions';
import ManageMfa from './ManageMfa';

function ProfilePage(): JSX.Element {
  const user = useSelector((state: RootState) => state.user);

  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [sharedPurchases, setSharedPurchases] = useState<Purchase[]>([]);
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    getMyPurchaseHistory(setPurchases);
    getMySharedPurchases(setSharedPurchases);
  }, [updateKey]);

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  return (
    <div className="page register-row">
      <Routes>
        <Route index element={<Navigate to="/profile/details" />} />
        <Route
          path="/*"
          element={
            <CategoryMenu
              id="user-profile"
              title="Profile"
              root="/profile"
              categories={[
                { id: 'details', href: '/details', name: 'Details' },
                {
                  id: 'emailPreferences',
                  href: '/email-preferences',
                  name: 'Email Preferences',
                },
                {
                  id: 'changePassword',
                  href: '/change-password',
                  name: 'Change Password',
                },
                {
                  id: 'purchaseHistory',
                  href: '/purchase-history',
                  name: 'Purchase History',
                },
                {
                  id: 'accessibility',
                  href: '/accessibility',
                  name: 'Accessibility',
                },
                {
                  id: 'manageMfa',
                  href: '/manage-mfa',
                  name: 'Manage MFA',
                },
              ]}
              categoryToComponentMap={{
                details: <ProfileDetails />,
                emailPreferences: <EmailPreferences />,
                changePassword: <PasswordChangePage />,
                purchaseHistory: (
                  <PurchaseHistory
                    purchases={purchases}
                    sharedPurchases={sharedPurchases}
                    updateData={updateData}
                    user={user}
                  />
                ),
                accessibility: <AccessibilityOptions />,                
                manageMfa: <ManageMfa user={user} />,
              }}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default ProfilePage;
