import React from 'react';
import { PRODUCTION_ENV } from '../../utils/constants';

interface Props {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

function ErrorFallbackPage({ error, componentStack }: Props): JSX.Element {
  return (
    <div className="site-container">
      <main id="site-content">
        <div className="page error-fallback-page">
          <h1>Oops!</h1>
          <h2>Something went wrong</h2>
          <button className="peer-button button-rad button-low" onClick={() => window.location.reload()}>
            Reload Page
          </button>

          {PRODUCTION_ENV ? null : (
            <pre style={{ marginTop: '2rem' }}>
              <code>{error.toString()}</code>
              <code>{componentStack}</code>
            </pre>
          )}
        </div>
      </main>
    </div>
  );
}

export default ErrorFallbackPage;
