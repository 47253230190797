import React, { useState } from 'react';
import PhaseCard from './PhaseCard';
import Button from '../../core/button/Button/Button';
import { PhaseCardProps } from './StudentDashboardOld';

interface ReviewCardProps extends PhaseCardProps {
  numberOfReviewers: number;
}

function ReviewCard({
  assignment,
  numberOfReviewers,
  progressStats,
  rootPath,
  userProgress,
}: ReviewCardProps): JSX.Element {
  const { selfReviewEnabled } = assignment;
  const { status, requiredReviewsCount } = userProgress;
  const { reviewPhase, submissionPhase } = progressStats;
  const [showAll, setShowMore] = useState(requiredReviewsCount > 4 ? false : true);

  const requiredReviewsCompleted = userProgress.reviewsCompleted + (userProgress.selfReview?.complete === true ? 1 : 0);

  const getReviewIcons = () => {
    const icons: JSX.Element[] = [];

    for (let i = 1; i <= requiredReviewsCount; i++) {
      const complete = i <= requiredReviewsCompleted;
      const newIcon = (
        <span key={'review-icon-' + i} className="review-icon">
          <i className={`review-icon-fill${complete ? ' complete' : ''}`}>rate_review</i>
        </span>
      );
      icons.push(newIcon);
    }
    return assignment.liveMode ? [] : icons;
  };

  const getReviewButtons = () => {
    const reviewButtons: JSX.Element[] = [];
    let reviewNum = 0;

    // Create self-review button if applicable
    if (selfReviewEnabled && userProgress.submissionInfo) {
      const { selfReview } = userProgress;
      if (selfReview && userProgress.status.canEditReviews) {
        reviewButtons.push(
          <Button
            key="review-btn-self"
            variant="alt"
            disabled={!reviewPhase}
            href={`${rootPath}/review/${selfReview.reviewId}`}
            route
          >
            {!selfReview.complete ? 'Continue' : 'Edit'} Self-Review
          </Button>,
        );
      } else if (selfReview && selfReview.complete) {
        reviewButtons.push(
          <Button key="review-btn-self" variant="alt" disabled>
            Self-Review Complete
          </Button>,
        );
      } else if (!selfReview && userProgress.status.canDoAdditionalReviews) {
        reviewButtons.push(
          <Button key="new-self-review-btn" href={`${rootPath}/review/new?selfReview=true`} route>
            Start Self-Review
          </Button>,
        );
      } else if (!selfReview || !selfReview.complete) {
        reviewButtons.push(
          <Button key="review-btn-self" variant="alt" disabled>
            Incomplete Self-Review
          </Button>,
        );
      }
    }

    // Create buttons for any completed reviews
    if (userProgress.completedReviews) {
      userProgress.completedReviews.forEach((review) => {
        if (reviewPhase && userProgress.status.canEditReviews)
          reviewButtons.push(
            <Button
              key={`review-btn-${reviewNum}`}
              variant="alt"
              disabled={!reviewPhase}
              href={`${rootPath}/review/${review.reviewId}`}
              route
            >
              Edit Review {review.displayName != null ? `of ${review.displayName}` : reviewNum + 1}
            </Button>,
          );
        else
          reviewButtons.push(
            <Button key={`review-btn-${reviewNum}`} variant="alt" disabled>
              {review.displayName != null
                ? `Review of ${review.displayName} Complete`
                : `Review ${reviewNum + 1} Complete`}
            </Button>,
          );
        reviewNum++;
      });
    }

    // Create buttons for any incomplete reviews
    if (userProgress.incompleteReviews) {
      //sort the de-anonymized userName in alphabetical order
      userProgress.incompleteReviews.sort(function (a, b) {
        return (a.displayName ?? '').toUpperCase().localeCompare((b.displayName ?? '').toUpperCase());
      });

      userProgress.incompleteReviews.forEach((review) => {
        if (reviewPhase || (assignment.liveMode && submissionPhase)) {
          reviewButtons.push(
            <Button key={`review-btn-${reviewNum}`} href={`${rootPath}/review/${review.reviewId}`} route>
              {review.displayName != null ? review.displayName : `Continue Review ${reviewNum + 1}`}
            </Button>,
          );
        } else {
          reviewButtons.push(
            <Button key={`review-btn-${reviewNum}`} variant="alt" disabled>
              Incomplete Review
            </Button>,
          );
          reviewNum++;
        }
      });
    }

    if (reviewPhase && reviewButtons.length < requiredReviewsCount && status.canDoAdditionalReviews) {
      let reviewNumber = 1;
      if (userProgress.completedReviews) reviewNumber += userProgress.completedReviews.length;
      const submissionLock =
        assignment.reviewingWithoutSubmission && !userProgress.status.hasSubmitted && reviewNumber === 1;
      const submissionLockParam = submissionLock ? '?submissionLock=true' : '';
      reviewButtons.push(
        <Button key={`new-review-btn`} href={`${rootPath}/review/new${submissionLockParam}`} route>
          Start Review {reviewNum + 1}
        </Button>,
      );
    }
    const firstFour = reviewButtons.slice(0, 4);

    return (
      <>
        {showAll ? (
          <>
            {reviewButtons}
            {reviewButtons.length > 4 ? (
              <Button onClick={() => setShowMore(false)} variant="secondary">
                Show Less
              </Button>
            ) : null}
          </>
        ) : (
          <>
            {firstFour}
            {reviewButtons.length > 4 ? (
              <Button onClick={() => setShowMore(true)} variant="secondary">
                Show More
              </Button>
            ) : null}
          </>
        )}
      </>
    );
  };

  const missedClass = !status.reviewingUpcoming && status.missedReviewing ? ' fail' : '';
  const completeClass = !status.reviewingUpcoming && status.completedAllReviews ? ' pass' : '';

  return (
    <PhaseCard type="REVIEW" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      {status.hasSubmitted ||
      (submissionPhase && !status.hasSubmitted) ||
      assignment.reviewingWithoutSubmission ||
      assignment.instructorUpload ||
      assignment.liveMode ? (
        <>
          <div className="review-icons-container" aria-hidden>
            {getReviewIcons()}
          </div>
          <div>
            <p className={`completed-text${missedClass}${completeClass}`}>
              Completed: {requiredReviewsCompleted}/{requiredReviewsCount}
            </p>
          </div>

          {status.missedReviewing ? <p>Missed review phase!</p> : null}
          {!status.reviewingUpcoming || assignment.liveMode ? getReviewButtons() : null}
          {reviewPhase && userProgress.reviewsCompleted / numberOfReviewers >= 1 && status.canDoAdditionalReviews ? (
            <div className="bonus-wrapper">
              <Button href={`${rootPath}/review/new?bonus=true`} route>
                Bonus Review
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <p>
          Review action unavailable <br /> {status.canLateSubmit ? '(Submission required)' : '(Missed submission)'}
        </p>
      )}
    </PhaseCard>
  );
}

export default ReviewCard;
