import { PayloadAction } from '@reduxjs/toolkit';
import { Tables } from '../actions';
import { UPDATE_PREVIOUS_TABLES } from '../utils/constants';

const previousTablesReducer = (state: Tables = {}, action: PayloadAction<Tables>): Tables => {
  switch (action.type) {
    case UPDATE_PREVIOUS_TABLES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default previousTablesReducer;
