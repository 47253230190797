import React, { useRef, useEffect } from 'react';

interface Props {
  children: React.ReactNode;
  color: string;
  r?: number;
  radius: number;
  stroke?: string;
  strokeWidth?: number;
}

function PieChart({ children, color, r = 10, radius, stroke, strokeWidth }: Props): JSX.Element {
  const svgEl = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (children && svgEl.current) {
      const circles = Array.from(svgEl.current.children).slice(1);
      const percents = new Array<number>(circles.length);

      if (percents.length > 0) {
        if (children.hasOwnProperty('length'))
          (children as JSX.Element[]).forEach((child, i) => {
            percents[i] = child.props.percent;
          });
        else percents[0] = (children as JSX.Element).props.percent;

        (circles as SVGCircleElement[]).forEach((circle, i) => {
          let percentSum = 0;
          for (let j = 0; j < i; j++) percentSum += percents[j];
          const rotation = (percentSum / 100) * 360;
          circle.setAttribute('transform', `rotate(${rotation - 90}, ${r}, ${r})`);
        });
      }
    }
  }, [children, r]);

  return (
    <svg
      ref={svgEl}
      className="pie-chart"
      height={r * 2}
      width={r * 2}
      viewBox={`0 0 ${r * 2} ${r * 2}`}
      style={{ width: `${2 * radius}px`, height: `${2 * radius}px` }}
    >
      <circle
        r={r - (strokeWidth ?? 0) / 2 - (strokeWidth ? 1 : 0)}
        cx={r}
        cy={r}
        fill={color}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      {children}
    </svg>
  );
}

interface SliceProps {
  color: string;
  percent: number;
  r?: number;
}

function Slice({ color, percent, r = 5 }: SliceProps) {
  const x = (r * 31.4) / 5;
  const mainStroke = (percent * x) / 100;

  const d = r * 2;
  return (
    <circle
      r={r}
      cx={d}
      cy={d}
      fill="transparent"
      stroke={color}
      strokeWidth={d}
      strokeDasharray={percent > 0 ? `${mainStroke} ${x}` : undefined}
      transform={`rotate(-90, ${d}, ${d})`}
      opacity={percent <= 0 ? 0 : undefined}
    />
  );
}

PieChart.Slice = Slice;

export default PieChart;
