import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { openModal, useModalContext } from '../../../../contexts/ModalContext';
import { User } from '../../../../types/types';
import { KEY_SELECTED_USER } from '../../../../utils/constants';
import { adminActAs, adminMarkPaid, adminMfaDisable, adminMfaReset, forgotPassword } from '../../../../utils/requests';
import Button from '../../button/Button/Button';
import Avatar from '../../display/Avatar/Avatar';
import Icon from '../../display/Icon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { storageAvailable } from '../../../../utils/functions';

function UserBanner(): JSX.Element {
  const { userId } = useParams() as { userId?: string };

  const [user, setUser] = useState<User | null>(null);

  const { modalDispatch } = useModalContext();

  useEffect(() => {
    if (storageAvailable('sessionStorage'))
      setUser(JSON.parse(window.sessionStorage.getItem(KEY_SELECTED_USER) ?? `${null}`));
  }, []);

  const triggerActAs = useCallback(
    () => (userId ? adminActAs(userId, '/users', () => window.location.replace('/')) : undefined),
    [userId],
  );

  const markPaid = useCallback(() => {
    if (user != null) {
      modalDispatch(
        openModal({
          heading: 'Mark Paid?',
          children: (
            <p>
              Are you sure you want to mark <b>{user.name}</b> as paid?
            </p>
          ),
          onConfirm: () =>
            adminMarkPaid(user.userId, () =>
              modalDispatch(
                openModal({
                  heading: 'Student Marked as Paid',
                  inputType: 'none',
                  buttonText: 'Continue',
                  cancelHide: true,
                }),
              ),
            ),
        }),
      );
    }
  }, [user, modalDispatch]);

  const disableMfa = useCallback(() => {
    if (user != null && user.mfaEnabled) {
      modalDispatch(
        openModal({
          heading: 'Disable Mfa?',
          children: (
            <p>
              Are you sure you want to disable MFA for <b>{user.name}</b>?
            </p>
          ),
          onConfirm: () =>
            adminMfaDisable(user.userId, () =>
              modalDispatch(
                openModal({
                  heading: 'Mfa Disabled',
                  inputType: 'none',
                  buttonText: 'Continue',
                  cancelHide: true,
                }),
              ),
            ),
        }),
      );
    }
  }, [user, modalDispatch]);
  const resetMfa = useCallback(() => {
    if (user != null && user.mfaEnabled && user.mfaRequired) {
      modalDispatch(
        openModal({
          heading: 'Reset Mfa?',
          children: (
            <p>
              Are you sure you want to reset MFA for <b>{user.name}</b>? This will wipe their current MFA app connection and require re-enrollment on next login.
            </p>
          ),
          onConfirm: () =>
            adminMfaReset(user.userId, () =>
              modalDispatch(
                openModal({
                  heading: 'Mfa Reset',
                  inputType: 'none',
                  buttonText: 'Continue',
                  cancelHide: true,
                }),
              ),
            ),
        }),
      );
    }
  }, [user, modalDispatch]);

  const resetPassword = useCallback(() => {
    if (user != null) {
      const email = user?.email;
      const message = 'a reset password email has been sent to ' + email;
      forgotPassword(email, () =>
        modalDispatch(
          openModal({
            heading: 'Email Verification Sent',
            label: message,
            buttonText: 'Okay',
            noCancel: true,
          }),
        ),
      );
    }
  }, [user, modalDispatch]);

  if (user && user.userId === userId)
    return (
      <div id="user-banner">
        <div className="banner-content" aria-label="User Details Navigation" role="navigation">
          <div id="profile-preview">
            <Avatar user={user} size={56} />
            <div className="details">
              <div className="name">{user.name}</div>
              <div className="role">{user.role.toLowerCase()}</div>
            </div>
          </div>
          <div className="ctrls">
            <Button classOverride href="/users" route>
              Return
            </Button>
            <Button classOverride onClick={triggerActAs}>
              Act As
            </Button>
            
            { user.purchasingEnabled ? (
              <Button classOverride onClick={() => markPaid()}>
                Mark Paid
              </Button>
            ) : null

            }
            { user.mfaEnabled ? (
              <Button classOverride onClick={() => disableMfa()}>
                Disable MFA
              </Button>
            ) : null}
            
            { user.mfaRequired ? (
              <Button classOverride onClick={() => resetMfa()}>
                Reset MFA
              </Button>
            ) : null

            }
            <Button classOverride onClick={() => resetPassword()}>
              Reset Password
            </Button>
            <Button classOverride href={`mailto:${user.email}`}>
              <Icon code="email" />
            </Button>
          </div>
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

export default UserBanner;
