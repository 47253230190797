import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Report } from '../../types/types';
import { approveReport, getReport, rejectReport } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import ReviewSubmission from '../core/display/Submission/ReviewSubmission';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function ReportPage(): JSX.Element {
  const { reportId } = useParams() as { reportId: string };

  const [report, setReport] = useState<Report | null>(null);

  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => getReport(reportId, setReport), [reportId]);

  const handleApprove = () =>
    modalDispatch(
      openModal({
        heading: 'Sustain Report?',
        label: 'Are you sure to want to sustain (approve) this report? This will pull the reported content.',
        onConfirm: () =>
          approveReport(reportId, (report) => {
            setReport(report);
            modalDispatch(
              openModal({
                heading: 'Report Sustained',
                label: 'Reported content will be pulled.',
                cancelHide: true,
                buttonText: 'Exit',
                onConfirm: () => navigate(-1),
              }),
            );
          }),
      }),
    );

  const handleReject = () =>
    modalDispatch(
      openModal({
        heading: 'Overrule Report?',
        label: 'Are you sure to want to overrule (reject) this report? This will release the reported content.',
        onConfirm: () =>
          rejectReport(reportId, (report) => {
            setReport(report);
            modalDispatch(
              openModal({
                heading: 'Report Overruled',
                label: 'Reported content will be released.',
                cancelHide: true,
                buttonText: 'Exit',
                onConfirm: () => navigate(-1),
              }),
            );
          }),
      }),
    );

  if (report) {
    const materialLabel = report.submissionInfo ? 'submission' : report.review ? 'review' : 'error';
    return (
      <div id="report-page" className="page">
        <div id="judgement-interface">
          {report.pending ? (
            <>
              <h1>Judge Report</h1>
              <p>
                <b>Reason: </b>
                {report.reason}
              </p>
              <div className="decision-ctrls">
                <Button className="decision-btn" classOverride onClick={handleApprove}>
                  <Icon code="gavel" ariaHidden />
                  <b>Sustain Report </b>
                  and pull {materialLabel}
                </Button>
                <Button className="decision-btn" classOverride onClick={handleReject}>
                  <Icon code="assignment_return" ariaHidden />
                  <b>Overrule Report </b>
                  and release {materialLabel}
                </Button>
              </div>
            </>
          ) : null}

          <h2>Reported Material:</h2>
          {report.submissionInfo ? (
            <div id="reported-submission-wrapper">
              <ReviewSubmission submissionInfo={report.submissionInfo} submitter={report.reportee.name} />
            </div>
          ) : null}
          {report.review ? (
            <div id="reported-review-wrapper" className="panel-sm">
              {report.review.reviewComments.map((comment) => (
                <blockquote key={comment.commentId}>{comment.comment}</blockquote>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default ReportPage;
