import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Contract, POST_Contract, Purchase, User } from '../../../types/types';
import {
  createContract,
  deleteContract, deleteSharedContractUser,
  editContract,
  getContracts, getSharedContractUsers,
  postShareContractWithUsers,
} from '../../../utils/requests';
import Table from '../../core/display/Table/Table';
import Dropdown from '../../core/button/Dropdown/Dropdown';
import Button from '../../core/button/Button/Button';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { formDataToObjectParsed } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import TableInput from '../../core/input/TableInput/TableInput';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  name: string;
  domain: string;
  seatsCap: number | string;
  purchase: Purchase;
  scope: string;
  contract: Contract;
  purchaserName: string;
  seatUsed: number | string;
  email: string;
};

interface Props {
  updateKey?: number;
  updateData?: () => void;
}

function ContractMenu({ updateKey, updateData }: Props): JSX.Element {
  const [contractUpdateKey, setContractUpdateKey] = useState(updateKey ? updateKey : 0);
  const user = useSelector((state: RootState) => state.user);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();
  const contractUpdateData = useCallback(() => {
    if (updateData) {
      updateData();
    }
    setContractUpdateKey((prevKey) => prevKey + 1);
  }, [updateData]);

  useEffect(() => {
    getContracts(setContracts);
  }, [contractUpdateKey]);

  const handleDelete = useCallback(
    (contract: Contract) => {
      modalDispatch(
        openModal({
          heading: 'Delete Contract',
          label: `Are you sure you want to delete ${contract.name}?`,
          buttonText: 'Delete',
          padding: '3rem',
          onConfirm: () => deleteContract(contract.contractId, setContracts),
        }),
      );
    },
    [modalDispatch],
  );

  const openShareContractModal = useCallback(
    (contract: Contract) =>
      modalDispatch(
        openModal({
          heading: 'Share Contract Seats',
          label:
            'Enter email address of the user with whom you want to share the contract with. The user you share with will be able to use the seats in the contract.',
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: <ShareContractDialogue contract={contract} contractUpdateData={contractUpdateData} />,
        }),
      ),
    [contractUpdateData, modalDispatch],
  );

  const openEditContractModal = useCallback(
    (contract: Contract) =>
      modalDispatch(
        openModal({
          heading: 'Edit Contract',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Update',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              email: string;
              name: string;
              notes: string;
              seats: number;
              scope: string;
              domain: string;
            };
            const newContract: POST_Contract = {
              name: data.name,
              domain: data.domain,
              seatsCap: data.seats,
              scope: data.scope,
              notes: data.notes,
            };
            editContract(contract.contractId, data.email, newContract, contractUpdateData);
          },
          children: (
            <CreateContractModalContent
              initContractName={contract.name}
              initWhitelistDomain={contract.domain}
              initSeats={contract.seatsCap}
              initScope={contract.scope}
              initNotes={contract.notes}
              initEmail={contract.purchaser.email}
            />
          ),
        }),
      ),
    [contractUpdateData, modalDispatch],
  );

  useMemo(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Name', accessorKey: 'name', meta:{className: 'left-align' }},
      { header: 'Email', accessorKey: 'email', meta:{className: 'left-align' }},
      { header: 'Purchaser', accessorKey: 'purchaserName', meta:{className: 'left-align' }},
      { header: 'Scope', accessorKey: 'scope', meta:{className: 'left-align' }},
      { header: 'Seats Cap', accessorKey: 'seatsCap', meta:{className: 'left-align' }},
      { header: 'Seats Used', accessorKey: 'seatUsed', meta:{className: 'left-align' }},
    ];
    if (user.admin) {
      columns.push({ header: 'Domain', accessorKey: 'domain', meta:{className: 'left-align' }});
      columns.push({
        header: '',
        accessorKey: 'contract',
        cell:  (cell) => {
          const contract = cell.getValue() as Contract;

          return (
            <Dropdown className="button-mini options-btn" iconCode="more_horiz" align="right">
              <>
                <Dropdown.Link href="#" onClick={() => openEditContractModal(contract)}>
                  Edit
                </Dropdown.Link>
                <Dropdown.Link href="#" onClick={() => openShareContractModal(contract)}>
                  Share
                </Dropdown.Link>
                <Dropdown.Link href="#" onClick={() => handleDelete(contract)}>
                  Delete
                </Dropdown.Link>
              </>
            </Dropdown>
          );
        },
        meta:{className: 'center-align options-cell'},
      });
    }
    else {
      columns.push({
        header: '',
        accessorKey: 'contract',
        cell:   (cell) => {
          const contract = cell.getValue() as Contract;
          return (
            <Dropdown className="button-mini options-btn" iconCode="more_horiz" align="right">
              <>
                <Dropdown.Link href="#" onClick={() => openShareContractModal(contract)}>
                  Share
                </Dropdown.Link>
              </>
            </Dropdown>
          );
        },
        meta:{className: 'center-align options-cell'},
      });
    }

    const dataTable: TableData[] = [];
    contracts.forEach((contract: Contract) => {
      const newRow: TableData = {
        name: contract.name,
        scope: contract.scope ? contract.scope : 'None',
        domain: contract.domain ? contract.domain : 'None',
        seatsCap: contract.seatsCap ? contract.seatsCap : 'Unlimited',
        contract: contract,
        purchase: contract.purchase,
        email: contract.purchaser?.email ?? 'None',
        purchaserName: contract.purchaser?.name ?? 'None',
        seatUsed: contract.seatUsed ? contract.seatUsed : 'None',
      };
      dataTable.push(newRow);
    });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [user.admin, contracts, openEditContractModal, openShareContractModal, handleDelete]);

  const OpenNewContractModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Create a Contract',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Create',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              email: string;
              name: string;
              notes: string;
              seats: number;
              scope: string;
              domain: string;
            };
            const contract: POST_Contract = {
              name: data.name,
              domain: data.domain,
              seatsCap: data.seats,
              scope: data.scope,
              notes: data.notes,
            };
            createContract(data.email, contract, contractUpdateData);
          },
          children: <CreateContractModalContent />,
        }),
      ),
    [contractUpdateData, modalDispatch],
  );

  return (
    <div className="contract">
      {user.admin ? (
        <Button id="new-contract-btn" onClick={OpenNewContractModal}>
          {' '}
          New Contract
        </Button>
      ) : null}
      <Table
        columns={tableColumns}
        data={tableData}
        sortBy="name"
        title="Contracts"
        headingLevel={1}
        id="contract-card"
        informOfRow={(row) => navigate(`/contract/${row.original.contract.contractId}/seats`)}
        noWrapper
      />
    </div>
  );
}

export interface CreateContractModalContentProps {
  initContractName?: string;
  initWhitelistDomain?: string;
  initEmail?: string;
  initScope?: string;
  initSeats?: number;
  initNotes?: string;
}

function CreateContractModalContent({
  initContractName = '',
  initEmail = '',
  initWhitelistDomain = '',
  initScope = 'school-wide',
  initSeats = 0,
  initNotes = '',
}: CreateContractModalContentProps): JSX.Element {
  const [contractName, setContractName] = useState<string>(initContractName);
  const [email, setEmail] = useState<string>(initEmail);
  const [whitelistDomain, setWhitelistDomain] = useState<string>(initWhitelistDomain);
  const [scope, setScope] = useState<string>(initScope);
  const [seats, setSeats] = useState<number>(initSeats);
  const [notes, setNotes] = useState<string>(initNotes);

  return (
    <div id="create-contract-modal-content">
      <div className="input-wrapper">
        <label className="sr-only" htmlFor="name">
          Contract Name:
        </label>
        <input
          id="name"
          name="name"
          type="text"
          onChange={(e) => {
            setContractName(e.target.value);
          }}
          placeholder={'Contract Name'}
          value={contractName}
        />
      </div>
      <div className="input-wrapper">
        <label className="domain" htmlFor="whitelistDomain">
          Whitelist Domain:
        </label>
        <input
          id="domain"
          name="domain"
          type="text"
          placeholder={'peerceptiv.edu'}
          value={whitelistDomain}
          onChange={(e) => {
            setWhitelistDomain(e.target.value);
          }}
        />
      </div>
      <div className="input-wrapper">
        <label htmlFor="email">For:</label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="input-wrapper">
        <label className="scope-selection" htmlFor="scope">
          Scope:
        </label>
        <select
          id="scope"
          name="scope"
          value={scope}
          onChange={(e) => {
            setScope(e.target.value);
          }}
        >
          <option value="school-wide">School-Wide</option>
          <option value="department-wide">Department-Wide</option>
          <option value="personal">Personal</option>
        </select>
      </div>
      <div className="input-wrapper">
        <label htmlFor="seats">Seats:</label>
        <input
          id="seats"
          name="seats"
          type="number"
          value={seats}
          min={1}
          onChange={(e) => {
            setSeats(parseInt(e.target.value));
          }}
        />
      </div>
      <div className="input-wrapper">
        <label className="sr-only" htmlFor="notes">
          Notes:
        </label>
        <textarea
          id="notes"
          name="notes"
          placeholder={'Notes(Optional)'}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export interface ShareContractDialogueProps {
  contract: Contract;
  contractUpdateData: () => void;
}

const tableInputHeaders = [
  { title: 'First Name', accessor: 'firstName' },
  { title: 'Last Name', accessor: 'lastName' },
  { title: 'Email', accessor: 'email' },
];

type AddUserData = { firstName: string; lastName: string; email: string; emailDisplay: string };

function ShareContractDialogue({ contract, contractUpdateData }: ShareContractDialogueProps): JSX.Element {
  const [users, setUsers] = useState<AddUserData[]>([]);
  const { modalDispatch } = useModalContext();
  const [addedUsers, setAddedUsers] = useState<AddUserData[]>([]);
  const translateUsers = (users: User[]) => {
    const formattedUsers = users.map(user => ({
      firstName: user.firstName??'',
      lastName: user.lastName??'',
      email: user.email??'',
      emailDisplay: user.email??'',
    }));
    setAddedUsers(formattedUsers);
  };

  useEffect(() => {
      // Fetch already added users with the translation callback
      getSharedContractUsers(contract.contractId, translateUsers);
    }, [contract.contractId]);

  const handleRemoveUser = (email: string) => {
      deleteSharedContractUser(contract.contractId, email, () => {
      setAddedUsers((prevUsers) => prevUsers.filter((user) => user.email !== email));
      contractUpdateData();
    });
  };

  return (
    <form
      onSubmit={() => {
        const validUsers = users.filter((user) => user.firstName !== '' && user.lastName !== '' && user.email !== '');
        postShareContractWithUsers(contract.contractId, validUsers, (users) => {
          const numInstructors = validUsers.length;
          const plural = numInstructors !== 1;
          modalDispatch(
            openModal({
              heading: `User${plural ? 's' : ''} Added to Share Contract`,
              label: `${numInstructors} user${plural ? 's are' : ' is'} now shared on the contract.`,
              inputType: 'none',
              buttonText: 'Continue',
              cancelHide: true,
            }),
          );
          contractUpdateData();
        });
      }}
    >
      <TableInput<AddUserData> headers={tableInputHeaders} onChange={setUsers} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
      <h3>Already Added Users</h3>
      <Table
        columns={[
          { header: 'First Name', accessorKey: 'firstName' },
          { header: 'Last Name', accessorKey: 'lastName' },
          { header: 'Email', accessorKey: 'emailDisplay' },
          {
            header: 'Actions',
            accessorKey: 'email',
            cell: (cell) => (
              <Button onClick={() => handleRemoveUser(cell.getValue())}>Remove</Button>
            ),
          },
        ]}
        title={'Shared Users'}
        data={addedUsers}
      />
    </form>
  );
}

export default ContractMenu;
