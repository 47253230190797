import { createStore, compose } from 'redux';
import { Tables } from '../actions';
import rootReducer from '../reducers';
import { Assignment, CommentTable, Course, FeedbackTable, RatingTable, User } from '../types/types';
import { sentryReduxEnhancer } from './sentryReduxEnhancer';
import { ErrorObj } from '../utils/requests';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(sentryReduxEnhancer));

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = {
  course: Course | null;
  assignment: Assignment | null;
  commentTable: CommentTable;
  ratingTable: RatingTable;
  previousTables: Tables;
  saveTimestamp: string;
  feedbackTable: FeedbackTable;
  updateKey: number;
  user: User;
  errors: ErrorObj[];
};
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
