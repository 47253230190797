import React from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';

interface Props {
  groupFormationOnly: boolean;
  peerEvaluationOnly: boolean;
  setGroupFormationOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setPeerEvaluationOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

function TypeSetup({
  groupFormationOnly,
  peerEvaluationOnly,
  setGroupFormationOnly,
  setPeerEvaluationOnly,
}: Props): JSX.Element {
  const course = useSelector(selectCourse);

  const changeHandler = (e: React.ChangeEvent) => {
    switch ((e.target as HTMLInputElement).value) {
      case 'peerAssessment':
        setPeerEvaluationOnly(false);
        setGroupFormationOnly(false);
        break;
      case 'peerEvaluationOnly':
        setPeerEvaluationOnly(true);
        setGroupFormationOnly(false);
        break;
      case 'groupFormationOnly':
        setPeerEvaluationOnly(false);
        setGroupFormationOnly(true);
    }
  };

  return (
    <>
      <Form.Header>
        <Form.Title>
          Assignment Type
          <HelpTag type="modal" heading="Assignment Type" margin="0 1rem" emphasize>
            <p>
              <b>Peer Assessment</b> assignments require participants to upload a submission (work product) that will be
              reviewed or assessed by their peers and/or instructors. When creating a group peer assessment, peer
              evaluation can be added.
            </p>
            <p>
              <b>Team Member Evaluation Only</b> assignments require individuals to evaluate their peers&apos; or other
              individual&apos;s contributions to a group project or class activity. Individuals do not upload a
              submission (work product) for these assignments.
            </p>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="col-inputs">
          <legend>Choose an assignment type:</legend>
          <div className="rad-radio-btn">
            <input
              id="peerAssessment"
              type="radio"
              name="peerAssessment"
              value="peerAssessment"
              checked={!peerEvaluationOnly && !groupFormationOnly}
              onChange={changeHandler}
            />
            <label htmlFor="peerAssessment">Peer Assessment</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="tmeOnly"
              type="radio"
              name="peerEvaluationOnly"
              value="peerEvaluationOnly"
              checked={peerEvaluationOnly && !groupFormationOnly}
              onChange={changeHandler}
            />
            <label htmlFor="tmeOnly">Team Member Evaluation Only</label>
          </div>
          {course && !course.asyncEnabled ? (
            <div className="rad-radio-btn">
              <input
                id="groupFormationOnly"
                type="radio"
                name="groupFormationOnly"
                value="groupFormationOnly"
                checked={groupFormationOnly && !peerEvaluationOnly}
                onChange={changeHandler}
              />
              <label htmlFor="groupFormationOnly">Group Formation Only</label>
            </div>
          ) : null}
        </fieldset>
      </Form.Body>
    </>
  );
}

export default TypeSetup;
