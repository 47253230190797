import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GroupFormationSurvey, GroupFormationSurveyPrompt, GroupFormationSurveyPromptType } from '../../types/types';
import { createGroupFormationSurvey, getGroupFormationSurvey, updateGroupFormationSurvey } from '../../utils/requests';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import Prompt from '../rubric/Prompt';
import BasicScheduler from '../core/input/Scheduler/BasicScheduler';
import { storageAvailable } from '../../utils/functions';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';
import _ from 'lodash';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { customControls, getItemStyle, getListStyle } from '../rubric/RubricEditorPage';
import Dropdown from '../core/button/Dropdown/Dropdown';

function GroupFormationSurveyEditor(): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const baseUrl = `/course/${courseId}/assignment/${assignmentId}/rubric/group_formation`;

  const assignment = useSelector(selectAssignment);

  const [survey, setSurvey] = useState<GroupFormationSurvey | null>(null);
  const [reorderQueued, setReorderQueued] = useState(false);

  const hasSchedule = useMemo(() => survey?.prompts.some((prompt) => prompt.type === 'SCHEDULE') ?? false, [survey]);

  const navigate = useNavigate();

  const readOnly = assignment?.status !== 'UNPUBLISHED';

  useEffect(() => {
    getGroupFormationSurvey(assignmentId, setSurvey, (err) => {
      if (err.response?.status === 404) {
        createGroupFormationSurvey(assignmentId, setSurvey);
        return true;
      }
      return false;
    });
  }, [assignmentId]);

  const onNewPrompt = useCallback(
    (type: GroupFormationSurveyPromptType) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(
          `prompt-new`,
          JSON.stringify({
            surveyPromptId: '',
            type,
            description: '',
            options: [],
            dimensionCount: -1,
            hiddenOnResults: false,
            order: -1,
          } as GroupFormationSurveyPrompt),
        );
      navigate(`${baseUrl}/prompt/new`);
    },
    [baseUrl, navigate],
  );

  const onEditPrompt = useCallback(
    (prompt: GroupFormationSurveyPrompt) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(`prompt-${prompt.surveyPromptId}`, JSON.stringify(prompt));
      navigate(`${baseUrl}/prompt/edit/${prompt.surveyPromptId}`);
    },
    [baseUrl, navigate],
  );

  const getAddButton = (type: GroupFormationSurveyPromptType) => {
    return (
      <Dropdown.Link href="#" onClick={() => onNewPrompt(type)}>
        Add {getReadableFromType(type)}
      </Dropdown.Link>
    );
  };

  const reorder = useCallback((startIndex: number, endIndex: number) => {
    setSurvey((prevSurvey) => {
      if (prevSurvey) {
        const newSurvey = _.cloneDeep(prevSurvey);
        const [removed] = newSurvey.prompts.splice(startIndex, 1);
        newSurvey.prompts.splice(endIndex, 0, removed);
        return { ...newSurvey, prompts: newSurvey.prompts.map((item, i) => ({ ...item, order: i + 1 })) };
      }
      return prevSurvey;
    });
    setReorderQueued(true);
  }, []);

  const handleReorder = useCallback(
    (i: number, diff: number) => {
      const newIndex = i + diff;
      if (survey && newIndex >= 0 && newIndex < survey.prompts.length) reorder(i, newIndex);
    },
    [reorder, survey],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      reorder(result.source.index, result.destination.index);
    },
    [reorder],
  );

  useEffect(() => {
    if (reorderQueued) {
      if (survey) updateGroupFormationSurvey(assignmentId, survey, setSurvey);
      setReorderQueued(false);
    }
  }, [assignmentId, reorder, reorderQueued, survey]);

  if (survey)
    return (
      <div id="rubric-editor-page" className="page">
        <div className="banner">
          <h1>Group Formation Survey</h1>
          <div className="details-row">
            <div className="main-section">
              <span id="num-prompts">{survey.prompts.length} Prompts</span>
            </div>
          </div>
          {!readOnly ? (
            <Dropdown
              className="peer-button button-rad button-low"
              id="add-prompt-dropdown"
              buttonContent="Add Prompt"
              iconCode="add"
              align="left"
              top="100%"
            >
              {!hasSchedule ? getAddButton('SCHEDULE') : null}
              {getAddButton('RANKED_CHOICE')}
              {getAddButton('CHOOSE_ONE')}
            </Dropdown>
          ) : null}
        </div>

        {survey.prompts.length < 1 ? (
          <div className="empty-interface">
            <h1>Build a Survey</h1>
            <p style={{ maxWidth: '560px' }}>
              Participants will answer these survey prompts, and their reponses will be used to sort them into groups.
            </p>
            <div className="choice-wrapper">
              <p>
                To get started, <b>select &quot;Add a Prompt&quot;</b> above
              </p>
            </div>
          </div>
        ) : (
          <div className="prompt-container">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {survey.prompts.map((prompt, i) => (
                      <Draggable key={prompt.surveyPromptId} draggableId={prompt.surveyPromptId} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Prompt
                              key={prompt.surveyPromptId}
                              iconCode={getIconFromType(prompt.type)}
                              name={getReadableFromType(prompt.type)}
                              description={prompt.description}
                              alwaysShowFull
                              onEdit={!readOnly ? () => onEditPrompt(prompt) : undefined}
                              customControls={
                                !readOnly
                                  ? customControls(i, handleReorder, provided.dragHandleProps ?? undefined)
                                  : undefined
                              }
                            >
                              {prompt.type === 'SCHEDULE' ? <BasicScheduler readOnly /> : null}
                              {prompt.type === 'RANKED_CHOICE' || prompt.type === 'CHOOSE_ONE' ? (
                                <ul>
                                  {prompt.options.map((option) => (
                                    <li key={option}>{option}</li>
                                  ))}
                                </ul>
                              ) : null}
                            </Prompt>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>
    );
  return <LoadingSpinner />;
}

export const getReadableFromType = (type: GroupFormationSurveyPromptType) => {
  switch (type) {
    case 'SCHEDULE':
      return 'Schedule';
    case 'RANKED_CHOICE':
      return 'Ranked Choice';
    case 'CHOOSE_ONE':
      return 'Multiple Choice';
  }
};

const getIconFromType = (type: GroupFormationSurveyPromptType) => {
  switch (type) {
    case 'SCHEDULE':
      return 'schedule';
    case 'RANKED_CHOICE':
      return 'star';
    case 'CHOOSE_ONE':
      return 'contact_support';
  }
};

export default GroupFormationSurveyEditor;
