import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReviewData,
  updatePreviousTables,
  setSaveTimestamp,
  setFeedbackTable,
  setCommentTable as setReduxCommentTable,
} from '../../actions';
import { genCommentTable, setPageTitle, sortRubric } from '../../utils/functions';
import { SAVE_DEBOUNCE_WAIT, SAVE_DEBOUNCE_MAX_WAIT } from '../../utils/constants';
import { getReview, getFeedback, postFeedback, getRubricComments, getSubmissionInfo } from '../../utils/requests';
import Form from './Form';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { Comment, CommentData, CommentTable, FeedbackTable, ReviewFeedback, SubmissionInfo } from '../../types/types';
import { RootState } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import FeedbackReview from './FeedbackReview';
import { selectAssignment } from '../../store/selectors';
import ReviewSubmission from '../core/display/Submission/ReviewSubmission';
import { PinDropProvider } from '../../contexts/PinDropContext';

interface Props {
  feedbackCompleted?: number;
}

function FeedbackPage({ feedbackCompleted }: Props): JSX.Element {
  useEffect(() => setPageTitle('Feedback'), []);

  const { courseId, assignmentId, reviewId } = useParams() as {
    courseId: string;
    assignmentId: string;
    reviewId: string;
  };

  // State:
  const [submissionId, setSubmissionId] = useState('');
  const [comments, setComments] = useState<Comment[] | null>(null);
  const [rubric, setRubric] = useState<Comment[] | null>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [commentTable, setCommentTable] = useState<CommentTable | null>(null);
  const [submissionInfo, setSubmissionInfo] = useState<SubmissionInfo | null>(null);
  const [submissionCollapsed, setSubmissionCollapsed] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const assignment = useSelector(selectAssignment);
  const feedbackTable = useSelector((state: RootState) => state.feedbackTable);
  const previousTables = useSelector((state: RootState) => state.previousTables);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  /**
   * Loads review comments, parses them into a table, and invokes callback on table as state
   */
  const loadComments = useCallback(
    (commentData: CommentData[]) => {
      genCommentTable(commentData, (commentTable: CommentTable) => {
        setCommentTable(commentTable);
        dispatch(setReduxCommentTable(commentTable));
      });
    },
    [dispatch],
  );

  /**
   * Loads feedback from server and saves it to Redux store
   */
  const loadFeedback = useCallback(
    (feedbackData: ReviewFeedback[]) => {
      /**
       * Stores in JSON indexable by comment ID
       */
      const feedbackTable = {} as FeedbackTable;
      feedbackData.forEach((feedback) => {
        feedbackTable[feedback.commentId] = {
          score: feedback.feedbackRating,
          comment: feedback.feedbackComment,
        };
      });

      // Store feedback table
      dispatch(setFeedbackTable(feedbackTable));
      // Store clone as the previous table
      dispatch(updatePreviousTables({ feedbackTable: _.cloneDeep(feedbackTable) }));
    },
    [dispatch],
  );

  /**
   * Effect for on component mount
   */
  useEffect(() => {
    // Clear redux store to prevent interference across pages
    dispatch(clearReviewData());
  }, [dispatch]);

  /**
   * Once a valid review ID is loaded into state, load the review
   */
  useEffect(() => {
    if (reviewId != '') {
      getReview(reviewId, (review) => {
        loadComments(review.reviewComments);
        setSubmissionId(review.submissionId);
      });
    }
  }, [reviewId, loadComments]);

  /**
   * Once a valid feedback ID is loaded into state, load the feedback
   */
  useEffect(() => {
    if (reviewId != '') {
      getFeedback(reviewId, loadFeedback);
    }
  }, [reviewId, loadFeedback]);

  /**
   * Once a valid submission ID is loaded into state, load the submission info
   */
  useEffect(() => {
    if (submissionId !== '') {
      getSubmissionInfo(submissionId, setSubmissionInfo);
    }
  }, [submissionId]);

  /**
   * Compiles the feedback rating & save data from the Redux store
   * and sends it to the server via POST request.
   *
   * This function is debounced and passed to children for use
   * via props.
   */
  const saveFeedback = useCallback(
    (successCb?: () => void) => {
      // Make sure there are changes to save before saving
      if (!_.isEqual(previousTables.feedbackTable, feedbackTable)) {
        // Compile data from tables
        const reviewFeedback = [] as ReviewFeedback[];
        for (const commentId in feedbackTable) {
          reviewFeedback.push({
            commentId: commentId,
            feedbackRating: feedbackTable[commentId].score,
            feedbackComment: feedbackTable[commentId].comment,
          });
        }

        // Save current table as previous table for next save time
        dispatch(
          updatePreviousTables({
            feedbackTable: _.cloneDeep(feedbackTable),
          }),
        );

        // Send data to server
        postFeedback(reviewId, assignmentId, submissionId, reviewFeedback, () => {
          // Set saved timestamp
          dispatch(setSaveTimestamp(moment().format('h:mm:ss A')));
          if (successCb) successCb();
        });
      } else {
        if (successCb) successCb();
      }
    },
    [assignmentId, dispatch, feedbackTable, previousTables.feedbackTable, reviewId, submissionId],
  );

  const debouncedSaveFeedback = useMemo(
    () =>
      _.debounce(saveFeedback, SAVE_DEBOUNCE_WAIT, {
        maxWait: SAVE_DEBOUNCE_MAX_WAIT,
      }),
    [saveFeedback],
  );

  useEffect(() => {
    return () => {
      debouncedSaveFeedback.cancel();
    };
  }, [debouncedSaveFeedback]);

  useEffect(() => getRubricComments(assignmentId, setComments), [assignmentId]);

  /**
   * Combine into rubric
   */
  useEffect(() => {
    if (comments !== null) setRubric(sortRubric(comments, []) as Comment[]);
  }, [comments]);

  useEffect(() => {
    if (assignment && feedbackCompleted === 0) {
      modalDispatch(
        openModal({
          heading: 'Rubric',
          label: `Before continuing, please read the feedback rubric carefully:`,
          inputType: 'none',
          noActionButtons: true,
          closeButton: true,
          children: (
            <table className="rubric-table">
              <thead>
                <tr>
                  <th>Score</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {assignment.feedbackRatings
                  .map((rating) => (
                    <tr key={rating.score}>
                      <th>{rating.score}</th>
                      <td>{rating.levelDescription}</td>
                    </tr>
                  ))
                  .reverse()}
              </tbody>
            </table>
          ),
        }),
      );
    }
  }, [assignment, feedbackCompleted, modalDispatch]);

  if (assignment && assignment.feedbackEnabled && submissionInfo && rubric && comments) {
    const layoutClass = submissionCollapsed ? 'column' : 'row';
    return (
      <PinDropProvider submissionInfo={submissionInfo} comments={comments}>
        <div id="feedback-page" className={`page ${layoutClass}`}>
          <div className="feedback-col" id="submission-col">
            {submissionInfo && (
              <ReviewSubmission
                submissionInfo={submissionInfo}
                onChange={(state) => setSubmissionCollapsed(state.collapsed)}
              />
            )}
          </div>
          <div className="feedback-col">
            <FeedbackReview comment={rubric[pageIndex]} reviewCommentTable={commentTable} pageNum={pageIndex} />
            <Form
              rubric={rubric as Comment[]}
              save={debouncedSaveFeedback}
              feedbackPrompt={assignment.feedbackPrompt}
              feedbackRatings={assignment.feedbackRatings}
              onPageIndexChange={setPageIndex}
              onSubmit={() => saveFeedback(() => navigate(`/course/${courseId}/assignment/${assignmentId}/dashboard`))}
            />
          </div>
        </div>
      </PinDropProvider>
    );
  }
  return <LoadingSpinner />;
}

export default FeedbackPage;
