import { PayloadAction } from '@reduxjs/toolkit';
import { RatingTable } from '../types/types';
import { RatingUpdateData } from '../actions';
import { SET_RATING_TABLE, UPDATE_RATING_TABLE } from '../utils/constants';

const ratingTableReducer = (
  state: RatingTable = {},
  action: PayloadAction<RatingTable | RatingUpdateData>,
): RatingTable => {
  switch (action.type) {
    case SET_RATING_TABLE:
      const setPayload = action.payload as RatingTable;
      return setPayload;
    case UPDATE_RATING_TABLE:
      const updatePayload = action.payload as RatingUpdateData;
      if (state[updatePayload.ratingId] == null) state[updatePayload.ratingId] = { score: NaN };
      state[updatePayload.ratingId].score = updatePayload.score;
      return state;
    default:
      return state;
  }
};

export default ratingTableReducer;
