import React, { useEffect, useMemo, useState } from 'react';
import { TeacherResultsTabProps } from './TeacherResultsPage';
import { GroupWithGroupFormationResults } from '../../types/types';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { getAllGroupFormationResults } from '../../utils/requests';
import { useParams } from 'react-router-dom';
import GroupFormationResults from './GroupFormationResults';

function TeacherGroupFormationResults({}: TeacherResultsTabProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [results, setResults] = useState<GroupWithGroupFormationResults[] | null>(null);
  const [currGroupId, setCurrGroupId] = useState('');

  const currResult = useMemo(
    () => results?.find((result) => result.group.groupId === currGroupId)?.promptResults,
    [currGroupId, results],
  );

  useEffect(() => getAllGroupFormationResults(assignmentId, setResults), [assignmentId]);

  if (results === null) return <LoadingSpinner />;
  return (
    <>
      <label htmlFor="group-formation-select">Select a group to view results:</label>
      <select id="group-formation-select" value={currGroupId} onChange={(e) => setCurrGroupId(e.target.value)}>
        <option value="">Select a Group</option>
        {results.map((result) => (
          <option key={result.group.groupId} value={result.group.groupId}>
            {result.group.groupName}
          </option>
        ))}
      </select>
      {currResult ? <GroupFormationResults key={currGroupId} results={currResult} /> : null}
    </>
  );
}

export default TeacherGroupFormationResults;
