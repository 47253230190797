import React, { useCallback } from 'react';
import Form from '../core/input/Form/Form';
import TextBox from '../core/input/TextBox/TextBox';

import RichReader from '../core/display/RichReader';
import { Comment } from '../../types/types';

interface Props extends Comment {
  initComment?: string;
  onInvalid?: () => void;
  updateCommentMap: (commentId: string, comment: string) => void;
}

function EvalFormCommentPage({
  commentId,
  commentName,
  commentPrompt,
  initComment,
  onInvalid,
  updateCommentMap,
}: Props): JSX.Element {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => updateCommentMap(commentId, e.target.value),
    [updateCommentMap, commentId],
  );

  return (
    <>
      <Form.Header>
        <Form.Title>{commentName}</Form.Title>
        <Form.Description size="sm">
          <RichReader content={commentPrompt} />
        </Form.Description>
      </Form.Header>
      <Form.Body>
        <TextBox
          commentId={commentId}
          labelText="Comment"
          required
          defaultValue={initComment}
          onChange={handleChange}
          onBlur={() => undefined}
          onInvalid={onInvalid}
        />
      </Form.Body>
    </>
  );
}

export default EvalFormCommentPage;
