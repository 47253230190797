import React from 'react';
import { curveMonotoneX } from '@visx/curve';
import { Axis, GlyphSeries, Grid, LineSeries, XYChart } from '@visx/xychart';
import moment from 'moment';
import Icon from '../Icon';
import { Rating, Tag } from '../../../../types/types';

type Datum = { date: string; score: number };

const accessors = {
  xAccessor: (d: Datum) => d.date,
  yAccessor: (d: Datum) => d.score,
};

interface Props {
  data: Datum[];
  height: number;
  rating?: Rating;
  tag?: Tag;
}

function TrackedXYChart({ data, height, rating, tag }: Props): JSX.Element {
  const getTrendLine = (prevScore: number, newScore: number) => {
    if (prevScore < newScore) return <Icon code="trending_up" color="#38B934" />;
    else if (prevScore > newScore) return <Icon code="trending_down" color="#EC4F4F" />;
    return <Icon code="trending_flat" />;
  };

  const isMaxScore = (currScore: number) => !data.some((d) => d.score > currScore);

  return (
    <div className="tracked-performance">
      <h2>Tracked Performance</h2>
      <p>Here you can track this student&apos;s performance on a recurring rating prompt or rating tag over time.</p>

      <h3>
        Tracking:{' '}
        <b>
          {rating?.name}
          {tag?.content}
        </b>
      </h3>

      <XYChart
        height={height}
        margin={{ top: 8, bottom: 16, right: 16, left: 32 }}
        xScale={{ type: 'band' }}
        yScale={{ type: 'linear', domain: [0, rating ? rating.maxScore ?? 100 : 100] }}
      >
        <Grid columns={false} numTicks={4} strokeDasharray="5 2" lineStyle={{ stroke: '#EEEEEE', strokeWidth: 2 }} />

        <LineSeries dataKey="Tracked Performance" data={data} {...accessors} curve={curveMonotoneX} stroke="#7878F1" />

        <GlyphSeries dataKey="Tracked Performance" data={data} {...accessors} colorAccessor={() => '#7878F1'} />

        <Axis
          orientation="bottom"
          numTicks={8}
          tickFormat={(date) => moment(date).format('MMM DD')}
          hideAxisLine
          hideTicks
        />
        <Axis
          orientation="left"
          numTicks={4}
          hideZero
          hideAxisLine
          hideTicks
          tickFormat={(val) => (Number.isInteger(val) ? val : '')}
        />
      </XYChart>

      <table className="nice-table">
        <thead>
          <tr>
            <th className="date">Date</th>
            <th className="score">Score</th>
            <th className="trend">Trend</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={d.date} className={isMaxScore(d.score) ? 'pr' : undefined}>
              <td className="date">{moment(d.date).format('MMMM D, YYYY')}</td>
              <td className="score">{d.score}</td>
              <td className="trend">{i > 0 ? getTrendLine(data[i - 1].score, d.score) : null}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TrackedXYChart;
