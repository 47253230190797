import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { RootState } from '../../store';
import { selectAssignment } from '../../store/selectors';
import { setPageTitle } from '../../utils/functions';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import BenchmarkPage from './benchmark/BenchmarkPage';
import StudentResultsPage from './StudentResultsPage';
import TeacherResultPage from './TeacherResultsPage';
import FullDialoguePage from './FullDialoguePage';

function ResultsPage(): JSX.Element {
  useEffect(() => setPageTitle('Assignment Results'), []);
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const assignment = useSelector(selectAssignment);
  const user = useSelector((state: RootState) => state.user);
  const { courseRole } = user;

  const rootPathWithIds = `/course/${courseId}/assignment/${assignmentId}/results`;

  if (assignment)
    switch (courseRole) {
      case 'STUDENT':
        return (
          <Routes>
            <Route index element={<StudentResultsPage />} />
            <Route path="/dialogue" element={<FullDialoguePage />} />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route index element={<TeacherResultPage assignment={assignment} />} />
            <Route
              path="/benchmark"
              element={assignment.benchmarkGradingAvailable ? <BenchmarkPage /> : <Navigate to={rootPathWithIds} />}
            />
          </Routes>
        );
    }
  return <LoadingSpinner />;
}

export default ResultsPage;
