import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getRubricComments, getRubricRatings } from '../../utils/requests';
import { sortRubric } from '../../utils/functions';
import FormCommentPage from './FormCommentPage';
import FormRatingPage from './FormRatingPage';
import CoreForm from '../core/input/Form/Form';
import { RootState } from '../../store';
import { Comment, Rating, Rubric } from '../../types/types';
import {
  CommentOrderTable,
  deactivatePinning,
  setCommentOrder,
  usePinDropContext,
} from '../../contexts/PinDropContext';

interface Props {
  assignmentId: string;
  onLoad?: () => void;
  onSubmit: () => void;
  saveReview: () => void;
}

function Form({ assignmentId, onLoad, onSubmit, saveReview }: Props): JSX.Element {
  // State:
  const [reviewFormPages, setFormPages] = useState<JSX.Element[]>([]);
  const [comments, setComments] = useState<Comment[] | null>(null);
  const [ratings, setRatings] = useState<Rating[] | null>(null);
  const [rubric, setRubric] = useState<Rubric | null>(null);

  // Redux:
  const saveTimestamp = useSelector((state: RootState) => state.saveTimestamp);

  // PinDrop Context:
  const { pinDropDispatch } = usePinDropContext();

  /**
   * Dynamically builds the pages of the form based on the rubric.
   * Creates a list of the pages and saves the list in state.
   */
  const buildPagesFromRubric = useCallback(
    (rubric: Rubric) => {
      const pages = [] as JSX.Element[];
      rubric.forEach((prompt) => {
        if (prompt.hasOwnProperty('commentId')) {
          const comment = prompt as Comment;
          pages.push(
            <FormCommentPage
              commentId={comment.commentId}
              commentName={comment.commentName}
              commentPrompt={comment.commentPrompt}
              minimumComments={comment.minimumComments}
              maximumComments={comment.maximumComments}
              saveReview={saveReview}
            />,
          );
        } else if (prompt.hasOwnProperty('ratingId')) {
          const rating = prompt as Rating;
          pages.push(
            <FormRatingPage
              ratingId={rating.ratingId}
              name={rating.name}
              prompt={rating.prompt}
              ratingLevels={rating.ratingLevels}
              saveReview={saveReview}
            />,
          );
        }
      });
      // Set the newly created pages as state
      setFormPages(pages);
    },
    [saveReview],
  );

  useEffect(() => {
    getRubricComments(assignmentId, setComments);
    getRubricRatings(assignmentId, setRatings);
  }, [assignmentId]);

  /**
   * Combine into rubric
   */
  useEffect(() => {
    if (comments !== null && ratings !== null) setRubric(sortRubric(comments, ratings));
  }, [comments, ratings]);

  /**
   * Build the form pages once a valid rubric state is stored
   */
  useEffect(() => {
    if (rubric) buildPagesFromRubric(rubric);
  }, [rubric, buildPagesFromRubric]);

  useEffect(() => {
    const commentOrderTable: CommentOrderTable = {};
    comments?.forEach((comment, i) => (commentOrderTable[comment.commentId] = i + 1));
    pinDropDispatch(setCommentOrder(commentOrderTable));
  }, [comments, pinDropDispatch]);

  return (
    <CoreForm
      id="review-form"
      pages={reviewFormPages}
      save={saveReview}
      saveTimestamp={saveTimestamp}
      onLoad={onLoad}
      onSubmit={onSubmit}
      onPageChange={() => pinDropDispatch(deactivatePinning())}
      submitButtonText="Finish"
    ></CoreForm>
  );
}

export default Form;
