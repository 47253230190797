import React from 'react';
import Button from '../core/button/Button/Button';

function NotFoundPage(): JSX.Element {
  return (
    <div className="page page-not-found">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <Button href="/" route>
        Back to Homepage
      </Button>
    </div>
  );
}

export default NotFoundPage;
