import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Rating, RatingScore, UserRatingScore } from '../../types/types';
import { KEY_SELECTED_RATING } from '../../utils/constants';
import { getPropsFromQuery, storageAvailable } from '../../utils/functions';
import { getAverageRatingScoreForAdmin, getRating } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import RatingPrompt from './RatingPrompt';
import Score from '../results/Score';

function RatingPage(): JSX.Element {
  const { ratingId } = useParams() as { ratingId: string };

  const query: { copy?: boolean } = getPropsFromQuery(new URLSearchParams(useLocation().search));
  const [rating, setRating] = useState<Rating | null>(null);
  const [averageRatingScore, setAverageRatingScore] = useState<RatingScore | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    let storedRating = null;
    if (storageAvailable('sessionStorage')) {
      storedRating = JSON.parse(window.sessionStorage.getItem(KEY_SELECTED_RATING) ?? 'null') as Rating | null;
      window.sessionStorage.setItem(KEY_SELECTED_RATING, 'null');
    }
    if (storedRating != null && storedRating.ratingId === ratingId) {
      setRating(storedRating);
    } else {
      getRating(ratingId, setRating);
    }
    getAverageRatingScoreForAdmin(ratingId, setAverageRatingScore);
  }, [ratingId]);

  const onEdit = useCallback(
    (rating: Rating) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(`rating-${rating.ratingId}`, JSON.stringify(rating));
      navigate(`/rubrics/library/edit/rating/${rating.ratingId}`);
    },
    [navigate],
  );

  const returnButtonProps = query.copy ? { onClick: () => navigate(-1) } : { href: '/rubrics/ratings', route: true };

  if (rating)
    return (
      <>
        <section className="prompt-page-main">
          <div className="main-wrapper">
            <Button variant="alt rad xs low" {...returnButtonProps}>
              Return to Prompt List
            </Button>
            <RatingPrompt rating={rating} onEdit={query.copy ? undefined : onEdit} />
            <div className={`panel-sm panel-white rubric-scores-card`}>
              {averageRatingScore?.averageScore ? <h3 className="title">Average Rating Score</h3> : null}
              <div className="scores-wrapper">
                {averageRatingScore?.averageScore ? (
                  <Score
                    key={`score-${averageRatingScore.id}`}
                    id={averageRatingScore.id}
                    name={averageRatingScore.name}
                    classAvg={averageRatingScore.averageScore}
                    studentScore={(averageRatingScore as UserRatingScore).userScore}
                    outOf={5}
                    onSelect={() => undefined}
                  />
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    <b>Average Rating Score Unavailable</b>
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  return <LoadingSpinner />;
}

export default RatingPage;
