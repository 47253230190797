import moment from 'moment';
import React from 'react';
import { AssignmentProgress, UserRole } from '../../../types/types';
import Icon from '../../core/display/Icon';

interface Props {
  asyncEnabled?: boolean;
  children: React.ReactNode;
  role: UserRole;
  type: 'SUBMIT' | 'REVIEW' | 'FEEDBACK' | 'EVALUATE' | 'REFLECT' | 'GROUP_FORMATION';
  userProgress: AssignmentProgress;
}

function PhaseCard({ asyncEnabled, children, role, type, userProgress }: Props): JSX.Element {
  const { id, iconCode, headingText, unlockDate, deadline, lockedClass, locked } = ((
    asyncEnabled,
    role,
    type,
    userProgress,
  ) => {
    const { assignment, status: userStatus } = userProgress;
    const {
      status,
      publicationTime,
      submissionDeadline,
      reviewDeadline,
      feedbackDeadline,
      peerEvaluationDeadline,
      peerEvaluationOnly,
      reflectionDeadline,
      groupFormationDeadline,
      instructorGradedOnly,
    } = assignment;

    switch (type) {
      case 'SUBMIT':
        const submitLocked = status === 'UNPUBLISHED';
        return {
          id: 'submit-card',
          iconCode: 'file_upload',
          headingText: 'Submit',
          unlockDate: moment(publicationTime).format('M/D/YY, h:mm A'),
          deadline: moment(submissionDeadline),
          lockedClass: submitLocked ? ' locked' : '',
          locked: submitLocked,
        };
      case 'REVIEW':
        const reviewLocked =
          !assignment.liveMode &&
          ((!asyncEnabled && userStatus.reviewingUpcoming) ||
            (asyncEnabled === true && status === 'UNPUBLISHED' && role === 'TEACHER'));
        return {
          id: 'review-card',
          iconCode: 'rate_review',
          headingText: 'Review',
          unlockDate: moment(asyncEnabled ? publicationTime : submissionDeadline).format('M/D/YY, h:mm A'),
          deadline: moment(reviewDeadline),
          lockedClass: reviewLocked ? ' locked' : '',
          locked: reviewLocked,
        };
      case 'FEEDBACK':
        const feedbackLocked =
          (!asyncEnabled && userStatus.feedbackUpcoming) ||
          (asyncEnabled === true && status === 'UNPUBLISHED' && role === 'TEACHER');
        return {
          id: 'feedback-card',
          iconCode: 'question_answer',
          headingText: 'Feedback',
          unlockDate: moment(asyncEnabled ? publicationTime : reviewDeadline).format('M/D/YY, h:mm A'),
          deadline: moment(feedbackDeadline),
          lockedClass: feedbackLocked ? ' locked' : '',
          locked: feedbackLocked,
        };
      case 'EVALUATE':
        const evaluationLocked =
          (!asyncEnabled && userStatus.evaluatingUpcoming) ||
          (asyncEnabled === true && status === 'UNPUBLISHED' && role === 'TEACHER');
        return {
          id: 'evaluate-card',
          iconCode: 'supervisor_account',
          headingText: 'Evaluate',
          unlockDate: moment(asyncEnabled || peerEvaluationOnly ? publicationTime : submissionDeadline).format(
            'M/D/YY, h:mm A',
          ),
          deadline: moment(peerEvaluationDeadline),
          lockedClass: evaluationLocked ? ' locked' : '',
          locked: evaluationLocked,
        };
      case 'REFLECT':
        const reflectionLocked =
          (!asyncEnabled && userStatus.reflectingUpcoming) ||
          (asyncEnabled === true && status === 'UNPUBLISHED' && role === 'TEACHER');
        return {
          id: 'reflect-card',
          iconCode: 'edit_square',
          headingText: 'Reflect',
          unlockDate: moment(
            asyncEnabled
              ? publicationTime
              : instructorGradedOnly
              ? submissionDeadline
              : assignment.peerEvaluationEnabled
              ? peerEvaluationDeadline
              : assignment.feedbackEnabled
              ? feedbackDeadline
              : reviewDeadline,
          ).format('M/D/YY, h:mm A'),
          deadline: moment(reflectionDeadline),
          lockedClass: reflectionLocked ? ' locked' : '',
          locked: reflectionLocked,
        };
      case 'GROUP_FORMATION':
        const groupFormationLocked = status === 'UNPUBLISHED';
        return {
          id: 'group-formation-card',
          iconCode: 'groups',
          headingText: 'Groups',
          unlockDate: moment(publicationTime).format('M/D/YY, h:mm A'),
          deadline: moment(groupFormationDeadline),
          lockedClass: groupFormationLocked ? ' locked' : '',
          locked: groupFormationLocked,
        };
    }
  })(asyncEnabled, role, type, userProgress);

  let index = 1;
  if (id != null) {
    const element = document.getElementById(id);
    if (element != null && element.parentNode != null) {
      index = Array.from(element.parentNode.children).indexOf(element) + 1;
    }
  }

  return (
    <div id={id} className={`panel-white phase-card ${lockedClass}`}>
      <div className="phase-icon">
        <Icon code={iconCode} ariaHidden />
      </div>
      <div className="title-row">
        <h2>
          {index}. {headingText}
        </h2>
        {deadline.isValid() ? (
          <div className="deadline-wrapper">
            <p>Due: {deadline.format('M/D/YY')}</p>
            <span className="time">@ {deadline.format('h:mm A')}</span>
          </div>
        ) : null}
      </div>
      {locked && !asyncEnabled ? (
        <div className="unlock-info">
          <Icon code="lock" label="Locked" />
          Unlocks {unlockDate}
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default PhaseCard;
