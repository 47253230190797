import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../core/button/Button/Button';
import { selectAssignment, selectUser } from '../../store/selectors';
import { AssignmentProgress } from '../../types/types';

interface Props {
  courseId: string;
  assignmentId: string;
  assignmentProgress: AssignmentProgress;
  canSelfManageGroup: boolean;
  requestCreateGroup: (assignmentId: string, groupName: string) => void;
}

function StudentGroupsNoGroup({
  courseId,
  assignmentId,
  assignmentProgress,
  canSelfManageGroup,
  requestCreateGroup,
}: Props): JSX.Element {
  const basePath = `/course/${courseId}/assignment/${assignmentId}/groups`;

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  return (
    <div id="no-group">
      <h1>You Are Not Yet In A Group</h1>
      {canSelfManageGroup ? (
        <>
          <Button
            id="group-create-btn"
            variant="rad alt"
            onClick={() => {
              requestCreateGroup(assignmentId, `${assignment?.anonymousGroups ? 'Anonymous' : user.firstName}'s Group`);
            }}
          >
            Create a Group
          </Button>
          <p>O R</p>
          <Button variant="rad alt" href={`${basePath}/student-list`} route>
            Join a Group (via Student)
          </Button>
          <Button variant="rad alt" href={`${basePath}/group-list`} route>
            Join a Group (via Group)
          </Button>
        </>
      ) : assignment?.groupFormationEnabled ? (
        <>
          {assignmentProgress.groupFormationResponseComplete ? (
            <>
              <Button variant="green" href={`${basePath}/formation`} route>
                Group Formation Survey Complete
              </Button>
              <p>You will be placed in a group once your peers have finished the survey</p>
            </>
          ) : (
            <>
              <p>You must fill out a survey to be placed into a group:</p>
              <Button href={`${basePath}/formation`} route>
                Take the Group Formation Survey
              </Button>
            </>
          )}
        </>
      ) : (
        <p>Your instructor must add you to a group</p>
      )}
    </div>
  );
}

export default StudentGroupsNoGroup;
