import React, { useState, useEffect } from 'react';
import { ErrorObj } from '../../utils/requests';

interface Props {
  errors: ErrorObj[];
}

function ErrorAlert({ errors }: Props): JSX.Element {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (errors.length > 0) {
      setVisible(true);
      setTimeout(() => setVisible(false), 3000);
    }
  }, [errors]);

  if (!visible) return <></>;

  return (
    <div id="error-overlay">
      <div id="error-alert" role="alert">
        <p>There was an error processing your request.</p>
        <p>Please try again later.</p>
      </div>
    </div>
  );
}

export default ErrorAlert;
