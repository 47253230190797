import React, { useState, useEffect } from 'react';
import RatingScoresCard from './RatingScoresCard';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import TeacherIndividualScoresTable from './TeacherIndividualScoresTable';
import { AssignmentDetailedResults, RatingScore } from '../../types/types';
import {
  getAssignmentAverageRatingScores,
  getInstructorResultsDetails,
  getInstructorUploadResults,
} from '../../utils/requests';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { TeacherResultsTabProps } from './TeacherResultsPage';

function TeacherSubmissionRatingScores({
  assignment,

  updateKey,
  hideTabId,
  accessPermission,
}: TeacherResultsTabProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };


  const [ratingScores, setRatingScores] = useState<RatingScore[] | null>(null);
  const [results, setResults] = useState<AssignmentDetailedResults[]>([]);
  const [filteredRatingScores, setFilteredRatingScores] = useState<RatingScore[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  useEffect(() => {
    if (assignment.instructorUpload) getInstructorUploadResults(assignmentId, setResults);
    else getInstructorResultsDetails(assignmentId, setResults);
    getAssignmentAverageRatingScores(assignmentId, setRatingScores);
  }, [assignment, updateKey, assignmentId]);

  // Determine which scores to display based on filter
  useEffect(() => {
    if (ratingScores)
      if (filterList.length > 0) {
        const category = filterList[0];
        switch (category) {
          case 'Rating Prompts':
            setFilteredRatingScores(
              ratingScores.filter((ratingScore) => ratingScore.rating !== null && ratingScore.averageScore >= 0),
            );
            break;
          case 'Tags':
            setFilteredRatingScores(
              ratingScores.filter((ratingScore) => ratingScore.tag !== null && ratingScore.averageScore >= 0),
            );
            break;
          default:
            console.error('Invalid category');
        }
      } else {
        setFilteredRatingScores(ratingScores.filter((ratingScore) => ratingScore.averageScore >= 0));
      }
  }, [filterList, ratingScores]);

  useEffect(() => {
    if (ratingScores && ratingScores.length < 1) hideTabId('submission-scores');
  }, [ratingScores, hideTabId]);

  const key = filterList.length > 0 ? filterList[0] : undefined;

  if (ratingScores === null) return <LoadingSpinner />;
  if (ratingScores.length > 0 && results.length > 0) {
    return (
      <>
        <section>
          <RatingScoresCard ratingScores={filteredRatingScores} size="wide" scoresWrapperKey={key}>
            <FilterTab label="Display as:" setFilterList={setFilterList}>
              <FilterTab.Button
                id="btn-ratings"
                type="radio"
                name="rubric-scores-display"
                iconCode="format_list_numbered"
                defaultChecked={true}
              >
                Rating Prompts
              </FilterTab.Button>
              <FilterTab.Button id="btn-tags" type="radio" name="rubric-scores-display" iconCode="local_offer">
                Tags
              </FilterTab.Button>
            </FilterTab>
          </RatingScoresCard>
        </section>
        <section>
          <TeacherIndividualScoresTable assignment={assignment} results={results} accessPermission={accessPermission} />
        </section>
      </>
    );
  }
  return <></>;
}

export default TeacherSubmissionRatingScores;
