import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { outsideClick } from '../../../../utils/functions';
import Icon from '../../display/Icon';

interface PeerHeader {
  title: string;
  accessor: string;
}

interface Props<DataType> {
  headers: PeerHeader[];
  onChange: (arg0: DataType[]) => void;
}

function TableInput<DataType extends { [index: string]: string }>({ headers, onChange }: Props<DataType>): JSX.Element {
  const [rows, setRows] = useState<string[][]>([new Array(headers.length).fill('')]);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const id = useRef(_.uniqueId());
  const tbodyEl = useRef<HTMLTableSectionElement>(null);
  const removeBtnEl = useRef<HTMLButtonElement>(null);

  const aggregateData = useCallback((headers: PeerHeader[], rows: string[][], onChange: (arg0: DataType[]) => void) => {
    const data: DataType[] = [];
    rows.forEach((row) => {
      const dataRowObject: DataType = {} as DataType;
      headers.forEach((header, i) => {
        (dataRowObject as { [index: string]: string })[header.accessor] = row[i];
      });
      data.push(dataRowObject);
    });
    onChange(data);
  }, []);

  const cellIdToIndices = (elem: HTMLElement): { rowNum: number; colNum: number } => {
    const params = elem.id.split('-');
    return { rowNum: parseInt(params[2]), colNum: parseInt(params[3]) };
  };

  const handleCellChange = (e: React.ChangeEvent) => {
    const elem = e.target as HTMLInputElement;
    const { rowNum, colNum } = cellIdToIndices(elem);
    setRows((prevRows) => {
      const newRows = Array.from(prevRows);
      newRows[rowNum][colNum] = elem.value;
      return newRows;
    });
  };

  const handleAddRow = () => {
    setRows((prevRows) => {
      const newRows = Array.from(prevRows);
      newRows.push(new Array(headers.length).fill(''));
      return newRows;
    });
  };

  const handleRemoveRow = () => {
    if (selectedRowId) {
      const params = selectedRowId.split('-');
      const rowNum = parseInt(params[2]);
      setRows((prevRows) => {
        const newRows = Array.from(prevRows);
        newRows.splice(rowNum, 1);
        return newRows.length > 0 ? newRows : [new Array(headers.length).fill('')];
      });
    } else {
      // Remove last row if none selected
      setRows((prevRows) => {
        const newRows = Array.from(prevRows);
        newRows.splice(newRows.length - 1, 1);
        return newRows.length > 0 ? newRows : [new Array(headers.length).fill('')];
      });
    }
  };

  const handleRowClick = (e: React.MouseEvent) => {
    let row = e.target as HTMLElement;
    if (row.tagName === 'INPUT' && row.parentElement) row = row.parentElement;
    if (row.tagName === 'TD' && row.parentElement) row = row.parentElement;
    if (row.tagName === 'TR') setSelectedRowId(row.id);
  };

  const handleSpreadsheetPaste = (e: React.ClipboardEvent) => {
    const clipboardData = e.clipboardData;

    const pastedText = clipboardData.getData('Text') || clipboardData.getData('text/plain');

    if (!pastedText && pastedText.length) return;

    const clipboardRows = pastedText
      .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, function (match, p1) {
        // This function runs for each cell with multi lined text.
        return (
          p1
            // Replace any double double-quotes with a single
            // double-quote
            .replace(/""/g, '"')
            // Replace all new lines with spaces.
            .replace(/\r\n|\n\r|\n|\r/g, ' ')
        );
      })
      // Split each line into rows
      .split(/\r\n|\n\r|\n|\r/g)
      .map((str) => str.split(/\t/g));

    if (clipboardRows[0].length === 1) return;

    e.preventDefault();

    const inputEl = e.target as HTMLInputElement;
    if (inputEl) {
      const { rowNum, colNum } = cellIdToIndices(inputEl);

      // Write copied data into table
      setRows((prevRows) => {
        const newRows = Array.from(prevRows);
        for (let clipRow = 0; clipRow < clipboardRows.length; clipRow++) {
          for (let clipCol = 0; clipCol < clipboardRows[clipRow].length; clipCol++) {
            let row = rowNum + clipRow;
            let col = colNum + clipCol;
            if (col >= newRows[row].length) {
              row++;
              col = col % newRows[row].length;
            }
            newRows[row][col] = clipboardRows[clipRow][clipCol];
            if (
              (col + 1 >= newRows[row].length || clipCol + 1 >= clipboardRows[clipRow].length) &&
              row + 1 >= newRows.length &&
              clipRow + 1 < clipboardRows.length
            )
              newRows.push(new Array(newRows[0].length).fill(''));
          }
        }

        return newRows;
      });
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    handleSpreadsheetPaste(e);
  };

  const checkUnselect = useCallback((e: MouseEvent) => {
    if (tbodyEl.current && removeBtnEl.current && outsideClick(e, [tbodyEl.current, removeBtnEl.current])) {
      setSelectedRowId(null);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', checkUnselect);
    return () => window.removeEventListener('mousedown', checkUnselect);
  }, [checkUnselect]);

  useEffect(() => {
    aggregateData(headers, rows, onChange);
  }, [headers, rows, onChange, aggregateData]);

  return (
    <div className="table-input">
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.accessor}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody ref={tbodyEl}>
          {rows.map((row, i) => {
            const emptyExample = new Array(row.length).fill('');
            const ignoreForValidation = _.isEqual(row, emptyExample);
            return (
              <tr
                key={`tr-${i}`}
                id={`${id.current}-row-${i}`}
                className={selectedRowId === `${id.current}-row-${i}` ? 'selected' : undefined}
                onClick={handleRowClick}
              >
                {row.map((cell, j) => (
                  <td key={`td-${i}-${j}`} onPaste={handlePaste}>
                    <label className="sr-only" htmlFor={`${id.current}-cell-${i}-${j}`}>{`${headers[j].title} ${
                      i + 1
                    }`}</label>
                    <input
                      id={`${id.current}-cell-${i}-${j}`}
                      type="text"
                      value={cell}
                      onChange={handleCellChange}
                      required={!ignoreForValidation}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="ctrls-wrapper">
        <button onClick={handleAddRow} type="button">
          <Icon code="add" label="Add row" />
        </button>
        <button ref={removeBtnEl} onClick={handleRemoveRow} type="button">
          <Icon code="remove" label="Remove row" />
        </button>
      </div>
    </div>
  );
}

export default TableInput;
