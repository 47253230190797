import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmissionInfo } from '../../../../types/types';
import { SCREEN_WIDTH_TABLET } from '../../../../utils/constants';
import { humanFileSize } from '../../../../utils/functions';
import ReportButton from '../../../auth/ReportButton';
import Button from '../../button/Button/Button';
import Icon from '../Icon';
import RichReader from '../RichReader';
import PdfCanvas from '../../editor/PdfCanvas/PdfCanvas';
import VideoJS from '../VideoJS';
import YouTubePlayer, { validateYouTubeUrl } from '../YouTubePlayer';
import { getSubmission } from '../../../../utils/requests';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';

interface Props {
  submissionInfo: SubmissionInfo;
  submitter?: string;
  enableReport?: boolean;
  displayName?: string;
}

interface ReviewSubmissionProps extends Props {
  onChange?: (state: { collapsed: boolean }) => void;
}

function ReviewSubmission({
  enableReport = false,
  submissionInfo,
  submitter,
  displayName = '',
  onChange = () => undefined,
}: ReviewSubmissionProps): JSX.Element {
  const [collapsed, setCollapsed] = useState(window.innerWidth < SCREEN_WIDTH_TABLET);
  const toggleCollapse = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, []);

  useEffect(() => onChange({ collapsed }), [collapsed, onChange]);
  return (
    <div className="review-submission-window">
      <MainRow
        submissionInfo={submissionInfo}
        submitter={submitter}
        collapsed={collapsed}
        toggleCollapse={toggleCollapse}
        displayName={displayName}
        enableReport={enableReport}
      />
      <div className="submission-display-wrapper" style={collapsed ? { display: 'none' } : undefined}>
        <SubmissionDisplay
          key={submissionInfo.submissionId}
          submissionInfo={submissionInfo}
          submitter={submitter}
          displayName={displayName}
        />
      </div>
    </div>
  );
}

interface MainRowProps extends Props {
  enableReport: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
}

function MainRow({ collapsed, enableReport, submissionInfo, submitter, toggleCollapse }: MainRowProps): JSX.Element {
  const { submissionType: type, embeddable, embedUrl, url, fileUrl, fileName } = submissionInfo;

  const renderCtrls = () => {
    switch (type) {
      case 'FILE':
        if (embeddable) {
          return (
            <>
              <Button
                classOverride
                className="circ-btn"
                href={embedUrl as string}
                ariaLabel="Open submission in new tab"
              >
                <Icon code="open_in_new" ariaHidden />
              </Button>
              <Button
                classOverride
                className="circ-btn"
                href={fileUrl as string}
                download={fileName as string}
                ariaLabel="Download submission"
              >
                <Icon code="download" ariaHidden />
              </Button>
            </>
          );
        } else {
          return (
            <>
              <Button
                classOverride
                className="circ-btn"
                href={fileUrl as string}
                download={fileName as string}
                ariaLabel="Download submission"
              >
                <Icon code="download" ariaHidden />
              </Button>
            </>
          );
        }
      case 'LINK':
        return (
          <>
            <Button classOverride className="circ-btn" href={url as string} ariaLabel="Open submission link">
              <Icon code="link" ariaHidden />
            </Button>
          </>
        );
      case 'TEXT':
        return <></>;
      case 'PRESENTATION':
        return <></>;
    }
  };

  return (
    <div className="main-row">
      <div className="details">
        {submitter ? (
          <p>
            <b>Submitter: </b> {submitter}
          </p>
        ) : null}
        <p className="type">
          <b>Type: </b> {type.charAt(0) + type.slice(1).toLowerCase()} {embeddable ? '(PDF)' : null}
        </p>
      </div>

      <div className="ctrls">
        <button id="collapse-btn" className="button-mini" onClick={toggleCollapse}>
          {collapsed ? 'Show' : 'Hide'}
        </button>
        <div className="icon-btns">
          {renderCtrls()}
          {enableReport ? <ReportButton submissionId={submissionInfo.submissionId} /> : null}
        </div>
      </div>
    </div>
  );
}

function SubmissionDisplay({ submissionInfo, displayName }: Props): JSX.Element {
  const { submissionType: type, embeddable, embedUrl, fileUrl, fileName, url, textSubmission } = submissionInfo;

  const [submissionSrc, setSubmissionSrc] = useState<string | null>(null);

  const lastEmbedUrl = useRef(embedUrl);
  const fileSize = useMemo(() => humanFileSize(submissionInfo.fileSize), [submissionInfo.fileSize]);

  const videoOptions = useMemo(
    () =>
      submissionSrc
        ? {
            controls: true,
            responsive: true,
            fluid: true,
            aspectRatio: '16:9',
            fill: true,
            sources: [{ src: submissionSrc, type: submissionInfo.mimeType }],
          }
        : null,
    [submissionSrc, submissionInfo.mimeType],
  );

  useEffect(() => {
    if (embedUrl !== lastEmbedUrl.current) {
      lastEmbedUrl.current = embedUrl;
    }
  }, [embedUrl, submissionInfo]);

  useEffect(() => {
    if (submissionInfo && submissionInfo.fileName) {
      getSubmission(submissionInfo.submissionId, (blob) => setSubmissionSrc(URL.createObjectURL(blob)));
    }
  }, [submissionInfo]);

  switch (type) {
    case 'FILE':
      if (embeddable) {
        return (
          <PdfCanvas url={embedUrl as string} downloadUrl={fileUrl as string} downloadFileName={fileName as string} />
        );
      } else if (submissionInfo.videoStream) {
        if (videoOptions) {
          return (
            <div className="video-file-submission-viewer">
              <VideoJS options={videoOptions} />
            </div>
          );
        } else {
          return <LoadingSpinner position="static" />;
        }
      } else {
        return (
          <div className="file-preview">
            <div className="info">
              <span>{fileName}</span>
              <span>{fileSize}</span>
            </div>
            <Button variant="rad low" href={fileUrl as string} download={fileName as string}>
              <Icon code="download" ariaHidden />
              <span>Download Submission</span>
            </Button>
          </div>
        );
      }
    case 'LINK':
      if (url)
        return (
          <div className="link-preview">
            {validateYouTubeUrl(url) ? (
              <YouTubePlayer url={url} />
            ) : (
              <Button variant="rad low" href={url}>
                <Icon code="link" ariaHidden />
                <span>Open Submission Link</span>
              </Button>
            )}
          </div>
        );
      return <></>;
    case 'TEXT':
      return (
        <div className="text-viewer">
          <RichReader content={textSubmission as string} />
        </div>
      );
    case 'PRESENTATION':
      return <div>In-class Presentation: {displayName}</div>;
  }
}

export default ReviewSubmission;
