import React from 'react';
import PhaseCard from './PhaseCard';
import Button from '../../core/button/Button/Button';
import moment from 'moment';
import { PhaseCardProps } from './StudentDashboardOld';

function FeedbackCard({ progressStats, rootPath, userProgress }: PhaseCardProps): JSX.Element {
  const { status, assignment, feedbackCompleted } = userProgress;
  const { feedbackPhase } = progressStats;

  const getFeedbackIcons = () => {
    const icons: JSX.Element[] = [];

    for (let i = 1; i <= userProgress.feedbackRequiredCount; i++) {
      const complete = i <= userProgress.feedbackCompleted;
      const newIcon = (
        <span key={'feedback-icon-' + i} className="feedback-icon">
          <i className={`feedback-icon-fill${complete ? ' complete' : ''}`}>question_answer</i>
        </span>
      );
      icons.push(newIcon);
    }
    return assignment.liveMode ? [] : icons;
  };

  const getFeedbackButtons = () => {
    const feedbackButtons: JSX.Element[] = [];
    let feedbackNum = 0;

    // Create buttons for any completed feedback
    if (userProgress.reviewsGivenFeedback) {
      userProgress.reviewsGivenFeedback.forEach((review) => {
        if (feedbackPhase && status.canCompleteFeedback && assignment.asyncEnabled === false)
          feedbackButtons.push(
            <Button
              key={`feedback-btn-${feedbackNum}`}
              variant="alt"
              disabled={!feedbackPhase}
              href={`${rootPath}/review/${review.reviewId}/feedback?feedbackCompleted=${feedbackCompleted}`}
              route
            >
              Edit Feedback {feedbackNum + 1}
            </Button>,
          );
        else
          feedbackButtons.push(
            <Button key={`feedback-btn-${feedbackNum}`} variant="alt" disabled>
              Feedback {feedbackNum + 1} Complete
            </Button>,
          );
        feedbackNum++;
      });
    }

    // Create buttons for any incomplete feedback
    if (userProgress.reviewsRequiringFeedback) {
      userProgress.reviewsRequiringFeedback.forEach((review) => {
        if (feedbackPhase && status.canCompleteFeedback)
          feedbackButtons.push(
            <Button
              key={`feedback-btn-${feedbackNum}`}
              href={`${rootPath}/review/${review.reviewId}/feedback?feedbackCompleted=${feedbackCompleted}`}
              route
            >
              Start Feedback {feedbackNum + 1}
            </Button>,
          );
        else
          feedbackButtons.push(
            <Button key={`feedback-btn-${feedbackNum}`} variant="alt" disabled>
              Incomplete Feedback
            </Button>,
          );
        feedbackNum++;
      });
    }

    if (assignment.asyncEnabled && status.canCompleteFeedback)
      while (feedbackButtons.length < assignment.numberOfReviewers) {
        feedbackButtons.push(
          <Button
            key={`feedback-btn-${feedbackNum}`}
            tooltip="Your peer has not yet reviewed your submission. You must wait for their review before providing feedback."
          >
            Awaiting Incoming Review
          </Button>,
        );
        feedbackNum++;
      }

    return feedbackButtons;
  };

  const getNoReviewsReceivedText = () => {
    if (assignment.submissionDeadline !== null && moment().isAfter(assignment.submissionDeadline)) {
      if (status.hasSubmitted)
        return (
          <p>
            Feedback action unavailable <br /> (No reviews received)
          </p>
        );
      else
        return (
          <p>
            Feedback action unavailable <br /> {status.canLateSubmit ? '(Submission required)' : '(Missed submission)'}
          </p>
        );
    }
    return <p>Awaiting reviews...</p>;
  };

  const awaiting = assignment.asyncEnabled && userProgress.reviewsReceived < assignment.numberOfReviewers;
  const missedClass = !status.feedbackUpcoming && status.missedFeedback ? ' fail' : '';
  const completeClass = !awaiting && !status.feedbackUpcoming && status.completedAllFeedback ? ' pass' : '';
  const awaitingClass = awaiting ? ' awaiting' : '';

  return (
    <PhaseCard type="FEEDBACK" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      <div className="feedback-icons-container" aria-hidden>
        {getFeedbackIcons()}
      </div>
      {userProgress.reviewsReceived > 0 ? (
        <p className={`completed-text${missedClass}${completeClass}${awaitingClass}`}>
          Completed: {userProgress.feedbackCompleted}/{userProgress.feedbackRequiredCount}
        </p>
      ) : (
        getNoReviewsReceivedText()
      )}
      {!status.feedbackUpcoming ? getFeedbackButtons() : null}
    </PhaseCard>
  );
}

export default FeedbackCard;
