import React from 'react';
import { Assignment } from '../../types/types';
import { confirmSubmission, postPresentationSubmission } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Submission from '../core/display/Submission/Submission';
import { State } from './SubmissionPage';
import OptionButton from '../core/button/OptionButton/OptionButton';

interface Props {
  allowEdit: boolean;
  assignmentId: string;
  assignment: Assignment;
  asyncEnabled: boolean;
  onDelete: () => void;
  requestSequenceBreak: (arg0: number) => void;
  requestStateChangeInSequence: (arg0: number) => void;
  returnToDashboard: () => void;
  setSubmissionIdAndCleanLocalstorage: (arg0: string) => void;

  submissionId: string;
  submissionState: number;
  submitterId: string;
}

function InClassPresentationMenu({
  onDelete,
  requestSequenceBreak,
  requestStateChangeInSequence,
  returnToDashboard,
  setSubmissionIdAndCleanLocalstorage,
  assignmentId,
  submissionId,
  submissionState,
  submitterId,
  allowEdit,
}: Props): JSX.Element {
  switch (submissionState) {
    case State.SUBMIT:
      return (
        <div className="submission-menu" key="text-confirm" id="confirm-menu" tabIndex={-1}>
          <h1>Submit In-Class Presentation</h1>
          <p>Please click on continue to submit in-class presentation.</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // Post presentation to server
              if (submissionId === '')
                postPresentationSubmission(assignmentId, submitterId, setSubmissionIdAndCleanLocalstorage, () => {
                  requestStateChangeInSequence(State.CONFIRM);
                });
            }}
          >
            <div className="buttons-wrapper">
              <Button
                variant="rad alt"
                type="button"
                onClick={() => {
                  requestSequenceBreak(State.CHOOSE);
                }}
              >
                Back
              </Button>
              <Button variant="rad" type="submit">
                Continue
              </Button>
            </div>
          </form>
        </div>
      );
    case State.CONFIRM:
      return (
        <div className="submission-menu" key="text-confirm" id="confirm-menu" tabIndex={-1}>
          <h1>Confirm &amp; Submit</h1>
          <p>
            In order to submit a presentation, do not need to upload any file. Only need to click on confirm and submit
            button.
          </p>
          <Submission
            key="presentation-confirm"
            submissionId={submissionId}
            flexDirection="column-reverse"
            presentationMenu={() => (
              <div className="view-ctrls">
                <Button
                  variant="rad"
                  onClick={() => {
                    confirmSubmission(submissionId);
                    requestStateChangeInSequence(State.FINISH);
                  }}
                >
                  Confirm &amp; Submit
                </Button>
              </div>
            )}
          />
        </div>
      );
    case State.FINISH:
      return (
        <div className="submission-menu" key="text-finish" id="finish-menu" tabIndex={-1}>
          <h1>Submission Successful</h1>
          <p>Your assignment has been successfully submitted.</p>
          <Button variant="rad" onClick={returnToDashboard}>
            Done
          </Button>
        </div>
      );
    case State.VIEW:
      return (
        <div className="submission-menu" key="presentation-view" id="view-menu" tabIndex={-1}>
          <h1>View Submission</h1>
          <p>
            Below is your presentation submission for your assignment.{' '}
            {allowEdit ? 'Here you may delete your presentation submission.' : ''}
          </p>
          <Submission
            key="presentation-view"
            submissionId={submissionId}
            flexDirection="column-reverse"
            presentationMenu={() => (
              <div className="view-ctrls flex-row">
                {allowEdit ? (
                  <OptionButton
                    id="delete-sub-btn"
                    onClick={() => onDelete()}
                    label="Delete submission"
                    iconCode="delete"
                  />
                ) : null}
              </div>
            )}
          />
        </div>
      );
    default:
      return <></>;
  }
}

export default InClassPresentationMenu;
