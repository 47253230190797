import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import Icon from '../../display/Icon';

interface Props {
  placeholder?: string;
  resultsLength: number;
  setValue: (arg0: string) => void;
  value: string;
}

function SearchBar({ placeholder, resultsLength, setValue, value }: Props): JSX.Element {
  const id = useRef(_.uniqueId('searchbar-'));
  const statusRef = useRef<HTMLDivElement>(null);

  const [debouncedValue, setDebouncedValue] = useState('');

  const clearStyle = value !== '' ? { opacity: '1' } : undefined;

  const debouncedSetDebouncedValue = useMemo(
    () => _.debounce((newValue: string) => setDebouncedValue(newValue), 500),
    [],
  );

  useEffect(() => {
    if (statusRef.current)
      statusRef.current.innerText =
        value === debouncedValue
          ? resultsLength > 0
            ? `${resultsLength} results available`
            : 'No results available'
          : '';
  }, [debouncedValue, resultsLength, value]);

  return (
    <div className="searchbar">
      <label htmlFor={id.current} className="sr-only">
        Search Term
      </label>
      <input
        id={id.current}
        type="text"
        placeholder={placeholder || 'Search'}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          debouncedSetDebouncedValue(e.target.value);
        }}
      />
      <button
        className="clear-search"
        type="button"
        style={clearStyle}
        onClick={() => setValue('')}
        disabled={value === ''}
      >
        <Icon code="cancel" label="Clear search" />
      </button>
      <div ref={statusRef} role="status" className="sr-only" />
    </div>
  );
}

export default SearchBar;
