import React, { useState } from 'react';
import Papa from 'papaparse';
import { ReviewUsers } from '../../../types/types';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import AlertBar from '../../core/display/AlertBar';
import ReviewUsersList from './ReviewUsersList';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { assignReviews } from '../../../utils/requests';
import { useParams } from 'react-router';
import { ReviewManagementMenuProps } from './ReviewManagement';

function AssignReviewsByUploadMenu({ changeToMenu, progress, updateData }: ReviewManagementMenuProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<ReviewUsers[] | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="page" id="review-management">
      {result === null || result.length < 1 ? (
        <form
          id="group-upload-menu"
          onSubmit={(e) => {
            e.preventDefault();
            if (file)
              Papa.parse(file, {
                complete: (results) => {
                  setLoading(true);
                  assignReviews(assignmentId, results.data as string[][], (result) => {
                    setResult(result);
                    updateData();
                    setLoading(false);
                  });
                },
              });
          }}
        >
          <h1>Manage Reviews</h1>
          <Button className="close-btn button-mini" classOverride onClick={() => changeToMenu('MAIN')}>
            <Icon code="close" />
          </Button>
          <p id="explainer">
            Upload a CSV spreadsheet file of student emails, with each row starting with the submitter, and each
            subsequent cell listing a reviewer assigned to that submission. The CSV file should be formatted like this:
          </p>
          <table id="csv-example">
            <tbody>
              <tr>
                <td>
                  <b>submitter_1@edu</b>
                </td>
                <td>reviewer_1@edu</td>
                <td>reviewer_2@edu</td>
                <td>...</td>
              </tr>
              <tr>
                <td>
                  <b>submitter_2@edu</b>
                </td>
                <td>reviewer_3@edu</td>
                <td>reviewer_4@edu</td>
                <td>...</td>
              </tr>
            </tbody>
          </table>
          <label className="peer-button button-rad">
            Choose CSV
            <input
              id="group-csv-upload"
              type="file"
              name="csvFile"
              accept="text/csv"
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                const files = target.files;
                if (files) {
                  const file = files.length > 0 ? files[0] : null;
                  setFile(file);
                }
              }}
              required
            />
          </label>
          <p>{file ? file.name : 'No file chosen'}</p>

          {result && result.length < 1 ? (
            <AlertBar>The file you chose resulted in no new assigned reviews</AlertBar>
          ) : null}

          <Button type="submit">Upload</Button>
        </form>
      ) : (
        <div id="confirmation">
          <h1>
            Reviews Assigned <Icon code="done" className="success-icon" ariaHidden />
          </h1>
          <p>The following reviews have been assigned successfully:</p>
          <ReviewUsersList reviewUsers={result} progress={progress} updateData={updateData} />
          <Button onClick={() => changeToMenu('MAIN')}>Finish</Button>
        </div>
      )}
      {loading ? <LoadingSpinner /> : null}
    </div>
  );
}

export default AssignReviewsByUploadMenu;
