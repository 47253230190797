import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import Icon from '../../display/Icon';

interface Props {
  onChange: (arg0: File | null) => void;
  required?: boolean;
}

function FileDropZone({ onChange, required }: Props): JSX.Element {
  const [file, setFile] = useState<File | null>(null);
  const [mouseEntered, setMouseEntered] = useState<boolean | HTMLDivElement>(false);

  const dropZoneEl = useRef<HTMLDivElement>(null);
  const fileInputEl = useRef<HTMLInputElement>(null);
  const fileNamePreviewEl = useRef<HTMLDivElement>(null);
  const uniqueId = useRef(_.uniqueId());

  const update = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files) {
      const file = files.length > 0 ? files[0] : null;
      setFile(file);
    }
  };

  const dropHandler = (e: React.DragEvent) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
        const file = e.dataTransfer.items[0].getAsFile();
        setFile(file);
        if (fileInputEl.current) fileInputEl.current.files = e.dataTransfer.files;
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        setFile(file);
        if (fileInputEl.current) fileInputEl.current.files = e.dataTransfer.files;
      }
    }
  };

  const dragOverHandler = (e: React.DragEvent) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
  };

  // Handle changes
  useEffect(() => {
    if (onChange) onChange(file);
  }, [file, onChange]);

  return (
    <div
      ref={dropZoneEl}
      className="file-drop-zone"
      onDrop={(e) => {
        dropHandler(e);
        if (dropZoneEl.current) dropZoneEl.current.classList.remove('dragging');
      }}
      onDragOver={dragOverHandler}
      onDragEnter={(e) => {
        setMouseEntered(e.target as HTMLDivElement);
        if (e.target === dropZoneEl.current) dropZoneEl.current.classList.add('dragging');
      }}
      onDragLeave={(e) => {
        if (e.target === mouseEntered && e.target === dropZoneEl.current)
          dropZoneEl.current.classList.remove('dragging');
      }}
    >
      <Icon code="cloud_upload" ariaHidden />
      <p>Drag and drop your file here</p>
      <p>Or</p>

      <div className="upload-btn-wrapper">
        <input id={`dropzone-btn-${uniqueId}`} ref={fileInputEl} type="file" onChange={update} required={required} />
        <label htmlFor={`dropzone-btn-${uniqueId}`} className="peer-button">
          Browse Files
        </label>
      </div>
      <div ref={fileNamePreviewEl} className="file-name-preview">
        {file ? file.name : 'No file chosen'}
      </div>
    </div>
  );
}

export default FileDropZone;
