import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import SingleForm from '../core/input/Form/SingleForm';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import { updatePassword, verifyPasswordResetCode } from '../../utils/requests';
import { formDataToObject, setPageTitle } from '../../utils/functions';
import PasswordInput from './PasswordInput';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { openModal, useModalContext } from '../../contexts/ModalContext';

function ResetPasswordPage(): JSX.Element {
  useEffect(() => setPageTitle('Reset Password'), []);

  const resetPasswordCode = (useParams() as { resetPasswordCode: string }).resetPasswordCode;

  const user = useSelector((state: RootState) => state.user);
  const { forcePasswordChange } = user;

  const [loaded, setLoaded] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  useEffect(() => {
    if (forcePasswordChange) {
      setLoaded(true);
    } else {
      verifyPasswordResetCode(
        resetPasswordCode,
        () => {
          setLoaded(true);
        },
        () => {
          setLoaded(true);
          modalDispatch(
            openModal({
              heading: 'Invalid or Expired Code',
              label:
                'No account found for that password reset code. Password reset links expire one hour after generation.',
              buttonText: 'Return to Login',
              noCancel: true,
              onConfirm: () => navigate('/login'),
            }),
          );
          return true;
        },
      );
    }
  }, [forcePasswordChange, modalDispatch, navigate, resetPasswordCode]);

  const handleSubmit = useCallback(
    (formData: FormData, callback: () => void) => {
      const { password } = formDataToObject(formData) as { password: string; newPassword: string };
      updatePassword(
        password,
        () =>
          modalDispatch(
            openModal({
              heading: 'Password Set',
              label: 'Your password has been set.',
              buttonText: 'Finish',
              noCancel: true,
              onConfirm: () => window.location.replace(`/`),
            }),
          ),
        callback,
      );
    },
    [modalDispatch],
  );

  if (loaded) {
    return (
      <div className="page register-row">
        <img src={logoSrc} width="256" alt="Peerceptiv logo" />
        <SingleForm className="reset-password-form" title="Set Password" submitText="Confirm" onSubmit={handleSubmit}>
          {passwordMismatch ? (
            <p className="invalid-log" role="alert">
              Passwords do not match. Please try again.
            </p>
          ) : null}
          <SingleForm.Row>
            <SingleForm.Col flexBasis="100%">
              <SingleForm.FormGroup>
                <SingleForm.InputGroup iconCode="password" flexDirection="column">
                  <PasswordInput passwordMismatch={passwordMismatch} setPasswordMismatch={setPasswordMismatch} />
                </SingleForm.InputGroup>
              </SingleForm.FormGroup>
            </SingleForm.Col>
          </SingleForm.Row>
        </SingleForm>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default ResetPasswordPage;
