import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Comment } from '../../types/types';
import { KEY_SELECTED_COMMENT } from '../../utils/constants';
import { getPropsFromQuery, storageAvailable } from '../../utils/functions';
import { getComment } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import CommentPrompt from './CommentPrompt';

function CommentPage(): JSX.Element {
  const { commentId } = useParams() as { commentId: string };

  const query: { copy?: boolean } = getPropsFromQuery(new URLSearchParams(useLocation().search));
  const [comment, setComment] = useState<Comment | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    let storedComment = null;
    if (storageAvailable('sessionStorage')) {
      storedComment = JSON.parse(window.sessionStorage.getItem(KEY_SELECTED_COMMENT) ?? 'null') as Comment | null;
      window.sessionStorage.setItem(KEY_SELECTED_COMMENT, 'null');
    }
    if (storedComment != null && storedComment.commentId === commentId) setComment(storedComment);
    else getComment(commentId, setComment);
  }, [commentId]);

  const onEdit = useCallback(
    (comment: Comment) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(`comment-${comment.commentId}`, JSON.stringify(comment));
      navigate(`/rubrics/library/edit/comment/${comment.commentId}`);
    },
    [navigate],
  );

  const returnButtonProps = query.copy ? { onClick: () => navigate(-1) } : { href: '/rubrics/comments', route: true };

  if (comment)
    return (
      <>
        <section className="prompt-page-main">
          <div className="main-wrapper">
            <Button variant="alt rad xs low" {...returnButtonProps}>
              Return to Prompt List
            </Button>
            <CommentPrompt comment={comment} onEdit={query.copy ? undefined : onEdit} />
          </div>
        </section>
      </>
    );
  return <LoadingSpinner />;
}

export default CommentPage;
