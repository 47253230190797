import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Rating, Result } from '../../../types/types';
import { getRubricRatings, getStudentResultsMap } from '../../../utils/requests';
import AccuracyBoxPlot from '../../core/display/Graph/AccuracyBoxPlot';
import GradesCard from '../../results/GradesCard';
import { StudentDetailsTabProps } from './StudentDetailsPage';

function GradeDetails({ assignmentProgress, phaseStatus }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId, userId } = useParams() as { assignmentId: string; userId: string };

  const [studentResultsMap, setStudentResultsMap] = useState<{ [index: string]: Result } | null>(null);
  const [ratings, setRatings] = useState<Rating[]>([]);

  const accuracyCorrelations: number[] = useMemo(
    () => Object.keys(studentResultsMap ?? {}).map((key) => (studentResultsMap ?? {})[key].accuracyCorrelation),
    [studentResultsMap],
  );

  useEffect(() => {
    getStudentResultsMap(assignmentId, setStudentResultsMap);
    getRubricRatings(assignmentId, setRatings);
  }, [assignmentId]);

  if (assignmentProgress.result && studentResultsMap) {
    const studentAccuracy = studentResultsMap[userId].accuracyCorrelation;
    return (
      <>
        <GradesCard assignment={assignmentProgress.assignment} result={assignmentProgress.result} />
        {phaseStatus.review && accuracyCorrelations.length > 0 && ratings.length > 0 ? (
          <div className="panel panel-sm accuracy-card">
            <h2 className="title">Accuracy</h2>
            <p>
              <b>Student&apos;s Accuracy Correlation:</b> {studentAccuracy.toFixed(4)}
            </p>
            <h3>Class Accuracy Correlation Distribution</h3>
            <AccuracyBoxPlot width={300} height={160} data={accuracyCorrelations} highlightedDatum={studentAccuracy} />
          </div>
        ) : null}
      </>
    );
  }
  return <></>;
}

export default GradeDetails;
