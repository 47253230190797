import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { customInvalidMessage, updateValidity } from '../../../../utils/functions';

interface Props {
  choices: string[];
  legend?: string;
  initRankedChoices?: string[];
  onChange?: (rankedChoices: string[]) => void;
}

function RankedChoice({ choices, legend, initRankedChoices, onChange = () => undefined }: Props): JSX.Element {
  const [rankedChoices, setRankedChoices] = useState<string[]>(initRankedChoices ?? []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, choice: string) => {
    setRankedChoices((prevChoices) => {
      const newChoices = _.cloneDeep(prevChoices);
      const index = newChoices.indexOf(choice);
      if (e.target.checked && index === -1) {
        newChoices.push(choice);
      } else if (!e.target.checked && index !== -1) {
        newChoices.splice(index, 1);
      }
      return newChoices;
    });
  };

  useEffect(() => onChange(rankedChoices), [onChange, rankedChoices]);

  return (
    <fieldset className="ranked-choice">
      <legend>{legend ?? 'Rank the following choices:'}</legend>
      {choices.map((choice, i) => {
        const index = rankedChoices.indexOf(choice);
        return (
          <div key={choice} className={`choice ${index !== -1 ? 'checked' : null}`}>
            <div className="rank">{index !== -1 ? index + 1 : null}</div>
            <input
              id={`choice-${i}`}
              name="ranked-choices"
              type="checkbox"
              checked={index !== -1}
              onChange={(e) => {
                handleChange(e, choice);
                updateValidity(e);
              }}
              aria-label={
                index === -1
                  ? `${choice}; Unranked; Check to rank #${rankedChoices.length + 1};`
                  : `${choice}; Ranked #${index + 1}; Uncheck to unrank;`
              }
              required
              onInvalid={customInvalidMessage({ valueMissing: 'Please rank this option to proceed.' })}
            />
            <label htmlFor={`choice-${i}`}>{choice}</label>
          </div>
        );
      })}
    </fieldset>
  );
}

export default RankedChoice;
