import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { RootState } from '../../../store';
import { User, AccessPermission, CourseUser } from '../../../types/types';
import { actAs, deleteUserFromCourse, getCourseUser, updateCourseUserRole, instructorMarkPaid } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import ConfirmButton from '../../core/button/ConfirmButton';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';

interface Props {
  courseId: string;
  enableRemove?: boolean;
  updateData: () => void;
  userId: string;
  accessPermission?: AccessPermission;
  studentPurchaseEnabled?: boolean;
}

function ExpandedRosterRow({
  courseId,
  enableRemove = true,
  updateData,
  userId,
  accessPermission,
  studentPurchaseEnabled,
}: Props): JSX.Element {
  const dashboardUrl = `/course/${courseId}/assignments`;
  const [courseUser, setUser] = useState<CourseUser | undefined>();
  const [loaded, setLoaded] = useState(false);
  const currUser = useSelector((state: RootState) => state.user);
  const { modalDispatch } = useModalContext();

  useEffect(() => {
    getCourseUser(courseId, userId, setUser);
  }, [courseId, userId]);

  useEffect(() => {
    if (courseUser) setLoaded(true);
  }, [courseUser]);

  const handleRoleUpdate = useCallback(() => {
    modalDispatch(
      openModal({
        heading: 'Update Role',
        headingHide: false,
        inputType: 'none',
        form: false,
        closeButton: true,
        noActionButtons: true,
        children: <UpdateButton courseId={courseId} disabled={false} courseUser={courseUser} updateData={updateData} />,
      }),
    );
  }, [courseId, courseUser, modalDispatch, updateData]);

  if (courseUser && loaded) {
    return (
      <div className="expanded-student-row">
        {courseUser ? (
          <div className="action-section">
            <div className="user-details">
              <h1>{courseUser.user.name}</h1>
              <a href={`mailto:${courseUser.user.email}`}>{courseUser.user.email}</a>
            </div>
          </div>
        ) : (
          ''
        )}
        {courseUser.userId !== currUser.userId && accessPermission?.actAsStudentPermission ? (
          <div className="action-section">
            <Button
              variant="rad sm low"
              onClick={() => {
                actAs(courseId, userId, dashboardUrl, () => {
                  window.location.replace(dashboardUrl);
                });
              }}
              disabled={!courseUser.user.activeAccount}
              tooltip={!courseUser.user.activeAccount ? 'Account requires activation' : undefined}
            >
              Act As
            </Button>
          </div>
        ) : null}
        {studentPurchaseEnabled && currUser.admin ? (
          <div className="action-section">
            <MarkPaidButton user={currUser} courseId={courseId} updateData={updateData} />
          </div>
        ) : null}
        <div className="action-section">
          <RemoveButton userId={userId} courseId={courseId} disabled={!enableRemove} updateData={updateData} />
        </div>
        {courseUser.role === 'TEACHING ASSISTANT' ||
        courseUser.role === 'GRADER' ||
        courseUser.role === 'EXTERNAL REVIEWER' ||
        courseUser.role === 'TEACHER' ? (
          <div className="action-section">
            <Button variant="rad sm low" onClick={handleRoleUpdate}>
              Update Role
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
  return <LoadingSpinner />;
}

interface RemoveButtonProps {
  courseId: string;
  disabled?: boolean;
  updateData: () => void;
  userId: string;
}

function RemoveButton({ courseId, disabled, updateData, userId }: RemoveButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  return (
    <>
      <ConfirmButton
        modalProps={{
          heading: 'Remove from Course',
          label: 'Are you sure you want to remove this user from the course?',
          buttonText: 'Remove',
          onConfirm: () =>
            deleteUserFromCourse(userId, courseId, () => {
              updateData();
              modalDispatch(
                openModal({
                  heading: 'User Removed',
                  label: 'The user has been removed from the course.',
                  buttonText: 'Continue',
                  cancelHide: true,
                  form: false,
                }),
              );
            }),
        }}
        noModal
      >
        <Button variant="rad sm low" disabled={disabled}>
          Remove From Course
        </Button>
      </ConfirmButton>
    </>
  );
}

interface MarkPaidButtonProps {
  courseId: string;
  updateData: () => void;
  user: User;
}

function MarkPaidButton({ courseId, updateData, user }: MarkPaidButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  return (
    <>
      <ConfirmButton
        modalProps={{
          heading: 'Mark Student Paid',
          label: `Are you sure you want to mark ${user.name} paid?`,
          buttonText: 'Confirm',
          onConfirm: () => {
            instructorMarkPaid(courseId, user.userId, () => {
              updateData();
              modalDispatch(
                openModal({
                  heading: 'Student Marked as Paid',
                  label: 'The studnet has been marked as paid.',
                  buttonText: 'Continue',
                  cancelHide: true,
                  form: false,
                  inputType: 'none',
                }),
              );
            });
          },
        }}
        noModal
      >
        <Button variant="rad sm low" >Mark As Paid</Button>
      </ConfirmButton>
    </>
  );
}

interface UpdateButtonProps {
  courseId: string;
  disabled?: boolean;
  updateData: () => void;
  courseUser: CourseUser | undefined;
}

function UpdateButton({ courseId, disabled, updateData, courseUser }: UpdateButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();
  const [selectedRole, setSelectedRole] = useState('TEACHING ASSISTANT');
  const handleRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleUpdateCourseUserRole = () => {
    if (courseUser != null) {
      updateCourseUserRole(courseId, courseUser?.userId, selectedRole, () => {
        modalDispatch(
          openModal({
            heading: `Role updated`,
            label: `The role has been updated.`,
            inputType: 'none',
            form: false,
            closeButton: true,
            noActionButtons: true,
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
        updateData();
      });
    }
  };

  return (
    <div>
      <div className="selection">
        <select value={selectedRole ? selectedRole : ''} onChange={handleRoleChange} id="roles" name="roles">
          <option value="TEACHING ASSISTANT">TA</option>
          <option value="LEAD INSTRUCTOR">Lead Instructor</option>
          <option value="TEACHER">Teacher</option>
          <option value="GRADER">Grader</option>
        </select>
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'baseline', marginTop: '1rem' }}>
          <Button className="update-role-btn" onClick={handleUpdateCourseUserRole}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ExpandedRosterRow;
