import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import './components/main.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { PRODUCTION_ENV, SENTRY_IGNORE_LIST } from './utils/constants';
import axe from '@axe-core/react';
import ErrorFallbackPage from './components/error/ErrorFallbackPage';
import 'simplebar-react/dist/simplebar.min.css';

if (PRODUCTION_ENV) {
  Sentry.init({
    dsn: 'https://1a2251e17a074fb3aa9d4abbfc6a0459@o1044586.ingest.sentry.io/6019697',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    environment: 'production',
    ignoreErrors: SENTRY_IGNORE_LIST,
  });
} else {
  axe(React, ReactDOM, 5000);
}

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  // TODO: Reintroduce React.StrictMode after implementing something like React Query
  root.render(
    <Sentry.ErrorBoundary fallback={(errorData) => <ErrorFallbackPage {...errorData} />} showDialog>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
