import React from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Point } from '@visx/point';
import { Line, LineRadial } from '@visx/shape';

const degrees = 360;

const genAngles = (length: number) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length) + (length % 2 === 0 ? 0 : degrees / length / 2),
  }));

const genPoints = (length: number, radius: number) => {
  const step = (Math.PI * 2) / length;
  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step),
    y: radius * Math.cos(i * step),
  }));
};

function genPolygonPoints(dataArray: Datum[], scale: (n: number) => number, getValue: (d: Datum) => number) {
  const step = (Math.PI * 2) / dataArray.length;
  const points: { x: number; y: number }[] = new Array(dataArray.length).fill({ x: 0, y: 0 });
  const pointString: string = new Array(dataArray.length + 1).fill('').reduce((res, _, i) => {
    if (i > dataArray.length) return res;
    const xVal = scale(getValue(dataArray[i - 1])) * Math.sin((i - 1) * step);
    const yVal = scale(getValue(dataArray[i - 1])) * Math.cos((i - 1) * step);
    points[i - 1] = { x: xVal, y: yVal };
    res += `${xVal},${yVal} `;
    return res;
  });

  return { points, pointString };
}

const defaultMargin = { top: 16, left: 16, right: 16, bottom: 16 };

type Datum = { x: number; y: number };

export type RadarDataSeries = {
  data: Datum[];
  color: string;
};

const y = (d: Datum) => d.y;

const defaultGraphLineStroke = '#d9d9d9';

interface Props {
  dataSeries: RadarDataSeries[];
  labels?: string[];
  width: number;
  height: number;
  id?: string;
  levels?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  maxDomain?: number;
  graphLineStroke?: string;
}

function RadarChart({
  dataSeries,
  labels,
  width,
  height,
  id,
  levels = 5,
  margin = defaultMargin,
  maxDomain,
  graphLineStroke = defaultGraphLineStroke,
}: Props) {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 2;

  const radialScale = scaleLinear<number>({
    range: [0, Math.PI * 2],
    domain: [degrees, 0],
  });

  const dataLength = dataSeries.length > 0 ? dataSeries[0].data.length : 0;
  const webs = genAngles(dataLength);

  return width < 10 ? null : (
    <svg id={id} width={width} height={height} style={{ overflow: 'visible' }}>
      <rect fill="transparent" width={width} height={height} rx={14} />
      <Group top={height / 2 - margin.top} left={width / 2}>
        {[...new Array(levels)].map((_, i) => (
          <LineRadial
            key={`web-${i}`}
            data={webs}
            angle={(d) => radialScale(d.angle) ?? 0}
            radius={((i + 1) * radius) / levels}
            fill="none"
            stroke={graphLineStroke}
            strokeWidth={2}
            strokeOpacity={0.8}
            strokeLinecap="round"
          />
        ))}

        {dataSeries.length > 0
          ? [...new Array(dataSeries[0].data.length)].map((_, i) => (
              <Line
                key={`radar-line-${i}`}
                from={new Point({ x: 0, y: 0 })}
                to={genPoints(dataSeries[0].data.length, radius)[i]}
                stroke={defaultGraphLineStroke}
              />
            ))
          : null}

        {dataSeries.map((series) => (
          <DataPresentation key={series.color} dataSeries={series} radius={radius} maxDomain={maxDomain} />
        ))}

        {labels && dataSeries.length > 0
          ? genPoints(dataSeries[0].data.length, radius * 1.08).map((point, i) => (
              <text
                key={`radar-point-${i}`}
                x={point.x}
                y={point.y}
                textAnchor={(() => {
                  const xRounded = Math.round(point.x);
                  if (xRounded > 0) return 'start';
                  if (xRounded < 0) return 'end';
                  return 'middle';
                })()}
                fontSize={10}
              >
                {labels[i]}
              </text>
            ))
          : null}
      </Group>
    </svg>
  );
}

function DataPresentation({
  dataSeries,
  maxDomain,
  radius,
}: {
  dataSeries: RadarDataSeries;
  maxDomain?: number;
  radius: number;
}): JSX.Element {
  const yScale = scaleLinear<number>({
    range: [0, radius],
    domain: [0, maxDomain ?? Math.max(...dataSeries.data.map(y))],
  });

  const polygonPoints = genPolygonPoints(dataSeries.data, (d) => yScale(d) ?? 0, y);

  return (
    <>
      <polygon
        points={polygonPoints.pointString}
        fill={dataSeries.color}
        fillOpacity={0.1}
        stroke={dataSeries.color}
        strokeWidth={1}
      />
      {polygonPoints.points.map((point, i) => (
        <circle key={`radar-point-${i}`} cx={point.x} cy={point.y} r={4} fill={dataSeries.color} />
      ))}
    </>
  );
}

export default RadarChart;
