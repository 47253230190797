import React, { useCallback, useEffect, useRef, useState } from 'react';
import pdfjs from '../../../../utils/pdfjsLegacy';
import { handleEnterKey, removeAllChildren } from '../../../../utils/functions';
import _ from 'lodash';
import Button from '../../button/Button/Button';
import Icon from '../../display/Icon';
import { PdfPin, deactivatePinning, unseekComment, usePinDropContext } from '../../../../contexts/PinDropContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useDispatch } from 'react-redux';
import { updateCommentTable } from '../../../../actions';
import JumpButton from '../../button/JumpButton';

const parseZoomNum = (val: number) => Math.min(Math.max(val, 25), 500);

type Coord = { x: number; y: number };

interface Props {
  downloadFileName: string;
  downloadUrl: string;
  url: string;
}

function PdfCanvas({ downloadFileName, downloadUrl, url }: Props): JSX.Element {
  const pdfjsLib = pdfjs as typeof pdfjs | undefined;

  const id = useRef(_.uniqueId());
  const containerRef1 = useRef<HTMLDivElement>(null);
  const containerRef2 = useRef<HTMLDivElement>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  const [pdf, setPdf] = useState<pdfjs.PDFDocumentProxy | null>(null);
  const [numPages, setNumPages] = useState(-1);
  const [currPage, setCurrPage] = useState(1);
  const [currPageText, setCurrPageText] = useState('1');
  const [zoom, setZoom] = useState(100);
  const [zoomText, setZoomText] = useState('100%');
  const [disableZoom, setDisableZoom] = useState(false);
  const [drawCanvasUpdateKey, setDrawCanvasUpdateKey] = useState(0);
  const [mouseHovering, setMouseHovering] = useState(false);
  const [drawLayerVisible, setDrawLayerVisible] = useState(true);
  const [resolution, setResolution] = useState(calcResolution());

  // PinDrop Context:
  const { pinDropContextState, pinDropDispatch } = usePinDropContext();

  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);

  const getDisplayContainer = () => {
    if (containerRef1.current && containerRef1.current.style.display !== 'none') return containerRef1.current;
    if (containerRef2.current && containerRef2.current.style.display !== 'none') return containerRef2.current;
    return null;
  };

  const handleCurrentPageEdit = useCallback(() => {
    if (numPages > 0) {
      let inputNum = parseInt(currPageText);
      if (isNaN(inputNum)) inputNum = currPage;
      const parsedNum = Math.min(Math.max(inputNum, 1), numPages);
      setCurrPage(parsedNum);
      setCurrPageText(`${parsedNum}`);

      // Scroll current page into view
      let displayContainer = getDisplayContainer();
      if (displayContainer && displayContainer.childNodes.length === numPages) {
        const page = displayContainer.childNodes[parsedNum - 1] as HTMLCanvasElement;
        page.scrollIntoView(true);
      }
    }
  }, [currPageText, currPage, numPages]);

  const handleZoomEdit = useCallback(() => {
    let inputNum = parseInt(zoomText);
    if (isNaN(inputNum)) inputNum = zoom;
    const parsedNum = parseZoomNum(inputNum);
    setZoom(parsedNum);
    setZoomText(`${parsedNum}%`);
  }, [zoomText, zoom]);

  const handleZoomButton = useCallback(
    (type: 'decrease' | 'increase') => {
      const zoomLandmarks = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
      if (!zoomLandmarks.includes(zoom)) {
        zoomLandmarks.push(zoom);
        zoomLandmarks.sort();
      }
      const indexOfCurrentZoom = zoomLandmarks.indexOf(zoom);
      let newZoom = 100;
      switch (type) {
        case 'decrease':
          newZoom = zoomLandmarks[Math.max(0, indexOfCurrentZoom - 1)];
          break;
        case 'increase':
          newZoom = zoomLandmarks[Math.min(indexOfCurrentZoom + 1, zoomLandmarks.length - 1)];
      }

      setZoom(newZoom);
      setZoomText(`${newZoom}%`);
    },
    [zoom],
  );

  const drawDot = useCallback((context: CanvasRenderingContext2D, coord: Coord, label: string) => {
    const x = coord.x * context.canvas.width;
    const y = coord.y * context.canvas.height;

    const fontstyle = 'bold';
    const fontsize = 12;
    const fontface = 'Roboto';
    const lineHeight = fontsize * 1.286;

    context.font = `${fontstyle} ${fontsize}px ${fontface}`;
    const textWidth = context.measureText(label).width;

    const padding = 12;
    const boxHeight = lineHeight + padding;
    const boxWidth = Math.max(textWidth + padding, boxHeight);

    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.fillStyle = '#DF830A';
    context.beginPath();
    if (context.roundRect) context.roundRect(x - boxWidth / 2, y - boxHeight / 2, boxWidth, boxHeight, [boxHeight / 2]);
    else context.rect(x - boxWidth / 2, y - boxHeight / 2, boxWidth, boxHeight);
    context.fill();

    context.fillStyle = '#FFFFFF';
    context.fillText(label, x, y);
  }, []);

  const handleCanvasClick = useCallback(
    (e: MouseEvent) => {
      const canvas = e.target as HTMLCanvasElement;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const trueX = x / rect.width;
      const y = e.clientY - rect.top;
      const trueY = y / rect.height;
      const pageNum = parseInt(canvas.getAttribute('page') ?? '');
      if (!isNaN(pageNum)) {
        const { commentId, commentNumber } = pinDropContextState;
        if (commentId && commentNumber) {
          const commentEntry =
            commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber)
              ? commentTable[commentId][commentNumber]
              : null;
          const comment = commentEntry?.comment ?? '';
          const altText = commentEntry?.pinDrop?.altText;
          dispatch(
            updateCommentTable({
              commentId,
              commentNumber,
              comment,
              pinDrop: { pageNum: pageNum, xPosition: trueX, yPosition: trueY, timestamp: -1, altText },
            }),
          );
        }
      }
      pinDropDispatch(deactivatePinning());
    },
    [commentTable, pinDropContextState, dispatch, pinDropDispatch],
  );

  // Disable changing zoom again until new pages are rendered
  useEffect(() => setDisableZoom(true), [zoom]);

  useEffect(() => {
    pdfjsLib
      ?.getDocument({
        url: url,
        withCredentials: true,
      })
      .promise.then(
        (pdf) => {
          setPdf(pdf);
          setNumPages(pdf.numPages);
        },
        (reason) => console.error(reason),
      );
  }, [pdfjsLib, url]);

  useEffect(() => {
    if (pdf && numPages > 0 && containerRef1.current && containerRef2.current) {
      let isFirstRender = false;

      const createPageChildren = (pageEl: HTMLDivElement, i: number) => {
        const canvas = document.createElement('canvas');
        const drawCanvas = document.createElement('canvas');
        const textLayer = document.createElement('div');
        canvas.className = 'pdf-canvas';
        textLayer.className = 'text-layer';
        drawCanvas.className = 'draw-canvas';
        drawCanvas.setAttribute('page', `${i}`);
        pageEl.appendChild(canvas);
        pageEl.appendChild(textLayer);
        pageEl.appendChild(drawCanvas);
      };

      const createPageElems = (containerEl: HTMLDivElement) => {
        for (let i = 0; i < numPages; i++) {
          const page = document.createElement('div');
          page.className = 'pdf-page';
          containerEl.appendChild(page);
          createPageChildren(page, i);
        }
      };

      if (!containerRef1.current.firstChild && !containerRef2.current.firstChild) {
        isFirstRender = true;

        createPageElems(containerRef1.current);
        createPageElems(containerRef2.current);

        containerRef1.current.style.display = 'none';
        containerRef2.current.style.display = 'none';
      }

      let bufferContainer = null;
      if (containerRef1.current.style.display === 'none') bufferContainer = containerRef1.current;
      if (containerRef2.current.style.display === 'none') bufferContainer = containerRef2.current;

      // Recreate canvases
      if (bufferContainer) {
        bufferContainer.querySelectorAll('.pdf-page').forEach((elem, i) => {
          const pageElem = elem as HTMLDivElement;
          removeAllChildren(pageElem);
          createPageChildren(pageElem, i);
        });
      }

      const pageDivs = bufferContainer?.childNodes;
      if (pageDivs && pageDivs.length === numPages) {
        let currPage = 1;
        const renderPromises: Promise<void>[] = [];
        const handlePages = (page: pdfjs.PDFPageProxy) => {
          page.cleanup();

          const scale = zoom / 100;
          const viewport = page.getViewport({ scale });

          const pageDiv = pageDivs[currPage - 1] as HTMLDivElement;
          const pdfCanvas = pageDiv.querySelector('canvas.pdf-canvas') as HTMLCanvasElement | null;
          const drawCanvas = pageDiv.querySelector('canvas.draw-canvas') as HTMLCanvasElement | null;
          const textLayer = pageDiv.querySelector('div');

          if (pdfCanvas && textLayer && drawCanvas) {
            const pdfCanvasContext = pdfCanvas.getContext('2d') as CanvasRenderingContext2D;
            const drawCanvasContext = pdfCanvas.getContext('2d') as CanvasRenderingContext2D;

            pageDiv.style.width = `${viewport.width}px`;
            pageDiv.style.height = `${viewport.height}px`;
            pageDiv.style.setProperty('--scale-factor', `${scale}`);
            pdfCanvas.width = resolution * viewport.width;
            pdfCanvas.height = resolution * viewport.height;
            pdfCanvas.style.width = `${viewport.width}px`;
            pdfCanvas.style.height = `${viewport.height}px`;
            drawCanvas.width = viewport.width;
            drawCanvas.height = viewport.height;
            drawCanvasContext?.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
            textLayer.style.width = `${viewport.width}px`;
            textLayer.style.height = `${viewport.height}px`;

            const renderPromise = page.render({
              canvasContext: pdfCanvasContext,
              viewport,
              transform: [resolution, 0, 0, resolution, 0, 0],
            }).promise;
            renderPromises.push(renderPromise);

            page.getTextContent().then((textContent) => {
              pdfjs.renderTextLayer({
                textContentSource: textContent,
                container: textLayer,
                viewport,
                textDivs: [],
              });
            });
          }

          currPage++;
          if (currPage <= numPages) pdf.getPage(currPage).then(handlePages);
          else {
            Promise.all(renderPromises).then(() => {
              if (containerRef1.current && containerRef2.current) {
                const container1Display = containerRef1.current.style.display;
                containerRef1.current.style.display = containerRef2.current.style.display;
                containerRef2.current.style.display = container1Display;
                if (isFirstRender) containerRef2.current.style.display = '';
              }
              setDisableZoom(false);
              setDrawCanvasUpdateKey((prevKey) => prevKey + 1);
            });
          }
        };

        pdf.getPage(currPage).then(handlePages);
      }
    }
  }, [pdf, numPages, zoom, resolution]);

  useEffect(() => {
    if (containerRef1.current && containerRef2.current) {
      const drawCanvases = [
        ...(containerRef1.current.querySelectorAll('canvas.draw-canvas') as NodeListOf<HTMLCanvasElement>),
        ...(containerRef2.current.querySelectorAll('canvas.draw-canvas') as NodeListOf<HTMLCanvasElement>),
      ];
      drawCanvases.forEach((drawCanvas) => (drawCanvas.onmousedown = handleCanvasClick));
    }
  }, [handleCanvasClick]);

  useEffect(() => {
    const resizeHandler = () => setResolution(calcResolution());
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  });

  useEffect(() => {
    const handleScroll = (e: Event) => {
      const scrollWrapper = e.target as HTMLElement;
      const scrollerTop = scrollWrapper.scrollTop;
      const displayContainer = getDisplayContainer();
      if (displayContainer) {
        const parentTop = displayContainer.getBoundingClientRect().top;
        const pages = Array.from(displayContainer.children);
        const pageTops = pages.map((page) => page.getBoundingClientRect().top - parentTop);
        let found = false;
        let pageNum = 1;
        for (let i = 0; i < pageTops.length - 1 && !found; i++) {
          if (scrollerTop > (pageTops[i + 1] - pageTops[i]) / 2 + pageTops[i]) pageNum = i + 2;
          else found = true;
        }
        if (!found) pageNum = pageTops.length;
        setCurrPage(pageNum);
        setCurrPageText(`${pageNum}`);
      }
    };

    const scrollElem = scrollWrapperRef.current;
    scrollElem?.addEventListener('scroll', handleScroll);
    return () => {
      scrollElem?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let displayContainer = getDisplayContainer();
    if (displayContainer) {
      const drawCanvases = displayContainer.querySelectorAll('canvas.draw-canvas') as NodeListOf<HTMLCanvasElement>;
      drawCanvases.forEach((drawCanvas) => {
        const context = drawCanvas.getContext('2d');
        if (context) context.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      });

      for (const commentId in commentTable) {
        for (const commentNumber in commentTable[commentId]) {
          const { pinDrop } = commentTable[commentId][commentNumber];
          if (pinDrop) {
            const { pageNum, xPosition, yPosition } = pinDrop as PdfPin;
            if (pageNum !== -1 && pageNum < drawCanvases.length) {
              const drawContext = drawCanvases[pageNum].getContext('2d');
              const dotLabel = `${pinDropContextState.commentOrder[commentId]}.${commentNumber}`;
              if (drawContext) drawDot(drawContext, { x: xPosition, y: yPosition }, dotLabel);
            }
          }
        }
      }
    }
  }, [drawCanvasUpdateKey, commentTable, drawDot, pinDropContextState.commentOrder]);

  // Control scrolling to pin-drop locations on PDF if Seek Context Actions are used
  useEffect(() => {
    const { seekComment, commentId, commentNumber } = pinDropContextState;
    if (
      seekComment &&
      commentId &&
      commentNumber &&
      commentTable.hasOwnProperty(commentId) &&
      commentTable[commentId].hasOwnProperty(commentNumber)
    ) {
      const { pinDrop } = commentTable[commentId][commentNumber];
      const displayContainer = getDisplayContainer();
      const scrollWrapper = scrollWrapperRef.current;
      if (pinDrop && displayContainer && scrollWrapper) {
        const page = displayContainer.childNodes[(pinDrop as PdfPin).pageNum] as HTMLDivElement;
        const pageTop = page.offsetTop;
        const offset = (pinDrop as PdfPin).yPosition * page.getBoundingClientRect().height;
        const top = Math.max(pageTop + offset - 64, 0);
        scrollWrapper.scrollTo({ top, behavior: 'smooth' });
      }
      pinDropDispatch(unseekComment());
    }
  }, [commentTable, pinDropContextState, pinDropDispatch]);

  return (
    <div
      className="pdf-canvas-viewer"
      onMouseOver={() => setMouseHovering(true)}
      onMouseOut={() => setMouseHovering(false)}
    >
      <p className="sr-pdf-alert sr-only" role="alert">
        You are being presented with a PDF. You can{' '}
        <a href={downloadUrl} download={downloadFileName} target="_blank" rel="noreferrer">
          download the document as a PDF here. <br />
        </a>
        Need software to view a PDF?{' '}
        <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">
          Download Adobe Acrobat Reader here.
        </a>
      </p>
      <JumpButton invisible id={`pre-pdf-btn-${id.current}`} targetId={`post-pdf-btn-${id.current}`} type="focus">
        Skip to after PDF
      </JumpButton>
      <div className="toolbar">
        <div className="nav-ctrls ctrl-group">
          <label className="sr-only" htmlFor={`nav-input-${id}`}>
            Current page:
          </label>
          <input
            id={`nav-input-${id}`}
            type="text"
            value={currPageText}
            onChange={(e) => setCurrPageText(e.target.value)}
            onBlur={handleCurrentPageEdit}
            onKeyDown={(e) => handleEnterKey(e, handleCurrentPageEdit)}
            autoComplete="off"
          />
          <span style={numPages > 0 ? undefined : { opacity: 0 }}> / {numPages}</span>
        </div>
        <div className="zoom-ctrls ctrl-group">
          <Button
            className="button-mini"
            classOverride
            onClick={() => handleZoomButton('decrease')}
            disabled={disableZoom}
          >
            <Icon code="remove" label="Zoom out" />
          </Button>
          <label className="sr-only" htmlFor={`zoom-input-${id}`}>
            Zoom:
          </label>
          <input
            id={`zoom-input-${id}`}
            type="text"
            value={zoomText}
            onChange={(e) => setZoomText(e.target.value)}
            onBlur={handleZoomEdit}
            onKeyDown={(e) => handleEnterKey(e, handleZoomEdit)}
            disabled={disableZoom}
            autoComplete="off"
          />
          <Button
            className="button-mini"
            classOverride
            onClick={() => handleZoomButton('increase')}
            disabled={disableZoom}
          >
            <Icon code="add" label="Zoom in" />
          </Button>
        </div>
        <div className="annotation-ctrls ctrl-group">
          <Button
            className="button-mini"
            classOverride
            onClick={() => setDrawLayerVisible((prevState) => !prevState)}
            tooltip={`${drawLayerVisible ? 'Hide' : 'Show'} Annotations`}
          >
            <Icon
              code={drawLayerVisible ? 'visibility' : 'visibility_off'}
              label={`${drawLayerVisible ? 'Hide' : 'Show'} Annotations`}
            />
            <Icon className="pen-icon" code="draw" ariaHidden />
          </Button>
        </div>
      </div>
      <div className="pdf-scroll-wrapper" ref={scrollWrapperRef} aria-label="PDF document" role="document" tabIndex={0}>
        <div
          className={`pdf-container ${pinDropContextState.commentId ? 'draw-enabled' : ''} ${
            drawLayerVisible ? '' : 'hide-annotations'
          }`}
          ref={containerRef1}
        />
        <div
          className={`pdf-container ${pinDropContextState.commentId ? 'draw-enabled' : ''} ${
            drawLayerVisible ? '' : 'hide-annotations'
          }`}
          ref={containerRef2}
        />
      </div>
      {pinDropContextState.commentId && !pinDropContextState.seekComment && !mouseHovering ? (
        <div className="pin-ready-overlay">Hover over this document to select a pin location</div>
      ) : null}
      {pdfjsLib === undefined ? (
        <div className="error-overlay">
          <div>
            Please update your browser to use this PDF Reader. Otherwise, you may access this PDF by downloading it via
            the <Icon code="download" /> button above.
          </div>
        </div>
      ) : null}
      <JumpButton invisible id={`post-pdf-btn-${id.current}`} targetId={`pre-pdf-btn-${id.current}`} type="focus">
        Skip to before PDF
      </JumpButton>
    </div>
  );
}

const calcResolution = () => {
  const pixelRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  return Math.max(1, 1 / pixelRatio, pixelRatio);
};

export default PdfCanvas;
