import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { User } from '../../../../types/types';
import { endActAs, resetTestDb } from '../../../../utils/requests';
import { useLocation, useParams } from 'react-router';
import logoIcon from '../../../../assets/logoIcon.png';
import Button from '../../button/Button/Button';
import HelpMenu from './HelpMenu';
import Icon from '../../display/Icon';
import { displayNavbar } from '../../../../utils/functions';
import { useSelector } from 'react-redux';
import { selectAssignment, selectCourse } from '../../../../store/selectors';
import NotificationsMenu from './NotificationsMenu';
import Avatar from '../../display/Avatar/Avatar';

interface Props {
  path: string;
  user: User;
}

function MainNavbar({ path, user }: Props): JSX.Element {
  const params = useParams() as { courseId?: string };
  const { courseId } = params;

  const course = useSelector(selectCourse);
  const assignment = useSelector(selectAssignment);
  const location = useLocation();

  const [numNotifications, setNumNotifications] = useState(0);

  useEffect(() => {
    const siteBanner = document.getElementById('site-banner');
    if (siteBanner) siteBanner.style.paddingTop = `56px`;
    return () => {
      if (siteBanner) siteBanner.style.paddingTop = `0`;
    };
  }, []);

  const displayActAs = () => {
    if (user.actingAs)
      return (
        <div className="acting-as">
          <Button
            id="end-act-as-btn"
            variant="alt-white sm rad"
            onClick={() =>
              endActAs((data) => {
                if (data.actAsUrl) window.location.replace(data.actAsUrl);
              })
            }
          >
            Stop Acting As
          </Button>
          <h2>Currently Acting As:</h2>
        </div>
      );
  };

  const checkTeacherRole = () => {
    return (
      user.role === 'TEACHER' ||
      user.role === 'TEACHING ASSISTANT' ||
      user.role === 'GRADER' ||
      user.role === 'EXTERNAL REVIEWER' ||
      user.schoolAdmin ||
      user.admin
    );
  };

  const checkInstructorRole = () => {
    return user.schoolAdmin || user.admin || (user.courseRole!==undefined &&(user.courseRole === 'TEACHER'));
  };

  const onNotificationsPage = location.pathname === '/notifications';

  if (!displayNavbar(user)) return <></>;

  return (
    <Navbar id="main-navbar">
      <Navbar.Logo href="/" src={logoIcon} width={40} alt="Peerceptiv logo" size="lg" />
      <Navbar.Logo href="/" src={logoIcon} width={40} alt="Peerceptiv logo" size="sm" />
      <Navbar.Content>
        {course ? (
          <Navbar.Menu title="Course" id="course-nav-menu">
            <h3>{course.courseName}</h3>
            <h4>{course.university}</h4>
            <Link className="course-link" to={`/course/${courseId}/assignments`}>
              <span>
                <Icon className="link-icon" code="view_list" ariaHidden />
                <span>All Assignments</span>
              </span>
              <Icon code="navigate_next" ariaHidden />
            </Link>
            {!course.template && checkTeacherRole() ? (
              <>
                <Link className="course-link" to={`/course/${courseId}/roster`}>
                  <span>
                    <Icon className="link-icon" code="groups" ariaHidden />
                    <span>Roster</span>
                  </span>
                  <Icon code="navigate_next" ariaHidden />
                </Link>
                <Link className="course-link" to={`/course/${courseId}/progress`}>
                  <span>
                    <Icon className="link-icon" code="update" ariaHidden />
                    <span>Progress</span>
                  </span>
                  <Icon code="navigate_next" ariaHidden />
                </Link>
                {course.ltiContextId ? (
                  <Link className="course-link" to={`/course/${courseId}/lti`}>
                    <span>
                      <Icon className="link-icon" code="link" ariaHidden />
                      <span>LTI Gradebook Connections</span>
                    </span>
                    <Icon code="navigate_next" ariaHidden />
                  </Link>
                ):null}
                {checkInstructorRole() ? (
                  <Link className="course-link" to={`/course/${courseId}/permission`}>
                    <span>
                      <Icon className="link-icon" code="edit" ariaHidden />

                      <span>Manage Roster Permissions</span>
                    </span>
                    <Icon code="navigate_next" ariaHidden />
                  </Link>
                ) : null}
                {checkInstructorRole() ? (
                  <Link className="course-link" to={`/course/${courseId}/section`}>
                    <span>
                      <Icon className="link-icon" code="group" ariaHidden />

                      <span>Manage Course Sections</span>
                    </span>
                    <Icon code="navigate_next" ariaHidden />
                  </Link>
                ) : null}
              </>
            ) : null}
            <Button id="course-list-btn" variant="alt-white sm rad" href="/course/dashboard" route>
              Return to Course List
            </Button>
          </Navbar.Menu>
        ) : null}

        <Navbar.Menu title="Profile" id="profile-menu">
          {displayActAs()}
          <div className="profile-info">
            <Avatar size={64} user={user} />
            <div>
              <h2>{user.name}</h2>
              <p>{user.email}</p>
            </div>
            <Button className="profile-btn" variant="alt-white sm rad" href="/profile" route>
              View Profile
            </Button>
            {(user.courseRole ?? user.role) === 'TEACHER' ? (
              <>
                <Button className="profile-btn" variant="alt-white sm rad" href="/rubrics/library" route>
                  <Icon code="content_paste" />
                  Manage Rubrics
                </Button>
                {user.purchasingEnabled ? (
                  <Button className="profile-btn" variant="alt-white sm rad" href="/purchase" route>
                    <Icon code="shopping_cart" />
                    Purchase Access
                  </Button>
                ) : null}
              </>
            ) : null}

            {user.admin ? (
              <>
                <Button className="profile-btn" variant="alt-white sm rad" href="/admin/dashboard" route>
                  <Icon code="admin_panel_settings" ariaHidden />
                  Admin Dashboard
                </Button>
                <Button
                  className="profile-btn"
                  variant="alt-white sm rad"
                  onClick={() => {
                    resetTestDb();
                  }}
                >
                  Reset Test Database
                </Button>
              </>
            ) : null}
          </div>
          <hr />
          <Link id="logout-btn" to="/logout">
            <Icon code="logout" ariaHidden />
            <span>Logout</span>
          </Link>
        </Navbar.Menu>

        {!onNotificationsPage ? (
          <Navbar.Menu title="Notifications" id="notifications-menu">
            <NotificationsMenu setNumNotifications={setNumNotifications} />
          </Navbar.Menu>
        ) : null}

        <Navbar.Menu title="Help" id="help-menu">
          <HelpMenu
            role={user.courseRole ?? user.role}
            course={course ?? undefined}
            assignment={assignment ?? undefined}
            path={path}
          />
        </Navbar.Menu>

        {course ? (
          <Navbar.Button label="Course" menuId="course-nav-menu" className="navbar-course-button">
            {course.courseName}
          </Navbar.Button>
        ) : null}
        <Navbar.Button label="Account Profile" menuId="profile-menu">
          <Avatar size={32} user={user} acting={user.actingAs} />
        </Navbar.Button>
        <Navbar.Button
          label={`Notifications ${numNotifications > 0 ? `(${numNotifications} Unread)` : ''}`}
          menuId="notifications-menu"
          disabled={onNotificationsPage}
        >
          <Icon code="notifications" ariaHidden />
          {numNotifications > 0 && !onNotificationsPage ? (
            <span className="notif-num-label">{numNotifications < 100 ? numNotifications : '99+'}</span>
          ) : null}
        </Navbar.Button>
        <Navbar.Button label="Help" menuId="help-menu">
          <Icon code="help_outline" ariaHidden />
        </Navbar.Button>
      </Navbar.Content>
    </Navbar>
  );
}

export default MainNavbar;
