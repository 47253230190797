import moment from 'moment';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EvaluationTarget, RatingTemplate, RubricTarget } from '../../types/types';
import { KEY_SELECTED_RATING } from '../../utils/constants';
import { setPageTitle, storageAvailable } from '../../utils/functions';
import {
  copyRatingIntoAssignmentEvalRubric,
  copyRatingIntoAssignmentRubric,
  copyRatingIntoRubricTemplate,
  getUserRatingTemplates,
} from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';

interface Props {
  isCopy?: boolean;
}

function RatingLibrary({ isCopy = false }: Props): JSX.Element {
  useEffect(() => setPageTitle('Rating Library'), []);

  const { rubricTemplateId, assignmentId, target } = useParams() as {
    rubricTemplateId?: string;
    assignmentId?: string;
    target?: RubricTarget;
  };

  const [addedList, setAddedList] = useState<string[]>([]);

  const navigate = useNavigate();

  const onAdd = useCallback(
    (ratingTemplate: RatingTemplate) => {
      if (rubricTemplateId) {
        copyRatingIntoRubricTemplate(rubricTemplateId, ratingTemplate.rating.ratingId, () => undefined);
      } else if (assignmentId && target) {
        if (target === 'SUBMISSION')
          copyRatingIntoAssignmentRubric(assignmentId, ratingTemplate.rating.ratingId, () => undefined);
        else
          copyRatingIntoAssignmentEvalRubric(
            assignmentId,
            target as EvaluationTarget,
            ratingTemplate.rating.ratingId,
            () => undefined,
          );
      }
      setAddedList((prevList) => [...prevList, ratingTemplate.rating.ratingId]);
    },
    [assignmentId, rubricTemplateId, target],
  );

  const handleRatingSelect = useCallback(
    (ratingTemplate: RatingTemplate) => {
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(KEY_SELECTED_RATING, JSON.stringify(ratingTemplate.rating));
      const copyParam = isCopy ? '?copy=true' : '';
      navigate(`/rubrics/rating/${ratingTemplate.rating.ratingId}${copyParam}`);
    },
    [navigate, isCopy],
  );

  const columns = useMemo<Column<RatingTemplate>[]>(() => {
    const cols: Column<RatingTemplate>[] = [
      { Header: 'Rating Name', accessor: 'name', className: 'left-align' },
      {
        Header: 'Created By',
        accessor: 'user',
        className: 'left-align',
        notSortable: true,
        Cell: (ratingTemplate: RatingTemplate) => ratingTemplate.user?.name,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (ratingTemplate: RatingTemplate) => moment(ratingTemplate.rating.createdAt).format('MMM D, YYYY'),
        className: 'left-align',
      },
      { Header: 'Uses', accessor: 'uses', className: 'center-align', notSortable: true },
    ];

    if (isCopy)
      cols.push({
        Header: 'Add',
        accessor: 'ratingId',
        Cell: function AddCell(ratingTemplate: RatingTemplate) {
          if (addedList.includes(ratingTemplate.rating.ratingId)) return <Button disabled>Added</Button>;
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onAdd(ratingTemplate);
              }}
            >
              Add
            </Button>
          );
        },
        className: 'center-align',
        notSortable: true,
      });

    return cols;
  }, [isCopy, addedList, onAdd]);

  return (
    <>
      <h1 className="sr-only">Rating Prompts</h1>
      {isCopy ? (
        <Button id="copy-return-btn" onClick={() => navigate(-1)}>
          Return to Rubric
        </Button>
      ) : null}
      <QueryTable
        title="Rating Prompts"
        ctrlsInsert={
          <div className="col-2">
            <Button variant="low sm" href="/rubrics/library/new/rating" route>
              <Icon code="add" ariaHidden /> New Rating
            </Button>
          </div>
        }
        columns={columns}
        queryRequest={getUserRatingTemplates}
        onRowSelect={handleRatingSelect}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsWrapperClassName: 'col-2',
          tableClassName: 'home-body',
        }}
      />
    </>
  );
}

export default RatingLibrary;
