import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';

function PaymentCancelled(): JSX.Element {
  const { courseId } = useParams() as { courseId?: string };

  return (
    <div id="payment-cancelled-page" className="page">
      <div className="panel purchase-menu">
        <h1>
          Payment Cancelled <Icon code="close" id="failure-icon" />
        </h1>

        <p>Your payment has been cancelled.</p>

        {courseId ? <p>Cannot enter course until access is successfully purchased.</p> : null}

        <Button id="redirect-btn" href="/" route>
          Home
        </Button>
      </div>
    </div>
  );
}

export default PaymentCancelled;
