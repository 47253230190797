import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import AlertBar from '../core/display/AlertBar';
import TutorialModal from './TutorialModal';
import Icon from '../core/display/Icon';

interface Props {
  buttonMode?: boolean;
  buttonText?: string;
  pinningEnabled?: boolean;
}

function CommentTutorial({ buttonMode = false, buttonText = '', pinningEnabled = false }: Props): JSX.Element {
  const user = useSelector((state: RootState) => state.user);
  const userId = user.actingAs ? 'null' : user.userId;
  const courseId = user.actingAs ? 'null' : user.courseRoleCourseId;

  return (
    <TutorialModal
      id={`comment-tutorial-for-${userId}-in-${courseId}`}
      heading="Tips for Helpful Commenting"
      buttonMode={buttonMode}
      buttonText={buttonText}
    >
      <div id="comment-tutorial">
        <ol>
          <li>Read the prompt carefully</li>
          <li>Point out strengths of the submission</li>
          <li>Offer specific ideas for how to improve your peers&apos; work</li>
        </ol>

        {pinningEnabled ? (
          <p>
            For PDF or video submissions, use the <b>Pin Icon</b>{' '}
            <Icon code="push_pin" style={{ fontSize: '16px', verticalAlign: 'middle' }} ariaHidden /> to connect your
            comment to a specific location in your peers&apos; work.
          </p>
        ) : null}

        <AlertBar backgroundColor="#ffefd2">
          The peers you are reviewing will score the helpfulness of each comment you provide.
        </AlertBar>
      </div>
    </TutorialModal>
  );
}

export default CommentTutorial;
