import React, { useState } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { ModalProps } from '../display/Modal/Modal';
import Button from './Button/Button';

interface Props {
  children: React.ReactNode;
  modalProps: ModalProps;
  noModal?: boolean;
}

function ConfirmButton({ children, modalProps, noModal = false }: Props): JSX.Element {
  const [openInPlace, setOpenInPlace] = useState(false);
  const { modalDispatch } = useModalContext();

  if (noModal) {
    if (!openInPlace) {
      return (
        <div className="confirm-btn-wrapper" onClick={() => setOpenInPlace(true)}>
          {children}
        </div>
      );
    } else {
      return (
        <div className="confirm-btn-wrapper">
          <p>
            <b>{modalProps.label}</b>
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'baseline' }}>
            <Button onClick={modalProps.onConfirm}>{modalProps.buttonText}</Button>
            <Button onClick={() => setOpenInPlace(false)}>Cancel</Button>
          </div>
        </div>
      );
    }
  }
  return (
    <div
      className="confirm-btn-wrapper"
      onClick={() =>
        modalDispatch(
          openModal({
            ...modalProps,
          }),
        )
      }
    >
      {children}
    </div>
  );
}

export default ConfirmButton;
