import React from 'react';
import Form from '../../core/input/Form/Form';

interface Props {
  studentSelectedEvaluations: boolean;
  setStudentSelectedEvaluations: React.Dispatch<React.SetStateAction<boolean>>;
}

function EvaluationSetup({ studentSelectedEvaluations, setStudentSelectedEvaluations }: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>Team Member Evaluation Only</Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="col-inputs">
          <legend>How should evaluations be issued?</legend>
          <div className="rad-radio-btn">
            <input
              id="studentGroups"
              type="radio"
              name="studentSelectedEvaluations"
              value="false"
              checked={!studentSelectedEvaluations}
              onChange={(e) => setStudentSelectedEvaluations(e.target.value === 'true')}
            />
            <label htmlFor="reviewGraded">Student Groups</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="studentSelectedEvaluations"
              type="radio"
              name="studentSelectedEvaluations"
              value="true"
              checked={studentSelectedEvaluations}
              onChange={(e) => setStudentSelectedEvaluations(e.target.value === 'true')}
            />
            <label htmlFor="teacherGraded">Students Select Peers to Evaluate</label>
          </div>
        </fieldset>
      </Form.Body>
    </>
  );
}

export default EvaluationSetup;
