import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Group } from '../../types/types';
import Button from '../core/button/Button/Button';
import Dropdown from '../core/button/Dropdown/Dropdown';
import Icon from '../core/display/Icon';
import SearchBar from '../core/input/SearchBar/SearchBar';
import { selectAssignment, selectUser } from '../../store/selectors';

interface Props {
  canSelfManageGroup: boolean;
  groups: Group[];
  myGroup: Group | null;
  myPendingGroups: Group[];
  requestAddUserToGroup: (userId: string, groupId: string, callback?: () => void) => void;
}

function StudentGroupsGroupsList({
  canSelfManageGroup,
  groups,
  myGroup,
  myPendingGroups,
  requestAddUserToGroup,
}: Props): JSX.Element {
  const [searchFilter, setSearchFilter] = useState(''); // Debounced
  const [searchValue, setSearchValue] = useState(''); // Raw Data
  const [sendLoading, setSendLoading] = useState<string[]>([]);

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  const { modalDispatch } = useModalContext();

  const filteredGroups = useMemo(
    () =>
      groups
        .filter((group) => {
          if (
            searchFilter !== '' &&
            group.groupName?.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) === -1
          )
            return false;
          return true;
        })
        .sort((a, b) => (a.groupName || '').localeCompare(b.groupName || '')),
    [groups, searchFilter],
  );

  const debouncedSetSearchFilter = useMemo(
    () =>
      _.debounce(setSearchFilter, 500, {
        maxWait: 1000,
      }),
    [],
  );

  useEffect(() => {
    debouncedSetSearchFilter(searchValue);
  }, [searchValue, debouncedSetSearchFilter]);

  const getNamesPreview = (names: string[]): string => {
    let numNamesForPreview = 0;
    let currCharacters = 0;
    const MAX_CHARS = 16;
    for (let i = 0; i < names.length && currCharacters <= MAX_CHARS; i++) {
      currCharacters += names[i].length;
      if (currCharacters <= MAX_CHARS) numNamesForPreview = i + 1;
    }

    let namesPreview = '';
    for (let i = 0; i < numNamesForPreview; i++) {
      if (i === names.length - 1) namesPreview += names[i];
      else if (i + 1 < numNamesForPreview) namesPreview += names[i] + ', ';
      else namesPreview += names[i] + '…';
    }

    return namesPreview;
  };

  const addSendLoadingState = (userId: string) => {
    setSendLoading((prevLoadingList) => [...prevLoadingList, userId]);
  };

  useEffect(() => {
    setSendLoading([]);
  }, [myGroup, myPendingGroups]);

  const isPendingJoin = (groupId: string): boolean => {
    let groupIsPending = false;
    myPendingGroups.some((group) => {
      if (group.groupId === groupId) {
        groupIsPending = true;
        return true;
      }
    });
    return groupIsPending;
  };

  const getGroupButton = (group: Group): JSX.Element => {
    if (sendLoading.includes(group.groupId))
      return (
        <Button className="sending-wait-btn" variant="rad" disabled>
          {group.openAccess ? 'Joining…' : 'Sending…'}
        </Button>
      );
    else if (group.groupId === myGroup?.groupId)
      return (
        <Button variant="rad" disabled>
          My Group
        </Button>
      );
    else if (isPendingJoin(group.groupId))
      return (
        <Button className="invite-sent-btn" variant="rad" disabled>
          <span>Request Sent</span>
          <Icon code="done" ariaHidden />
        </Button>
      );
    else if (canSelfManageGroup && assignment?.enableGroupLeaders === false && !group.openAccess)
      return (
        <Button variant="rad" disabled>
          Closed Group
        </Button>
      );
    else if (group.groupMembers.length >= (assignment?.groupSizeLimit ?? 100))
      return (
        <Button variant="rad" disabled>
          Group Full
        </Button>
      );
    else
      return (
        <Button
          variant="rad"
          disabled={!canSelfManageGroup}
          tooltip={!canSelfManageGroup ? 'Only your instructor(s) may manage groups for this assignment.' : undefined}
          onClick={() => {
            const sendRequest = () => {
              requestAddUserToGroup(user.userId, group.groupId);
              addSendLoadingState(group.groupId);
            };

            if (myGroup !== null) {
              modalDispatch(
                openModal({
                  heading: 'Already in a Group',
                  inputType: 'none',
                  buttonText: group.openAccess ? 'Join Group' : 'Send Request',
                  children: (
                    <div id="already-in-group-modal-content">
                      <p>
                        {`You are already in a group. Are you sure you want to join `}
                        <b>{`"${group.groupName}"`}</b>
                        {`?`}
                      </p>
                      {group.openAccess ? (
                        <p>{`Note: Joining a new group will cause you to leave your current group.`}</p>
                      ) : (
                        <p>{`Note: You will remain in your current group until your request is accepted.`}</p>
                      )}
                    </div>
                  ),
                  onConfirm: sendRequest,
                }),
              );
            } else {
              sendRequest();
            }
          }}
        >
          {group.openAccess ? 'Join Group' : 'Request to Join'}
        </Button>
      );
  };

  return (
    <div id="group-list">
      <div id="list-ctrls">
        <SearchBar
          placeholder="Search Groups"
          value={searchValue}
          setValue={setSearchValue}
          resultsLength={filteredGroups.length}
        />
      </div>

      <div id="list-wrapper">
        {filteredGroups.map((group) => (
          <div key={`group-${group.groupId}`} className="group-entry">
            <div className="details">
              <span className="name">
                <b>{group.groupName}</b>
              </span>
              <span className="members">
                {assignment?.anonymousGroups ? (
                  <span className="group-size-indicator">
                    <Icon code="person" ariaHidden />
                    <span role="img" aria-label={`${(group.groupMembers ?? []).length} Members`}>
                      <b>{(group.groupMembers ?? []).length}</b>
                    </span>
                  </span>
                ) : (
                  <Dropdown
                    buttonContent={
                      <>
                        <span className="group-size-indicator">
                          <Icon code="person" ariaHidden />
                          <span role="img" aria-label={`${(group.groupMembers ?? []).length} Members`}>
                            <b>{(group.groupMembers ?? []).length}</b>
                          </span>
                          <span className="names-preview">
                            {getNamesPreview(
                              ((group.groupMembers ?? []).map((member) => member.user.firstName as string) ?? []).sort(
                                (a, b) => (a.length < b.length ? -1 : 1),
                              ),
                            )}
                          </span>
                        </span>
                      </>
                    }
                    iconCode="expand_more"
                    align="left"
                  >
                    <ul tabIndex={0} aria-label="Group Members">
                      {(group.groupMembers ?? [])
                        .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
                        .map((member) => (
                          <li key={`name-${member.user.userId}`}>{member.user.name}</li>
                        ))}
                    </ul>
                  </Dropdown>
                )}
              </span>
            </div>

            {getGroupButton(group)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default StudentGroupsGroupsList;
