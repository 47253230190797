import React, { useState, useMemo, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import {
  AssignmentDashboardStats,
  CourseDashboardStats,
  RubricDashboardStats,
  UserDashboardStats,
} from '../../../../types/types';
import {
  getAssignmentDashboardStats,
  getCourseDashboardStats,
  getRubricDashboardStats,
  getUserDashboardStats,
} from '../../../../utils/requests';
import DonutChart from '../../display/Graph/DonutChart';
import SemesterBarChart from '../../display/Graph/SemesterBarChart';
import Icon from '../../display/Icon';

interface Props {
  type: 'COURSE' | 'ASSIGNMENT' | 'RUBRIC' | 'USER';
}

function DashboardStatsBar({ type }: Props): JSX.Element {
  const [courseStats, setCourseStats] = useState<CourseDashboardStats | null>(null);
  const [assignmentStats, setAssignmentStats] = useState<AssignmentDashboardStats | null>(null);
  const [rubricStats, setRubricStats] = useState<RubricDashboardStats | null>(null);
  const [userStats, setUserStats] = useState<UserDashboardStats | null>(null);

  const assignmentTypeData = useMemo(
    () =>
      assignmentStats
        ? [
            {
              label: 'Peer Assessment',
              total: assignmentStats.totalPeerAssessments,
              usage: (assignmentStats.totalPeerAssessments / assignmentStats.totalAssignments) * 100,
              color: '#5144DB',
            },
            {
              label: 'Team Member Evaluation',
              total: assignmentStats.totalPeerEvaluations,
              usage: (assignmentStats.totalPeerEvaluations / assignmentStats.totalAssignments) * 100,
              color: '#DB4839',
            },
            {
              label: 'Group Peer Assessment',
              total: assignmentStats.totalGroupPeerAssessments,
              usage: (assignmentStats.totalGroupPeerAssessments / assignmentStats.totalAssignments) * 100,
              color: '#39DB85',
            },
            {
              label: 'Other',
              total: assignmentStats.totalOtherAssignments,
              usage: (assignmentStats.totalOtherAssignments / assignmentStats.totalAssignments) * 100,
              color: '#DBC647',
            },
          ]
        : null,
    [assignmentStats],
  );

  useEffect(() => {
    switch (type) {
      case 'COURSE':
        getCourseDashboardStats(setCourseStats);
        break;
      case 'ASSIGNMENT':
        getAssignmentDashboardStats(setAssignmentStats);
        break;
      case 'RUBRIC':
        getRubricDashboardStats(setRubricStats);
        break;
      case 'USER':
        getUserDashboardStats(setUserStats);
        break;
    }
  }, [type]);

  return (
    <section className="data-bar">
      <SimpleBar className="card-scroller">
        {(() => {
          switch (type) {
            case 'COURSE':
              if (courseStats)
                return (
                  <>
                    <div className="card feature-number">
                      <p className="title">Active Courses</p>
                      <p className="main">
                        <b>
                          {courseStats.totalActiveCourses}
                          <span className="live-dot" />
                        </b>
                      </p>
                      <p className="secondary">
                        <b>{courseStats.totalCourses}</b> Total
                      </p>
                    </div>
                    <div className="card">
                      <SemesterBarChart
                        width={208}
                        height={80}
                        data={courseStats.activeCoursesPerSemester}
                        xLabel="# Active Courses"
                      />
                    </div>
                  </>
                );
              break;
            case 'ASSIGNMENT':
              if (assignmentStats && assignmentTypeData)
                return (
                  <>
                    <div className="card feature-number">
                      <p className="title">Active Assignments</p>
                      <p className="main">
                        <b>
                          {assignmentStats.totalActiveAssignments} <div className="live-dot" />
                        </b>
                      </p>
                      <p className="secondary">
                        <b>{assignmentStats.totalAssignments}</b> Total
                      </p>
                    </div>
                    <div className="card-horizontal" id="assignment-type-chart">
                      <DonutChart
                        labelMain={`${assignmentStats.totalAssignments}`}
                        labelSub="Total"
                        data={assignmentTypeData}
                        width={96}
                        height={96}
                        donutThickness={12}
                      />
                      <table className="interactions-table">
                        <thead className="sr-only">
                          <tr>
                            <th>Assignment Type</th>
                            <th>Total Assignments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignmentTypeData.map((datum) => (
                            <tr key={datum.label}>
                              <td>
                                <div className="color-box" style={{ backgroundColor: datum.color }} />
                                {datum.label}
                              </td>
                              <td className="num">({datum.total})</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="card">
                      <SemesterBarChart
                        width={208}
                        height={80}
                        data={assignmentStats.activeAssignmentsPerSemester}
                        xLabel="# Active Assignments"
                      />
                    </div>
                  </>
                );
              break;
            case 'RUBRIC':
              if (rubricStats)
                return (
                  <>
                    <div className="card table-card">
                      <p className="title">Total Rubrics</p>
                      <table>
                        <tbody>
                          <tr>
                            <td>{rubricStats.totalAssignmentRubrics}</td>
                            <th>assignment rubrics</th>
                          </tr>
                          <tr>
                            <td>{rubricStats.totalRubricTemplates}</td>
                            <th>rubric templates</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card table-card">
                      <p className="title">Prompt Library</p>
                      <table>
                        <tbody>
                          <tr>
                            <td>{rubricStats.totalCommentPrompts}</td>
                            <th>comment prompts</th>
                          </tr>
                          <tr>
                            <td>{rubricStats.totalRatingPrompts}</td>
                            <th>rating prompts</th>
                          </tr>
                          <tr>
                            <td>{rubricStats.totalTags}</td>
                            <th>rating tags</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              break;
            case 'USER':
              if (userStats)
                return (
                  <>
                    <div className="card feature-number">
                      <p className="title">Online Users</p>
                      <p className="main">
                        <b>
                          <Icon code="person_outline" ariaHidden />
                          {userStats.totalUsersOnlineNow}
                          <span className="live-dot" />
                        </b>
                      </p>
                      <p className="secondary">{userStats.totalUsersOnlineToday} Today</p>
                    </div>
                    <div className="card feature-number">
                      <p className="title">Total Users</p>
                      <p className="main-2">
                        <b>{userStats.totalUsers}</b>
                      </p>
                    </div>
                    <div className="card">
                      <SemesterBarChart
                        width={208}
                        height={80}
                        data={userStats.activeUsersPerSemester}
                        xLabel="# Active Users"
                      />
                    </div>
                  </>
                );
              break;
          }
        })()}
      </SimpleBar>
    </section>
  );
}

export default DashboardStatsBar;
