import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { Group, User, AssignmentProgress, UserWithGroupInfo } from '../../types/types';
import {
  acceptGroupInvite,
  addUserToGroup,
  createGroup,
  getAssignmentGroups,
  getGroupsRoster,
  getStudentAssignmentProgress,
  getMyPendingGroups,
  rejectGroupInvite,
  removeUserFromGroup,
  promoteUserToGroupLeader,
  demoteGroupLeader,
  openAssignmentGroup,
  closeAssignmentGroup,
} from '../../utils/requests';
import MiniNav from '../core/layout/MiniNav/MiniNav';
import InviteAccept from './InviteAccept';
import InviteReject from './InviteReject';
import StudentGroupsGroupsList from './StudentGroupsGroupsList';
import StudentGroupsMyGroup from './StudentGroupsMyGroup';
import StudentGroupsNoGroup from './StudentGroupsNoGroup';
import StudentGroupsStudentList from './StudentGroupsStudentList';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';
import _ from 'lodash';
import GroupFormation from './GroupFormation';

interface Props {
  circles?: boolean;
  saveGroup: (group: Group, callback?: () => void) => void;
}

function StudentGroupsPage({ saveGroup }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const rootPathWithIds = `/course/${courseId}/assignment/${assignmentId}/groups`;

  const [userProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const [myPendingGroups, setMyPendingGroups] = useState<Group[]>([]);
  const [groupsRoster, setGroupsRoster] = useState<UserWithGroupInfo[]>([]);
  const [allGroups, setAllGroups] = useState<Group[]>([]);
  const [updateKey, setUpdateKey] = useState(0);

  const assignment = useSelector(selectAssignment);

  const myGroup = useMemo(() => (userProgress ? userProgress.group : undefined), [userProgress]);

  useEffect(() => getStudentAssignmentProgress(assignmentId, setAssignmentProgress), [updateKey, assignmentId]);

  useEffect(() => getGroupsRoster(assignmentId, setGroupsRoster), [assignmentId, updateKey]);

  useEffect(() => getAssignmentGroups(assignmentId, setAllGroups), [assignmentId, updateKey]);

  useEffect(() => getMyPendingGroups(assignmentId, setMyPendingGroups), [assignmentId, myGroup, updateKey]);

  const forceUpdate = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  const requestRemoveUserFromGroup = useCallback(
    (user: User, group: Group) => removeUserFromGroup(assignmentId, group.groupId, user.userId, forceUpdate),
    [assignmentId, forceUpdate],
  );

  const requestAddUserToGroup = useCallback(
    (userId: string, groupId: string, callback: () => void = () => undefined) =>
      addUserToGroup(assignmentId, userId, groupId, () => {
        forceUpdate();
        callback();
      }),
    [assignmentId, forceUpdate],
  );

  const wrappedSaveGroup = useCallback(
    (group: Group) =>
      setAssignmentProgress((prevProg) => {
        if (prevProg && !_.isEqual(prevProg.group, group)) {
          saveGroup(group, forceUpdate);
          return { ..._.cloneDeep(prevProg), group };
        }
        return prevProg;
      }),
    [forceUpdate, saveGroup],
  );

  const requestAcceptGroupInvite = useCallback(
    (assignmentId: string, groupInviteId: string, callback: () => void = () => undefined) =>
      acceptGroupInvite(assignmentId, groupInviteId, () => {
        callback();
        forceUpdate();
      }),
    [forceUpdate],
  );

  const requestRejectGroupInvite = useCallback(
    (assignmentId: string, groupInviteId: string, callback: () => void = () => undefined) =>
      rejectGroupInvite(assignmentId, groupInviteId, () => {
        callback();
        forceUpdate();
      }),
    [forceUpdate],
  );

  const requestCreateGroup = useCallback(
    (assignmentId: string, groupName: string) => createGroup(assignmentId, groupName, forceUpdate),
    [forceUpdate],
  );

  const requestPromoteUserToGroupLeader = useCallback(
    (assignmentId: string, groupId: string, userId: string) =>
      promoteUserToGroupLeader(assignmentId, groupId, userId, forceUpdate),
    [forceUpdate],
  );

  const requestDemoteGroupLeader = useCallback(
    (assignmentId: string, groupId: string, userId: string) =>
      demoteGroupLeader(assignmentId, groupId, userId, forceUpdate),
    [forceUpdate],
  );

  const requestSetGroupOpenAccess = useCallback(
    (assignmentId: string, groupId: string, access: 'open' | 'closed') => {
      if (access === 'open') openAssignmentGroup(assignmentId, groupId, forceUpdate);
      if (access === 'closed') closeAssignmentGroup(assignmentId, groupId, forceUpdate);
    },
    [forceUpdate],
  );

  return (
    <div id="student-groups-page" className="page">
      <Routes>
        {[`/my-group/*`, `/student-list/*`, `/group-list/*`].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <MiniNav>
                <MiniNav.Link to={`${rootPathWithIds}/my-group`}>My Group</MiniNav.Link>
                {!assignment?.anonymousGroups ? (
                  <MiniNav.Link to={`${rootPathWithIds}/student-list`}>Students</MiniNav.Link>
                ) : null}
                <MiniNav.Link to={`${rootPathWithIds}/group-list`}>Groups</MiniNav.Link>
              </MiniNav>
            }
          />
        ))}
      </Routes>
      <section id="groups-content">
        {userProgress && myGroup !== undefined ? (
          <Routes>
            <Route index element={<Navigate to={`${rootPathWithIds}/my-group`} />} />
            <Route
              path={`/my-group/*`}
              element={
                myGroup === null ? (
                  <StudentGroupsNoGroup
                    courseId={courseId}
                    assignmentId={assignmentId}
                    assignmentProgress={userProgress}
                    canSelfManageGroup={userProgress.status.canSelfManageGroup}
                    requestCreateGroup={requestCreateGroup}
                  />
                ) : (
                  <StudentGroupsMyGroup
                    courseId={courseId}
                    assignmentId={assignmentId}
                    canSelfManageGroup={userProgress.status.canSelfManageGroup}
                    myGroup={myGroup}
                    requestRemoveUserFromGroup={requestRemoveUserFromGroup}
                    requestPromoteUserToGroupLeader={requestPromoteUserToGroupLeader}
                    requestDemoteGroupLeader={requestDemoteGroupLeader}
                    requestSetGroupOpenAccess={requestSetGroupOpenAccess}
                    saveGroup={wrappedSaveGroup}
                  />
                )
              }
            />
            {!assignment?.anonymousGroups ? (
              <Route
                path={`/student-list`}
                element={
                  <StudentGroupsStudentList
                    canSelfManageGroup={userProgress.status.canSelfManageGroup}
                    groupsRoster={groupsRoster}
                    myGroup={myGroup}
                    myPendingGroups={myPendingGroups}
                    requestAddUserToGroup={requestAddUserToGroup}
                  />
                }
              />
            ) : null}
            <Route
              path={`/group-list`}
              element={
                <StudentGroupsGroupsList
                  canSelfManageGroup={userProgress.status.canSelfManageGroup}
                  myGroup={myGroup}
                  myPendingGroups={myPendingGroups}
                  groups={allGroups}
                  requestAddUserToGroup={requestAddUserToGroup}
                />
              }
            />
            <Route
              path={`/invite/:groupInviteId/accept`}
              element={<InviteAccept requestAcceptGroupInvite={requestAcceptGroupInvite} />}
            />
            <Route
              path={`/invite/:groupInviteId/reject`}
              element={<InviteReject requestRejectGroupInvite={requestRejectGroupInvite} />}
            />
            <Route path={`/formation`} element={<GroupFormation updateData={forceUpdate} />} />
          </Routes>
        ) : null}
      </section>
    </div>
  );
}

export default StudentGroupsPage;
