import _ from 'lodash';
import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainSidebar, { sidebarPaths } from '../Sidebar/MainSidebar';
import { focusFirstElement } from '../../../../utils/functions';

interface Props {
  children: React.ReactNode;
}

function SiteContainer({ children }: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const backToTop = useCallback(() => {
    const siteContent = document.getElementById('site-content');
    if (siteContent) {
      siteContent.scrollTop = 0;
      focusFirstElement(siteContent);
    }
  }, []);

  useEffect(() => backToTop(), [backToTop, location]);

  const calcOffset = useMemo(
    () =>
      _.debounce(
        () => {
          requestAnimationFrame(() => {
            if (containerRef.current) {
              let calcEquation = `100vh`;
              const siteBanner = document.getElementById('site-banner');
              if (siteBanner) calcEquation += ` - ${siteBanner.offsetHeight}px`;
              const mobileMenuBottom = document.getElementById('mobile-menu-bottom');
              if (mobileMenuBottom && mobileMenuBottom.getAttribute('scroll-hidden') === 'true')
                calcEquation += ` + ${mobileMenuBottom.offsetHeight}px`;
              containerRef.current.style.height = `calc(${calcEquation})`;
            }
          });
        },
        100,
        { maxWait: 1000 },
      ),
    [],
  );

  useEffect(() => {
    const config: MutationObserverInit = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(calcOffset);
    const siteBanner = document.getElementById('site-banner');
    if (siteBanner) observer.observe(siteBanner, config);
    return () => {
      observer.disconnect();
    };
  }, [calcOffset]);

  return (
    <div ref={containerRef} id="site-container">
      <Routes>
        {sidebarPaths.map((path) => (
          <Route key={path} path={path} element={<MainSidebar />} />
        ))}
        <Route path="*" element={<></>} />
      </Routes>
      <main ref={contentRef} id="site-content">
        <div id="page-announcer" role="status" className="sr-only" />
        <div tabIndex={-1} className="sr-only">
          Main Content Start
        </div>
        {children}
        <a
          className="sr-only sr-in-place-show-on-focus"
          href="#site-content"
          onClick={(e) => {
            e.preventDefault();
            backToTop();
          }}
        >
          Back to top
        </a>
      </main>
    </div>
  );
}

export default SiteContainer;
