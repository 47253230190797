import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { getPropsFromQuery } from '../../utils/functions';
import { createNewReview, createSelfReview } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function NewReviewPage(): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const query: { bonus?: boolean; selfReview?: boolean; submissionLock?: boolean } = getPropsFromQuery(
    new URLSearchParams(useLocation().search),
  );

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const requestCreateNewReview = useCallback(() => {
    setLoading(true);
    (query.selfReview ? createSelfReview : createNewReview)(
      assignmentId,
      (review) => {
        setLoading(false);
        navigate(`/course/${courseId}/assignment/${assignmentId}/review/${review.reviewId}`, { replace: true });
      },
      (error) => {
        setLoading(false);

        let heading = 'Error';
        let label = '';

        if (error.response?.status === 404) {
          if (query.bonus) {
            heading = 'Bonus Not Available';
            label = 'No bonus docs available for review.';
          } else {
            heading = 'No Peer Submissions to Review';
            label = 'There are no submissions to review right now. Please try again later.';
          }
        } else {
          heading = 'New Review Request Denied';
          label = 'Failed to request a new review.';
        }

        modalDispatch(
          openModal({
            heading,
            label,
            buttonText: 'Back',
            noCancel: true,
            onConfirm: () => navigate(-1),
          }),
        );

        return true;
      },
    );
  }, [assignmentId, courseId, modalDispatch, navigate, query]);

  const openLockModal = useCallback(() => {
    requestAnimationFrame(() => {
      modalDispatch(
        openModal({
          heading: 'Reviewing Locks Submitting',
          label: `You have yet to submit for this assignment. You may submit late. If you continue to your review now, you will not be able to submit for this assignment.`,
          buttonText: 'Continue',
          cancelText: 'Go Back',
          onConfirm: requestCreateNewReview,
          onCancel: () => navigate(-1),
          maxWidth: '512px',
        }),
      );
    });
  }, [modalDispatch, navigate, requestCreateNewReview]);

  useEffect(() => {
    if (!init) {
      if (query.submissionLock === true) openLockModal();
      else requestCreateNewReview();
      setInit(true);
    }
  }, [init, navigate, modalDispatch, query, requestCreateNewReview, openLockModal]);

  return loading ? <LoadingSpinner /> : <></>;
}

export default NewReviewPage;
