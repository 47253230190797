import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRatingTable } from '../../actions';
import { RootState } from '../../store';
import { RatingLevel } from '../../types/types';
import Form from '../core/input/Form/Form';
import Rating from '../core/input/Rating/Rating';
import RatingTutorial from '../tutorial/RatingTutorial';
import _ from 'lodash';

interface Props {
  name: string;
  onInvalid?: () => void;
  prompt: string;
  ratingId: string;
  ratingLevels: RatingLevel[];
  saveReview: () => void;
}

function FormRatingPage({ name, onInvalid, prompt, ratingId, ratingLevels, saveReview }: Props): JSX.Element {
  // State
  const [ratingEntries, setRatingEntries] = useState<JSX.Element[]>([]);

  // Redux:
  const dispatch = useDispatch();
  const ratingTable = useSelector((state: RootState) => state.ratingTable);

  useEffect(() => {
    // Get initial score, if any
    let initScore = -1;
    if (ratingTable[ratingId]) {
      initScore = ratingTable[ratingId].score;
    }

    // Generate all rating entries
    const newRatingEntries = [] as JSX.Element[];
    ratingLevels.forEach((level) => {
      newRatingEntries.push(
        <Rating.Entry
          key={ratingId + '-' + level.ratingLevelId}
          variant="lg"
          id={level.ratingLevelId + ''}
          name={ratingId + ''}
          value={level.score}
          defaultChecked={initScore === level.score}
          onInvalid={onInvalid}
        >
          {level.levelDescription}
        </Rating.Entry>,
      );
    });
    setRatingEntries(newRatingEntries);
  }, [onInvalid, ratingId, ratingLevels, ratingTable]);

  /**
   * The event handler for changes to the review rating card.
   * Updates Redux store and saves review.
   * @param {object} e Event
   */
  const handleChange = (e: React.ChangeEvent) => {
    const targetEl = e.target as HTMLInputElement;

    // Update rating table
    dispatch(
      updateRatingTable({
        ratingId: ratingId,
        score: parseInt(targetEl.value),
      }),
    );

    // Save review
    saveReview();
  };

  const ratingEntriesOrdered = _.clone(ratingEntries).reverse();
  return (
    <>
      <Form.Header>
        <Form.Title>{name}</Form.Title>
      </Form.Header>
      <Form.Body>
        <Rating
          key={`rating-${ratingId}`}
          id={ratingId + ''}
          variant="lg"
          legend={prompt}
          richLegend
          onChange={handleChange}
        >
          {ratingEntriesOrdered}
        </Rating>
      </Form.Body>
      <RatingTutorial />
    </>
  );
}

export default FormRatingPage;
