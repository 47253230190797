import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from '../core/input/Form/Form';
import BasicScheduler, { BasicSchedule, initializeSchedule } from '../core/input/Scheduler/BasicScheduler';
import RankedChoice from '../core/input/RankedChoice/RankedChoice';
import { useNavigate, useParams } from 'react-router-dom';
import { getGroupFormationResponse, getGroupFormationSurvey, saveGroupFormationResponse } from '../../utils/requests';
import { GroupFormationResponse, GroupFormationSurvey, GroupFormationSurveyPrompt } from '../../types/types';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import _ from 'lodash';
import Rating from '../core/input/Rating/Rating';

interface Props {
  updateData: () => void;
}

type PageData = BasicSchedule[] | string[] | number;
export type GroupFormationFormData = { [index: string]: PageData };

function GroupFormation({ updateData }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [survey, setSurvey] = useState<GroupFormationSurvey | null>(null);
  const [inputData, setInputData] = useState<GroupFormationResponse | null>(null);
  const [formData, setFormData] = useState<GroupFormationFormData>({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const updateFormData = useCallback((surveyPromptId: string, data: PageData) => {
    setFormData((prevFormData) => {
      const newFormData = _.cloneDeep(prevFormData);
      newFormData[surveyPromptId] = data;
      return newFormData;
    });
  }, []);

  useEffect(() => {
    getGroupFormationSurvey(assignmentId, setSurvey);
    getGroupFormationResponse(assignmentId, (responseData) => {
      if (responseData) setInputData(responseData);
      setLoading(false);
    });
  }, [assignmentId]);

  useEffect(() => {
    if (survey && !loading) {
      setFormData(generateFormDataFromGroupFormationSurveyAndResponse(survey, inputData));
    }
  }, [survey, inputData, loading]);

  const convertToOutputData = () => {
    if (survey && !_.isEmpty(formData)) {
      const outputData: number[] = [];
      let incomplete = false;
      survey.prompts.forEach((prompt) => {
        switch (prompt.type) {
          case 'SCHEDULE':
            const schedule = formData[prompt.surveyPromptId] as BasicSchedule[];
            let isAnyAvailable = false;
            schedule.forEach((daySchedule) => {
              outputData.push(
                ...daySchedule.availability.map((a) => {
                  if (a.isAvailable) isAnyAvailable = true;
                  return a.isAvailable ? 1 : 0;
                }),
              );
            });
            if (!isAnyAvailable) incomplete = true;
            break;
          case 'RANKED_CHOICE':
            const rankedChoices = formData[prompt.surveyPromptId] as string[];
            if (rankedChoices.length < prompt.dimensionCount) incomplete = true;
            rankedChoices.forEach((rankedChoice) => outputData.push(prompt.options.indexOf(rankedChoice)));
            break;
          case 'CHOOSE_ONE':
            const choice = formData[prompt.surveyPromptId] as number;
            if (choice < 0) incomplete = true;
            outputData.push(choice);
        }
      });
      if (incomplete) return null;
      return outputData;
    }
  };

  const formPages = useMemo(() => {
    if (survey && !_.isEmpty(formData))
      return survey?.prompts.map((prompt) => {
        const sharedProps = { prompt, updateFormData };
        const currData = formData[prompt.surveyPromptId];
        switch (prompt.type) {
          case 'SCHEDULE':
            return (
              <SchedulerPage key={prompt.surveyPromptId} schedule={currData as BasicSchedule[]} {...sharedProps} />
            );
          case 'RANKED_CHOICE':
            return (
              <RankedChoicePage key={prompt.surveyPromptId} rankedChoices={currData as string[]} {...sharedProps} />
            );
          case 'CHOOSE_ONE':
            return <ChooseOnePage key={prompt.surveyPromptId} choice={currData as number} {...sharedProps} />;
        }
        return <></>;
      });
    return [];
  }, [survey, formData, updateFormData]);

  if (loading) return <LoadingSpinner />;
  return (
    <Form
      id="group-formation-form"
      pages={formPages}
      onSubmit={() => {
        const outputData = convertToOutputData();
        if (outputData)
          saveGroupFormationResponse(assignmentId, outputData, () => {
            updateData();
            navigate(`/course/${courseId}/assignment/${assignmentId}/groups`);
          });
        else {
          modalDispatch(
            openModal({
              heading: 'Unfinished Survey',
              label: 'You must complete the survey before submitting.',
              cancelHide: true,
              buttonText: 'Go Back',
            }),
          );
        }
      }}
    />
  );
}

function FormPage({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>Group Formation</Form.Title>
        <Form.Description size="sm">
          Your answers will help <i>Peerceptiv</i> determine which group you&apos;ll fit into best.
        </Form.Description>
      </Form.Header>
      <Form.Body>{children}</Form.Body>
    </>
  );
}

interface SurveyPageProps {
  prompt: GroupFormationSurveyPrompt;
  updateFormData: (surveyPromptId: string, data: PageData) => void;
}

interface SchedulerPageProps extends SurveyPageProps {
  schedule: BasicSchedule[];
}

function SchedulerPage({ schedule, prompt, updateFormData }: SchedulerPageProps): JSX.Element {
  const onChange = useCallback(
    (newSchedule: BasicSchedule[]) => updateFormData(prompt.surveyPromptId, newSchedule),
    [prompt.surveyPromptId, updateFormData],
  );

  return (
    <FormPage>
      <BasicScheduler initSchedule={schedule} onChange={onChange} />
    </FormPage>
  );
}

interface RankedChoicePageProps extends SurveyPageProps {
  rankedChoices: string[];
}

function RankedChoicePage({ rankedChoices, prompt, updateFormData }: RankedChoicePageProps): JSX.Element {
  const onChange = useCallback(
    (newRankedChoices: string[]) => updateFormData(prompt.surveyPromptId, newRankedChoices),
    [prompt.surveyPromptId, updateFormData],
  );

  return (
    <FormPage>
      <RankedChoice
        legend={prompt.description}
        choices={prompt.options}
        initRankedChoices={rankedChoices}
        onChange={onChange}
      />
    </FormPage>
  );
}

interface ChooseOnePageProps extends SurveyPageProps {
  choice: number;
}

function ChooseOnePage({ choice, prompt, updateFormData }: ChooseOnePageProps): JSX.Element {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => updateFormData(prompt.surveyPromptId, parseInt(e.target.value)),
    [prompt.surveyPromptId, updateFormData],
  );

  return (
    <FormPage>
      <Rating id={prompt.surveyPromptId} variant="lg" legend={prompt.description} onChange={onChange}>
        {prompt.options.map((option, i) => (
          <Rating.Entry
            key={`${prompt.surveyPromptId}-${i}`}
            id={`${prompt.surveyPromptId}-${i}`}
            variant="lg"
            name={prompt.surveyPromptId}
            value={i}
            defaultChecked={choice === i}
            hideValue
          >
            {option}
          </Rating.Entry>
        ))}
      </Rating>
    </FormPage>
  );
}

export const generateFormDataFromGroupFormationSurveyAndResponse = (
  survey: GroupFormationSurvey,
  inputData: GroupFormationResponse | null,
) => {
  const initFormData: GroupFormationFormData = {};
  let offset = 0;
  survey.prompts.forEach((prompt) => {
    const rawData = inputData?.responses.slice(offset, offset + prompt.dimensionCount);
    switch (prompt.type) {
      case 'SCHEDULE':
        const initSchedule = initializeSchedule();
        rawData?.forEach((value, i) => {
          initSchedule[Math.floor(i / 3)].availability[i % 3].isAvailable = value === 1;
        });
        initFormData[prompt.surveyPromptId] = initSchedule;
        break;
      case 'RANKED_CHOICE':
        const initRankedChoices = rawData?.map((resRankedChoice) => prompt.options[resRankedChoice]) ?? [];
        initFormData[prompt.surveyPromptId] = initRankedChoices;
        break;
      case 'CHOOSE_ONE':
        const choice = rawData && rawData.length > 0 ? rawData[0] : -1;
        initFormData[prompt.surveyPromptId] = choice;
    }
    offset += prompt.dimensionCount;
  });
  return initFormData;
};

export default GroupFormation;
