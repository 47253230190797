import React from 'react';
import { lightenDarkenColor } from '../../../utils/functions';
import Icon from './Icon';

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  iconCode?: string;
  id?: string;
}

function AlertBar({ children, iconCode = 'report', id, backgroundColor = '#FFD2D2' }: Props): JSX.Element {
  return (
    <div
      id={id}
      className="alert-bar with-icon"
      style={{ backgroundColor, borderRadius: '0.5rem', padding: '0.5rem', fontWeight: 'bold', display: 'flex' }}
      role="alert"
    >
      <Icon
        code={iconCode}
        color={lightenDarkenColor(backgroundColor, -120)}
        style={{ fontSize: '24px', marginRight: '0.5rem' }}
        label="Alert"
      />
      {children}
    </div>
  );
}

export default AlertBar;
