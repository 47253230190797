import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import MultiseatPurchase from './MultiseatPurchase';
import MultiseatReviewOrder from './MultiseatReviewOrder';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../../utils/constants';
import StudentCourseAccessPurchase from './StudentCourseAccessPurchase';
import StudentCourseAccessReviewOrder from './StudentCourseAccessReviewOrder';
import PaymentComplete from './PaymentComplete';
import PaymentCancelled from './PaymentCancelled';
import { setPageTitle } from '../../../utils/functions';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';

interface Props {
  path: '/purchase/*' | '/course/:courseId/purchase/*';
}

function Purchasing({ path }: Props): JSX.Element {
  useEffect(() => setPageTitle('Purchase'), []);

  const { courseId } = useParams() as { courseId?: string };

  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);

  const stripe = useMemo(() => (STRIPE_PUBLIC_KEY ? loadStripe(STRIPE_PUBLIC_KEY) : null), []);

  if (
    (path === '/purchase/*' && user.role !== 'TEACHER') ||
    (path === '/course/:courseId/purchase/*' && user.role !== 'STUDENT')
  )
    navigate('/');

  if (courseId ? user.courseRole : user) {
    return (
      <Elements stripe={stripe}>
        <Routes>
          {path === '/purchase/*' ? (
            <>
              <Route index element={<MultiseatPurchase />} />
              <Route path="/review-order" element={<MultiseatReviewOrder />} />
            </>
          ) : null}
          {path === '/course/:courseId/purchase/*' ? (
            <>
              <Route index element={<StudentCourseAccessPurchase />} />
              <Route path="/review-order" element={<StudentCourseAccessReviewOrder />} />
            </>
          ) : null}
          <Route path="/:purchaseId/complete" element={<PaymentComplete />} />
          <Route path="/cancelled" element={<PaymentCancelled />} />
        </Routes>
      </Elements>
    );
  }
  return <LoadingSpinner />;
}

export default Purchasing;
