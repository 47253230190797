import React from 'react';
import Icon from '../Icon';
import PieChart from '../PieChart/PieChart';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../layout/Tooltip/Tooltip';
import { PhaseCode } from '../../../../types/types';
import { getPhaseName } from '../../../../utils/functions';

interface Props {
  backgroundColor: string;
  children?: React.ReactNode;
  color: string;
  iconCode?: string;
  percent: number;
  phase: PhaseCode;
  r?: number;
  stroke: string;
  tooltip?: string;
  units: string;
}

function ProgressIcon({
  backgroundColor,
  children,
  color,
  iconCode,
  percent,
  phase,
  r = 24,
  stroke,
  tooltip,
  units,
}: Props): JSX.Element {
  return (
    <Tooltip>
      <TooltipTrigger
        tag="span"
        className="progress-icon"
        role="img"
        tabIndex={0}
        ariaLabel={`${getPhaseName(phase)} phase progress`}
      >
        <PieChart radius={r} color={backgroundColor} r={r} stroke={stroke} strokeWidth={1}>
          <PieChart.Slice percent={percent} color={color} r={r / 2} />
        </PieChart>
        <div className="icon-wrapper">{iconCode ? <Icon code={iconCode} ariaHidden /> : children}</div>
      </TooltipTrigger>
      <TooltipContent>
        <div style={{ textAlign: 'center' }}>
          <div>{tooltip}</div>
          <div>{units}</div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
}

export default ProgressIcon;
