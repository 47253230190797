import React, { useCallback, useState } from 'react';
import TabList from '../../core/layout/TabList/TabList';
import ContractMenu from './ContractMenu';
import ApprovedDomainsMenu from './ApprovedDomainsMenu';

function AdminContract(): JSX.Element {
  const [updateKey, setUpdateKey] = useState(0);
  const updateData = useCallback(() => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div className="contract-container">
      <TabList
        label=""
        tabs={
          <>
            <TabList.Tab id="contract" controls="contract-tab">
              Contracts
            </TabList.Tab>
            <TabList.Tab id="whitelist" controls="whitelist-tab">
              Approved Domains
            </TabList.Tab>
          </>
        }
      >
        <TabList.TabPanel id="contract-tab" labeledBy="contract">
          <ContractMenu updateKey={updateKey} updateData={updateData} />
        </TabList.TabPanel>
        <TabList.TabPanel id="whitelist-tab" labeledBy="whitelist">
          <ApprovedDomainsMenu updateKey={updateKey} updateData={updateData} />
        </TabList.TabPanel>
      </TabList>
    </div>
  );
}

export default AdminContract;
