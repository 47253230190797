import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import logoSrc from '../../../assets/peerceptiv_logo_black_text.png';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { selectCourse } from '../../../store/selectors';
import { formDataToObject } from '../../../utils/functions';
import { redeemStudentPurchaseCode } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';

function StudentCourseAccessPurchase(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };

  const course = useSelector(selectCourse);

  const { modalDispatch } = useModalContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const purchaseCode = (formDataToObject(formData) as { purchaseCode: string }).purchaseCode;

    redeemStudentPurchaseCode(
      courseId,
      purchaseCode,
      () => {
        modalDispatch(
          openModal({
            heading: 'Redemption Successful',
            label: `You will be automatically redirected to your course momentarily...`,
            inputType: 'none',
            noActionButtons: true,
            closeButton: true,
          }),
        );
        setTimeout(() => window.location.replace(`/course/${courseId}/assignments`), 3000);
      },
      (error) => {
        if (error.response?.status === 403) {
          modalDispatch(
            openModal({
              heading: 'Invalid Code',
              label: `The code you entered is not valid. Please try again.`,
              inputType: 'none',
              buttonText: 'Okay',
              cancelHide: true,
            }),
          );
          return true;
        }
        return false;
      },
    );
  };

  if (course) {
    return (
      <div className="page" id="student-course-access-purchase-page">
        <div className="panel purchase-menu">
          <img src={logoSrc} width="256" alt="Peerceptiv logo" />
          <h1>Gain Course Access</h1>

          {course.preventDirectPurchase === false ? (
            <>
              <Button variant="rad" href={`/course/${courseId}/purchase/review-order`} route>
                Purchase Access
              </Button>

              <div id="or-divider">
                <span>Or</span>
                <hr />
              </div>
            </>
          ) : null}

          <p>
            Enter your bookstore code to redeem <i>Peerceptiv</i> access:
          </p>
          <form onSubmit={handleSubmit}>
            <label className="sr-only" htmlFor="purchaseCode">
              Purchase Code:
            </label>
            <input type="text" placeholder="Code" name="purchaseCode" id="purchaseCode" required />
            <Button variant="rad sm">Redeem</Button>
          </form>
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default StudentCourseAccessPurchase;
