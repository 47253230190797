import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import FormPage from './FormPage';
import CoreForm from '../core/input/Form/Form';
import { Comment, RatingLevel } from '../../types/types';
import { RootState } from '../../store';

interface Props {
  feedbackPrompt: string | null;
  feedbackRatings: RatingLevel[];
  onPageIndexChange: (index: number) => void;
  onSubmit: () => void;
  rubric: Comment[];
  save: () => void;
}

function Form({ feedbackPrompt, feedbackRatings, onPageIndexChange, onSubmit, rubric, save }: Props): JSX.Element {
  // State:
  const [feedbackFormPages, setFormPages] = useState<JSX.Element[]>([]);

  // Redux:
  const saveTimestamp = useSelector((state: RootState) => state.saveTimestamp);

  /**
   * Dynamically builds the pages of the form based on the rubric.
   * Creates a list of the pages and saves the list in state.
   */
  const buildPagesFromRubric = useCallback(
    (rubric: Comment[]) => {
      const pages = [] as JSX.Element[];
      rubric.forEach((prompt) => {
        pages.push(
          <FormPage
            key={`feedback-for-${prompt.commentId}`}
            commentId={prompt.commentId}
            feedbackPrompt={feedbackPrompt}
            feedbackRatings={feedbackRatings}
            save={save}
          />,
        );
      });
      // Set the newly created pages as state
      setFormPages(pages);
    },
    [feedbackPrompt, feedbackRatings, save],
  );

  /**
   * Build the form pages once a valid rubric state is stored
   */
  useEffect(() => buildPagesFromRubric(rubric as Comment[]), [rubric, buildPagesFromRubric]);

  return (
    <CoreForm
      id="feedback-form"
      pages={feedbackFormPages}
      save={save}
      saveTimestamp={saveTimestamp}
      submitButtonText="Finish"
      onSubmit={onSubmit}
      onPageIndexChange={onPageIndexChange}
    />
  );
}

export default Form;
