import React, { useState, useEffect, useRef } from 'react';
import Icon from '../core/display/Icon';

interface Props {
  className?: string;
  classPercent: number;
  studentPercent?: number;
}

function ScoreBar({ className, classPercent, studentPercent }: Props): JSX.Element {
  const [animatedPercent, setAnimatedPercent] = useState(0);

  const scorebarEl = useRef<HTMLDivElement>(null);
  const majorIndicatorEl = useRef<HTMLDivElement>(null);
  const minorIndicatorEl = useRef<HTMLDivElement>(null);

  // Animate scorebar
  useEffect(() => {
    requestAnimationFrame(() => {
      if (scorebarEl.current) {
        // Set percent for bar to animate
        const newPercent = (studentPercent || classPercent) - 6; // Subtract line cap
        setAnimatedPercent(newPercent);

        const containerWidth = scorebarEl.current.getBoundingClientRect().width;

        // Set major indicator transform
        if (majorIndicatorEl.current) {
          const majorFactor = (studentPercent || classPercent) / 100;
          const majorIndicatorWidth = majorIndicatorEl.current.getBoundingClientRect().width;
          let majorPixelOffset = containerWidth * majorFactor - majorIndicatorWidth / 2;
          // Inset if too close to the edges of the bar
          if (majorPixelOffset < -16) majorPixelOffset = -16;
          if (majorPixelOffset > containerWidth - majorIndicatorWidth / 2 - 8)
            majorPixelOffset = containerWidth - majorIndicatorWidth / 2 - 8;
          // Set indicator transformation
          majorIndicatorEl.current.style.transform = `translateX(${majorPixelOffset}px)`;
        }

        // Set class indicator transform
        if (studentPercent && minorIndicatorEl.current) {
          const minorFactor = classPercent / 100;
          const minorIndicatorWidth = minorIndicatorEl.current.getBoundingClientRect().width;
          let minorPixelOffset = containerWidth * minorFactor - minorIndicatorWidth / 2;
          // Inset if too close to the edges of the bar
          if (minorPixelOffset < -16) minorPixelOffset = -16;
          if (minorPixelOffset > containerWidth - minorIndicatorWidth / 2 - 8)
            minorPixelOffset = containerWidth - minorIndicatorWidth / 2 - 8;
          // Set indicator transform
          minorIndicatorEl.current.style.transform = `translateX(${minorPixelOffset}px)`;
        }
      }
    });
  }, [studentPercent, classPercent]);

  return (
    <div ref={scorebarEl} className={`score-bar${className ? ' ' + className : ''}`}>
      <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <g fill="none">
          <path
            stroke="#D9D9D9"
            strokeWidth="16"
            strokeLinecap="round"
            d={`M 3,50 l 94,0`}
            vectorEffect="non-scaling-stroke"
          />
          <path
            className="bar-fill"
            stroke="#58cf7c"
            strokeWidth="16"
            strokeLinecap="round"
            d={`M 3,50 l ${animatedPercent},0`}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>
      {studentPercent ? (
        <div className="indicator-container">
          <div
            ref={minorIndicatorEl}
            className={`minor-indicator${classPercent > studentPercent ? ' class-above' : ''}`}
          >
            <Icon code="school" label={`Class Average = ${Math.round(classPercent)}%`} />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="indicator-container">
        <div ref={majorIndicatorEl} className="major-indicator">
          <Icon
            code={studentPercent ? 'account_circle' : 'school'}
            label={
              studentPercent
                ? `Your Score = ${Math.round(studentPercent)}%`
                : `Class Average = ${Math.round(classPercent)}%`
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ScoreBar;
