import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Contract, SeatConsumption } from '../../../types/types';
import Table from '../../core/display/Table/Table';
import { getContract, getSeatConsumption } from '../../../utils/requests';
import { formatDate } from '../../../utils/functions';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  consumerName: string;
  consumerEmail: string;
  usedAt: string;
  courseName: string;
};

function ContractSeatTracking(): JSX.Element {
  const { contractId } = useParams() as { contractId: string };
  const [contract, setContract] = useState<Contract>();
  const [seatConsumptions, setSeatConsumptions] = useState<SeatConsumption[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  useEffect(() => {
    getSeatConsumption(contractId, setSeatConsumptions);
    getContract(contractId, setContract);
  }, [contractId]);

  useMemo(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Name', accessorKey: 'consumerName', meta:{className: 'left-align'}},
      { header: 'Email', accessorKey: 'consumerEmail', meta:{className: 'left-align'}},
      { header: 'Course Name', accessorKey: 'courseName', meta:{className: 'left-align'}},
      { header: 'usedAt', accessorKey: 'usedAt',meta:{className: 'left-align'}},
    ];

    const dataTable: TableData[] = [];
    seatConsumptions.forEach((consumption: SeatConsumption) => {
      const newRow: TableData = {
        consumerName: consumption.consumer?.sortableName,
        consumerEmail: consumption.consumer?.email,
        courseName: consumption.courseName,
        usedAt: formatDate(consumption.usedAt),
      };
      dataTable.push(newRow);
    });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [seatConsumptions]);

  return (
    <div className="contract-seat-tracking">
      <div className="seat-tracking-title">
        <h2>{contract?.name} Seats Tracking</h2>
      </div>
      <div>
        <Table
          columns={tableColumns}
          data={tableData}
          sortBy="name"
          headingLevel={1}
          noWrapper
          id="seat-tracking-card"
          title="Seat Tracking"
        />
      </div>
    </div>
  );
}

export default ContractSeatTracking;
