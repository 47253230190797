import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';
import { BenchmarkGrade, Rubric } from '../../../types/types';
import {
  getSubmissionRubricItems,
  getSubmissionsForBenchmarking,
  getUnselectedBenchmarkSubmissions,
  postBenchmarkGrades,
} from '../../../utils/requests';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { SAVE_DEBOUNCE_MAX_WAIT, SAVE_DEBOUNCE_WAIT } from '../../../utils/constants';
import GradingForm from './GradingForm';
import Graph from './Graph';
import { PointsRange } from '../../core/display/Graph/Scatterplot';
import Tutorial from './Tutorial';
import { setPageTitle } from '../../../utils/functions';
import { useDispatch } from 'react-redux';
import { newUpdateKey } from '../../../actions';

export type BenchmarkView = 'TUTORIAL' | 'GRADING' | 'GRAPH';

function BenchmarkPage(): JSX.Element {
  useEffect(() => setPageTitle('Benchmark Grade'), []);

  const { assignmentId } = useParams() as { assignmentId: string };

  const [benchmarkGrades, setBenchmarkGrades] = useState<BenchmarkGrade[] | null>(null);
  const [rubric, setRubric] = useState<Rubric>([]);
  const [unselectedSubmissions, setUnselectedSubmissions] = useState<BenchmarkGrade[] | null>(null);
  const [progress, setProgress] = useState(0);
  const [view, setView] = useState<BenchmarkView>('GRADING');
  const [initFormPage, setInitFormPage] = useState(0);

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(newUpdateKey());
    },
    [dispatch],
  );

  useEffect(() => {
    getSubmissionsForBenchmarking(assignmentId, (grades) => {
      setBenchmarkGrades(grades);
      if (!grades.some((grade) => grade.needsGrading)) setView('GRAPH');
      if (!grades.some((grade) => !grade.needsGrading)) setView('TUTORIAL');
    });
    getSubmissionRubricItems(assignmentId, setRubric);
    getUnselectedBenchmarkSubmissions(assignmentId, setUnselectedSubmissions);
  }, [assignmentId]);

  useEffect(() => {
    if (benchmarkGrades) {
      let numComplete = 0;
      benchmarkGrades.forEach((grade) => {
        if (!grade.needsGrading) numComplete++;
      });
      setProgress((numComplete / benchmarkGrades.length) * 100);
    }
  }, [benchmarkGrades]);

  useEffect(() => {
    if (progress === 100) getUnselectedBenchmarkSubmissions(assignmentId, setUnselectedSubmissions);
  }, [benchmarkGrades, progress, assignmentId]);

  const saveBenchmarkGrades = useCallback(
    (grades: BenchmarkGrade[]) => postBenchmarkGrades(assignmentId, grades, setBenchmarkGrades),
    [assignmentId],
  );

  const debouncedSaveBenchmarkGrades = useMemo(
    () =>
      _.debounce(saveBenchmarkGrades, SAVE_DEBOUNCE_WAIT, {
        maxWait: SAVE_DEBOUNCE_MAX_WAIT,
      }),
    [saveBenchmarkGrades],
  );

  const handleDataSelect = useCallback(
    (data: PointsRange) => {
      if (benchmarkGrades)
        benchmarkGrades.forEach((grade, i) => {
          if (data[0] === grade.submissionGrade && data[1] === grade.benchmarkGrade) {
            setInitFormPage(i);
            setView('GRADING');
          }
        });
    },
    [benchmarkGrades],
  );

  switch (view) {
    case 'TUTORIAL':
      return <Tutorial numSubmissions={benchmarkGrades ? benchmarkGrades.length : undefined} setView={setView} />;
    case 'GRAPH':
      return benchmarkGrades && unselectedSubmissions ? (
        <Graph
          benchmarkGrades={benchmarkGrades}
          unselectedSubmissions={unselectedSubmissions}
          setView={setView}
          onDataSelect={handleDataSelect}
        />
      ) : (
        <LoadingSpinner />
      );
    case 'GRADING':
      return benchmarkGrades ? (
        <GradingForm
          benchmarkGrades={benchmarkGrades}
          progress={progress}
          setBenchmarkGrades={setBenchmarkGrades}
          rubric={rubric}
          save={debouncedSaveBenchmarkGrades}
          setView={setView}
          initPageNum={initFormPage}
        />
      ) : (
        <LoadingSpinner />
      );

    default:
      return <LoadingSpinner />;
  }
}

export default BenchmarkPage;
