import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReviewData,
  setCommentTable,
  setRatingTable,
  updatePreviousTables,
  setSaveTimestamp,
} from '../../actions';
import {
  SAVE_DEBOUNCE_WAIT,
  SAVE_DEBOUNCE_MAX_WAIT,
  SCREEN_WIDTH_TABLET,
  SCREEN_WIDTH_LAPTOP_SM,
} from '../../utils/constants';
import { genCommentTable, setPageTitle } from '../../utils/functions';
import { getReview, postReview, getSubmissionInfo } from '../../utils/requests';
import _ from 'lodash';
import Form from './Form';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../store';
import { CommentData, RatingData, RatingTable, SubmissionInfo } from '../../types/types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ReviewSubmission from '../core/display/Submission/ReviewSubmission';
import { PinDropProvider } from '../../contexts/PinDropContext';

function ReviewPage(): JSX.Element {
  useEffect(() => setPageTitle('Review Submission'), []);

  const params = useParams() as { courseId: string; assignmentId: string; reviewId: string };
  const courseId = useMemo(() => params.courseId, [params]);
  const assignmentId = useMemo(() => params.assignmentId, [params]);
  const reviewId = useMemo(() => params.reviewId, [params]);

  // State:
  const [submissionId, setSubmissionId] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [submission, setSubmission] = useState<SubmissionInfo | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [submissionCollapsed, setSubmissionCollapsed] = useState(false);
  const [onMobile, setOnMobile] = useState(window.innerWidth < SCREEN_WIDTH_LAPTOP_SM);
  // TODO: Make so only needs to resort to mobile layout on mobile, not also small laptops

  // Redux:
  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);
  const ratingTable = useSelector((state: RootState) => state.ratingTable);
  const previousTables = useSelector((state: RootState) => state.previousTables);

  const navigate = useNavigate();

  /**
   * Loads and stores comments in format usable for CommentBox components.
   * Stores in Redux state management
   * @param {object[]} commentData
   */
  const loadComments = useCallback(
    (commentData: CommentData[]) => {
      genCommentTable(commentData, (commentTable) => {
        // Store comment table
        dispatch(setCommentTable(commentTable));
        // Store clone as the previous table
        dispatch(updatePreviousTables({ commentTable: _.cloneDeep(commentTable) }));
      });
    },
    [dispatch],
  );

  /**
   * Loads and stores ratings in format usable for RatingEntry components.
   * Stores in Redux state management
   * @param {object[]} ratingData
   */
  const loadRatings = useCallback(
    (ratingData: RatingData[]) => {
      /*
       * Stores in JSON indexable by rating ID
       */
      const ratingTable = {} as RatingTable;
      ratingData.forEach((rating) => {
        ratingTable[rating.ratingId] = {
          score: rating.score,
        };
      });

      // Store rating table
      dispatch(setRatingTable(ratingTable));
      // Store clone as the previous table
      dispatch(updatePreviousTables({ ratingTable: _.cloneDeep(ratingTable) }));
    },
    [dispatch],
  );

  const handleLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  /**
   * Effect for on component mount
   */
  useEffect(() => {
    // Clear redux store to prevent interference across pages
    dispatch(clearReviewData());
  }, [dispatch]);

  /**
   * Once a valid review ID loaded into state, load the review
   */
  useEffect(() => {
    if (reviewId !== '') {
      getReview(reviewId, (review) => {
        loadComments(review.reviewComments);
        loadRatings(review.reviewRatings);
        setSubmissionId(review.submissionId);
        setDisplayName(review.displayName);
      });
    }
  }, [reviewId, loadComments, loadRatings]);

  useEffect(() => {
    if (submissionId) getSubmissionInfo(submissionId, setSubmission);
  }, [submissionId]);

  /**
   * Compiles the comment and save data from the Redux store
   * and sends it to the server via POST request.
   *
   * This function is debounced and passed to children for use
   * via props.
   */
  const saveReview = useCallback(
    (successCb?: () => void) => {
      // Make sure there are changes to save before saving
      if (
        submissionId &&
        (!_.isEqual(previousTables.commentTable, commentTable) || !_.isEqual(previousTables.ratingTable, ratingTable))
      ) {
        // Compile comment data from table
        const commentData = [] as CommentData[];
        for (const commentId in commentTable) {
          for (const commentNumber in commentTable[commentId]) {
            commentData.push({
              reviewId: reviewId,
              commentId: commentId,
              commentNumber: parseInt(commentNumber),
              comment: commentTable[commentId][commentNumber].comment,
              pinDrop: commentTable[commentId][commentNumber].pinDrop,
            });
          }
        }

        // Compile rating data from table
        const ratingData = [] as RatingData[];
        for (const ratingId in ratingTable) {
          ratingData.push({
            ratingId: ratingId,
            reviewId: reviewId,
            score: ratingTable[ratingId].score,
          });
        }

        // Save current tables as previous tables for next save time
        dispatch(
          updatePreviousTables({
            commentTable: _.cloneDeep(commentTable),
            ratingTable: _.cloneDeep(ratingTable),
          }),
        );

        // Send data to server
        postReview(reviewId, commentData, ratingData, submissionId, assignmentId, () => {
          // Set saved timestamp
          dispatch(setSaveTimestamp(moment().format('h:mm:ss A')));

          if (successCb) successCb();
        });
      } else {
        if (successCb) successCb();
      }
    },
    [
      assignmentId,
      commentTable,
      dispatch,
      previousTables.commentTable,
      previousTables.ratingTable,
      ratingTable,
      reviewId,
      submissionId,
    ],
  );

  const debouncedSaveReview = useMemo(
    () =>
      _.debounce(saveReview, SAVE_DEBOUNCE_WAIT, {
        maxWait: SAVE_DEBOUNCE_MAX_WAIT,
      }),
    [saveReview],
  );

  useEffect(() => debouncedSaveReview(), [debouncedSaveReview, commentTable, ratingTable]);

  useEffect(() => {
    return () => {
      debouncedSaveReview.cancel();
    };
  }, [debouncedSaveReview]);

  useEffect(() => {
    const handleResize = () => {
      const currMobileState = window.innerWidth < SCREEN_WIDTH_TABLET;
      if (onMobile !== currMobileState) setOnMobile(currMobileState);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [onMobile]);

  const layoutClass = submissionCollapsed || onMobile ? 'column' : 'row';

  if (submission) {
    return (
      <PinDropProvider submissionInfo={submission}>
        {!loaded ? <LoadingSpinner /> : null}
        <div className={`page ${layoutClass}`} id="review-page">
          <div className="col" id="submission-column">
            {submission && (
              <ReviewSubmission
                submissionInfo={submission}
                displayName={displayName ?? undefined}
                onChange={(state) => setSubmissionCollapsed(state.collapsed)}
                enableReport
              />
            )}
          </div>
          <div className="col" id="review-column">
            <Form
              assignmentId={assignmentId}
              saveReview={debouncedSaveReview}
              onLoad={handleLoad}
              onSubmit={() => {
                saveReview(() => {
                  navigate(`/course/${courseId}/assignment/${assignmentId}/dashboard`);
                });
              }}
            />
          </div>
        </div>
      </PinDropProvider>
    );
  }
  return <LoadingSpinner />;
}

export default ReviewPage;
