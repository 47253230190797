import React from 'react';
import { RatingResource } from '../../types/types';
import Icon from '../core/display/Icon';

interface Props {
  resources: RatingResource[];
}

function ResourcesCard({ resources }: Props): JSX.Element {
  if (resources.length < 1) return <></>;
  return (
    <div className="panel-sm panel-white resources-card">
      <h2 className="title">Resources</h2>
      <div className="resources-container flex-col">
        {resources.map((resource, i) => (
          <Resource key={`resource-${i}`} type="article" href={resource.url || '/'}>
            {resource.resourceName}
          </Resource>
        ))}
      </div>
      {resources.length < 1 ? <p>No recommended resources at this time.</p> : ''}
    </div>
  );
}

interface ResourceProps {
  children: React.ReactNode;
  href: string;
  type: 'video' | 'article';
}

function Resource({ children, href, type }: ResourceProps): JSX.Element {
  const getIconFromType = () => {
    switch (type) {
      case 'video':
        return <Icon code="play_arrow" label="Video" />;
      case 'article':
        return <Icon code="article" />;
      default:
        return <Icon code="link" />;
    }
  };

  return (
    <a href={href} target="_blank" rel="noreferrer">
      {getIconFromType()}
      <span>{children}</span>
    </a>
  );
}

ResourcesCard.Resource = Resource;

export default ResourcesCard;
