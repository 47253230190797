import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { RootState } from '../../store';
import { AverageRating, PageQuery, PageQueryParams } from '../../types/types';
import { KEY_SELECTED_TAG } from '../../utils/constants';
import { formDataToObjectParsed, storageAvailable } from '../../utils/functions';
import { adminGetTagAverages, createTag, getInstructorTagAverages } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';

function TagsLibrary(): JSX.Element {
  const [updateKey, setUpdateKey] = useState(0);

  const user = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const queryRequest = useMemo(() => {
    if (user.admin || user.schoolAdmin) return adminGetTagAverages;
    if (user.role === 'TEACHER')
      return (pageQueryParams: PageQueryParams, successCb: (arg0: PageQuery<AverageRating>) => void) =>
        getInstructorTagAverages(user.userId, pageQueryParams, successCb);
    return () => undefined;
  }, [user]);

  const openCreateTagModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'New Tag',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Create',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              content: string;
            };
            createTag(data.content, () => setUpdateKey((prev) => prev + 1));
          },
          children: (
            <>
              <label htmlFor={`create-tag`} className="sr-only">
                Tag Name
              </label>
              <input id={`create-tag`} type="text" name="content" placeholder="Tag Name" required />
            </>
          ),
        }),
      ),
    [modalDispatch],
  );

  const handleTagSelect = useCallback(
    (averageRating: AverageRating) => {
      if (averageRating.tag) {
        if (storageAvailable('sessionStorage'))
          window.sessionStorage.setItem(KEY_SELECTED_TAG, JSON.stringify(averageRating.tag));
        navigate(`/rubrics/tag/${averageRating.tag.tagId}`);
      }
    },
    [navigate],
  );

  const columns = useMemo<Column<AverageRating>[]>(
    () => [
      {
        Header: 'Tag',
        accessor: 'tag',
        Cell: (avg: AverageRating) => avg.tag?.content,
        className: 'name',
        customSort: 'content',
      },
      {
        Header: 'Average Mastery',
        accessor: 'averageRating',
        Cell: (avg: AverageRating) => `${Math.round(avg.averageRating)}%`,
        Style: (avg: AverageRating) => {
          let barColor = '#2e74a3';
          if (avg.averageRating >= 80) barColor = '#298547';
          else if (avg.averageRating < 60) barColor = '#A32E2E';
          return {
            backgroundImage: `linear-gradient(to right, ${barColor} 2.5rem, rgba(255, 255, 255, 0) 2.5rem), linear-gradient(to right, ${barColor} ${avg.averageRating}%, rgba(255, 255, 255, 0) ${avg.averageRating}%)`,
          };
        },
        className: 'score',
        notSortable: true,
      },
    ],
    [],
  );

  return (
    <>
      <h1 className="sr-only">Tags</h1>
      <QueryTable
        title="Tags"
        ctrlsInsert={
          <div className="col-2">
            <Button variant="low sm" onClick={openCreateTagModal}>
              <Icon code="add" ariaHidden /> New Tag
            </Button>
          </div>
        }
        columns={columns}
        queryRequest={queryRequest}
        onRowSelect={handleTagSelect}
        updateKey={updateKey}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsWrapperClassName: 'col-2',
          tableClassName: 'home-body bar-chart',
        }}
      />
    </>
  );
}

export default TagsLibrary;
