import React from 'react';
import Icon from '../core/display/Icon';

interface Props {
  commentPrompt: string;
  feedbackComment: string;
  feedbackRating: number;
  reviewComment: string;
}

function FeedbackResult({ commentPrompt, feedbackComment, feedbackRating, reviewComment }: Props): JSX.Element {
  const renderStarForRating = (rating: number): React.ReactNode => {
    const stars = [];
    for (let i = 0; i < 5; i++)
      stars.push(<Icon key={`star-${i}`} code="star" color={i < rating ? '#FFDC00' : '#99A4AD'} ariaHidden />);
    return stars;
  };

  return (
    <div className="feedback-result">
      <blockquote className="review-comment right">
        <div className="comment-prompt">
          on <b>{commentPrompt}</b>, you commented:
        </div>
        {reviewComment}
      </blockquote>
      <div className="feedback-wrapper">
        <div className="feedback-rating" aria-label={`${feedbackRating} out of 5 stars`}>
          {renderStarForRating(feedbackRating)}
        </div>
        <blockquote className="feedback-comment">{feedbackComment}</blockquote>
      </div>
    </div>
  );
}

export default FeedbackResult;
