import React from 'react';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';

function AdminDashboard(): JSX.Element {
  return (
    <div id="admin-dashboard" className="page">
      <div id="menu" className="panel">
        <h1 className="title">
          <Icon code="admin_panel_settings" /> Admin Dashboard
        </h1>
        <Button variant="rad low" href="/admin/users" route>
          All Users
        </Button>
        <Button variant="rad low" href="/admin/courses" route>
          All Courses
        </Button>
        <Button variant="rad low" href="/admin/purchasing" route>
          Purchase Management
        </Button>
        <Button variant="rad low" href="/admin/stats" route>
          Statistics
        </Button>
        <Button variant="rad low" href="/rubrics/library" route>
          Curated Rubric Management
        </Button>
      </div>
    </div>
  );
}

export default AdminDashboard;
