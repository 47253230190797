import React, { useState, useEffect } from 'react';
import Form from '../core/input/Form/Form';
import { Comment, EvaluationTarget, Rating, Rubric, User } from '../../types/types';
import EvalFormCommentPage from './EvalFormCommentPage';
import EvalFormRatingPage from './EvalFormRatingPage';
import Avatar from '../core/display/Avatar/Avatar';
import { getEvalTargetFormatted } from '../../utils/functions';
import EvalFormReviewPage from './EvalFormReviewPage';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';
import AlertBar from '../core/display/AlertBar';

interface Props {
  commentMap: { [index: string]: string };
  lastSaveTimestamp?: string;
  onLoad?: () => void;
  onPageIndexChange: (pageIndex: number) => void;
  onSubmit: () => void;
  peer: User | null;
  ratingMap: { [index: string]: number };
  role: EvaluationTarget;
  rubric: Rubric;
  updateCommentMap: (commentId: string, comment: string) => void;
  updateRatingMap: (ratingId: string, score: number) => void;
}

function EvalForm({
  commentMap,
  lastSaveTimestamp,
  onLoad,
  onPageIndexChange,
  onSubmit,
  peer,
  ratingMap,
  role,
  rubric,
  updateCommentMap,
  updateRatingMap,
}: Props): JSX.Element {
  const assignment = useSelector(selectAssignment);

  const [formPages, setFormPages] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const pages = [] as JSX.Element[];
    rubric.forEach((prompt) => {
      if (prompt.hasOwnProperty('commentId')) {
        const comment = prompt as Comment;
        pages.push(
          <EvalFormCommentPage
            {...comment}
            initComment={commentMap[comment.commentId]}
            updateCommentMap={updateCommentMap}
          />,
        );
      } else if (prompt.hasOwnProperty('ratingId')) {
        const rating = prompt as Rating;
        pages.push(
          <EvalFormRatingPage {...rating} initScore={ratingMap[rating.ratingId]} updateRatingMap={updateRatingMap} />,
        );
      }
    });
    pages.push(
      <EvalFormReviewPage rubric={rubric} ratingIdToScoreMap={ratingMap} commentIdToCommentMap={commentMap} />,
    );
    setFormPages(pages);
  }, [rubric, commentMap, ratingMap, updateCommentMap, updateRatingMap]);

  return (
    <Form
      id="eval-form"
      pages={formPages}
      save={() => undefined}
      saveTimestamp={lastSaveTimestamp}
      onLoad={onLoad}
      onSubmit={onSubmit}
      onPageIndexChange={onPageIndexChange}
      submitButtonText="Finish"
    >
      <div className="peer-id-bar">
        <span>
          {peer ? (
            <>
              <Avatar size={40} user={peer} />
              <h1>{peer.name}</h1>
            </>
          ) : (
            <h1>{getEvalTargetFormatted(role)}</h1>
          )}
        </span>
        <span className="badge">
          <span>{getEvalTargetFormatted(role)}</span>
        </span>
      </div>
      {assignment?.showEvaluatorIdentities ? (
        <AlertBar backgroundColor="#fff082">
          Your evaluations are <u>not</u> anonymous for this assignment.
        </AlertBar>
      ) : null}
    </Form>
  );
}

export default EvalForm;
