import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { POST_Purchase, Purchase } from '../../../types/types';
import { formatDollar, formDataToObjectParsed } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import Table from '../../core/display/Table/Table';
import FilterTab from '../../core/layout/FilterTab/FilterTab';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { ColumnDef, Row } from '@tanstack/react-table';

type TableData = {
  purchase: Purchase;
  purchaseId: string;
  purchaser: string;
  email: string;
  name: string;
  date: string;
  price: number;
  seatsUsed: number;
  seats: number;
  active: boolean;
  refunded: boolean;
  status: string;
};

interface Props {
  createPurchase: (email: string, purchase: POST_Purchase) => void;
  deletePurchase: (purchaseId: string) => void;
  purchases?: Purchase[];
}

function AdminPurchaseLog({ createPurchase, deletePurchase, purchases }: Props): JSX.Element {
  const [allTableData, setAllTableData] = useState<TableData[]>([]);
  const [activeTableData, setActiveTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const { modalDispatch } = useModalContext();

  const openCreatePurchaseModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Create a Purchase',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Create',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              email: string;
              name: string | number;
              notes: string | number;
              seats: number;
            };
            const purchase: POST_Purchase = { seats: data.seats };
            if (typeof data.name === 'string') purchase.name = data.name;
            if (typeof data.notes === 'string') purchase.notes = data.notes;
            createPurchase(data.email, purchase);
          },
          children: <CreatePurchaseModalContent />,
        }),
      ),
    [modalDispatch, createPurchase],
  );

  const openPurchaseDetailsModal = useCallback(
    (row: Row<TableData>) =>
      modalDispatch(
        openModal({
          heading: 'Purchase Details',
          headingHide: true,
          closeButton: true,
          noActionButtons: true,
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as { deleteCheck: 'on' | 'off' };
            if (data.deleteCheck === 'on') deletePurchase(row.original.purchaseId);
          },
          children: <PurchaseDetailsModalContent purchase={row.original.purchase} />,
        }),
      ),

    [modalDispatch, deletePurchase],
  );

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      {
        accessorKey: 'date',
        header: 'Date',
        cell: (cell) => moment(cell.getValue() as string).format('MM/DD/YY HH:mm'),
        meta: { className: 'left-align' },
      },
      { accessorKey: 'purchaser', header: 'Purchaser', meta: { className: 'left-align' } },
      { accessorKey: 'email', header: 'Email', meta: { className: 'left-align' } },
      { accessorKey: 'name', header: 'Name', meta: { className: 'left-align' } },
      {
        accessorKey: 'price',
        header: 'Price',
        cell: (cell) => formatDollar(cell.getValue() as number),
      },
      {
        header: 'Seats',
        columns: [
          { accessorKey: 'seats', header: 'Total' },
          {
            accessorKey: 'seatsUsed',
            header: 'Used',
            cell: (cell) => `${cell.getValue()} (${Math.floor((cell.getValue() / cell.row.original.seats) * 100)}%)`,
          },
        ],
      },
      { accessorKey: 'status', header: 'Status' },
    ];

    const parsePurchasesToTableData = (purchaseList?: Purchase[]): TableData[] => {
      const dataTable: TableData[] = [];
      purchaseList?.forEach((purchase) => {
        const newRow: TableData = {
          purchase: purchase,
          purchaseId: purchase.purchaseId,
          purchaser: purchase.user.name ?? '',
          email: purchase.user.email,
          date: purchase.createdAt,
          name: purchase.name,
          price: purchase.price,
          seatsUsed: purchase.seatsUsed,
          seats: purchase.seats,
          active: purchase.active,
          refunded: purchase.refunded,
          status: `${purchase.active ? 'Active' : 'Inactive'}${purchase.refunded ? ', Refunded' : ''}`,
        };

        dataTable.push(newRow);
      });
      return dataTable;
    };

    setAllTableData(parsePurchasesToTableData(purchases));
    setActiveTableData(parsePurchasesToTableData(purchases?.filter((purchase) => purchase.active)));
    setTableColumns(columns);
  }, [purchases]);

  const tableData = filterList.includes('Active') ? activeTableData : allTableData;

  if (purchases) {
    return (
      <>
        <div className="ctrls-row">
          <Button onClick={openCreatePurchaseModal}>Create Purchase</Button>
        </div>
        <div className="table-wrapper panel-sm">
          <Table
            columns={tableColumns}
            data={tableData}
            sortBy="date"
            title="Purchase Log"
            id="purchase-log-table"
            noWrapper
            informOfRow={openPurchaseDetailsModal}
          >
            <FilterTab label="Show:" setFilterList={setFilterList}>
              <FilterTab.Button id="btn-all" type="radio" name="purchase-filters" defaultChecked={true}>
                All
              </FilterTab.Button>
              <FilterTab.Button id="btn-active" type="radio" name="purchase-filters">
                Active
              </FilterTab.Button>
            </FilterTab>
          </Table>
        </div>
      </>
    );
  }
  return <LoadingSpinner />;
}

function PurchaseDetailsModalContent({ purchase }: { purchase: Purchase }): JSX.Element {
  const [deleteCheck, setDeleteCheck] = useState(false);

  return (
    <div id="admin-purchase-modal-content">
      <table className="nice-table">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{purchase.name}</td>
          </tr>
          <tr>
            <th>Date</th>
            <td>{moment(purchase.createdAt).format('MM/DD/YY HH:mm')}</td>
          </tr>
          <tr>
            <th>Purchaser</th>
            <td>{purchase.user.name}</td>
          </tr>
          <tr>
            <th>Price</th>
            <td>{formatDollar(purchase.price)}</td>
          </tr>
          <tr>
            <th>Seats</th>
            <td>{`${purchase.seats} (Used: ${purchase.seatsUsed})`}</td>
          </tr>
          <tr>
            <th>Active?</th>
            <td>
              <Icon code={purchase.active ? 'done' : 'close'} />
            </td>
          </tr>
          <tr>
            <th>Refunded?</th>
            <td>
              <Icon code={purchase.refunded ? 'done' : 'close'} />
            </td>
          </tr>
          <tr>
            <th>Shared With</th>
            <td>
              {purchase.sharedPurchases.length > 0
                ? purchase.sharedPurchases.map((sp) => sp.user.name).join(', ')
                : 'None'}
            </td>
          </tr>
          <tr>
            <th>Notes:</th>
            <td>{purchase.notes ?? 'None'}</td>
          </tr>
        </tbody>
      </table>

      <div id="delete-check-wrapper" className={`rad-radio-btn ${deleteCheck === true ? 'sr-only' : ''}`}>
        <input
          id="delete-check"
          name="deleteCheck"
          type="checkbox"
          checked={deleteCheck}
          onChange={(e) => setDeleteCheck(e.target.checked)}
        />
        <label htmlFor="delete-check">
          <Icon code="delete" /> Delete Purchase
        </label>
      </div>

      {deleteCheck === true ? (
        <div id="delete-confirm-wrapper">
          <p>
            <b>Are you sure you want to delete this purchase?</b>
          </p>
          <div className="ctrls">
            <Button variant="rad" type="submit">
              Delete
            </Button>
            <Button variant="rad alt" type="button" onClick={() => setDeleteCheck(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function CreatePurchaseModalContent(): JSX.Element {
  return (
    <div id="create-purchase-modal-content">
      <div className="input-wrapper">
        <label htmlFor="email">For:</label>
        <input id="email" name="email" type="email" placeholder="Email Address" required />
      </div>
      <div className="input-wrapper">
        <label className="sr-only" htmlFor="name">
          Purchase Name:
        </label>
        <input id="name" name="name" type="text" placeholder="Purchase Name (Optional)" />
      </div>
      <div className="input-wrapper">
        <label htmlFor="seats">Seats:</label>
        <input id="seats" name="seats" type="number" placeholder="#" min={1} required />
      </div>
      <div className="input-wrapper">
        <label className="sr-only" htmlFor="notes">
          Notes:
        </label>
        <textarea id="notes" name="notes" placeholder="Notes (Optional)" />
      </div>
    </div>
  );
}

export default AdminPurchaseLog;
