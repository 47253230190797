import React, { useMemo } from 'react';
import { User } from '../../../../types/types';
import { Base64 } from 'js-base64';

interface Props {
  acting?: boolean;
  anonymous?: boolean;
  size?: number;
  user: User;
}

function Avatar({ acting, anonymous = false, size = 32, user }: Props): JSX.Element {
  const char = useMemo(() => (user.name && !anonymous ? user.name.charAt(0) : '?'), [anonymous, user.name]);

  const svg = useMemo(
    () =>
      `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='${size}px' width='${size}px'>
      <g style="text-anchor: middle; font-size:${size * 0.7}; font-family: Roboto, sans-serif">
      <defs>
        <filter id="shadow">
          <feGaussianBlur stdDeviation="1" result="shadow"/>
          <feOffset dx="1" dy="1"/>
        </filter>
      </defs>
      <text x='${size / 2}' y='${size * 0.75}' style="filter: url(#shadow); fill: black">${char}</text>
      <text x='${size / 2}' y='${size * 0.75}' style="fill: white">${char}</text>
    </g></svg>`,
    [char, size],
  );

  return (
    <div
      className={`avatar ${acting ? 'acting' : ''}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size * 0.5}px`,
        backgroundImage: `url(data:image/svg+xml;base64,${Base64.encode(svg)})`,
      }}
      role="img"
      aria-label={`User avatar labeled '${char}'`}
    />
  );
}

export default Avatar;
