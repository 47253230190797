import React from 'react';
import { focusFirstElement } from '../../../utils/functions';
import Button from './Button/Button';

interface Props {
  ariaHidden?: boolean;
  asLink?: boolean;
  children: React.ReactNode;
  className?: string;
  id?: string;
  invisible?: boolean;
  targetId: string;
  type: 'focus' | 'focus-child' | 'scroll';
  variant?: string;
}

function JumpButton({
  ariaHidden,
  asLink = false,
  children,
  className,
  id,
  invisible = false,
  targetId,
  type,
  variant,
}: Props): JSX.Element {
  return (
    <OptionalWrapper invisible={invisible}>
      <Button
        id={id}
        className={`${className} ${invisible ? 'sr-only sr-show-on-focus' : ''}`}
        variant={variant}
        onClick={() => {
          const targetEl = document.getElementById(targetId);
          if (targetEl) {
            switch (type) {
              case 'focus':
                targetEl.focus();
                break;
              case 'focus-child':
                focusFirstElement(targetEl);
                break;
              case 'scroll':
                targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }
        }}
        ariaHidden={ariaHidden}
        href={asLink ? `#${targetId}` : undefined}
        refresh={asLink}
      >
        {children}
      </Button>
    </OptionalWrapper>
  );
}

function OptionalWrapper({ children, invisible }: { children: React.ReactNode; invisible: boolean }): JSX.Element {
  if (invisible) return <div style={{ position: 'relative' }}>{children}</div>;
  return <>{children}</>;
}

export default JumpButton;
