import React, { useEffect, useState } from 'react';
import { AssignmentProgress, Comment, CommentTable, CommentWithReviewComments } from '../../types/types';
import ReviewSubmission from '../core/display/Submission/ReviewSubmission';
import { useParams } from 'react-router-dom';
import { getStudentAssignmentProgress, getStudentCommentsReceivedByPrompt } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import RichReader from '../core/display/RichReader';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import Button from '../core/button/Button/Button';
import { mod } from '../../utils/functions';
import { useDispatch } from 'react-redux';
import { setCommentTable } from '../../actions';
import { PinDropProvider } from '../../contexts/PinDropContext';
import ReviewDialogue from '../core/display/ReviewDialogue/ReviewDialogue';

function FullDialoguePage(): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [assignmentProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const [peerComments, setPeerComments] = useState<CommentWithReviewComments[] | null>(null);
  const [comments, setComments] = useState<Comment[] | null>(null);
  const [pageNum, setPageNum] = useState(0);
  const [submissionCollapsed, setSubmissionCollapsed] = useState(false);

  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();

  useEffect(() => getStudentAssignmentProgress(assignmentId, setAssignmentProgress), [assignmentId]);

  useEffect(() => {
    if (assignmentProgress && assignmentProgress.status.hasResult) {
      getStudentCommentsReceivedByPrompt(assignmentId, setPeerComments);
    }
  }, [assignmentId, assignmentProgress]);

  useEffect(() => {
    if (peerComments) {
      const commentTable: CommentTable = {};
      const commentList: Comment[] = [];

      peerComments.forEach((peerComment) => {
        commentTable[peerComment.comment.commentId] = {};
        commentList.push(peerComment.comment);

        let commentNumber = 1;
        for (let reviewCommentId in peerComment.reviewCommentsMap) {
          const reviewComments = peerComment.reviewCommentsMap[reviewCommentId];
          reviewComments.forEach((reviewComment) => {
            commentTable[peerComment.comment.commentId][commentNumber] = {
              comment: reviewComment.comment,
              pinDrop: reviewComment.pinDrop,
            };
            commentNumber++;
          });
        }
      });
      dispatch(setCommentTable(commentTable));
      setComments(commentList);
    }
  }, [dispatch, peerComments]);

  if (assignmentProgress && assignmentProgress.submissionInfo && peerComments && comments) {
    return (
      <PinDropProvider submissionInfo={assignmentProgress.submissionInfo} comments={comments}>
        <div id="results-full-dialogue" className={`page ${submissionCollapsed ? 'submission-collapsed' : ''}`}>
          <div className="dialogue-col" id="submission-col">
            {assignmentProgress.submissionInfo && (
              <ReviewSubmission
                submissionInfo={assignmentProgress.submissionInfo}
                onChange={(state) => setSubmissionCollapsed(state.collapsed)}
              />
            )}
          </div>
          {pageNum >= 0 && pageNum < peerComments.length ? (
            <div className="dialogue-col" id="quote-col">
              <div className="title-bar">
                <div className="item">
                  <h1>
                    on <b>{peerComments[pageNum].comment.commentName}</b>
                  </h1>
                  <Button
                    className="prompt-btn"
                    classOverride
                    onClick={() =>
                      modalDispatch(
                        openModal({
                          heading: 'Prompt Description:',
                          closeButton: true,
                          noActionButtons: true,
                          padding: '4rem',
                          maxWidth: '640px',
                          children: (
                            <RichReader
                              key={peerComments[pageNum].comment.commentId}
                              content={peerComments[pageNum].comment.commentPrompt}
                            />
                          ),
                        }),
                      )
                    }
                  >
                    View Prompt
                  </Button>
                </div>
                <div className="item">
                  <Button
                    id="back-btn"
                    variant="alt xs"
                    onClick={() => setPageNum((prevNum) => mod(prevNum - 1, peerComments.length))}
                    disabled={peerComments.length < 2}
                  >
                    Back
                  </Button>
                  <Button
                    variant="xs"
                    onClick={() => setPageNum((prevNum) => mod(prevNum + 1, peerComments.length))}
                    disabled={peerComments.length < 2}
                  >
                    Next
                  </Button>
                </div>
              </div>

              <ReviewDialogue commentWithReviewComments={peerComments[pageNum]} submitterView />
            </div>
          ) : null}
        </div>
      </PinDropProvider>
    );
  }

  return <LoadingSpinner />;
}

export default FullDialoguePage;
