import React from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';

interface Props {
  reflectionEnabled: boolean;
  setReflectionEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReflectionSetup({ reflectionEnabled, setReflectionEnabled }: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>
          Reflection
          <HelpTag type="modal" heading="Reflection Phase" margin="0 1rem" emphasize>
            <p>
              Optional 4th phase of a Peer Assessment assignment in which students are required to provide a written
              reflection on their learning.
            </p>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="row-inputs">
          <legend>Include reflection phase?</legend>
          <div className="rad-radio-btn">
            <input
              id="reflectionOn"
              type="radio"
              name="feedbackEnabled"
              value="true"
              checked={reflectionEnabled}
              onChange={(e) => setReflectionEnabled(e.target.value === 'true')}
            />
            <label htmlFor="reflectionOn">Yes</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="reflectionOff"
              type="radio"
              name="feedbackEnabled"
              value="false"
              checked={!reflectionEnabled}
              onChange={(e) => setReflectionEnabled(e.target.value === 'true')}
            />
            <label htmlFor="reflectionOff">No</label>
          </div>
        </fieldset>
      </Form.Body>
    </>
  );
}

export default ReflectionSetup;
