import React, { useCallback } from 'react';
import { Comment } from '../../types/types';
import Prompt from './Prompt';

interface Prompt {
  added?: boolean;
  comment: Comment;
  customControls?: React.ReactNode;
  onAdd?: (comment: Comment) => void;
  onEdit?: (comment: Comment) => void;
  onSelect?: (comment: Comment) => void;
}

function CommentPrompt({ added = false, comment, customControls, onAdd, onEdit, onSelect }: Prompt): JSX.Element {
  const { commentName, minimumComments, maximumComments } = comment;
  const commentStatus =
    (minimumComments !== maximumComments ? `${minimumComments}-${maximumComments}` : `${maximumComments}`) +
    ` Comment${maximumComments > 1 ? 's' : ''}`;

  const handleEdit = useCallback(() => (onEdit ? onEdit(comment) : undefined), [onEdit, comment]);
  const handleSelect = useCallback(() => (onSelect ? onSelect(comment) : undefined), [onSelect, comment]);
  const handleAdd = useCallback(() => (onAdd ? onAdd(comment) : undefined), [onAdd, comment]);

  return (
    <Prompt
      iconCode="comment"
      name={commentName}
      label="Comment Prompt"
      status={commentStatus}
      description={comment.commentPrompt}
      tags={comment.tags}
      onAdd={onAdd ? handleAdd : undefined}
      onEdit={onEdit ? handleEdit : undefined}
      onSelect={onSelect ? handleSelect : undefined}
      customControls={customControls}
      added={added}
    >
      <></>
    </Prompt>
  );
}

export default CommentPrompt;
