import React, { useEffect, useRef, useState } from 'react';
import { Assignment } from '../../types/types';
import { getAssignmentTypeText, getPhaseColorFromAssignment } from '../../utils/functions';
import AssignmentPhaseIcon from '../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import RichReader from '../core/display/RichReader';

interface Props {
  assignment: Assignment;
  children?: React.ReactNode;
  options?: React.ReactNode;
}

function AssignmentDetailsCard({ assignment, children, options }: Props): JSX.Element {
  const descElem = useRef<HTMLDivElement>(null);

  const [showMore, setShowMore] = useState(false);
  const [showDescToggle, setShowDescToggle] = useState(false);

  useEffect(() => {
    if (descElem.current) setShowDescToggle(descElem.current.clientHeight < descElem.current.scrollHeight);
  }, []);

  const phaseColor = getPhaseColorFromAssignment(assignment);

  return (
    <div
      id="details-card"
      className="card"
      style={{ background: `linear-gradient(${phaseColor} 0.5rem, white 0.5rem 100%)` }}
    >
      <div className="heading">
        <div className="title-wrapper">
          <AssignmentPhaseIcon assignment={assignment} size={32} />
          <h1>{assignment.assignmentName}</h1>
          <h2>{getAssignmentTypeText(assignment)}</h2>
        </div>
        {options ? <span className="options-wrapper">{options}</span> : null}
        {children}
      </div>
      <div id="assignment-description" style={showMore ? { maxHeight: 'none' } : undefined} ref={descElem}>
        <h3 id="desc-label">Assignment Description:</h3>
        <div id="reader-wrapper">
          <RichReader content={assignment.assignmentDescription} />
        </div>
      </div>
      {showDescToggle ? (
        <button
          id="toggle-description-size"
          className={showMore ? 'less' : 'more'}
          onClick={() => setShowMore((prevState) => !prevState)}
          aria-expanded={showMore}
          aria-controls="assignment-description"
        >
          {showMore ? 'Show Less' : 'Show More'}
        </button>
      ) : null}
    </div>
  );
}

export default AssignmentDetailsCard;
