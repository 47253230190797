import React, { useEffect, useRef } from 'react';
import { handleEnterKey } from '../../../../utils/functions';

interface TabListProps {
  children: React.ReactNode;
  label: string;
  tabs: React.ReactNode;
}

function ScrollingTabList({ children, label, tabs }: TabListProps): JSX.Element {
  const containerEl = useRef<HTMLDivElement>(null);
  const tablistEl = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    let foundFirst = false;
    document.querySelectorAll('.tabpanel').forEach((tabPanel) => {
      const tabId = tabPanel.getAttribute('aria-labelledby');
      const tabEl = document.getElementById(tabId ?? '');
      if (!foundFirst && containerEl.current && panelIsInViewport(tabPanel, containerEl.current)) {
        tabEl?.classList.add('selected');
        foundFirst = true;
      } else {
        tabEl?.classList.remove('selected');
      }
    });
  };

  useEffect(() => handleScroll, []);

  return (
    <div className="tabs scrolling-tabs" ref={containerEl} onScrollCapture={handleScroll}>
      <div className="tablist" role="navigation" aria-label={label} ref={tablistEl}>
        {tabs}
      </div>
      {children}
    </div>
  );
}

interface TabProps {
  children: React.ReactNode;
  controls: string;
  id: string;
}

function Tab({ children, controls, id }: TabProps): JSX.Element {
  const handleClick = () => {
    const panel = document.getElementById(controls);
    const panelHeading = panel?.querySelector('h2');
    requestAnimationFrame(() => {
      panelHeading?.focus();
    });
  };

  return (
    <a
      href={`#${controls}`}
      className="tab"
      tabIndex={0}
      role="link"
      aria-selected="false"
      id={id}
      onClick={handleClick}
      onKeyDown={(e) => handleEnterKey(e, handleClick)}
    >
      {children}
    </a>
  );
}

interface TabPanelProps {
  children: React.ReactNode;
  id: string;
  labeledBy: string;
  title: string;
}

function TabPanel({ children, id, labeledBy, title }: TabPanelProps): JSX.Element {
  const panelEl = useRef<HTMLDivElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Home':
        if (panelEl.current) {
          e.preventDefault();
          const firstLink = panelEl.current.parentElement?.querySelector('.tablist a');
          requestAnimationFrame(() => {
            (firstLink as HTMLLinkElement | null | undefined)?.focus();
          });
        }
    }
  };

  return (
    <div ref={panelEl} className="tabpanel" id={id} aria-labelledby={labeledBy} onKeyDown={handleKeyDown}>
      <h2 tabIndex={-1}>{title}</h2>
      {children}
    </div>
  );
}

export const panelIsInViewport = (element: Element, viewPort: HTMLElement): boolean => {
  const elemRect = element.getBoundingClientRect();
  const viewportRect = viewPort.getBoundingClientRect();

  return elemRect.top < (viewportRect.height - viewportRect.top) / 2 && elemRect.bottom >= viewportRect.top + 8;
};

ScrollingTabList.Tab = Tab;
ScrollingTabList.TabPanel = TabPanel;

export default ScrollingTabList;
