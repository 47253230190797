import React, { useCallback, useEffect, useState } from 'react';
import { AssignmentProgress, ReviewUsers } from '../../../types/types';
import { getAssignmentProgress, getReviewUsers } from '../../../utils/requests';
import { useParams } from 'react-router-dom';
import { setPageTitle } from '../../../utils/functions';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import AssignedReviewsMenu from './AssignedReviewsMenu';
import DeanonymizeMenu from './DeanonymizeMenu';
import AssignReviewsByUploadMenu from './AssignReviewsByUploadMenu';
import CopyAssignedReviewsMenu from './CopyAssignedReviewsMenu';
import DeleteReviewMenu from './DeleteReviewsMenu';

type MenuState = 'MAIN' | 'COPY' | 'DEANONYMIZE' | 'UPLOAD' | 'DELETE_ALL';

export interface ReviewManagementMenuProps {
  changeToMenu: (state: MenuState) => void;
  progress: AssignmentProgress[];
  reviewUsers: ReviewUsers[];
  updateData: () => void;
}

function ReviewManagement(): JSX.Element {
  useEffect(() => setPageTitle('Manage Reviews'), []);
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [progress, setProgress] = useState<AssignmentProgress[] | null>(null);
  const [reviewUsers, setReviewUsers] = useState<ReviewUsers[] | null>(null);
  const [menuState, setMenuState] = useState<MenuState>('MAIN');
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    getReviewUsers(assignmentId, setReviewUsers);
    getAssignmentProgress(assignmentId, setProgress);
  }, [courseId, assignmentId, updateKey]);

  const changeToMenu = useCallback((state: MenuState) => setMenuState(state), []);
  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  if (progress && reviewUsers) {
    const sharedProps = { changeToMenu, progress, reviewUsers, updateData };
    switch (menuState) {
      case 'MAIN':
        return <AssignedReviewsMenu {...sharedProps} />;
      case 'COPY':
        return <CopyAssignedReviewsMenu {...sharedProps} />;
      case 'DEANONYMIZE':
        return <DeanonymizeMenu {...sharedProps} />;
      case 'UPLOAD':
        return <AssignReviewsByUploadMenu {...sharedProps} />;
      case 'DELETE_ALL':
        return <DeleteReviewMenu {...sharedProps} />;
    }
  }

  return <LoadingSpinner />;
}

export default ReviewManagement;
