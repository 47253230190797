import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../actions';
import { UserUpdateRequest } from '../../../types/types';
import { formDataToObject, presetInvalidMessage, setPageTitle, updateValidity } from '../../../utils/functions';
import { editUser } from '../../../utils/requests';
import SingleForm from '../../core/input/Form/SingleForm';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/selectors';
const { Row, Col, FormGroup, InputGroup } = SingleForm;

function ProfileDetails(): JSX.Element {
  useEffect(() => setPageTitle('Profile: Details'), []);

  const user = useSelector(selectUser);

  const [editingEnabled, setEditingEnabled] = useState(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      if (user.firstName) setFirstName(user.firstName);
      if (user.lastName) setLastName(user.lastName);
      setEmail(user.email);
      setRole(user.role);
    }
  }, [user]);

  const handleSubmit = useCallback(
    (formData: FormData, callback: () => void) => {
      const dataObject = formDataToObject(formData) as UserUpdateRequest;
      if (user)
        editUser(user.userId, dataObject, (user) => {
          dispatch(setUser(user));
          callback();
        });
    },
    [user, dispatch],
  );

  return (
    <SingleForm
      className="profile-form"
      title="Profile"
      submitText="Update"
      editable
      onEdit={setEditingEnabled}
      onSubmit={handleSubmit}
      noWrapper
    >
      <Row>
        <Col flexBasis="100%">
          <FormGroup>
            <InputGroup iconCode="face" flexDirection="row">
              {editingEnabled ? (
                <>
                  <label className="sr-only" htmlFor="firstName">
                    First name:
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    required={true}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      updateValidity(e);
                    }}
                    onInvalid={presetInvalidMessage('first name')}
                  />
                  <label className="sr-only" htmlFor="lastName">
                    Last name:
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    required={true}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      updateValidity(e);
                    }}
                    onInvalid={presetInvalidMessage('last name')}
                  />
                </>
              ) : (
                <span>
                  <b className="sr-only">Name: </b>
                  {user.firstName} {user.lastName}
                </span>
              )}
            </InputGroup>
            <InputGroup iconCode="email" flexDirection="column">
              {editingEnabled ? (
                <>
                  <label className="sr-only" htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required={true}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      updateValidity(e);
                    }}
                    onInvalid={presetInvalidMessage('email')}
                  />
                </>
              ) : (
                <span>
                  <b className="sr-only">Email: </b>
                  {user.email}
                </span>
              )}
            </InputGroup>
            <InputGroup iconCode="school" flexDirection="row">
              {editingEnabled ? (
                <>
                  <label className="sr-only" htmlFor="role">
                    Role
                  </label>
                  <span id="i-am-a">I am a </span>
                  <select
                    name="role"
                    id="role"
                    required={true}
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  >
                    <option value="STUDENT">Student</option>
                    <option value="TEACHER">Instructor</option>
                  </select>
                </>
              ) : (
                <span>
                  <b>I am a:</b> <span style={{ textTransform: 'capitalize' }}>{user.role.toLocaleLowerCase()}</span>
                </span>
              )}
            </InputGroup>
            <InputGroup iconCode="verified_user" flexDirection="row">
              <span>
                <b>Pseudonym: </b> {user.pseudonym}
              </span>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </SingleForm>
  );
}

export default ProfileDetails;
