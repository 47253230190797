import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Routes, Navigate, useLocation } from 'react-router';
import { RootState } from '../../store';
import { Assignment, AssignmentRubric, PhaseCode, RubricsTargetMap, UserRole } from '../../types/types';
import { getRubricsMap } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import Button from '../core/button/Button/Button';
import AssignmentPhaseIcon from '../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import AssignmentSubmissionRubricPage from './AssignmentSubmissionRubricPage';
import AssignmentEvalRubricPage from './AssignmentEvalRubricPage';
import PromptEditorController from './PromptEditController';
import RatingLibrary from './RatingLibrary';
import CommentLibrary from './CommentLibrary';
import RubricLibrary from './RubricLibrary';
import RubricTemplatePage from './RubricTemplatePage';
import { setPageTitle } from '../../utils/functions';
import { selectAssignment } from '../../store/selectors';
import { newUpdateKey } from '../../actions';
import AssignmentReflectionRubricPage from './AssignmentReflectionRubricPage';
import GroupFormationSurveyEditor from '../groups/GroupFormationSurveyEditor';
import GroupFormationSurveyPromptEditor from '../groups/GroupFormationSurveyPromptEditor';

function AssignmentRubricController(): JSX.Element {
  useEffect(() => setPageTitle('Assignment Rubric'), []);

  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const baseUrlWithIds = `/course/${courseId}/assignment/${assignmentId}/rubric`;

  const [rubricsTargetMap, setRubricsTargetMap] = useState<RubricsTargetMap>({});
  const [submissionRubric, setSubmissionRubric] = useState<AssignmentRubric | null | undefined>(undefined);
  const [peerEvalGroupRubric, setPeerEvalGroupRubric] = useState<AssignmentRubric | null | undefined>(undefined);
  const [peerEvalMemberRubric, setPeerEvalMemberRubric] = useState<AssignmentRubric | null | undefined>(undefined);
  const [peerEvalLeaderRubric, setPeerEvalLeaderRubric] = useState<AssignmentRubric | null | undefined>(undefined);
  const [peerEvalInstructorRubric, setPeerEvalInstructorRubric] = useState<AssignmentRubric | null | undefined>(
    undefined,
  );
  const [reflectionRubric, setReflectionRubric] = useState<AssignmentRubric | null | undefined>(undefined);
  const [updateKey, setUpdateKey] = useState(0);

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  const assignment = useSelector(selectAssignment);
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(newUpdateKey());
    },
    [dispatch],
  );

  const editable = useMemo(
    () =>
      assignment &&
      user.courseRole === 'TEACHER' &&
      ((assignment.asyncEnabled && assignment.status === 'UNPUBLISHED') ||
        (!assignment.asyncEnabled &&
          (assignment.status === 'UNPUBLISHED' || assignment.progressStats?.submissionPhase))),
    [assignment, user.courseRole],
  );

  useEffect(() => {
    getRubricsMap(assignmentId, (rubricsTargetMap) => {
      setRubricsTargetMap(rubricsTargetMap);
      setSubmissionRubric(rubricsTargetMap.SUBMISSION ?? null);
      setPeerEvalGroupRubric(rubricsTargetMap.GROUP ?? null);
      setPeerEvalMemberRubric(rubricsTargetMap.MEMBER ?? null);
      setPeerEvalLeaderRubric(rubricsTargetMap.GROUP_LEADER ?? null);
      setPeerEvalInstructorRubric(rubricsTargetMap.INSTRUCTOR ?? null);
      setReflectionRubric(rubricsTargetMap.REFLECT ?? null);
    });
  }, [updateKey, assignmentId]);

  useEffect(() => updateData(), [location, updateData]);

  const getOneRubricRedirect = (): string | null => {
    if (Object.keys(rubricsTargetMap).length === 1) {
      if (submissionRubric) return `${baseUrlWithIds}/SUBMISSION`;
      if (peerEvalMemberRubric) return `${baseUrlWithIds}/MEMBER`;
      if (peerEvalGroupRubric) return `${baseUrlWithIds}/GROUP`;
      if (peerEvalLeaderRubric) return `${baseUrlWithIds}/GROUP_LEADER`;
      if (peerEvalInstructorRubric) return `${baseUrlWithIds}/INSTRUCTOR`;
      if (reflectionRubric) return `${baseUrlWithIds}/REFLECT`;
    }
    if (assignment?.groupFormationOnly) return `/course/${courseId}/assignment/${assignmentId}/rubric/group_formation`;
    return null;
  };

  const oneRubricRedirect = getOneRubricRedirect();
  if (assignment) {
    return (
      <Routes>
        <Route
          index
          element={
            oneRubricRedirect !== null ? (
              <Navigate to={oneRubricRedirect} />
            ) : (
              <AssignmentRubricCatalog
                assignment={assignment}
                baseUrlWithIds={baseUrlWithIds}
                submissionRubric={submissionRubric}
                peerEvalGroupRubric={peerEvalGroupRubric}
                peerEvalMemberRubric={peerEvalMemberRubric}
                peerEvalLeaderRubric={peerEvalLeaderRubric}
                peerEvalInstructorRubric={peerEvalInstructorRubric}
                reflectionRubric={reflectionRubric}
              />
            )
          }
        />
        <Route
          path={`/SUBMISSION`}
          element={
            <AssignmentSubmissionRubricPage
              rubric={submissionRubric}
              setRubric={setSubmissionRubric}
              readOnly={!editable}
            />
          }
        />
        <Route
          path={`/:target`}
          element={
            <AssignmentEvalRubricPage
              peerEvalGroupRubric={peerEvalGroupRubric}
              setPeerEvalGroupRubric={setPeerEvalGroupRubric}
              peerEvalMemberRubric={peerEvalMemberRubric}
              setPeerEvalMemberRubric={setPeerEvalMemberRubric}
              peerEvalLeaderRubric={peerEvalLeaderRubric}
              setPeerEvalLeaderRubric={setPeerEvalLeaderRubric}
              peerEvalInstructorRubric={peerEvalInstructorRubric}
              setPeerEvalInstructorRubric={setPeerEvalInstructorRubric}
              readOnly={!editable}
            />
          }
        />
        <Route
          path={`/REFLECT`}
          element={
            <AssignmentReflectionRubricPage
              reflectionRubric={reflectionRubric}
              setReflectionRubric={setReflectionRubric}
              updateData={updateData}
              assignment={assignment}
            />
          }
        />
        <Route
          path={`/:target/copy/comment`}
          element={
            <div className="page home-page" id="rubric-library">
              <CommentLibrary isCopy />
            </div>
          }
        />
        <Route
          path={`/:target/copy/rating`}
          element={
            <div className="page home-page" id="rubric-library">
              <RatingLibrary isCopy />
            </div>
          }
        />
        <Route
          path={`/:target/rubrics/library`}
          element={
            <div className="page home-page" id="rubric-library">
              <RubricLibrary />
            </div>
          }
        />
        <Route path={`/:target/rubrics/library/rubric/:rubricTemplateId`} element={<RubricTemplatePage />} />
        <Route path={`/:target/:actionType/:promptType/:id?`} element={<PromptEditorController />} />

        <Route path={`/group_formation`} element={<GroupFormationSurveyEditor />} />
        <Route
          path={`/group_formation/prompt/edit/:surveyPromptId`}
          element={<GroupFormationSurveyPromptEditor type="edit" />}
        />
        <Route path={`/group_formation/prompt/new`} element={<GroupFormationSurveyPromptEditor type="new" />} />
      </Routes>
    );
  }

  return <LoadingSpinner />;
}

interface AssignmentRubricCatalogProps {
  assignment: Assignment;
  baseUrlWithIds: string;
  submissionRubric: AssignmentRubric | null | undefined;
  peerEvalGroupRubric: AssignmentRubric | null | undefined;
  peerEvalMemberRubric: AssignmentRubric | null | undefined;
  peerEvalLeaderRubric: AssignmentRubric | null | undefined;
  peerEvalInstructorRubric: AssignmentRubric | null | undefined;
  reflectionRubric: AssignmentRubric | null | undefined;
}

function AssignmentRubricCatalog({
  assignment,
  baseUrlWithIds,
  submissionRubric,
  peerEvalGroupRubric,
  peerEvalMemberRubric,
  peerEvalLeaderRubric,
  peerEvalInstructorRubric,
  reflectionRubric,
}: AssignmentRubricCatalogProps): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const user = useSelector((state: RootState) => state.user);

  if (
    user.courseRole !== null &&
    submissionRubric !== undefined &&
    peerEvalGroupRubric !== undefined &&
    peerEvalMemberRubric !== undefined &&
    peerEvalLeaderRubric !== undefined &&
    peerEvalInstructorRubric !== undefined &&
    reflectionRubric !== undefined
  )
    return (
      <div id="assignment-rubric-catalog" className="page">
        <div id="menu">
          <h1>Assignment Rubrics Menu</h1>
          {assignment.groupFormationEnabled ? (
            <CatalogItem
              heading="Group Formation Survey"
              description="Students will use this survey to be sorted into groups."
              phase="groupFormation"
              href={`/course/${courseId}/assignment/${assignmentId}/rubric/group_formation`}
              role={user.courseRole}
            />
          ) : null}
          {!assignment.peerEvaluationOnly && submissionRubric ? (
            <CatalogItem
              heading="Submission Rubric"
              description="Students will use this rubric to review their peers' submissions."
              phase="submit"
              rubric={submissionRubric}
              href={`${baseUrlWithIds}/SUBMISSION`}
              role={user.courseRole}
            />
          ) : null}
          {peerEvalMemberRubric ? (
            <CatalogItem
              heading="Group Member Evaluation Rubric"
              description="Students will use this rubric to evaluate individual group members or class members."
              phase="evaluate"
              rubric={peerEvalMemberRubric}
              href={`${baseUrlWithIds}/MEMBER`}
              role={user.courseRole}
            />
          ) : null}
          {assignment.groupEvalEnabled && peerEvalGroupRubric ? (
            <CatalogItem
              heading="Group Evaluation Rubric"
              description="Students will use this rubric to evaluate the group as a whole."
              phase="evaluate"
              rubric={peerEvalGroupRubric}
              href={`${baseUrlWithIds}/GROUP`}
              role={user.courseRole}
            />
          ) : null}
          {assignment.leaderEvalEnabled && peerEvalLeaderRubric ? (
            <CatalogItem
              heading="Group Leader Evaluation Rubric"
              description="Students will use this rubric to evaluate the group leader."
              phase="evaluate"
              rubric={peerEvalLeaderRubric}
              href={`${baseUrlWithIds}/GROUP_LEADER`}
              role={user.courseRole}
            />
          ) : null}
          {assignment.instructorEvalEnabled && peerEvalInstructorRubric ? (
            <CatalogItem
              heading="Instructor Evaluation Rubric"
              description="Students will use this rubric to evaluate the instructors."
              phase="evaluate"
              rubric={peerEvalInstructorRubric}
              href={`${baseUrlWithIds}/INSTRUCTOR`}
              role={user.courseRole}
            />
          ) : null}
          {assignment.reflectionEnabled && reflectionRubric ? (
            <CatalogItem
              heading="Reflection Rubric"
              description="Students will use this rubric to complete the reflection phase."
              phase="reflection"
              rubric={reflectionRubric}
              href={`${baseUrlWithIds}/REFLECT`}
              role={user.courseRole}
            />
          ) : null}
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

interface CatalogItemProps {
  description: string;
  heading: string;
  href: string;
  phase: PhaseCode;
  role: UserRole;
  rubric?: AssignmentRubric;
}

function CatalogItem({ description, heading, href, phase, role, rubric }: CatalogItemProps): JSX.Element {
  return (
    <div className="catalog-item">
      <div className="details">
        <h2>
          <AssignmentPhaseIcon phase={phase} /> {heading}
        </h2>
        <p>{description}</p>
      </div>
      <Button variant={`rad low ${rubric ? 'alt' : ''}`} href={href} route>
        {role === 'TEACHER' && rubric?.editable ? (rubric ? 'Edit' : 'Start') : 'View'}
      </Button>
    </div>
  );
}

export default AssignmentRubricController;
