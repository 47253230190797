import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import AlertBar from '../core/display/AlertBar';
import TutorialModal from './TutorialModal';

interface Props {
  buttonMode?: boolean;
  buttonText?: string;
}

function RatingTutorial({ buttonMode = false, buttonText = '' }: Props): JSX.Element {
  const user = useSelector((state: RootState) => state.user);
  const userId = user.actingAs ? 'null' : user.userId;
  const courseId = user.actingAs ? 'null' : user.courseRoleCourseId;

  return (
    <TutorialModal
      id={`rating-tutorial-for-${userId}-in-${courseId}`}
      heading="Tips for Accurate Rating"
      buttonMode={buttonMode}
      buttonText={buttonText}
    >
      <div id="rating-tutorial">
        <ol>
          <li>Take your time</li>
          <li>Read the prompt and descriptions carefully</li>
          <li>
            Rate each submission as if you were the instructor. Being overly generous does not help the peers you are
            reviewing.
          </li>
        </ol>
        <AlertBar backgroundColor="#ffefd2">
          Part of your overall grade is determined by how accurately you rate peer submissions.
        </AlertBar>
      </div>
    </TutorialModal>
  );
}

export default RatingTutorial;
