import { PayloadAction } from '@reduxjs/toolkit';
import { FeedbackTable } from '../types/types';
import { FeedbackUpdateData } from '../actions';
import { SET_FEEDBACK_TABLE, UPDATE_FEEDBACK_TABLE } from '../utils/constants';

const feedbackTableReducer = (
  state: FeedbackTable = {},
  action: PayloadAction<FeedbackTable | FeedbackUpdateData>,
): FeedbackTable => {
  switch (action.type) {
    case SET_FEEDBACK_TABLE:
      const setPayload = action.payload as FeedbackTable;
      return setPayload;
    case UPDATE_FEEDBACK_TABLE:
      const updatePayload = action.payload as FeedbackUpdateData;
      state[updatePayload.id] = {
        ...state[updatePayload.id],
        ...updatePayload.data,
      };
      return state;
    default:
      return state;
  }
};

export default feedbackTableReducer;
