import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SCREEN_WIDTH_LAPTOP } from '../../../../utils/constants';
import JumpButton from '../../button/JumpButton';
import Icon from '../../display/Icon';
import { isHeightReflow } from '../../../../utils/functions';

interface Props {
  children: React.ReactNode;
  title?: string | JSX.Element;
  subtitle?: string;
  menuLabel: string;
  titleHref: string;
}

function Sidebar({ children, title, subtitle, menuLabel, titleHref }: Props): JSX.Element {
  const [minimized, setMinimized] = useState(false);
  const [mobile, setMobile] = useState(document.documentElement.clientWidth < SCREEN_WIDTH_LAPTOP);
  const [heightBP, setHeightBP] = useState(isHeightReflow());

  const minClass = minimized ? ' sidebar-minimized' : ' sidebar-maximized';

  useEffect(() => {
    const resizeHandler = () => setHeightBP(isHeightReflow());
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  });

  useEffect(() => {
    const contentSnapshot = document.getElementById('site-content');
    const scrollHandler = (e: Event) => {
      const scrollWrapper = e.target as HTMLElement;
      const mobileMenuTop = document.getElementById('mobile-menu-top');
      const mobileMenuBottom = document.getElementById('mobile-menu-bottom');
      const siteContent = document.getElementById('site-content');
      const isHidden = mobileMenuTop?.getAttribute('scroll-hidden');
      if (heightBP && mobileMenuTop && mobileMenuBottom && siteContent) {
        if (isHidden === 'true') {
          if (scrollWrapper.scrollTop < 1) {
            mobileMenuTop.style.marginTop = `0`;
            mobileMenuBottom.style.bottom = `0`;
            mobileMenuTop.setAttribute('scroll-hidden', 'false');
            mobileMenuBottom.setAttribute('scroll-hidden', 'false');
          }
        } else {
          if (scrollWrapper.scrollTop > 56) {
            mobileMenuTop.style.marginTop = `-38px`;
            mobileMenuBottom.style.bottom = `-4rem`;
            mobileMenuTop.setAttribute('scroll-hidden', 'true');
            mobileMenuBottom.setAttribute('scroll-hidden', 'true');
          }
        }
      }
    };
    contentSnapshot?.addEventListener('scroll', scrollHandler);
    return () => {
      contentSnapshot?.removeEventListener('scroll', scrollHandler);
    };
  }, [heightBP]);

  useEffect(() => {
    if (!heightBP) {
      const mobileMenuTop = document.getElementById('mobile-menu-top');
      const mobileMenuBottom = document.getElementById('mobile-menu-bottom');
      if (mobileMenuTop && mobileMenuBottom) {
        mobileMenuTop.style.marginTop = `0`;
        mobileMenuBottom.style.bottom = `0`;
        mobileMenuTop.setAttribute('scroll-hidden', 'false');
        mobileMenuBottom.setAttribute('scroll-hidden', 'false');
      }
    }
  }, [heightBP]);

  useEffect(() => {
    const handleResize = () => {
      const w = document.documentElement.clientWidth;
      if (w < SCREEN_WIDTH_LAPTOP) {
        // Set to mobile
        setMobile(true);
      } else {
        // Set to desktop
        setMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setMobile]);

  useEffect(() => {
    const siteContentElem = document.getElementById('site-content');
    if (siteContentElem) siteContentElem.classList.add('with-sidebar');
    return () => siteContentElem?.classList.remove('with-sidebar');
  }, []);

  let headingLinkClasses = 'sidebar-heading-title';
  if (titleHref !== '/' && window.location.href.includes(titleHref))
    headingLinkClasses += ' sidebar-heading-title-selected';

  if (mobile) {
    return (
      <>
        <div id="mobile-menu-top" className="soft-shadow">
          <RouterLink className={headingLinkClasses} to={titleHref}>
            <div className="title">{title}</div>
          </RouterLink>
        </div>
        <div id="mobile-menu-bottom" className="soft-shadow">
          <div className="links-container">{children}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <JumpButton type="focus-child" targetId="site-content" invisible>
        Skip to Main Content
      </JumpButton>
      <div className={'peer-sidebar' + minClass} role="navigation" aria-label={menuLabel}>
        <div className="sidebar-menu-label" aria-hidden>
          {menuLabel}
        </div>
        <div className="sidebar-top">
          <div className="sidebar-heading">
            <RouterLink className={headingLinkClasses} to={titleHref}>
              <div className="title">{title}</div>
              {subtitle ? <div className="subtitle">{subtitle}</div> : null}
            </RouterLink>
          </div>
          <div className="links-container">{children}</div>
        </div>
        <div className="sidebar-bottom">
          <button className="button-mini sidebar-minimize-btn" onClick={() => setMinimized(!minimized)}>
            <Icon code={`chevron_${minimized ? 'right' : 'left'}`} label={minimized ? 'Maximize' : 'Minimize'} />
          </button>
        </div>
      </div>
    </>
  );
}

interface LinkProps {
  children: React.ReactNode;
  href: string;
  iconCode: string;
}

function Link({ children, href, iconCode }: LinkProps): JSX.Element {
  let classes = 'sidebar-link';
  let current = false;
  if (href !== '/' && window.location.href.includes(href)) {
    current = true;
    classes += ' sidebar-link-selected';
  }

  return (
    <RouterLink
      className={classes}
      to={href}
      aria-label={typeof children === 'string' ? (children as string) : undefined}
      aria-current={current}
    >
      <Icon code={iconCode} ariaHidden />
      <span className="sidebar-link-name">{children}</span>
    </RouterLink>
  );
}

Sidebar.Link = Link;

export default Sidebar;
