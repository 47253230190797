import React, { useState, useEffect } from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';

interface Props {
  groupsEnabled: boolean;
  instructorUpload: boolean;
  setGroupsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setInstructorUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setPeerEvaluationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  peerEvaluationEnabled: boolean;
}

function SubmissionSetup({
  groupsEnabled,
  instructorUpload,
  setGroupsEnabled,
  setInstructorUpload,
  setPeerEvaluationEnabled,
  peerEvaluationEnabled,
}: Props): JSX.Element {
  let initWhoSubmits: 'individual' | 'group' | 'teacher' = 'individual';
  if (groupsEnabled && !instructorUpload) initWhoSubmits = 'group';
  if (!groupsEnabled && instructorUpload) initWhoSubmits = 'teacher';

  const [whoSubmits, setWhoSubmits] = useState<'individual' | 'group' | 'teacher'>(initWhoSubmits);

  useEffect(() => {
    switch (whoSubmits) {
      case 'individual':
        setGroupsEnabled(false);
        setInstructorUpload(false);
        setPeerEvaluationEnabled(false);
        break;
      case 'group':
        setGroupsEnabled(true);
        setInstructorUpload(false);
        break;
      case 'teacher':
        setGroupsEnabled(false);
        setInstructorUpload(true);
        setPeerEvaluationEnabled(false);
        break;
      default:
    }
  }, [setGroupsEnabled, setInstructorUpload, setPeerEvaluationEnabled, whoSubmits]);

  return (
    <>
      <Form.Header>
        <Form.Title>
          Submission
          <HelpTag type="modal" heading="Submission Phase" margin="0 1rem" emphasize>
            <div>
              In the first phase of a Peerceptiv peer assessment, the work is submitted to be available for review.
              Peerceptiv has three submission options:
            </div>
            <ol>
              <li>Individual students can upload their own work,</li>
              <li>A group member can upload their group&apos;s work, or</li>
              <li>The instructor can upload exemplars for review.</li>
            </ol>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="col-inputs">
          <legend>Who submits?</legend>
          <div className="rad-radio-btn">
            <input
              id="individualStudents"
              type="radio"
              name="whoSubmits"
              value="individual"
              checked={whoSubmits === 'individual'}
              onChange={(e) => setWhoSubmits(e.target.value as 'individual')}
            />
            <label htmlFor="individualStudents">Individual Students</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="studentGroups"
              type="radio"
              name="whoSubmits"
              value="group"
              checked={whoSubmits === 'group'}
              onChange={(e) => setWhoSubmits(e.target.value as 'group')}
            />
            <label htmlFor="studentGroups">Student Groups</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="instructorUpload"
              type="radio"
              name="whoSubmits"
              value="teacher"
              checked={whoSubmits === 'teacher'}
              onChange={(e) => setWhoSubmits(e.target.value as 'teacher')}
            />
            <label htmlFor="instructorUpload">Instructor</label>
          </div>
        </fieldset>
        <fieldset
          className="row-inputs"
          style={{
            pointerEvents: whoSubmits === 'group' ? 'auto' : 'none',
            opacity: whoSubmits === 'group' ? 1 : 0,
            transition: '0.1s',
          }}
        >
          <legend>Add a team member evaluation stage?</legend>
          <div className="rad-radio-btn">
            <input
              id="teamEvalOn"
              type="radio"
              name="peerEvaluationEnabled"
              value="true"
              checked={peerEvaluationEnabled}
              onChange={(e) => setPeerEvaluationEnabled(e.target.value === 'true')}
              tabIndex={whoSubmits === 'group' ? undefined : -1}
            />
            <label htmlFor="teamEvalOn">Yes</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="teamEvalOff"
              type="radio"
              name="peerEvaluationEnabled"
              value="false"
              checked={!peerEvaluationEnabled}
              onChange={(e) => setPeerEvaluationEnabled(e.target.value === 'true')}
              tabIndex={whoSubmits === 'group' ? undefined : -1}
            />
            <label htmlFor="teamEvalOff">No</label>
          </div>
        </fieldset>
      </Form.Body>
    </>
  );
}

export default SubmissionSetup;
