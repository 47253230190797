import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { ActivityStats, DailyTaskEvents } from '../../types/types';
import { getCourseActivityStats, getCourseDailyTaskEvents } from '../../utils/requests';
import DonutChart, { PieDatum } from '../core/display/Graph/DonutChart';
import ActivityGraph from '../dashboard/teacher/ActivityGraph';
import { TEST_ENV } from '../../utils/constants';

interface InteractionData extends PieDatum {
  total: number;
}

function CourseDataBar(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };

  const [dailyTaskEvents, setDailyTaskEvents] = useState<DailyTaskEvents[] | null>(null);
  const [activityStats, setActivityStats] = useState<ActivityStats | null>(null);
  const [totalInteractionsData, setTotalInteractionsData] = useState<InteractionData[]>([]);
  const [todayInteractionsData, setTodayInteractionsData] = useState<InteractionData[]>([]);

  useEffect(() => {
    getCourseDailyTaskEvents(courseId, setDailyTaskEvents);
    getCourseActivityStats(courseId, setActivityStats);
  }, [courseId]);

  useEffect(() => {
    if (activityStats) {
      const { totalSubmissions, totalReviews, totalFeedback, totalEvaluations } = activityStats;
      const { submissionPercent, reviewPercent, feedbackPercent, evaluationPercent } = activityStats;
      setTotalInteractionsData([
        { label: 'Submissions', total: totalSubmissions, usage: submissionPercent, color: '#7878F1' },
        { label: 'Reviews', total: totalReviews, usage: reviewPercent, color: '#E676E3' },
        { label: 'Feedback', total: totalFeedback, usage: feedbackPercent, color: '#E4C445' },
        { label: 'Evaluations', total: totalEvaluations, usage: evaluationPercent, color: '#55C92D' },
      ]);
      const { totalSubmissionsToday, totalReviewsToday, totalFeedbackToday, totalEvaluationsToday } = activityStats;
      const { submissionPercentToday, reviewPercentToday, feedbackPercentToday, evaluationPercentToday } =
        activityStats;
      setTodayInteractionsData([
        { label: 'Submissions', total: totalSubmissionsToday, usage: submissionPercentToday, color: '#7878F1' },
        { label: 'Reviews', total: totalReviewsToday, usage: reviewPercentToday, color: '#E676E3' },
        { label: 'Feedback', total: totalFeedbackToday, usage: feedbackPercentToday, color: '#E4C445' },
        { label: 'Evaluations', total: totalEvaluationsToday, usage: evaluationPercentToday, color: '#55C92D' },
      ]);
    }
  }, [activityStats]);

  if (TEST_ENV) return <></>;
  return (
    <section className="data-bar">
      <SimpleBar className="card-scroller graphs-wrapper">
        {activityStats ? (
          <div id="interactions-card">
            <h2>Student Interactions</h2>
            <div className="chart-wrapper">
              <DonutChart
                labelMain={`${activityStats.totalTasks}`}
                labelSub="Total"
                data={totalInteractionsData}
                width={96}
                height={96}
                donutThickness={12}
              />
              <DonutChart
                labelMain={`${activityStats.totalTasksToday}`}
                labelSub="Today"
                data={todayInteractionsData}
                width={64}
                height={64}
                donutThickness={8}
              />
            </div>
            <table className="interactions-table">
              <thead className="sr-only">
                <tr>
                  <th>Type</th>
                  <th>Total Interactions</th>
                  <th>Interactions Added Today</th>
                </tr>
              </thead>
              <tbody>
                {totalInteractionsData.map((d) => (
                  <tr key={d.color}>
                    <td className="label">
                      <div className="color-box" style={{ backgroundColor: d.color }} />
                      {d.label}
                    </td>
                    <td className="total">{d.total} total</td>
                    <td className="today">
                      (+{todayInteractionsData.find((dToday) => dToday.label === d.label)?.total ?? 0})
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {dailyTaskEvents ? (
          <div id="activity-card">
            <h2>Activity</h2>
            <ActivityGraph height={150} dailyTaskEvents={dailyTaskEvents} dataDisplay="TASKS" />
          </div>
        ) : null}
      </SimpleBar>
    </section>
  );
}

export default CourseDataBar;
