import React, { useState, useEffect } from 'react';
import { changeNumberInputWithBounds } from '../../../utils/functions';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Grading2Props {
  meanGrade: number;
  standardDeviation: number;
}

function GradingPage2({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Grading2Props>): JSX.Element {
  const [meanGrade, setMeanGrade] = useState(assignmentSettings.meanGrade);
  const [standardDeviation, setStandardDeviation] = useState(assignmentSettings.standardDeviation);

  useEffect(() => {
    updateSettings({ meanGrade, standardDeviation });
  }, [updateSettings, meanGrade, standardDeviation]);

  return (
    <>
      <div className="sub-section">
        <h3>Grade Curve</h3>
        <p>
          The mean and standard deviation will be used to generate student submission grades (unless the assignment is
          benchmarked), accuracy grades, and helpfulness grades.
        </p>
        <div className="stats-interface">
          <span>
            <label htmlFor="meanGrade">Mean: </label>
            <input
              id="meanGrade"
              name="meanGrade"
              type="number"
              min="0"
              max="100"
              value={meanGrade}
              onChange={(e) => {
                changeNumberInputWithBounds(e.target.value, 0, 100, setMeanGrade);
              }}
            />
          </span>
          <span>
            <label htmlFor="standardDeviation">Standard Deviation: </label>
            <input
              id="standardDeviation"
              name="standardDeviation"
              type="number"
              min="0"
              max="100"
              value={standardDeviation}
              onChange={(e) => {
                changeNumberInputWithBounds(e.target.value, 0, 100, setStandardDeviation);
              }}
            />
          </span>
        </div>
      </div>
    </>
  );
}

export default GradingPage2;
