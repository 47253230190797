import React, { useState, useEffect, useCallback } from 'react';
import { CourseUser, AccessPermission } from '../../types/types';
import {
  getRosterInstructors,
  getRosterStudents,
  getRoster,
  postAddStudents,
  postAddInstructors,
  getCourseSeatsAvailable,
  postAddTAs,
  postAddGraders,
  postAddExternalReviewers,
  getAccessPermission,
  syncRoster,
} from '../../utils/requests';
import AddButton from '../core/button/AddButton/AddButton';
import TableInput from '../core/input/TableInput/TableInput';
import InstructorsTable from './InstructorsTable';
import StudentsTable from './StudentsTable';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import TabList from '../core/layout/TabList/TabList';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Icon from '../core/display/Icon';
import Button from '../core/button/Button/Button';
import { setPageTitle } from '../../utils/functions';
import { selectCourse } from '../../store/selectors';

function RosterPage(): JSX.Element {
  useEffect(() => setPageTitle('Course Roster'), []);
  const { courseId } = useParams() as { courseId: string; assignmentId: string };
  const course = useSelector(selectCourse);
  const [instructors, setInstructors] = useState<CourseUser[] | null>(null);
  const [students, setStudents] = useState<CourseUser[] | null>(null);
  const [rosterHeaders, setRosterHeaders] = useState<string[]>([]);
  const [rosterData, setRosterData] = useState<string[][]>([]);
  const [accessPermission, setAccessPermission] = useState<AccessPermission>();
  const [seatsAvailable, setSeatsAvailable] = useState(0);
  const [updateKey, setUpdateKey] = useState(0);
  const user = useSelector((state: RootState) => state.user);

  const { modalDispatch } = useModalContext();

  const updateData = useCallback(() => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  const setupRosterForExport = useCallback((rosterData: CourseUser[]) => {
    const headers = ['Name', 'Pseudonymn', 'Email', 'Role'];
    const data: string[][] = [];
    type RosterRow = { sortableName: string; pseudonym: string; email: string; role: string };
    rosterData.forEach((entry) => {
      const { sortableName, pseudonym, email, role }: RosterRow = { ...entry.user, role: entry.role };
      const row = [sortableName, pseudonym, email, role];
      data.push(row);
    });
    setRosterHeaders(headers);
    setRosterData(data);
  }, []);

  useEffect(() => {
    getRosterInstructors(courseId, setInstructors);
    getRoster(courseId, setupRosterForExport);
    getCourseSeatsAvailable(courseId, setSeatsAvailable);
    getRosterStudents(courseId, setStudents);
  }, [setupRosterForExport, updateKey, courseId, course?.sectioned, user.userId, user.role]);

  useEffect(() => {
    if (user.userId && course?.courseId) {
      getAccessPermission(course?.courseId, setAccessPermission);
    }
  }, [course?.courseId, user.userId]);

  const openAddStudentsDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Add Students',
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: <AddStudentsDialogue courseId={courseId} seatsAvailable={seatsAvailable} updateData={updateData} />,
        }),
      ),
    [modalDispatch, courseId, seatsAvailable, updateData],
  );

  const openAddInstructorsDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Add Instructors',
          label: 'Enter the instructor\'s details below and submit to add them to the course:',
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: (
            <AddInstructorsDialogue courseId={courseId} seatsAvailable={seatsAvailable} updateData={updateData} />
          ),
        }),
      ),
    [modalDispatch, courseId, seatsAvailable, updateData],
  );

  const openAddTeachingAssistantDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Add Teaching Assistants',
          label: 'Enter the teaching assistant\'s details below and submit to add them to the course:',
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: <AddTADialogue courseId={courseId} seatsAvailable={seatsAvailable} updateData={updateData} />,
        }),
      ),
    [modalDispatch, courseId, seatsAvailable, updateData],
  );

  const openAddGraderDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Add Graders',
          label: 'Enter the grader\'s details below and submit to add them to the course:',
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: <AddGraderDialogue courseId={courseId} seatsAvailable={seatsAvailable} updateData={updateData} />,
        }),
      ),
    [modalDispatch, courseId, seatsAvailable, updateData],
  );

  const openAddExternalReviewerDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Add External Reviewers',
          label: 'Enter the external reviewer\'s details below and submit to add them to the course:',
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: (
            <AddExternalReviewersDialogue courseId={courseId} seatsAvailable={seatsAvailable} updateData={updateData} />
          ),
        }),
      ),
    [modalDispatch, courseId, seatsAvailable, updateData],
  );
  
  const openSyncRosterDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Sync Roster',
          label: `Are you sure you want to sync the roster with the LMS? \nThis will remove students not
           listed in the LMS who have not submitted any work and will run in the background.\n 
           You will not see immediate changes and can navigate away from this page while it runs.`,
          buttonText: 'Sync Roster',
          onConfirm: () => {
            syncRoster(courseId, updateData);
          },
        }),
      ),
    [courseId, modalDispatch, updateData],
  );

  return (
    <div>
      {course && course.ltiContextId && user.courseRole!=="STUDENT"? (
        <div className="sync-roster">
          <Button onClick={openSyncRosterDialogue}>Sync Roster</Button>
        </div>
      ) : (
        ''
      )}
      <div className="page teacher-results-row">
        {course && user.courseRole!=="STUDENT"? (
          <AddButton iconCode="more_vert">
            <AddButton.ActionButton action={openAddStudentsDialogue} iconCode="person_add_alt_1">
              Add Students
            </AddButton.ActionButton>
            <AddButton.ActionButton action={openAddInstructorsDialogue} iconCode="person_add_alt">
              Add Instructors
            </AddButton.ActionButton>
            <AddButton.ActionButton action={openAddTeachingAssistantDialogue} iconCode="person_add_alt">
              Add Teaching Assistants
            </AddButton.ActionButton>
            <AddButton.ActionButton action={openAddGraderDialogue} iconCode="person_add_alt">
              Add Graders
            </AddButton.ActionButton>
            <AddButton.ActionButton
              action={() => {
                const rosterDownloadBtn = document.getElementById('roster-download-btn');
                if (rosterDownloadBtn) rosterDownloadBtn.click();
              }}
              iconCode="download"
            >
              Export Roster
            </AddButton.ActionButton>
          </AddButton>
        ):null}
        <div className="roster-container">
          <TabList
            label="Roster Menu"
            tabs={
              <>
                <TabList.Tab id="students" controls="students-tab">
                  Students
                </TabList.Tab>
                <TabList.Tab id="instructors" controls="instructors-tab">
                  Instructors
                </TabList.Tab>
              </>
            }
          >
            <TabList.TabPanel id="students-tab" labeledBy="students">
              <section>
                {students && course ? (
                  <StudentsTable
                    studentData={students}
                    course={course}
                    showAddModal={openAddStudentsDialogue}
                    updateData={updateData}
                    accessPermission={accessPermission}
                  />
                ) : (
                  ''
                )}
              </section>
            </TabList.TabPanel>

            <TabList.TabPanel id="instructors-tab" labeledBy="instructors">
              <section>
                {instructors ? (
                  <InstructorsTable
                    instructorData={instructors}
                    showAddModal={openAddInstructorsDialogue}
                    updateData={updateData}
                    accessPermission={accessPermission}
                  />
                ) : (
                  ''
                )}
              </section>
            </TabList.TabPanel>
          </TabList>
        </div>

        <div style={{ display: 'none' }}>
          <CSVLink
            headers={rosterHeaders}
            data={rosterData}
            filename={'roster.csv'}
            className="peer-btn-low"
            id="roster-download-btn"
            target="_blank"
          >
            <Icon code="download" label="Download table as CSV" />
          </CSVLink>
        </div>
      </div>
    </div>
  );
}

interface AddDialogueProps {
  courseId: string;
  seatsAvailable?: number;
  updateData: () => void;
}

type AddUserData = { firstName: string; lastName: string; email: string };

const tableInputHeaders = [
  { title: 'First Name', accessor: 'firstName' },
  { title: 'Last Name', accessor: 'lastName' },
  { title: 'Email', accessor: 'email' },
];

function AddStudentsDialogue({ courseId, seatsAvailable, updateData }: AddDialogueProps): JSX.Element {
  const [students, setStudents] = useState<AddUserData[]>([]);
  const { modalDispatch } = useModalContext();
  const user = useSelector((state: RootState) => state.user);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const validStudents = students.filter(
          (student) => student.firstName !== '' && student.lastName !== '' && student.email !== '',
        );
        postAddStudents(
          courseId,
          validStudents,
          () => {
            const numStudents = validStudents.length;
            const plural = numStudents !== 1;
            modalDispatch(
              openModal({
                heading: `Student${plural ? 's' : ''} Added`,
                label: `${numStudents} student${plural ? 's have' : ' has'} been added to the course.`,
                inputType: 'none',
                buttonText: 'Continue',
                cancelHide: true,
              }),
            );
            updateData();
          },
          (error) => {
            if (error.response?.status === 402) {
              modalDispatch(
                openModal({
                  heading: `No Seats Available`,
                  label: 'Seats must be purchased in order to add students to the roster.',
                  buttonText: 'Purchase Seats',
                  confirmHref: '/purchase',
                }),
              );
              return true;
            }
            return false;
          },
        );
      }}
    >
      <p style={{ maxWidth: '560px' }}>
        Enter the student&apos;s details below and submit to add them to the course or Paste in roster from a
        spreadsheet with the student&apos;s first name, last name, and email respectively (formatted like the table
        below).
        {user.purchasingEnabled ? (
          <>
            <br />
            <br />
            <b>Seats Available: {seatsAvailable}</b>
          </>
        ) : null}
      </p>
      <TableInput<AddUserData> headers={tableInputHeaders} onChange={setStudents} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

function AddInstructorsDialogue({ courseId, updateData }: AddDialogueProps): JSX.Element {
  const [instructors, setInstructors] = useState<AddUserData[]>([]);
  const { modalDispatch } = useModalContext();

  return (
    <form
      onSubmit={() => {
        const validInstructors = instructors.filter(
          (instructor) => instructor.firstName !== '' && instructor.lastName !== '' && instructor.email !== '',
        );
        postAddInstructors(courseId, validInstructors, () => {
          const numInstructors = validInstructors.length;
          const plural = numInstructors !== 1;
          modalDispatch(
            openModal({
              heading: `Instructor${plural ? 's' : ''} Added`,
              label: `${numInstructors} instructor${plural ? 's have' : ' has'} been added to the course.`,
              inputType: 'none',
              buttonText: 'Continue',
              cancelHide: true,
            }),
          );
          updateData();
        });
      }}
    >
      <TableInput<AddUserData> headers={tableInputHeaders} onChange={setInstructors} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

function AddTADialogue({ courseId, updateData }: AddDialogueProps): JSX.Element {
  const [teachingAssistants, setInstructors] = useState<AddUserData[]>([]);
  const { modalDispatch } = useModalContext();

  return (
    <form
      onSubmit={() => {
        const validTAs = teachingAssistants.filter(
          (teachingAssistant) =>
            teachingAssistant.firstName !== '' && teachingAssistant.lastName !== '' && teachingAssistant.email !== '',
        );
        postAddTAs(courseId, validTAs, () => {
          const numInstructors = validTAs.length;
          const plural = numInstructors !== 1;
          modalDispatch(
            openModal({
              heading: `TA${plural ? 's' : ''} Added`,
              label: `${numInstructors} TA${plural ? 's have' : ' has'} been added to the course.`,
              inputType: 'none',
              buttonText: 'Continue',
              cancelHide: true,
            }),
          );
          updateData();
        });
      }}
    >
      <TableInput<AddUserData> headers={tableInputHeaders} onChange={setInstructors} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

function AddGraderDialogue({ courseId, updateData }: AddDialogueProps): JSX.Element {
  const [graders, setGraders] = useState<AddUserData[]>([]);
  const { modalDispatch } = useModalContext();

  return (
    <form
      onSubmit={() => {
        const validGraders = graders.filter(
          (grader) => grader.firstName !== '' && grader.lastName !== '' && grader.email !== '',
        );
        postAddGraders(courseId, validGraders, () => {
          const numInstructors = validGraders.length;
          const plural = numInstructors !== 1;
          modalDispatch(
            openModal({
              heading: `Grader${plural ? 's' : ''} Added`,
              label: `${numInstructors} grader${plural ? 's have' : ' has'} been added to the course.`,
              inputType: 'none',
              buttonText: 'Continue',
              cancelHide: true,
            }),
          );
          updateData();
        });
      }}
    >
      <TableInput<AddUserData> headers={tableInputHeaders} onChange={setGraders} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

function AddExternalReviewersDialogue({ courseId, updateData }: AddDialogueProps): JSX.Element {
  const [externalReviewers, setExternalReviewers] = useState<AddUserData[]>([]);
  const { modalDispatch } = useModalContext();

  return (
    <form
      onSubmit={() => {
        const validReviewer = externalReviewers.filter(
          (externalReviewer) =>
            externalReviewer.firstName !== '' && externalReviewer.lastName !== '' && externalReviewer.email !== '',
        );
        postAddExternalReviewers(courseId, validReviewer, () => {
          const numInstructors = validReviewer.length;
          const plural = numInstructors !== 1;
          modalDispatch(
            openModal({
              heading: `Grader${plural ? 's' : ''} Added`,
              label: `${numInstructors} grader${plural ? 's have' : ' has'} been added to the course.`,
              inputType: 'none',
              buttonText: 'Continue',
              cancelHide: true,
            }),
          );
          updateData();
        });
      }}
    >
      <TableInput<AddUserData> headers={tableInputHeaders} onChange={setExternalReviewers} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

export default RosterPage;
