import React, { useState, useMemo, useEffect } from 'react';
import { AverageRating, PageQuery, PageQueryParams, Rating, Tag, User } from '../../types/types';
import { stringArrayIncludes } from '../../utils/functions';
import {
  getInstructorRatingAverages,
  getInstructorTagAverages,
  getUserAveragesOvertimeForRating,
  getUserAveragesOvertimeForTag,
  getUserRatingAverages,
  getUserTagAverages,
} from '../../utils/requests';
import Button from '../core/button/Button/Button';
import TrackedXYChart from '../core/display/Graph/TrackedXYChart';
import Icon from '../core/display/Icon';
import QueryTable, { Column as QueryCol } from '../core/display/QueryTable/QueryTable';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { UserDetailsTabProps } from './UserDetailsPage';

type Type = 'RATING' | 'TAG';

function UserDetailsPerformanceTab({ user }: UserDetailsTabProps): JSX.Element {
  const [filterList, setFilterList] = useState<string[]>([]);
  const [trackedId, setTrackedId] = useState<string | null>(null);
  const [trackedMetric, setTrackedMetric] = useState<Rating | Tag | null>(null);
  const [trackedAverages, setTrackedAverages] = useState<AverageRating[]>([]);

  const type: Type = useMemo(() => (stringArrayIncludes(filterList, 'Tags') ? 'TAG' : 'RATING'), [filterList]);

  useEffect(() => {
    if (trackedId)
      if (type === 'RATING') getUserAveragesOvertimeForRating(user.userId, trackedId, setTrackedAverages);
      else getUserAveragesOvertimeForTag(user.userId, trackedId, setTrackedAverages);
  }, [trackedId, type, user]);

  const clearTrackedData = () => {
    setTrackedId(null);
    setTrackedMetric(null);
    setTrackedAverages([]);
  };

  const TabComponent = user.role === 'STUDENT' ? StudentPerformanceTab : InstructorPerformanceTab;
  const props = {
    setTrackedId,
    setTrackedMetric,
    type,
    user,
  };

  return (
    <>
      <div className="home-page" style={trackedId !== null || trackedMetric !== null ? { display: 'none' } : undefined}>
        <TabComponent {...props}>
          <FilterTab label="Compare:" setFilterList={setFilterList}>
            <FilterTab.Button id="btn-ratings" type="radio" name="avg-type" defaultChecked={true}>
              Ratings
            </FilterTab.Button>
            <FilterTab.Button id="btn-tags" type="radio" name="avg-type">
              Tags
            </FilterTab.Button>
          </FilterTab>
        </TabComponent>
      </div>

      {trackedId === null && trackedMetric === null ? null : trackedAverages.length > 0 ? (
        <>
          <Button id="return-btn" variant="alt rad low xs" onClick={clearTrackedData}>
            Back to Performance Comparison
          </Button>
          <TrackedXYChart
            height={108}
            data={trackedAverages.map((avg) => ({ date: avg.date, score: avg.averageRating }))}
            rating={type === 'RATING' ? (trackedMetric as Rating) : undefined}
            tag={type === 'TAG' ? (trackedMetric as Tag) : undefined}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}

interface TabProps {
  children: React.ReactNode;
  setTrackedId: (arg0: string | null) => void;
  setTrackedMetric: (arg0: Rating | Tag | null) => void;
  type: Type;
  user: User;
}

function InstructorPerformanceTab({ children, setTrackedId, setTrackedMetric, type, user }: TabProps): JSX.Element {
  const columns = useMemo<QueryCol<AverageRating>[]>(() => {
    const cols: QueryCol<AverageRating>[] = [];

    switch (type) {
      case 'RATING':
        cols.push({
          Header: 'Rating',
          accessor: 'rating',
          Cell: (avg: AverageRating) => avg.rating?.name,
          className: 'name',
          customSort: 'name',
        });
        break;
      case 'TAG':
        cols.push({
          Header: 'Tag',
          accessor: 'tag',
          Cell: (avg: AverageRating) => avg.tag?.content,
          className: 'name',
          customSort: 'content',
        });
    }

    cols.push(
      {
        Header: 'Average Mastery',
        accessor: 'averageRating',
        Cell: (avg: AverageRating) => `${Math.round(avg.averageRating)}%`,
        Style: (avg: AverageRating) => {
          let barColor = '#2e74a3';
          if (avg.averageRating >= 80) barColor = '#298547';
          else if (avg.averageRating < 60) barColor = '#A32E2E';
          return {
            backgroundImage: `linear-gradient(to right, ${barColor} 2.5rem, rgba(255, 255, 255, 0) 2.5rem), linear-gradient(to right, ${barColor} ${avg.averageRating}%, rgba(255, 255, 255, 0) ${avg.averageRating}%)`,
          };
        },
        className: 'score',
        notSortable: true,
      },
      {
        Header: 'Track',
        accessor: 'averageRatingId',
        Cell: function render(avg: AverageRating) {
          if (avg.averageRating === 0) return '';
          return (
            <Button
              classOverride
              onClick={() => {
                setTrackedId((type === 'RATING' ? avg?.rating?.ratingId : avg?.tag?.tagId) ?? null);
                setTrackedMetric(type === 'RATING' ? avg?.rating : avg?.tag);
              }}
            >
              Track <Icon code="chevron_right" />
            </Button>
          );
        },
        className: 'track',
        notSortable: true,
      },
    );

    return cols;
  }, [setTrackedId, setTrackedMetric, type]);

  const queryRequest = useMemo(() => {
    let requestCb = getInstructorRatingAverages;
    if (type === 'TAG') requestCb = getInstructorTagAverages;
    return (params: PageQueryParams, successCb: (arg0: PageQuery<AverageRating>) => void) =>
      requestCb(user.userId, params, successCb);
  }, [type, user]);

  return (
    <QueryTable
      title="Instructor Performance"
      ctrlsInsert={<div className="col-2">{children}</div>}
      columns={columns}
      queryRequest={queryRequest}
      classNames={{
        ctrlsClassName: 'ctrls-row',
        ctrlsWrapperClassName: 'col-2',
        tableClassName: 'home-body bar-chart',
      }}
    />
  );
}

function StudentPerformanceTab({ children, setTrackedId, setTrackedMetric, type, user }: TabProps): JSX.Element {
  const [ratingAverages, setRatingAverages] = useState<AverageRating[]>([]);
  const [tagAverages, setTagAverages] = useState<AverageRating[]>([]);

  const averages = useMemo(
    () => (type === 'RATING' ? ratingAverages : tagAverages),
    [type, ratingAverages, tagAverages],
  );

  useEffect(() => {
    getUserRatingAverages(user.userId, setRatingAverages);
    getUserTagAverages(user.userId, setTagAverages);
  }, [user]);

  return (
    <>
      {children}
      <table className="bar-chart">
        <thead>
          <tr>
            <th className="name">{type}</th>
            <th className="score">Average Mastery</th>
            <th className="track">Track</th>
          </tr>
        </thead>
        <tbody>
          {averages.map((avg, i) => {
            let barColor = '#2e74a3';
            if (avg.averageRating >= 80) barColor = '#298547';
            else if (avg.averageRating < 60) barColor = '#A32E2E';
            return (
              <tr key={avg.averageRatingId ?? `avg-${i}`}>
                <td className="name">{type === 'RATING' ? avg?.rating?.name : avg?.tag?.content}</td>
                <td
                  className="score"
                  style={{
                    backgroundImage: `linear-gradient(to right, ${barColor} 2.5rem, rgba(255, 255, 255, 0) 2.5rem), linear-gradient(to right, ${barColor} ${avg.averageRating}%, rgba(255, 255, 255, 0) ${avg.averageRating}%)`,
                  }}
                >
                  {Math.round(avg.averageRating)}%
                </td>
                <td className="track">
                  <Button
                    classOverride
                    onClick={() => {
                      setTrackedId((type === 'RATING' ? avg?.rating?.ratingId : avg?.tag?.tagId) ?? null);
                      setTrackedMetric(type === 'RATING' ? avg?.rating : avg?.tag);
                    }}
                  >
                    Track <Icon code="chevron_right" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default UserDetailsPerformanceTab;
