import React from 'react';
import { appendClass } from '../../../../utils/functions';

interface Props {
  className?: string;
  id?: string;
  percent: number;
}

function ProgressBar({ className, id, percent }: Props): JSX.Element {
  const progressBarClass = 'progress-bar-wrapper';
  let classes = progressBarClass;
  classes = className ? appendClass(classes, className) : classes;

  const complete = percent > 99;
  const completeClass = complete ? ' complete' : '';

  return (
    <div className={classes} id={id}>
      <span>{Math.round(percent)}% Complete</span>
      <div className={'progress-bar' + completeClass}>
        <div className="bar-back" />
        <div className="bar-front" style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
}

export default ProgressBar;
