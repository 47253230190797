import { PayloadAction } from '@reduxjs/toolkit';
import { SET_SAVE_TIMESTAMP } from '../utils/constants';

const saveTimestampReducer = (state = '', action: PayloadAction<string>): string => {
  switch (action.type) {
    case SET_SAVE_TIMESTAMP:
      return action.payload;
    default:
      return state;
  }
};

export default saveTimestampReducer;
