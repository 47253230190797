import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorObj } from '../utils/requests';
import { LOG_ERROR } from '../utils/constants';

const errorsReducer = (state = [] as ErrorObj[], action: PayloadAction<ErrorObj[]>): ErrorObj[] => {
  switch (action.type) {
    case LOG_ERROR:
      return [...state, ...action.payload];
    default:
      return state;
  }
};

export default errorsReducer;
