import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { newUpdateKey } from '../../actions';
import { Comment, EvaluationTarget, Rating, RubricTarget } from '../../types/types';
import {
  createRubricTemplateComment,
  createRubricTemplateRating,
  createUserCommentPrompt,
  createUserRatingPrompt,
  deleteComment,
  deleteRating,
  getRatingResourceFile,
  postCommentNew,
  postEvalRubricCommentNew,
  postEvalRubricRatingNew,
  postRatingNew,
  postRatingResourceFile,
  postReflectionCommentNew,
  updateComment,
  updateRating,
} from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import CommentPromptEditor from './CommentPromptEditor';
import RatingPromptEditor from './RatingPromptEditor';
import { storageAvailable } from '../../utils/functions';

function PromptEditorController(): JSX.Element {
  const { rubricTemplateId, actionType, promptType, id, assignmentId, target } = useParams() as {
    rubricTemplateId?: string;
    actionType: 'new' | 'edit';
    promptType: 'comment' | 'rating';
    id?: string;
    assignmentId?: string;
    target?: RubricTarget;
  };

  const [initComment, setInitComment] = useState<Comment | undefined>(undefined);
  const [initRating, setInitRating] = useState<Rating | undefined>(undefined);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(newUpdateKey());
    },
    [dispatch],
  );

  useEffect(() => {
    if (id) {
      const storedValue = storageAvailable('sessionStorage')
        ? window.sessionStorage.getItem(`${promptType}-${id}`)
        : null;
      if (typeof storedValue === 'string') {
        const prompt = JSON.parse(storedValue) as Comment | Rating;
        switch (promptType) {
          case 'comment':
            setInitComment(prompt as Comment);
            break;
          case 'rating':
            setInitRating(prompt as Rating);
            break;
        }
      }
    }
  }, [id, promptType]);

  const returnToRubricEditor = useCallback(() => navigate(-1), [navigate]);

  const requestUpdateComment = useCallback(
    (comment: Comment, successCb: (arg0: Comment) => void, errorCb: () => void) =>
      updateComment(comment.commentId, comment, successCb, errorCb),
    [],
  );

  const requestUpdateRating = useCallback(
    (rating: Rating, successCb: (arg0: Rating) => void, errorCb: () => void) =>
      updateRating(rating.ratingId, rating, successCb, errorCb),
    [],
  );

  const requestCreateComment = useCallback(
    (comment: Comment, successCb: (arg0: Comment) => void, errorCb: () => void) =>
      assignmentId
        ? target === 'SUBMISSION'
          ? postCommentNew(assignmentId, -1, comment, successCb, errorCb)
          : target === 'REFLECT'
          ? postReflectionCommentNew(assignmentId, -1, comment, successCb)
          : postEvalRubricCommentNew(assignmentId, target as EvaluationTarget, -1, comment, successCb, errorCb)
        : rubricTemplateId
        ? createRubricTemplateComment(rubricTemplateId, comment, successCb, errorCb)
        : createUserCommentPrompt(comment, successCb, errorCb),
    [assignmentId, rubricTemplateId, target],
  );

  const requestCreateRating = useCallback(
    (rating: Rating, successCb: (arg0: Rating) => void, errorCb: () => void) =>
      assignmentId
        ? target === 'SUBMISSION'
          ? postRatingNew(assignmentId, -1, rating, successCb, errorCb)
          : postEvalRubricRatingNew(assignmentId, target as EvaluationTarget, -1, rating, successCb, errorCb)
        : rubricTemplateId
        ? createRubricTemplateRating(rubricTemplateId, rating, successCb, errorCb)
        : createUserRatingPrompt(rating, successCb, errorCb),
    [assignmentId, rubricTemplateId, target],
  );

  const requestDeleteCommentPrompt = useCallback((commentId: string, successCb: () => void) => {
    deleteComment(commentId, successCb);
  }, []);

  const requestDeleteRatingPrompt = useCallback((ratingId: string, successCb: () => void) => {
    deleteRating(ratingId, successCb);
  }, []);

  const requestPostRatingResourceFile = useCallback((ratingId: string, formData: FormData) => {
    postRatingResourceFile(ratingId, formData, () => undefined);
  }, []);

  const requestGetRatingResourceFile = useCallback((ratingId: string, successCb: (resource: File) => void) => {
    getRatingResourceFile(ratingId, successCb);
  }, []);

  if (id === undefined || initComment || initRating) {
    switch (promptType) {
      case 'comment':
        return (
          <CommentPromptEditor
            initPrompt={initComment}
            type={actionType}
            requestCreateComment={requestCreateComment}
            requestUpdateComment={requestUpdateComment}
            requestDeleteCommentPrompt={requestDeleteCommentPrompt}
            returnToRubricEditor={returnToRubricEditor}
            fromLibrary={rubricTemplateId !== undefined}
          />
        );
      case 'rating':
        return (
          <RatingPromptEditor
            initPrompt={initRating}
            type={actionType}
            target={target}
            requestCreateRating={requestCreateRating}
            requestUpdateRating={requestUpdateRating}
            requestPostRatingResourceFile={requestPostRatingResourceFile}
            requestGetRatingResourceFile={requestGetRatingResourceFile}
            requestDeleteRatingPrompt={requestDeleteRatingPrompt}
            returnToRubricEditor={returnToRubricEditor}
            fromLibrary={rubricTemplateId !== undefined}
          />
        );
    }
  }
  return <LoadingSpinner />;
}

export default PromptEditorController;
