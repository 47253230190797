import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getRubricsMap } from '../../utils/requests';
import CoreForm from '../core/input/Form/Form';
import { RootState } from '../../store';
import { AssignmentRubric, Comment, Rubric } from '../../types/types';
import FormCommentPage from './FormCommentPage';

interface Props {
  assignmentId: string;
  onLoad?: () => void;
  onSubmit: () => void;
  saveReflection: () => void;
}

function Form({ assignmentId, onLoad, onSubmit, saveReflection }: Props): JSX.Element {
  // State:
  const [reflectionFormPages, setFormPages] = useState<JSX.Element[]>([]);
  const [reflectionRubric, setReflectionRubric] = useState<AssignmentRubric | null | undefined>(undefined);

  // Redux:
  const saveTimestamp = useSelector((state: RootState) => state.saveTimestamp);

  useEffect(() => {
    getRubricsMap(assignmentId, (rubricsTargetMap) => {
      setReflectionRubric(rubricsTargetMap.REFLECT ?? null);
    });
  }, [assignmentId]);

  const sortReflectionRubric = useCallback(() => {
    reflectionRubric?.items.sort((a, b) => a.order - b.order);
  }, [reflectionRubric]);

  useEffect(() => {
    sortReflectionRubric();
  }, [reflectionRubric, sortReflectionRubric]);

  /**
   * Dynamically builds the pages of the form based on the rubric.
   * Creates a list of the pages and saves the list in state.
   */
  const buildPagesFromRubric = useCallback(
    (reflectionRubric: Rubric) => {
      const pages = [] as JSX.Element[];
      reflectionRubric.forEach((prompt) => {
        if (prompt.hasOwnProperty('commentId')) {
          const comment = prompt as Comment;
          pages.push(
            <FormCommentPage
              commentId={comment.commentId}
              commentName={comment.commentName}
              commentPrompt={comment.commentPrompt}
              minimumComments={comment.minimumComments}
              maximumComments={comment.maximumComments}
              saveReflection={saveReflection}
            />,
          );
        }
      });
      // Set the newly created pages as state
      setFormPages(pages);
    },
    [saveReflection],
  );

  /**
   * Build the form pages once a valid rubric state is stored
   */
  useEffect(() => {
    if (reflectionRubric) buildPagesFromRubric(reflectionRubric.items);
  }, [reflectionRubric, buildPagesFromRubric]);

  return (
    <CoreForm
      id="reflection-form"
      pages={reflectionFormPages}
      save={saveReflection}
      saveTimestamp={saveTimestamp}
      onLoad={onLoad}
      onSubmit={onSubmit}
      submitButtonText="Finish"
    ></CoreForm>
  );
}

export default Form;
