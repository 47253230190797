import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewByItem } from '../../../types/types';
import { getResetReviewsForUserInAssignment, getReviewItemsForSubmission } from '../../../utils/requests';
import JumpButton from '../../core/button/JumpButton';
import DeleteReviewButton from './DeleteReviewButton';
import RatingDetailsTable from './RatingDetailsTable';
import { getCompletionStatus, getJumpButtons, StudentDetailsTabProps } from './StudentDetailsPage';
import ReviewDialogue from '../../core/display/ReviewDialogue/ReviewDialogue';
import Accordion from '../../core/layout/Accordion/Accordion';
import AlertBar from '../../core/display/AlertBar';

function ReviewsReceivedDetails({ assignmentProgress, updateData, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId, userId } = useParams() as { assignmentId: string; userId: string };

  const [reviewsReceived, setReviewReceived] = useState<ReviewByItem[] | null>(null);
  const [resetReviews, setResetReviews] = useState<ReviewByItem[] | null>(null);

  useEffect(() => {
    if (assignmentProgress.submissionId !== null)
      getReviewItemsForSubmission(assignmentId, assignmentProgress.submissionId, setReviewReceived);
    if (assignmentProgress.assignment.asyncEnabled)
      getResetReviewsForUserInAssignment(userId, assignmentId, setResetReviews);
  }, [updateKey, assignmentProgress, assignmentId, userId]);

  return (
    <>
      {reviewsReceived && reviewsReceived.length > 0 ? (
        <>
          <div id="reviewers-table" className="panel-sm panel-white">
            <h2 className="title" tabIndex={-1}>
              Reviewers
            </h2>
            <table>
              <tbody>
                {reviewsReceived.map((review) => (
                  <tr key={`row-${review.reviewId}`}>
                    <th>
                      <b>{review.user.name}</b>
                    </th>
                    <td>{getCompletionStatus(review.complete)}</td>
                    <td>{getJumpButtons(`review-received-${review.reviewId}`, 'Jump to Review')}</td>
                    <td>
                      <DeleteReviewButton reviewId={review.reviewId} updateData={updateData} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {reviewsReceived.map((reviewByItem) => (
            <ReviewReceived key={reviewByItem.reviewId} reviewByItem={reviewByItem} />
          ))}
        </>
      ) : (
        <div className="panel-sm panel-white no-progress-message">
          <h2 className="title">No Reviews Received Yet</h2>
          <div>
            <p>
              <b>{assignmentProgress.user.name}</b> has not yet received peer reviews for this assignment.
            </p>
            <p>More details will be available once they have received peer reviews.</p>
          </div>
        </div>
      )}

      {resetReviews && resetReviews.length > 0 ? (
        <Accordion className="reset-reviews-accordion" name="Reset Reviews">
          <AlertBar backgroundColor="#c1daf7" id="template-disclaimer">
            These are reviews which were nullified by the student resubmitting and restarting the assignment.
          </AlertBar>
          {resetReviews.map((reviewByItem) => (
            <ReviewReceived key={reviewByItem.reviewId} reviewByItem={reviewByItem} />
          ))}
        </Accordion>
      ) : null}
    </>
  );
}

function ReviewReceived({ reviewByItem }: { reviewByItem: ReviewByItem }): JSX.Element {
  return (
    <div
      key={`review-received-${reviewByItem.reviewId}`}
      id={`review-received-${reviewByItem.reviewId}`}
      className="panel-sm panel-white review-wrapper"
    >
      <JumpButton type="focus-child" targetId="reviewers-table" invisible>
        Back to reviewers table
      </JumpButton>
      <h2 className="title">Reviewer: {reviewByItem.user.name}</h2>
      <div className="status-wrapper">
        <span className="target">Peer Review</span> {getCompletionStatus(reviewByItem.complete)}
      </div>

      {reviewByItem.comments.length > 0 ? (
        <>
          <h3>Review Comments</h3>
          {reviewByItem.comments.map((commentItem) => (
            <section key={`dialogue-${commentItem.comment.commentId}`} className="dialogue-container">
              <h4>
                on &quot;<i>{commentItem.comment.commentName}</i>&quot;
              </h4>
              <ReviewDialogue commentWithReviewComments={commentItem} teacherView submitterView />
            </section>
          ))}
        </>
      ) : null}

      {reviewByItem.ratings.length > 0 ? (
        <RatingDetailsTable heading="Review Ratings" reviewableByItem={reviewByItem} />
      ) : null}
    </div>
  );
}

export default ReviewsReceivedDetails;
