import React from 'react';
import { Comment, Rating } from '../../types/types';
import CommentPrompt from './CommentPrompt';
import RatingPrompt from './RatingPrompt';

interface Props {
  prompts: (Comment | Rating)[];
}

function ReadOnlyRubric({ prompts }: Props): JSX.Element {
  return (
    <div className="read-only-rubric">
      <p id="no-edit-label">This rubric is no longer editable due to the assignment&apos;s progression</p>
      {prompts.map((prompt) => {
        const type = prompt.hasOwnProperty('commentId') ? 'COMMENT' : 'RATING';
        switch (type) {
          case 'COMMENT':
            const commentPrompt = prompt as Comment;
            return <CommentPrompt key={commentPrompt.commentId} comment={commentPrompt} />;
          case 'RATING':
            const ratingPrompt = prompt as Rating;
            return <RatingPrompt key={ratingPrompt.ratingId} rating={ratingPrompt} />;
        }
      })}
    </div>
  );
}

export default ReadOnlyRubric;
