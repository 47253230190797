import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Credentials, LoginResponse } from '../../types/types';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import Button from '../core/button/Button/Button';
import { formDataToObject, setPageTitle, storageAvailable } from '../../utils/functions';
import { login } from '../../utils/requests';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../actions';
import { LOGIN_RETURN_PATH_KEY } from '../../utils/constants';
import { RootState } from '../../store';

function LoginPage(): JSX.Element {
  useEffect(() => setPageTitle('Login'), []);

  const [validLogin, setValidLogin] = useState(true);
  const redirectPending = useRef(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user.userId !== '') {
      if (user.forcePasswordChange) navigate(`/reset_password`);
      else if (redirectPending.current === false) {
        navigate('/course/dashboard');
      }
    }
  }, [navigate, user]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement);
      const credentials = formDataToObject(formData) as Credentials;
      login(
        credentials,
        (loginResponse: LoginResponse) => {
          if(loginResponse.user){
            setValidLogin(true);
            dispatch(setUser(loginResponse.user));
            const returnPath = storageAvailable('sessionStorage')
              ? window.sessionStorage.getItem(LOGIN_RETURN_PATH_KEY)
              : '/';
            if (loginResponse.user.forcePasswordChange) {
              navigate(`/reset_password`);
            } else if (loginResponse.user.requireMfaEnrollment) {              
              navigate(`/mfa/enroll`);
            } else if (returnPath && returnPath !== '/login') {
              if (storageAvailable('sessionStorage')) window.sessionStorage.clear();
              navigate(returnPath);
            }
            else {
              navigate('/course/dashboard');
            }
            redirectPending.current = true;
          }
          else if(loginResponse.mfaToken){
            navigate(`/mfa/verify?mfaToken=${loginResponse.mfaToken}&emailMfa=${loginResponse.emailMfa}`);
            redirectPending.current = true;
          }
          
        },
        (error) => {
          switch (error.response?.status) {
            case 403:
              const queryParams = new URLSearchParams({
                username: credentials.username,
              });
              navigate(`/verify-email?${queryParams.toString()}`);
              return true;
            case 401:
              setValidLogin(false);
              return true;
            default:
              return false;
          }
        },
      );
    },
    [dispatch, navigate],
  );

  if (user.userId !== '') return <></>;
  return (
    <div className="page login-row">
      <img src={logoSrc} width="256" alt="Peerceptiv logo" />
      <div className="panel-white login-container">
        {validLogin ? null : (
          <p className="invalid-log" role="alert">
            Invalid login. Please try again.
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <label className="sr-only" htmlFor="username">
            Email:
          </label>
          <input type="email" id="username" name="username" placeholder="Email" required={true} autoComplete="username"/>
          <label className="sr-only" htmlFor="current-password">
            Password:
          </label>
          <input
            type="password"
            id="current-password"
            name="password"
            placeholder="Password"
            autoComplete="current-password"
            required={true}
          />
          <Button>
            <h1>Log in</h1>
          </Button>
          <Link id="forgot-pwd" to="forgot_password">
            Forgot Password?
          </Link>
        </form>
        <Button
          variant="secondary"
          onClick={() => {
            navigate('/register');
          }}
        >
          Register
        </Button>
      </div>
    </div>
  );
}

export default LoginPage;
