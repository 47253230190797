import React, { useRef, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  id?: string;
}

function MiniNav({ children, id }: Props): JSX.Element {
  const navEl = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (navEl.current) {
      let found = false;
      for (let i = 0; i < navEl.current.children.length; i++) {
        const child = navEl.current.children[i] as HTMLLinkElement;
        if (!found && child.href.includes(location.pathname)) {
          child.classList.add('selected');
          found = true;
        } else child.classList.remove('selected');
      }
    }
  }, [location]);

  return (
    <nav ref={navEl} id={id} className="mininav">
      {children}
    </nav>
  );
}

interface LinkProps {
  children: React.ReactNode;
  to: string;
}

function Link({ children, to }: LinkProps): JSX.Element {
  const className = window.location.pathname === to ? 'selected' : undefined;

  return (
    <RouterLink className={className} to={to}>
      {children}
    </RouterLink>
  );
}

MiniNav.Link = Link;

export default MiniNav;
