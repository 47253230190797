import React from 'react';

interface Props {
  position?: 'static' | 'fixed' | 'absolute';
  size?: number;
}

function LoadingSpinner({ position = 'fixed', size = 300 }: Props): JSX.Element {
  return (
    <div className="loading-spinner-wrapper" style={{ position }}>
      <svg
        className="loading-spinner"
        width={size}
        height={size}
        version="1.1"
        viewBox="0 0 26.458332 26.458335"
        preserveAspectRatio="none"
      >
        <g transform="translate(-27.1 -115.23)">
          <g transform="translate(2.6477 -2.6477)">
            <path
              transform="translate(15.261 125.64)"
              d="m28.237 4.8856c-1.7222-0.82912-2.6387-2.3317-2.4027-4.1613l-3.413-2.0052e-4 -3.4131-2.0155e-4c0.14307 1.9061-0.70024 3.4512-2.4027 4.1616l1.7064 2.9561 1.7067 2.9557c1.5791-1.0769 3.3385-1.1197 4.805-5.23e-4l1.7068-2.9552z"
              fill="#0069a6"
            />
            <circle
              className="outer-circ"
              id="outer-circ-1"
              transform="rotate(120)"
              cx="89.34"
              cy="-95.092"
              r="3.7042"
              fill="#0069a6"
              fillRule="evenodd"
            />
            <circle
              className="outer-circ"
              id="outer-circ-2"
              transform="rotate(120)"
              cx="94.698"
              cy="-104.37"
              r="3.7042"
              fill="#0069a6"
              fillRule="evenodd"
            />
            <circle
              className="outer-circ"
              id="outer-circ-3"
              transform="rotate(120)"
              cx="100.06"
              cy="-95.092"
              r="3.7042"
              fill="#0069a6"
              fillRule="evenodd"
            />
            <circle
              className="inner-circ"
              id="inner-circ-1"
              transform="rotate(120)"
              cx="89.341"
              cy="-95.092"
              r="1.5875"
              fill="#fff"
              fillRule="evenodd"
            />
            <circle
              className="inner-circ"
              id="inner-circ-2"
              transform="rotate(120)"
              cx="94.698"
              cy="-104.37"
              r="1.5875"
              fill="#fff"
              fillRule="evenodd"
            />
            <circle
              className="inner-circ"
              id="inner-circ-3"
              transform="rotate(120)"
              cx="100.06"
              cy="-95.092"
              r="1.5875"
              fill="#fff"
              fillRule="evenodd"
            />
            <circle transform="rotate(120)" cx="94.699" cy="-98.186" r="1.5875" fill="#fff" fillRule="evenodd" />
            <path
              d="m43.278 133.79-5.1206-2.9564 2e-3 -5.9122-0.95416-1.1e-4 7.66e-4 5.9126-5.1209 2.9557 0.4774 0.82575 5.1191-2.9578 5.1207 2.9564z"
              color="#000000"
              colorRendering="auto"
              dominantBaseline="auto"
              fill="#fff"
              imageRendering="auto"
              shapeRendering="auto"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default LoadingSpinner;
