import React, { useState, useRef, useCallback } from 'react';
import Score from './Score';
import { RatingScore, Result, UserRatingScore } from '../../types/types';

interface Props {
  children?: React.ReactNode;
  ratingScores: (RatingScore | UserRatingScore)[];
  result?: Result;
  scoresWrapperKey?: string;
  size: 'long' | 'wide';
}

function RatingScoresCard({ children, ratingScores, result, scoresWrapperKey, size }: Props): JSX.Element {
  ratingScores.sort((a, b) => {
    if ('user' in a && 'user' in b) {
      return a.userScore - b.userScore;
    } else if ('user' in a) {
      return 1;
    } else if ('user' in b) {
      return -1;
    } else {
      return a.averageScore - b.averageScore;
    }
  });

  const [selectedScoreId, setSelectedScoreId] = useState('');

  const scoresWrapperEl = useRef<HTMLDivElement>(null);

  const handleScoreSelect = useCallback((id: string) => {
    setSelectedScoreId((prevId) => {
      if (prevId !== '') return id;
      else return '';
    });
  }, []);

  const getCardSize = (): string => {
    switch (size) {
      case 'long':
        return 'results-card-long';
      case 'wide':
        return 'results-card-wide';
      default:
        return 'results-card-long';
    }
  };

  const dataExists = ratingScores.length > 0;
  const emptyClass = !dataExists ? ' empty' : '';

  return (
    <div className={`panel-sm panel-white rubric-scores-card ${getCardSize()}${emptyClass}`}>
      <h2 className="title">Average Rating Scores</h2>
      {children}
      {dataExists && !(result && result.submissionInfo == null) ? (
        <div key={scoresWrapperKey} ref={scoresWrapperEl} className="scores-wrapper">
          {ratingScores.map((ratingScore) => {
            const id = ratingScore.id;
            const { ratingLevels, maxScore } = ratingScore.rating || {};
            return (
              <Score
                key={`score-${id}`}
                id={id}
                className={selectedScoreId !== '' && id !== selectedScoreId ? 'hidden' : undefined}
                name={ratingScore.name}
                classAvg={ratingScore.averageScore}
                studentScore={(ratingScore as UserRatingScore).userScore}
                ratingLevels={ratingLevels}
                outOf={maxScore ?? 100}
                onSelect={handleScoreSelect}
              />
            );
          })}
          <div
            className="back-to-top"
            onClick={() => {
              if (scoresWrapperEl.current) scoresWrapperEl.current.scrollTop = 0;
            }}
          >
            <p>Back to top</p>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>
          <b>Scores Unavailable</b> <br /> User did not submit
        </p>
      )}
    </div>
  );
}

export default RatingScoresCard;
