import { PayloadAction } from '@reduxjs/toolkit';
import { Course } from '../types/types';
import { CLEAR_COURSE, SET_COURSE } from '../utils/constants';

const courseReducer = (state = null as Course | null, action: PayloadAction<Course>): Course | null => {
  switch (action.type) {
    case SET_COURSE:
      return action.payload;
    case CLEAR_COURSE:
      return action.payload;
    default:
      return state;
  }
};

export default courseReducer;
