import React, { useEffect } from 'react';
import { Assignment, AssignmentProgress, PhaseCode, ProgressStats } from '../../../types/types';
import PhaseMenu, { PhaseMenuDetails } from './PhaseMenu';

interface Props {
  assignment: Assignment;
  userProgress: AssignmentProgress;
  setPhaseDetails: (phase: PhaseCode, details: PhaseMenuDetails) => void;
}

function EvaluateMenu({ assignment, userProgress, setPhaseDetails }: Props): JSX.Element {
  const rootPath = `/course/${assignment.courseId}/assignment/${assignment.assignmentId}`;
  const { progressStats, asyncEnabled } = assignment;
  const { status, countEvaluationsComplete, evaluationCount } = userProgress;
  const { reviewPhase, submissionPhase } = progressStats as ProgressStats;
  const dueDate = assignment.asyncEndDeadline ?? assignment.peerEvaluationDeadline ?? '';

  useEffect(
    () =>
      setPhaseDetails('submit', {
        complete: status.hasSubmitted,
        active: asyncEnabled ? status.submissionPriority : submissionPhase,
        locked: false,
        deadline: dueDate,
      }),
    [asyncEnabled, dueDate, reviewPhase, setPhaseDetails, status, submissionPhase, userProgress],
  );

  return (
    <PhaseMenu.SubMenu
      description={
        <>
          <b>Evaluation Phase</b> - Evaluate your peers based on their performance.
        </>
      }
    >
      <PhaseMenu.Status
        phaseGoal={`Perform ${evaluationCount} evaluations to complete this phase.`}
        tasksCompleted={countEvaluationsComplete}
        tasksToComplete={evaluationCount}
        dueDate={dueDate}
        iconCode="supervisor_account"
      />
      <PhaseMenu.TaskList phase="submit" status={status}>
        <PhaseMenu.TaskEntry
          name={`Perform ${evaluationCount} Evaluations`}
          buttonText="Evaluate"
          disabled={!status.canEvaluate}
          href={status.canEvaluate ? `${rootPath}/evaluate` : undefined}
          incomplete={status.missedEvaluating}
          complete={countEvaluationsComplete === evaluationCount}
        />
      </PhaseMenu.TaskList>
    </PhaseMenu.SubMenu>
  );
}

export default EvaluateMenu;
