import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCourse } from '../../../store/selectors';
import { Purchase } from '../../../types/types';
import { getPurchase } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import CourseCard from '../../course/CourseCard';

function PaymentComplete(): JSX.Element {
  const { purchaseId, courseId } = useParams() as { purchaseId: string; courseId?: string };

  const [purchase, setPurchase] = useState<Purchase | null>(null);
  const course = useSelector(selectCourse);

  useEffect(() => getPurchase(purchaseId, setPurchase), [purchaseId]);

  if (purchase && (!courseId || (courseId && course !== null))) {
    return (
      <div id="payment-complete-page" className="page">
        <div className="panel purchase-menu">
          <h1>
            Payment Complete <Icon code="done" id="success-icon" />
          </h1>

          <p className="purchase-details">
            <b>{purchase.itemName}</b>
            {purchase.quantity > 1 ? (
              <>
                <br />
                (Quantity: {purchase.quantity})
              </>
            ) : null}
          </p>

          {courseId && course ? (
            <>
              <p>You can now access the following course:</p>
              <CourseCard {...course} name={course.courseName} readOnly />
              <Button id="redirect-btn" href={`/course/${courseId}/assignments`} refresh>
                Enter Course
              </Button>
            </>
          ) : (
            <>
              <p>Your purchased seats have been added to your account.</p>
              <Button id="redirect-btn" href="/" route>
                Home
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default PaymentComplete;
