import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminPurchasing from './AdminPurchasing';
import AdminPurchasingSidebar from './AdminPurchasingSidebar';
import AdminContract from './AdminContract';

function AdminPurchasingController(): JSX.Element {
  return (
    <div id="site-container">
      <AdminPurchasingSidebar />
      <main id="site-content">
        <Routes>
          <Route index element={<Navigate to="/admin/purchasing/management" />} />
          <Route path="/management" element={<AdminContract />} />
          <Route path="/log" element={<AdminPurchasing />} />
        </Routes>
      </main>
    </div>
  );
}

export default AdminPurchasingController;
