import React, { useCallback } from 'react';
import { Course } from '../../types/types';
import Icon from '../core/display/Icon';
import CourseCopyTable from './CourseCopyTable';
import { useNavigate } from 'react-router-dom';
import { copyCourseAsTemplate } from '../../utils/requests';
import AlertBar from '../core/display/AlertBar';

interface Props {
  templateMode?: boolean;
}

function CopyCourseList({ templateMode = false }: Props): JSX.Element {
  const navigate = useNavigate();

  const handleSelect = useCallback(
    (course: Course) => {
      if (templateMode) {
        copyCourseAsTemplate(String(course.courseId), (newCourseTemplate) =>
          navigate(`/course/${newCourseTemplate.courseId}/assignments`),
        );
      } else {
        navigate(
          `/course/dashboard/copy?${new URLSearchParams({
            courseId: String(course.courseId),
            courseName: String(course.courseName),
            discipline: String(course.discipline),
            university: String(course.university),
            timeZone: String(course.timeZone),
            label: String(course.label),
            studentPurchaseEnabled: String(course.studentPurchaseEnabled),
            asyncEnabled: String(course.asyncEnabled),
          }).toString()}`,
        );
      }
    },
    [navigate, templateMode],
  );

  return (
    <div className="panel-white assignment-copy-menu">
      <h1>Copy a Course</h1>
      <h2>Select an existing course to copy</h2>
      <button className="button-mini exit-btn" type="button" onClick={() => navigate(-1)} aria-label="Go back">
        <Icon code="close" ariaHidden />
      </button>

      {templateMode ? (
        <AlertBar backgroundColor="#ffefd2">
          Course Templates copied from existing courses will be accessible to all teachers at your institution, not just
          the author of the original course.
        </AlertBar>
      ) : null}

      <CourseCopyTable onRowSelect={handleSelect} />
    </div>
  );
}

export default CopyCourseList;
