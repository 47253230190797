import React from 'react';
import { useParams } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router';
import { Group } from '../../types/types';
import MiniNav from '../core/layout/MiniNav/MiniNav';
import TeacherGroupsList from './TeacherGroupsList';
import TeacherGroupsMemberAdd from './TeacherGroupsMemberAdd';
import TeacherGroupsStudentList from './TeacherGroupsStudentList';

interface Props {
  circles?: boolean;
  saveGroup: (group: Group, callback?: () => void) => void;
}

function TeacherGroupsPage({ circles, saveGroup }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const rootPathWithIds = `/course/${courseId}/assignment/${assignmentId}/${circles ? 'circles' : 'groups'}`;

  const groupType = circles ? 'Circle' : 'Group';
  const groupListType = circles ? 'circle-list' : 'group-list';
  return (
    <div id="teacher-groups-page" className="page">
      <Routes>
        {[`/${groupListType}`, `/student-list`].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <MiniNav>
                <MiniNav.Link to={`${rootPathWithIds}/${groupListType}`}>{groupType}s</MiniNav.Link>
                <MiniNav.Link to={`${rootPathWithIds}/student-list`}>Students</MiniNav.Link>
              </MiniNav>
            }
          />
        ))}
      </Routes>
      <section id="groups-content">
        <Routes>
          <Route index element={<Navigate to={`${rootPathWithIds}/${groupListType}`} />} />
          <Route path={`/${groupListType}`} element={<TeacherGroupsList saveGroup={saveGroup} circles={circles} />} />
          <Route
            path="/student-list"
            element={<TeacherGroupsStudentList assignmentId={assignmentId} circles={circles} />}
          />
          <Route
            path="/:groupId/add"
            element={<TeacherGroupsMemberAdd assignmentId={assignmentId} circles={circles} />}
          />
        </Routes>
      </section>
    </div>
  );
}

export default TeacherGroupsPage;
