import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationAction } from '../../../types/types';
import { requestPost, requestPut } from '../../../utils/functions';
import { getNotificationAction } from '../../../utils/requests';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';

interface Props {
  id?: string;
}

function NotificationActionHandler({ id }: Props): JSX.Element {
  const [action, setAction] = useState<NotificationAction | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) getNotificationAction(id, setAction);
  }, [id]);

  useEffect(() => {
    if (action)
      handleNotificationAction(action, navigate, () => {
        navigate(-1);
        return true;
      });
  }, [action, navigate]);

  return <LoadingSpinner />;
}

const handleNotificationAction = (
  action: NotificationAction,
  redirectCb: (url: string) => void,
  returnCb: () => boolean,
) => {
  const requestMethod = (() => {
    switch (action.method) {
      case 'POST':
        return requestPost;
      case 'PUT':
        return requestPut;
    }
  })();

  requestMethod(action.url, null, () => redirectCb(action.redirect), returnCb);
};

export default NotificationActionHandler;
