import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { formDataToObjectParsed } from '../../../utils/functions';
import { generateStudentPurchaseCode } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import Table from '../../core/display/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  purchaseCode: string;
};

function AdminStudentPurchaseCode(): JSX.Element {
  const [studentPurchaseCodes, setStudentPurchaseCode] = useState<string[]>([]);
  const [allTableData, setAllTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<Array<ColumnDef<TableData>>>([]);

  const { modalDispatch } = useModalContext();
  const openGenerateStudnetPurchaseCodeModal = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Student Purchase Code',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Create',
          form: true,
          onSubmit: (purchaseCodeData) => {
            const data = formDataToObjectParsed(purchaseCodeData) as {
              notes: string;
              seats: string;
            };
            const formData = new FormData();
            if (data.seats) formData.append('number', data.seats);
            if (data.notes) formData.append('notes', data.notes);
            generateStudentPurchaseCode(formData, setStudentPurchaseCode);
          },
          children: <CreateStudentPurchaseCodeModalContent />,
        }),
      ),
    [modalDispatch],
  );

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      {
        header: 'purchaseCode',
        accessorKey: 'purchaseCode',
        meta:{className: 'left-align'},
      },
    ];
    const parsePurchasesToTableData = (studentPurchaseCodes?: string[]): TableData[] => {
      const dataTable: TableData[] = [];
      studentPurchaseCodes?.forEach((code) => {
        const newRow: TableData = {
          purchaseCode: code,
        };
        dataTable.push(newRow);
      });
      return dataTable;
    };
    setAllTableData(parsePurchasesToTableData(studentPurchaseCodes));
    setTableColumns(columns);
  }, [studentPurchaseCodes]);

  return (
    <div>
      <div>
        <Button onClick={openGenerateStudnetPurchaseCodeModal} id="purchase-code">
          Generate Student Purchase Code
        </Button>
      </div>
      <div className="table-wrapper panel-sm">
        <Table columns={tableColumns} data={allTableData} sortBy="date" noWrapper title="Admin Student Purchase Codes"></Table>
      </div>
    </div>
  );
}

function CreateStudentPurchaseCodeModalContent(): JSX.Element {
  return (
    <div id="create-purchase-modal-content">
      <div className="input-wrapper">
        <label htmlFor="seats">Seats:</label>
        <input id="seats" name="seats" type="number" placeholder="#" min={1} required />
      </div>
      <div className="input-wrapper">
        <label className="sr-only" htmlFor="notes">
          Notes:
        </label>
        <textarea id="notes" name="notes" placeholder="Notes (Optional)" />
      </div>
    </div>
  );
}

export default AdminStudentPurchaseCode;
