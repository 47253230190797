import React, { useEffect, useState } from 'react';
import { KEY_ACCESSIBILITY_ANIM_OFF } from '../../../utils/constants';
import { setPageTitle, storageAvailable } from '../../../utils/functions';

// TODO: Write an API to store these settings on the backend

function AccessibilityOptions(): JSX.Element {
  useEffect(() => setPageTitle('Profile: Accessibility'), []);

  const [animationsOff, setAnimationsOff] = useState(
    storageAvailable('localStorage') && window.localStorage.getItem(KEY_ACCESSIBILITY_ANIM_OFF) === 'true',
  );

  useEffect(() => {
    if (storageAvailable('localStorage')) window.localStorage.setItem(KEY_ACCESSIBILITY_ANIM_OFF, `${animationsOff}`);
    applyAccessibilitySettings();
  }, [animationsOff]);

  return (
    <form id="accessibility-options-form">
      <div className="radio-wrapper">
        <input
          id="animOff"
          type="checkbox"
          value="allEmails"
          checked={animationsOff}
          onChange={(e) => setAnimationsOff(e.target.checked)}
        />
        <label htmlFor="animOff">Turn off website animations</label>
      </div>
    </form>
  );
}

export const isAnimationOff = () =>
  storageAvailable('localStorage') && window.localStorage.getItem(KEY_ACCESSIBILITY_ANIM_OFF) === 'true';

export const applyAccessibilitySettings = () => {
  const rootElem = document.getElementById('root');
  if (rootElem) {
    const noAnimClass = 'no-animations';
    if (isAnimationOff()) rootElem.classList.add(noAnimClass);
    else rootElem.classList.remove(noAnimClass);
  }
};

export default AccessibilityOptions;
