import React, { useState, useEffect, useRef } from 'react';
import { RatingLevel } from '../../types/types';
import Icon from '../core/display/Icon';
import ScoreBar from './ScoreBar';

interface Props {
  classAvg: number;
  className?: string;
  id: string;
  name: string;
  onSelect: (arg0: string) => void;
  outOf: number;
  ratingLevels?: RatingLevel[];
  studentScore?: number;
}

function Score({ classAvg, className, id, name, onSelect, outOf, ratingLevels, studentScore }: Props): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const [closestLevel, setClosestLevel] = useState(-1);

  const scoreEl = useRef<HTMLDivElement>(null);

  const getClassFromScore = () => {
    const percent = ((studentScore || classAvg) / outOf) * 100;
    if (percent < 60)
      // F range
      return 'bot';
    else if (percent < 80)
      // C-D range
      return 'mid';
    // A-B range
    else return 'top';
  };

  const getAverageComparator = () => {
    if (studentScore)
      if (studentScore > classAvg)
        return (
          <div className="class-comparator above" role="img" aria-label="Above Average">
            <Icon code="arrow_drop_up" ariaHidden />
            Avg.
          </div>
        );
      else if (studentScore < classAvg) {
        return (
          <div className="class-comparator below" role="img" aria-label="Below Average">
            <Icon code="arrow_drop_down" ariaHidden />
            Avg.
          </div>
        );
      } else {
        return (
          <div className="class-comparator equal" role="img" aria-label="Average">
            — Avg.
          </div>
        );
      }
  };

  // Animate the score to slide to the top of the container when selected
  const animateUp = () => {
    const element = scoreEl.current;
    if (element) {
      element.style.transform = ``;
      element.style.transition = ``;
      const y1 = element.getBoundingClientRect().y;
      requestAnimationFrame(() => {
        const y2 = element.getBoundingClientRect().y;
        element.style.transition = ``;
        element.style.transform = `translateY(${y1 - y2}px)`;
        requestAnimationFrame(() => {
          element.style.transition = `transform 0.4s`;
          element.style.transform = ``;
        });
      });
    }
  };

  useEffect(() => {
    if (studentScore && ratingLevels) {
      // Find the closest rating level to student score
      let currClosest = -1;
      let diff = 1000;
      ratingLevels.forEach((level) => {
        const currDiff = Math.abs(studentScore - level.score);
        if (currDiff <= diff) {
          diff = currDiff;
          currClosest = level.score;
        }
      });
      setClosestLevel(currClosest);
    }
  }, [studentScore, ratingLevels]);

  return (
    <div ref={scoreEl} className={`score ${getClassFromScore()}${className ? ' ' + className : ''}`} tabIndex={0}>
      <div className="name-label">
        <b>{name}</b>
      </div>
      <ScoreBar
        className={getClassFromScore()}
        studentPercent={studentScore ? (studentScore / outOf) * 100 : undefined}
        classPercent={(classAvg / outOf) * 100}
      />
      <div className="score-details-wrapper">
        <div className="score-label">
          <span className="user-score">{(studentScore || classAvg).toFixed(1)}</span>
          <span className="max-score">{outOf === 100 ? ' %' : ' / ' + outOf}</span>
        </div>
        {studentScore && ratingLevels ? (
          <>
            {getAverageComparator()}
            <button
              className="expand-btn"
              onClick={() => {
                onSelect(id);
                setExpanded((prevState) => {
                  if (!prevState) animateUp();
                  return !prevState;
                });
              }}
            >
              {expanded ? (
                <>
                  Less <Icon code="expand_less" ariaHidden />
                </>
              ) : (
                <>
                  More <Icon code="expand_more" ariaHidden />
                </>
              )}
            </button>
          </>
        ) : (
          ''
        )}
      </div>
      {expanded && studentScore && ratingLevels ? (
        <div className="score-dropdown">
          <div className="average-data">
            <span>
              <Icon code="account_circle" ariaHidden />
              Your Score: <b>{studentScore.toFixed(1)}</b>
            </span>
            <span>
              <Icon code="school" ariaHidden />
              Class Average: <b>{classAvg.toFixed(1)}</b>
            </span>
          </div>
          <ul className="rating-levels-list" aria-label="Prompt Rating Levels">
            {ratingLevels.map((level) => {
              return (
                <li
                  key={`level-${level.ratingLevelId}`}
                  aria-current={closestLevel === level.score}
                  aria-label={`Rating Level ${level.score}: '${level.levelDescription}'`}
                >
                  <div className={`rating-level-wrapper${closestLevel === level.score ? ' highlighted' : ''}`}>
                    <div className="score-icon">{level.score}</div>
                    <div className="description-label">{level.levelDescription}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default Score;
