import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { POST_Report } from '../../types/types';
import { formDataToObjectParsed } from '../../utils/functions';
import { reportReview, reportSubmission } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import { AxiosError } from 'axios';

interface Props {
  buttonText?: string;
  className?: string;
  reviewId?: string;
  submissionId?: string;
}

function ReportButton({ buttonText, className, reviewId, submissionId }: Props): JSX.Element {
  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();

  const onReportSuccess = (redirectOn: boolean) =>
    modalDispatch(
      openModal({
        heading: 'Report Submitted',
        label: `Your instructor will review your report. ${
          redirectOn
            ? `You are not required to continue your ${
                submissionId ? 'review' : reviewId ? 'feedback' : ''
              } unless your instructor determines your report is invalid. Click 'Exit' to return to the Assignment Dashboard`
            : `You may now continue your ${submissionId ? 'review' : reviewId ? 'feedback' : ''}.`
        }.`,
        buttonText: redirectOn ? 'Exit' : 'Continue',
        cancelHide: true,
        onConfirm: redirectOn ? () => navigate(-1) : undefined,
        onCancel: redirectOn ? () => navigate(-1) : undefined,
        maxWidth: '600px',
        padding: '2rem',
      }),
    );

  const onReportFail = (error: AxiosError) => {
    let errorDescription = 'There was an error submitting your report. Please try again later.';
    if (error.response?.status === 400)
      errorDescription =
        'Your instructor has already reviewed this document and determined that it does not violate course policies.';
    modalDispatch(
      openModal({
        heading: 'Report Failed',
        label: errorDescription,
        buttonText: 'Continue',
        cancelHide: true,
        maxWidth: '480px',
      }),
    );
    return true;
  };

  return (
    <Button
      classOverride
      className={className ?? 'circ-btn'}
      tooltip={buttonText ? undefined : 'Report'}
      ariaLabel="Report"
      onClick={() =>
        modalDispatch(
          openModal({
            heading: `Report ${submissionId ? 'Submission' : reviewId ? 'Review' : ''}`,
            buttonText: 'Report',
            padding: '2rem',
            onSubmit: (formData) => {
              const data = formDataToObjectParsed(formData) as {
                reason: string;
                comment: string | 0;
                nonBlockingReport?: 'on';
              };
              const parsedReport: POST_Report = {
                reason: data.reason,
                comment: typeof data.comment === 'string' ? data.comment : '',
                nonBlockingReport: data.nonBlockingReport === 'on',
              };
              if (submissionId)
                reportSubmission(
                  submissionId,
                  parsedReport,
                  () => onReportSuccess(!parsedReport.nonBlockingReport),
                  onReportFail,
                );
              if (reviewId)
                reportReview(
                  reviewId,
                  parsedReport,
                  () => onReportSuccess(!parsedReport.nonBlockingReport),
                  onReportFail,
                );
            },
            children: <ReportingForm reviewId={reviewId} submissionId={submissionId} />,
          }),
        )
      }
    >
      {buttonText ?? <Icon code="flag" ariaHidden />}
    </Button>
  );
}

function ReportingForm({ reviewId, submissionId }: Props): JSX.Element {
  const reasonsList = ['Harassment', 'Vulgar', 'Content Failed to Load', 'Plagiarism'];

  const [reason, setReason] = useState('');

  return (
    <div id="report-form-content">
      <div>
        <label className="sr-only" htmlFor="report-reason">
          Reason for report
        </label>
        <select id="report-reason" name="reason" value={reason} onChange={(e) => setReason(e.target.value)} required>
          <option value="" disabled>
            Choose an Issue to Report
          </option>
          {reasonsList.map((reasonOption) => (
            <option key={reasonOption} value={reasonOption}>
              {reasonOption}
            </option>
          ))}
        </select>
      </div>
      {reason !== '' ? (
        <>
          <div>
            <label className="sr-only" htmlFor="report-comment">
              Report comment
            </label>
            <textarea
              id="report-comment"
              name="comment"
              placeholder="Optional: Add details that would help your instructor understand the reason you reported this content if necessary."
            />
          </div>
          <div>
            <input id="reason-continue" name="nonBlockingReport" type="checkbox" />
            <label htmlFor="reason-continue">
              I can finish my {submissionId ? 'review' : reviewId ? 'feedback' : ''} on this{' '}
              {submissionId ? 'submission' : reviewId ? 'review' : ''}
            </label>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ReportButton;
