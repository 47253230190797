import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { TEST_ENV } from '../../utils/constants';
import { storageAvailable } from '../../utils/functions';

interface Props {
  buttonMode?: boolean;
  buttonText?: string;
  checkToDismiss?: boolean;
  children: React.ReactNode;
  heading: string;
  id: string;
}

function TutorialModal({
  buttonMode = false,
  buttonText = 'Open Tutorial',
  checkToDismiss = false,
  children,
  heading,
  id,
}: Props): JSX.Element {
  const { modalDispatch } = useModalContext();

  const [modalTriggered, setModalTriggered] = useState(false);

  const tutorialSeenFlag = useMemo(() => `${id}-seen`, [id]);

  const setTutorialMemory = useCallback(
    (value = true) => {
      if (storageAvailable('localStorage')) window.localStorage.setItem(tutorialSeenFlag, String(value));
    },
    [tutorialSeenFlag],
  );

  const onCheckChange = useCallback(
    (e: React.ChangeEvent) => setTutorialMemory((e.target as HTMLInputElement).checked),
    [setTutorialMemory],
  );

  const triggerModal = useCallback(() => {
    const tutorialSeen = storageAvailable('localStorage') && window.localStorage.getItem(tutorialSeenFlag) === 'true';
    if ((!tutorialSeen || buttonMode) && !TEST_ENV)
      modalDispatch(
        openModal({
          heading,
          closeButton: true,
          noActionButtons: true,
          padding: '2rem',
          children: (
            <>
              {children}
              {checkToDismiss ? (
                <div className="tutorial-modal-checkbox-wrapper">
                  <input id="active" name="active" type="checkbox" onChange={onCheckChange} />
                  <label htmlFor="active">Don&apos;t show me this message again</label>
                </div>
              ) : null}
            </>
          ),
        }),
      );
    setModalTriggered(true);
  }, [tutorialSeenFlag, buttonMode, modalDispatch, heading, children, checkToDismiss, onCheckChange]);

  useEffect(() => {
    if (!buttonMode && !modalTriggered) {
      triggerModal();
      if (!checkToDismiss) setTutorialMemory();
    }
  }, [buttonMode, checkToDismiss, setTutorialMemory, triggerModal, modalTriggered]);

  if (buttonMode)
    return (
      <button type="button" onClick={triggerModal}>
        {buttonText}
      </button>
    );
  return <></>;
}

export default TutorialModal;
