import React, { createContext, useContext, useReducer } from 'react';
import {
  ACTIVATE_PINNING,
  DEACTIVATE_PINNING,
  SEEK_COMMENT,
  SET_COMMENT_ORDER,
  SET_PIN_TYPE,
  UNSEEK_COMMENT,
} from '../utils/constants';
import moment from 'moment';
import { Comment, SubmissionInfo } from '../types/types';

export type PdfPin = {
  pageNum: number;
  xPosition: number;
  yPosition: number;
};

export type VideoPin = {
  timestamp: number;
};

type PinAccessibility = {
  altText?: string | null;
};

export type PinData = PdfPin & VideoPin & PinAccessibility;

export type CommentOrderTable = { [commentId: string]: number };

export type PinType = 'PDF' | 'VIDEO';

export type PinDropContextState = {
  // null signifies that pinning is inactive
  commentId: string | null;
  commentNumber: number | null;
  commentOrder: CommentOrderTable;
  seekComment: boolean;
  type: PinType | null;
};

type ActivatePinningAction = {
  type: string;
  payload: { commentId: string; commentNumber: number };
};

type DeactivatePinningAction = {
  type: string;
  payload: null;
};

type SetCommentOrderAction = {
  type: string;
  payload: CommentOrderTable;
};

type SeekCommentAction = {
  type: string;
  payload: { commentId: string; commentNumber: number };
};

type UnseekCommentAction = {
  type: string;
  payload: null;
};

type SetPinTypeAction = {
  type: string;
  payload: PinType;
};

type Context = {
  pinDropContextState: PinDropContextState;
  pinDropDispatch: React.Dispatch<
    | ActivatePinningAction
    | DeactivatePinningAction
    | SetCommentOrderAction
    | SeekCommentAction
    | UnseekCommentAction
    | SetPinTypeAction
  >;
};

export const initPinDropState: PinDropContextState = {
  commentId: null,
  commentNumber: null,
  commentOrder: {},
  seekComment: false,
  type: null,
};

export const pinDropReducer = (
  state: PinDropContextState = initPinDropState,
  action:
    | ActivatePinningAction
    | DeactivatePinningAction
    | SetCommentOrderAction
    | SeekCommentAction
    | UnseekCommentAction
    | SetPinTypeAction,
): PinDropContextState => {
  switch (action.type) {
    case ACTIVATE_PINNING:
      return { ...state, ...(action as ActivatePinningAction).payload };
    case DEACTIVATE_PINNING:
      return { ...state, commentId: null, commentNumber: null };
    case SET_COMMENT_ORDER:
      return { ...state, commentOrder: (action as SetCommentOrderAction).payload };
    case SEEK_COMMENT:
      return { ...state, ...(action as SeekCommentAction).payload, seekComment: true };
    case UNSEEK_COMMENT:
      return { ...state, seekComment: false, commentId: null, commentNumber: null };
    case SET_PIN_TYPE:
      return { ...state, type: (action as SetPinTypeAction).payload };
    default:
      return state;
  }
};

export const PinDropContext = createContext<Context>({
  pinDropContextState: initPinDropState,
  pinDropDispatch: () => null,
});

export function PinDropProvider({
  children,
  comments,
  submissionInfo,
}: {
  children: React.ReactNode;
  comments?: Comment[];
  submissionInfo: SubmissionInfo;
}): JSX.Element {
  // These values must be final upon first render
  const type = (() => {
    let pinType: PinType | null = null;
    if (submissionInfo.submissionType === 'FILE') {
      if (submissionInfo.embeddable) pinType = 'PDF';
      if (submissionInfo.videoStream) pinType = 'VIDEO';
    }
    return pinType;
  })();
  const commentOrder = (() => {
    const table: CommentOrderTable = {};
    comments?.forEach((comment, i) => {
      table[comment.commentId] = i + 1;
    });
    return table;
  })();

  const [pinDropContextState, pinDropDispatch] = useReducer(pinDropReducer, {
    ...initPinDropState,
    type,
    commentOrder,
  });
  const pinDropData = { pinDropContextState, pinDropDispatch };

  return <PinDropContext.Provider value={pinDropData}>{children}</PinDropContext.Provider>;
}

export const usePinDropContext = (): Context => {
  return useContext(PinDropContext);
};

export const activatePinning = (commentId: string, commentNumber: number): ActivatePinningAction => ({
  type: ACTIVATE_PINNING,
  payload: { commentId, commentNumber },
});

export const deactivatePinning = (): DeactivatePinningAction => ({
  type: DEACTIVATE_PINNING,
  payload: null,
});

export const setCommentOrder = (commentOrderTable: CommentOrderTable): SetCommentOrderAction => ({
  type: SET_COMMENT_ORDER,
  payload: commentOrderTable,
});

export const seekComment = (commentId: string, commentNumber: number): SeekCommentAction => ({
  type: SEEK_COMMENT,
  payload: { commentId, commentNumber },
});

export const unseekComment = (): UnseekCommentAction => ({
  type: UNSEEK_COMMENT,
  payload: null,
});

export const setPinType = (type: PinType): SetPinTypeAction => ({
  type: SET_PIN_TYPE,
  payload: type,
});

export const renderPinDropLabel = (
  commentId: string,
  commentNumber: number,
  pinDrop: PinData | null | undefined,
  pinDropContextState: PinDropContextState,
) => {
  switch (pinDropContextState.type) {
    case 'PDF':
      return `${pinDropContextState.commentOrder[commentId]}.${commentNumber}`;
    case 'VIDEO':
      const secs = (pinDrop as VideoPin | null | undefined)?.timestamp ?? 0;
      let format = 'm:ss';
      if (secs >= 600) format = 'mm:ss';
      if (secs >= 3600) format = 'H:mm:ss';
      return moment.utc(secs * 1000).format(format);
    default:
      return 'error';
  }
};
