import React, { useEffect, useRef } from 'react';

interface Props {
  children: React.ReactNode;
  isReturn?: boolean;
  onReturn?: (e: React.MouseEvent) => void;
  unavailable?: boolean;
}

function Breadcrumbs({ children, isReturn, onReturn, unavailable = false }: Props): JSX.Element {
  const navEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (navEl.current) {
      const btnEls = navEl.current.querySelectorAll('button');
      let selectedBtnFound = false;
      [...btnEls].forEach((btnEl) => {
        btnEl.setAttribute('aria-disabled', selectedBtnFound || unavailable ? 'true' : 'false');
        if (btnEl.className.includes('selected')) selectedBtnFound = true;
      });
    }
  }, [children, unavailable]);

  return (
    <nav className="peer-breadcrumb" ref={navEl} aria-label="Breadcrumb">
      <ol>
        {isReturn ? (
          <Item key="item-return" onClick={onReturn} selected={true} isReturn>
            Return
          </Item>
        ) : (
          children
        )}
      </ol>
    </nav>
  );
}

interface ItemProps {
  children: React.ReactNode;
  isReturn?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  selected?: boolean;
}

function Item({ children, isReturn, onClick, selected }: ItemProps): JSX.Element {
  return (
    <li className={isReturn ? ' return' : undefined}>
      <button
        key={`item-${children}`}
        className={`crumb${selected ? ' selected' : ''}`}
        onClick={onClick}
        aria-current={selected && !isReturn ? 'page' : undefined}
        role="link"
      >
        {children}
      </button>
    </li>
  );
}

Breadcrumbs.Item = Item;

export default Breadcrumbs;
