import React, { useState, useRef, useCallback, useEffect } from 'react';
import { focusFirstElement } from '../../../../utils/functions';
import Button from '../../button/Button/Button';
import Icon from '../../display/Icon';

export interface WizardSection {
  title: string;
  pages: JSX.Element[];
}

interface Props {
  name: string;
  noSkipping?: boolean;
  onFinish?: () => void;
  onSubmit?: (e: React.FormEvent) => boolean;
  sections: WizardSection[];
}

function Wizard({
  name,
  noSkipping = false,
  onFinish = () => undefined,
  onSubmit = () => true,
  sections,
}: Props): JSX.Element {
  const [sectionIndex, setSectionIndex] = useState(0);
  const [innerPageIndex, setInnerPageIndex] = useState(0);

  const formEl = useRef<HTMLFormElement>(null);
  const timelineBarEl = useRef<HTMLDivElement>(null);

  const handleTimelineClick = (index: number) => {
    if (!noSkipping) {
      setInnerPageIndex(0);
      setSectionIndex(index);
    }
  };

  const handleInnerPageClick = (index: number) => {
    if (!noSkipping) setInnerPageIndex(index);
  };

  const resetFocus = () => {
    requestAnimationFrame(() => {
      const pageContent = document.querySelector('.wizard .content');
      if (pageContent) focusFirstElement(pageContent);
    });
  };

  const nextPage = () => {
    const newInnerPageIndex = innerPageIndex + 1;
    if (newInnerPageIndex < sections[sectionIndex].pages.length) {
      setInnerPageIndex(newInnerPageIndex);
    } else {
      const newSectionIndex = sectionIndex + 1;
      if (newSectionIndex < sections.length) {
        setInnerPageIndex(0);
        setSectionIndex(newSectionIndex);
      }
    }
  };

  const prevPage = () => {
    const newInnerPageIndex = innerPageIndex - 1;
    if (newInnerPageIndex >= 0) {
      setInnerPageIndex(newInnerPageIndex);
    } else {
      const newSectionIndex = sectionIndex - 1;
      if (newSectionIndex >= 0) {
        setInnerPageIndex(sections[newSectionIndex].pages.length - 1);
        setSectionIndex(newSectionIndex);
      }
    }
  };

  const setFormSubmitter = useCallback((submitterId: string) => {
    if (formEl.current) formEl.current.submitter = submitterId;
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (formEl.current) {
      const submitterId = formEl ? formEl.current.submitter : -1;

      if (!formEl.current.checkValidity()) return false;
      if (onSubmit(e) === false) return false;
      if (submitterId.indexOf('section') !== -1) {
        const sectionIndex = parseInt(submitterId.substring(submitterId.indexOf('-') + 1));
        handleTimelineClick(sectionIndex);
      } else {
        switch (submitterId) {
          case 'wizard-btn-prev':
            prevPage();
            break;
          case 'wizard-btn-next':
            nextPage();
            break;
          default:
            console.error('Unexpected form submission');
        }
      }
    }
    return true;
  };

  useEffect(() => {
    requestAnimationFrame(resetFocus);
  }, [sectionIndex, innerPageIndex]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (timelineBarEl.current) {
        if (sectionIndex === sections.length - 1) {
          timelineBarEl.current.style.bottom = ``;
        } else {
          const itemEl = document.getElementById(`section-${sectionIndex}`);
          const listEl = itemEl?.parentElement;
          const containerEl = timelineBarEl.current.parentElement;

          if (itemEl && listEl && containerEl) {
            const distance = listEl.offsetTop + itemEl.offsetTop + itemEl.offsetHeight + 16;
            const containerHeight = containerEl.offsetHeight;
            timelineBarEl.current.style.bottom = `${containerHeight - distance}px`;
          }
        }
      }
    });
  }, [sectionIndex, innerPageIndex, sections]);

  return (
    <form className="wizard" ref={formEl} onSubmit={handleSubmit}>
      <Button className="sr-only" classOverride type="submit" onClick={() => setFormSubmitter('wizard-btn-next')}>
        Next
      </Button>

      <div className="timeline">
        <h1>{name}</h1>
        <div className="list-wrapper">
          {sections.map((section, i) => {
            let classes = undefined;
            if (sectionIndex > i) classes = 'past';
            if (sectionIndex === i) classes = 'current';
            return (
              <button
                key={`section-${i}-${section.title}`}
                className={classes}
                id={`section-${i}`}
                onClick={() => setFormSubmitter(`section-${i}`)}
                aria-label={`${section.title} (Jump to wizard section)`}
              >
                {i + 1}. {section.title}
              </button>
            );
          })}
        </div>
        <div className="back-progress-bar" ref={timelineBarEl} />
      </div>

      <div className="content">
        <div className="form-content">
          <h2 tabIndex={-1}>{sections[sectionIndex].title}</h2>
          {sections[sectionIndex].pages[innerPageIndex]}
        </div>
        <div className="footer-ctrls">
          <Button
            id="wizard-btn-prev"
            variant="rad alt"
            onClick={() => setFormSubmitter('wizard-btn-prev')}
            disabled={sectionIndex === 0 && innerPageIndex === 0}
          >
            <span className="text">Back</span>
            <span className="icon">
              <Icon code="chevron_left" label="Back" />
            </span>
          </Button>
          {sections[sectionIndex].pages.length > 1 ? (
            <div className="inner-pagination">
              {sections[sectionIndex].pages.map((page, i) => {
                let classes = 'dot';
                if (i === innerPageIndex) classes += ' current';
                return <div key={`dot-${i}`} className={classes} onClick={() => handleInnerPageClick(i)} />;
              })}
            </div>
          ) : null}

          {sectionIndex >= sections.length - 1 && innerPageIndex >= sections[sectionIndex].pages.length - 1 ? (
            <Button id="wizard-btn-next" variant="rad secondary" type="button" onClick={onFinish}>
              <span className="text">Finish</span>
              <span className="icon">
                <Icon code="done" label="Finish" />
              </span>
            </Button>
          ) : (
            <Button id="wizard-btn-next" variant="rad" onClick={() => setFormSubmitter('wizard-btn-next')}>
              <span className="text">Next</span>
              <span className="icon">
                <Icon code="chevron_right" label="Next" />
              </span>
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}

export default Wizard;
