import React, { useEffect } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { resendVerification } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import Icon from '../core/display/Icon';
import { setPageTitle } from '../../utils/functions';

interface Props {
  username?: string;
}

function EmailVerifyPrompt({ username }: Props): JSX.Element {
  useEffect(() => setPageTitle('Verify Email'), []);

  const { modalDispatch } = useModalContext();

  return (
    <div className="page" id="email-verify-page">
      <img src={logoSrc} width="256" alt="Peerceptiv logo" />
      <div id="prompt">
        <Icon code="email" ariaHidden />
        <h1>Verify your email</h1>
        <p>Please check your email for a link to activate your account.</p>
        {username ? (
          <Button
            variant="rad low sm"
            onClick={() => {
              resendVerification(username, () => {
                modalDispatch(
                  openModal({
                    heading: 'Verification Email Resent',
                    label: `Please check your inbox for a new verification email.`,
                    inputType: 'none',
                    buttonText: 'Okay',
                    cancelHide: true,
                  }),
                );
              });
            }}
          >
            Resend Verification Email
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default EmailVerifyPrompt;
