import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { GroupFormationSurveyPrompt, GroupFormationSurveyPromptType } from '../../types/types';
import { setPageTitle, storageAvailable } from '../../utils/functions';
import Icon from '../core/display/Icon';
import ListEditor from '../core/editor/ListEditor/ListEditor';
import SingleForm from '../core/input/Form/SingleForm';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { getReadableFromType } from './GroupFormationSurveyEditor';
import {
  createGroupFormationSurveyPrompt,
  deleteGroupFormationSurveyPrompt,
  updateGroupFormationSurveyPrompt,
} from '../../utils/requests';
import { useDispatch } from 'react-redux';
import { newUpdateKey } from '../../actions';
import BasicScheduler from '../core/input/Scheduler/BasicScheduler';
import Button from '../core/button/Button/Button';
import Toggle from '../core/input/Toggle/Toggle';
const { Row, Col } = SingleForm;

interface Props {
  type: 'new' | 'edit';
}

function GroupFormationSurveyPromptEditor({ type }: Props): JSX.Element {
  useEffect(() => setPageTitle('Edit Prompt'), []);

  const { courseId, assignmentId, surveyPromptId } = useParams() as {
    courseId: string;
    assignmentId: string;
    surveyPromptId?: string;
  };
  const baseUrl = `/course/${courseId}/assignment/${assignmentId}/rubric/group_formation`;

  const navigate = useNavigate();

  const [promptType, setPromptType] = useState<GroupFormationSurveyPromptType | null>(null);
  const [initPrompt, setInitPrompt] = useState<GroupFormationSurveyPrompt | null>(null);
  const [description, setDescription] = useState('');
  const [options, setOptions] = useState<string[]>([]);
  const [hiddenOnResults, setHiddenOnResults] = useState(false);
  const [loading, setLoading] = useState(type === 'edit');
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();

  useEffect(() => {
    const storedValue = storageAvailable('sessionStorage')
      ? window.sessionStorage.getItem(`prompt-${surveyPromptId ?? 'new'}`)
      : null;
    if (typeof storedValue === 'string') {
      const prompt = JSON.parse(storedValue) as GroupFormationSurveyPrompt;
      setInitPrompt(prompt);
      setPromptType(prompt.type);
      setDescription(prompt.description);
      setOptions(prompt.options);
      setHiddenOnResults(prompt.hiddenOnResults);
    }
    setLoading(false);
  }, [surveyPromptId]);

  const returnToRubricEditor = useCallback(() => {
    dispatch(newUpdateKey());
    navigate(baseUrl);
  }, [baseUrl, dispatch, navigate]);

  const save = useCallback(
    (successCb: () => void, errorCb: () => void) => {
      if (promptType) {
        const promptData: GroupFormationSurveyPrompt = {
          surveyPromptId: surveyPromptId ?? '',
          type: promptType,
          description,
          options,
          hiddenOnResults,
          dimensionCount: -1,
          order: initPrompt ? initPrompt.order : -1,
        };
        if (type === 'edit') updateGroupFormationSurveyPrompt(surveyPromptId as string, promptData, successCb, errorCb);
        else if (type === 'new') createGroupFormationSurveyPrompt(assignmentId, promptData, successCb, errorCb);
      }
    },
    [assignmentId, description, hiddenOnResults, initPrompt, options, promptType, surveyPromptId, type],
  );

  const onSubmit = useCallback(
    (arg0: FormData, callback: () => void) => {
      save(returnToRubricEditor, callback);
    },
    [returnToRubricEditor, save],
  );

  if (loading || promptType === null) return <LoadingSpinner />;
  return (
    <div className="page" id="rating-prompt-editor">
      <SingleForm
        className="rating-prompt-form"
        title={`${(type === 'new' && 'New') || (type === 'edit' && 'Edit')} ${getReadableFromType(promptType)} Prompt`}
        submitText="Save"
        onSubmit={onSubmit}
        backButton
        buttonPosition="top"
        customCtrls={
          type === 'edit' ? (
            <button
              className="button-mini delete-btn"
              type="button"
              onClick={() => {
                modalDispatch(
                  openModal({
                    heading: 'Delete Prompt',
                    label: 'Are you sure you want to delete this prompt?',
                    buttonText: 'Delete',
                    onConfirm: () => {
                      if (surveyPromptId) deleteGroupFormationSurveyPrompt(surveyPromptId, returnToRubricEditor);
                    },
                  }),
                );
              }}
            >
              <Icon code="delete" />
            </button>
          ) : undefined
        }
      >
        <Row>
          <Col flexBasis="100%">
            {promptType === 'SCHEDULE' ? (
              <div style={{ marginBottom: '2rem' }}>
                <p>
                  <b>[Schedule Prompt Preview:]</b>
                </p>
                <BasicScheduler readOnly />
              </div>
            ) : (
              <>
                <label htmlFor="description">Prompt:</label>
                <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />

                <label>Options:</label>
                <ListEditor defaultList={options} descriptionLabel="Prompt" onChange={setOptions} />
              </>
            )}

            <Button
              className="advanced-btn"
              variant="link low"
              type="button"
              onClick={() => setAdvancedSettingsOpen((prevState) => !prevState)}
            >
              <Icon code={advancedSettingsOpen ? 'expand_more' : 'chevron_right'} />
              Advanced Settings
            </Button>
            <div className="advanced-container" style={advancedSettingsOpen ? undefined : { display: 'none' }}>
              <Toggle checked={hiddenOnResults} onChange={(e) => setHiddenOnResults(e.target.checked)}>
                Hide on Students Results
              </Toggle>
            </div>
          </Col>
        </Row>
      </SingleForm>
    </div>
  );
}

export default GroupFormationSurveyPromptEditor;
