import React, { useCallback, useEffect, useState } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Rating, RubricTarget, Tag } from '../../types/types';
import { formDataToObject, setPageTitle } from '../../utils/functions';
import Button from '../core/button/Button/Button';
import AlertBar from '../core/display/AlertBar';
import HelpTag from '../core/display/HelpTag';
import Icon from '../core/display/Icon';
import ListEditor from '../core/editor/ListEditor/ListEditor';
import RichEditor from '../core/editor/RichEditor/RichEditor';
import SingleForm from '../core/input/Form/SingleForm';
import TagsInput from '../core/input/TagsInput/TagsInput';
import TabList from '../core/layout/TabList/TabList';
import Toggle from '../core/input/Toggle/Toggle';
const { Row, Col } = SingleForm;

interface Props {
  fromLibrary?: boolean;
  initPrompt?: Rating;
  requestCreateRating: (ratingData: Rating, successCb: (rating: Rating) => void, errorCb: () => void) => void;
  requestDeleteRatingPrompt: (ratingId: string, successCb: () => void) => void;
  requestGetRatingResourceFile: (ratingId: string, successCb: (resource: File) => void) => void;
  requestPostRatingResourceFile: (ratingId: string, formData: FormData) => void;
  requestUpdateRating: (ratingData: Rating, successCb: (rating: Rating) => void, errorCb: () => void) => void;
  returnToRubricEditor: () => void;
  target?: RubricTarget;
  type: 'new' | 'edit';
}

type ResourceData = {
  fileResource?: File;
  urlResource?: string;
  resourceName: string;
  scoreThreshold: number;
};

function RatingPromptEditor({
  fromLibrary = false,
  initPrompt,
  requestCreateRating,
  requestDeleteRatingPrompt,
  requestGetRatingResourceFile,
  requestPostRatingResourceFile,
  requestUpdateRating,
  returnToRubricEditor,
  type,
}: Props): JSX.Element {
  useEffect(() => setPageTitle('Edit Rating Prompt'), []);

  const [name, setName] = useState(initPrompt ? initPrompt.name : '');
  const [prompt, setPrompt] = useState(initPrompt ? initPrompt.prompt : '');
  const [ratingWeight, setRatingWeight] = useState(initPrompt ? initPrompt.ratingWeight : 1.0);
  const [ratingLevels, setRatingLevels] = useState<string[]>(
    initPrompt ? initPrompt.ratingLevels.map((level) => level.levelDescription) : [],
  );
  const [tags, setTags] = useState<Tag[]>(initPrompt ? initPrompt.tags : []);
  const attachmentExists = initPrompt ? initPrompt.attachmentExists : false;
  const [hiddenOnResults, setHiddenOnResults] = useState<boolean>(
    initPrompt ? initPrompt.hiddenOnResults ?? false : false,
  );

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

  const [resourceData, setResourceData] = useState<ResourceData>(
    initPrompt && initPrompt.ratingResource
      ? {
          urlResource: initPrompt.ratingResource.url || undefined,
          resourceName: initPrompt.ratingResource.resourceName,
          scoreThreshold: initPrompt.ratingResource.scoreThreshold,
        }
      : { resourceName: '', scoreThreshold: 1 },
  );

  const { modalDispatch } = useModalContext();

  useEffect(() => {
    if (initPrompt && initPrompt.ratingResource && initPrompt.ratingResource.fileAttached) {
      requestGetRatingResourceFile(initPrompt.ratingId, (resource) => {
        setResourceData((prevData) => ({ ...prevData, fileResource: resource }));
      });
    }
  }, [initPrompt, requestGetRatingResourceFile]);

  const save = useCallback(
    (successCb: () => void, errorCb: () => void) => {
      const ratingData: Rating = {
        ratingId: initPrompt ? initPrompt.ratingId : '',
        name,
        prompt,
        ratingLevels: ratingLevels.map((level, i) => {
          return { score: i + 1, levelDescription: level };
        }),
        ratingWeight,
        order: initPrompt ? initPrompt.order : -1,
        tags,
        target: 'SUBMISSION',
        attachmentExists,
        ratingResource:
          resourceData.fileResource !== undefined || resourceData.urlResource !== undefined
            ? {
                url: resourceData.urlResource ? resourceData.urlResource : null,
                scoreThreshold: resourceData.scoreThreshold,
                fileAttached: resourceData.fileResource !== undefined,
                resourceName: resourceData.resourceName,
              }
            : null,
        hiddenOnResults,
      };

      const saveFileResource = (rating: Rating) => {
        if (resourceData.fileResource !== undefined) {
          const formData = new FormData();
          formData.append('resource', resourceData.fileResource);
          requestPostRatingResourceFile(rating.ratingId, formData);
        }
      };

      const cb = (rating: Rating) => {
        saveFileResource(rating);
        successCb();
      };

      if (type === 'edit') requestUpdateRating(ratingData, cb, errorCb);
      else if (type === 'new') requestCreateRating(ratingData, cb, errorCb);
    },
    [
      initPrompt,
      name,
      prompt,
      ratingLevels,
      ratingWeight,
      tags,
      attachmentExists,
      hiddenOnResults,
      resourceData,
      type,
      requestUpdateRating,
      requestCreateRating,
      requestPostRatingResourceFile,
    ],
  );

  const onSubmit = useCallback(
    (arg0: FormData, callback: () => void) => {
      save(returnToRubricEditor, callback);
    },
    [returnToRubricEditor, save],
  );

  const renderResourceInput = () => {
    if (resourceData.fileResource !== undefined || resourceData.urlResource !== undefined) {
      const src = resourceData.fileResource ? URL.createObjectURL(resourceData.fileResource) : resourceData.urlResource;
      return (
        <>
          <label>Resources:</label>
          <table id="resource-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Threshold</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{resourceData.resourceName}</td>
                <td>{`<${resourceData.scoreThreshold}`}</td>
                <td>
                  <Button className="button-mini" classOverride href={src} tooltip="Open">
                    <Icon code="open_in_new" label="Open resource" />
                  </Button>

                  <Button
                    className="button-mini"
                    classOverride
                    type="button"
                    onClick={() => {
                      modalDispatch(
                        openModal({
                          heading: 'Delete Resource',
                          label: 'Are you sure you want to delete this resource?',
                          buttonText: 'Delete',
                          onConfirm: () => {
                            setResourceData({ resourceName: '', scoreThreshold: 1 });
                          },
                        }),
                      );
                    }}
                    tooltip="Delete"
                  >
                    <Icon code="delete" label="Delete Resource" />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }

    return (
      <div id="help-resource-btn-wrapper">
        <Button
          variant="rad low"
          type="button"
          onClick={() => {
            modalDispatch(
              openModal({
                heading: 'Attach a Help Resource',
                inputType: 'none',
                buttonText: 'Attach',
                children: <ResourceMenu maxScore={ratingLevels.length} />,
                onSubmit: (formData) => {
                  const data = formDataToObject(formData);
                  setResourceData(data);
                },
              }),
            );
          }}
        >
          Attach a Help Resource
        </Button>
        <span>(Optional)</span>
        <HelpTag>
          <b>Help Resources</b> will automatically distribute to students who score below a set threshold.
        </HelpTag>
      </div>
    );
  };

  return (
    <div className="page" id="rating-prompt-editor">
      <SingleForm
        className="rating-prompt-form"
        title={`${(type === 'new' && 'New') || (type === 'edit' && 'Edit')} Rating Prompt`}
        submitText="Save"
        onSubmit={onSubmit}
        backButton
        buttonPosition="both"
        customCtrls={
          initPrompt && type === 'edit' ? (
            <button
              className="button-mini delete-btn"
              type="button"
              onClick={() => {
                modalDispatch(
                  openModal({
                    heading: 'Delete Rating Prompt',
                    label: 'Are you sure you want to delete this rating prompt?',
                    buttonText: 'Delete',
                    onConfirm: () => {
                      requestDeleteRatingPrompt(initPrompt.ratingId, returnToRubricEditor);
                    },
                    children: fromLibrary ? (
                      <AlertBar>
                        Warning: Deleting this prompt will also remove it from any library rubrics that include it
                      </AlertBar>
                    ) : undefined,
                  }),
                );
              }}
            >
              <Icon code="delete" />
            </button>
          ) : undefined
        }
      >
        <Row>
          <Col flexBasis="100%">
            <label className="sr-only" htmlFor="rating-name">
              Rating Name:
            </label>
            <input
              id="rating-name"
              type="text"
              placeholder="Rating name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <label>Rating Prompt:</label>
            <RichEditor initContent={prompt} onChange={setPrompt} />

            <label>Rating Levels:</label>
            <ListEditor
              orderLabel="Score"
              defaultList={ratingLevels}
              descriptionLabel="Prompt"
              onChange={setRatingLevels}
            />

            <label htmlFor="tags">Tags:</label>
            <TagsInput id="tags" defaultTags={tags} onChange={setTags} />

            <div className="flex-row align-center" id="weight-wrapper">
              <label id="rating-weight-lbl" htmlFor="rating-weight">
                Weight:
              </label>
              <input
                id="rating-weight"
                type="number"
                value={ratingWeight}
                onChange={(e) => {
                  setRatingWeight(parseFloat(e.target.value));
                }}
                step="0.5"
                min={0}
              />
            </div>

            {renderResourceInput()}

            <Button
              className="advanced-btn"
              variant="link low"
              type="button"
              onClick={() => setAdvancedSettingsOpen((prevState) => !prevState)}
            >
              <Icon code={advancedSettingsOpen ? 'expand_more' : 'chevron_right'} />
              Advanced Settings
            </Button>
            <div className="advanced-container" style={advancedSettingsOpen ? undefined : { display: 'none' }}>
              <Toggle checked={hiddenOnResults} onChange={(e) => setHiddenOnResults(e.target.checked)}>
                Hide on Students Results
              </Toggle>
            </div>
          </Col>
        </Row>
      </SingleForm>
    </div>
  );
}

interface ResourceMenuProps {
  maxScore: number;
}

function ResourceMenu({ maxScore }: ResourceMenuProps): JSX.Element {
  const [file, setFile] = useState<File | null>(null);
  const [activeTabId, setActiveTabId] = useState('');

  return (
    <div id="resource-menu">
      <p id="explainer">Choose a resource type:</p>

      <TabList
        mini
        label="Attach Resource Menu"
        tabs={
          <>
            <TabList.Tab id="url-option" controls="url-option-tab">
              URL
            </TabList.Tab>
            <TabList.Tab id="file-option" controls="file-option-tab">
              Upload
            </TabList.Tab>
          </>
        }
        onTabChange={setActiveTabId}
      >
        <TabList.TabPanel id="url-option-tab" labeledBy="url-option">
          {activeTabId === 'url-option' ? (
            <div className="modal-tab">
              <div className="link-input-wrapper flex-row align-center">
                <Icon code="link" ariaHidden />
                <label className="sr-only" htmlFor="urlResource">
                  Choose File
                </label>
                <input
                  id="urlResource"
                  type="url"
                  name="urlResource"
                  placeholder="Paste your link here"
                  required={activeTabId === 'url-option'}
                />
              </div>
            </div>
          ) : null}
        </TabList.TabPanel>
        <TabList.TabPanel id="file-option-tab" labeledBy="file-option">
          {activeTabId === 'file-option' ? (
            <div className="modal-tab">
              <input
                id="fileResource"
                type="file"
                name="fileResource"
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const files = target.files;
                  if (files) {
                    const file = files.length > 0 ? files[0] : null;
                    setFile(file);
                  }
                }}
                required
              />
              <label className="peer-button button-rad button-low" htmlFor="fileResource">
                Choose File
              </label>
              <p>{file ? file.name : 'No file chosen'}</p>
            </div>
          ) : null}
        </TabList.TabPanel>
      </TabList>

      <label className="sr-only" htmlFor="resourceName">
        Resource Name:
      </label>
      <input type="text" placeholder="Resource name" id="resourceName" name="resourceName" required />

      <div id="threshold-wrapper">
        <label htmlFor="scoreThreshold">Score Threshold:</label>
        <input
          type="number"
          defaultValue={1}
          min={1}
          max={maxScore}
          step="0.1"
          id="scoreThreshold"
          name="scoreThreshold"
          required
        />
      </div>
    </div>
  );
}

export default RatingPromptEditor;
