import React from 'react';
import { Assignment, AssignmentProgress, PhaseCode } from '../../../../types/types';
import Icon from '../Icon';

interface Props {
  assignment?: Assignment;
  phase?: PhaseCode;
  size?: number;
  userProgress?: AssignmentProgress;
}

function AssignmentPhaseIcon({ assignment, phase, size = 32, userProgress }: Props): JSX.Element {
  let className = '';
  let iconCode = '';

  let isSubmissionPhase = false;
  let isReviewPhase = false;
  let isFeedbackPhase = false;
  let isEvaluationPhase = false;
  let isGroupFormationPhase = false;
  let isAsyncPhase = false;
  let isReflectionPhase = false;

  switch (phase) {
    case 'submit':
      isSubmissionPhase = true;
      break;
    case 'review':
      isReviewPhase = true;
      break;
    case 'feedback':
      isFeedbackPhase = true;
      break;
    case 'evaluate':
      isEvaluationPhase = true;
      break;
    case 'async':
      isAsyncPhase = true;
      break;
    case 'reflection':
      isReflectionPhase = true;
      break;
    case 'groupFormation':
      isGroupFormationPhase = true;
      break;
  }

  if (assignment) {
    const { submissionPhase, reviewPhase, feedbackPhase, evalPhase, reflectionPhase, groupFormationPhase } =
      assignment.progressStats ?? {};
    const {
      submissionPriority,
      reviewPriority,
      feedbackPriority,
      peerEvalPriority,
      reflectionPriority,
      groupFormationPriority,
      waitingForResults,
      waitingForReviews,
      waitingForSubmissions,
      hasResult,
    } = userProgress?.status ?? {};

    const { asyncEnabled, status } = assignment;

    isSubmissionPhase = asyncEnabled ? submissionPriority === true : submissionPhase === true;
    isReviewPhase = asyncEnabled ? reviewPriority === true : reviewPhase === true;
    isFeedbackPhase = asyncEnabled ? feedbackPriority === true : feedbackPhase === true;
    isEvaluationPhase = asyncEnabled ? peerEvalPriority === true : evalPhase === true;
    isReflectionPhase = asyncEnabled ? reflectionPriority === true : reflectionPhase === true;
    isGroupFormationPhase = asyncEnabled ? groupFormationPriority === true : groupFormationPhase === true;

    if (assignment?.template) {
      className = 'awaiting';
      iconCode = 'assignment';
    } else if (status === 'UNPUBLISHED') {
      className = 'awaiting';
      iconCode = 'event';
    } else if (assignment.asyncEnabled && (waitingForResults || waitingForReviews || waitingForSubmissions)) {
      className = 'awaiting';
      iconCode = 'hourglass_empty';
    } else if (assignment.asyncEnabled && hasResult) {
      className = 'complete';
      iconCode = 'done';
    } else if (assignment.asyncEnabled && assignment.status === 'ACTIVE') {
      className = 'awaiting';
      iconCode = 'update';
    } else if (status === 'NEEDS_GRADING') {
      className = 'awaiting';
      iconCode = 'pending_actions';
    } else if (status === 'COMPLETE') {
      className = 'complete';
      iconCode = 'done';
    }
  }

  if (isSubmissionPhase) {
    className = 'submit';
    iconCode = 'file_upload';
  } else if (isReviewPhase) {
    className = 'review';
    iconCode = 'rate_review';
  } else if (isFeedbackPhase) {
    className = 'feedback';
    iconCode = 'question_answer';
  } else if (isEvaluationPhase) {
    className = 'evaluate';
    iconCode = 'supervisor_account';
  } else if (isAsyncPhase) {
    className = 'awaiting';
    iconCode = 'update';
  } else if (isReflectionPhase) {
    className = 'reflection';
    iconCode = 'edit_square';
  } else if (isGroupFormationPhase) {
    className = 'group-formation';
    iconCode = 'groups';
  }

  return (
    <div className={`assignment-phase-icon ${className}`} style={{ width: `${size}px`, height: `${size}px` }}>
      <Icon code={iconCode} style={{ fontSize: `${size * 0.6}px`, lineHeight: `${size}px` }} ariaHidden />
    </div>
  );
}

export default AssignmentPhaseIcon;
