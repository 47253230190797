import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoSrc from '../../../assets/peerceptiv_logo_black_text.png';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { SpecialPurchase } from '../../../types/types';
import { getSpecialPurchaseByCode } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';

function MultiseatPurchase(): JSX.Element {
  const [showSeats, setShowSeats] = useState(true);
  const [showRedeem, setShowRedeem] = useState(false);
  const [seats, setSeats] = useState('');
  const [code, setCode] = useState('');
  const [specialPurchase, setSpecialPurchase] = useState<SpecialPurchase | null>(null);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const handleCodeChange = (e: React.ChangeEvent) => {
    let value = (e.target as HTMLInputElement).value;

    value = value.toUpperCase();
    value = value.replaceAll(/[^A-Z0-9]/gi, '');
    if (value.length > 20) value = value.slice(0, 20);

    const segment1 = value.slice(0, 5);
    const segment2 = value.slice(5, 10);
    const segment3 = value.slice(10, 15);
    const segment4 = value.slice(15, 20);

    let newString = segment1;
    if (segment2.length > 0) newString = `${newString}-${segment2}`;
    if (segment3.length > 0) newString = `${newString}-${segment3}`;
    if (segment4.length > 0) newString = `${newString}-${segment4}`;

    setCode(newString);
  };

  const redeemCode = useCallback(
    () =>
      getSpecialPurchaseByCode(
        code,
        (sp: SpecialPurchase) => {
          if (sp.discountPercentage === 0) {
            navigate(`/purchase/review-order?code=${code}`);
          } else {
            setSpecialPurchase(sp);
            setShowSeats(true);
          }
        },
        (error) => {
          if (error.response?.status === 404) {
            modalDispatch(
              openModal({
                heading: 'Code Not Found',
                label: 'The code entered does not exist.',
                inputType: 'none',
                buttonText: 'Okay',
                cancelHide: true,
                padding: '3rem',
              }),
            );
            return true;
          }
          return false;
        },
      ),
    [code, navigate, modalDispatch],
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (showRedeem && specialPurchase === null) redeemCode();
    else navigate(`/purchase/review-order?seats=${seats}${code !== '' ? `&code=${code}` : ''}`);
  };

  return (
    <div id="multiseat-purchase-page" className="page">
      <form className="panel" onSubmit={handleSubmit}>
        <img src={logoSrc} width="256" alt="Peerceptiv logo" />
        <h1 className="title">Purchase Access</h1>

        {showRedeem ? (
          <>
            <p>Enter your Purchase Code or Discount Code:</p>
            <div className="input-wrapper">
              <label className="sr-only" htmlFor="code">
                Purchase Code or Discount Code
              </label>
              <input
                placeholder="Enter Code"
                type="text"
                id="code"
                name="code"
                value={code}
                onChange={handleCodeChange}
                required
                disabled={specialPurchase !== null}
                pattern="((([A-Z0-9]){5}-){3})([A-Z0-9]){5}"
              />
              <p id="code-ex">
                Example of code format:
                <br />
                AAAAA-00000-A0A0A-0A0A0
              </p>
            </div>
            {specialPurchase === null ? (
              <>
                <Button className="confirm-btn" type="submit" variant="rad" disabled={code.length < 1}>
                  Redeem Code
                </Button>
                <button
                  id="menu-toggle"
                  type="button"
                  onClick={() => {
                    setShowSeats(true);
                    setShowRedeem(false);
                  }}
                >
                  Or, <b>Purchase Seats</b>
                </button>
              </>
            ) : (
              <p>
                Code Redeemed: <b>{specialPurchase.name}</b>
              </p>
            )}
          </>
        ) : null}

        {showSeats ? (
          <>
            <p>Enter the number of enrollment seats to purchase:</p>
            <div className="input-wrapper">
              <label className="sr-only" htmlFor="seats">
                Seat Count
              </label>
              <input
                placeholder="#"
                type="number"
                id="seats"
                name="seats"
                value={seats}
                onChange={(e) => setSeats(e.target.value)}
                min={1}
                required
              />
              <span>Seat{seats !== '1' ? 's' : ''}</span>
            </div>
            <Button className="confirm-btn" variant="rad" type="submit" disabled={seats.length < 1}>
              Review Order
            </Button>
            {specialPurchase === null ? (
              <button
                id="menu-toggle"
                type="button"
                onClick={() => {
                  setShowRedeem(true);
                  setShowSeats(false);
                }}
              >
                Or, <b>Redeem a Purchase or Discount Code</b>
              </button>
            ) : null}
          </>
        ) : null}
      </form>
    </div>
  );
}

export default MultiseatPurchase;
