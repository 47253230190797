import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TabList from '../core/layout/TabList/TabList';
import IndividualCourseRatings from './IndividualCourseRatings';
import IndividualCourseTags from './IndividualCourseTags';

function CourseRatingLibrary(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };
  const [courseName, setCourseName] = useState('')
  return (
    <div id="course-rating-page">
      <div id="course-name">
        <h2>{courseName}</h2>
      </div>
      <div>
        <TabList
          label={'course-ratings'}
          tabs={
            <>
              <TabList.Tab id="ratings" controls="rating-tab">
                Ratings
              </TabList.Tab>
              <TabList.Tab id="tags" controls="tag-tab">
                Tags
              </TabList.Tab>
            </>
          }
        >
          <TabList.TabPanel id="rating-tab" labeledBy="ratings">
            <div>
              <IndividualCourseRatings courseId={courseId}  setCourseName={setCourseName} />
            </div>
          </TabList.TabPanel>
          <TabList.TabPanel id="tag-tab" labeledBy="tags">
            <div>
              <IndividualCourseTags courseId={courseId}  />
            </div>
          </TabList.TabPanel>
        </TabList>
      </div>
    </div>
  );
}

export default CourseRatingLibrary;
