import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {  Rating, RatingScore, UserRatingScore } from '../../types/types';
import { getAverageRatingScoreForCourse, getRating } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import RatingPrompt from './RatingPrompt';
import Score from '../results/Score';

function IndividualCourseRatingPage(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };
  const { ratingId } = useParams() as { ratingId: string };
  const [rating, setRating] = useState<Rating | null>(null);
  const navigate = useNavigate();
  const [averageRatingScore, setAverageRatingScore] = useState<RatingScore | undefined>();

  useEffect(() => {
    getRating(ratingId, setRating);
    getAverageRatingScoreForCourse(courseId, ratingId, setAverageRatingScore);
  }, [courseId, ratingId]);

  if (rating)
    return (
      <>
        <section className="prompt-page-main">
          <div className="main-wrapper">
            <Button variant="alt rad xs low" onClick={() => navigate(-1)}>
              Return to Prompt List
            </Button>
            <RatingPrompt rating={rating} />
            <div className={`panel-sm panel-white rubric-scores-card wide ''`}>
              {averageRatingScore?.averageScore ? <h3 className="title">Average Rating Score</h3> : null}
              <div className="scores-wrapper">
                {averageRatingScore?.averageScore ? (
                  <Score
                    key={`score-${averageRatingScore.id}`}
                    id={averageRatingScore.id}
                    name={averageRatingScore.name}
                    classAvg={averageRatingScore.averageScore}
                    studentScore={(averageRatingScore as UserRatingScore).userScore}
                    outOf={5}
                    onSelect={() => undefined}
                  />
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    <b>Average Rating Score Unavailable</b>
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  return <LoadingSpinner />;
}

export default IndividualCourseRatingPage;
