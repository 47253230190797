import React, { useMemo } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { AxisLeft } from '@visx/axis';

type BinnedData = { bin: number; frequency: number };

export type BarsProps = {
  data: number[];
  bins: number[];
  width: number;
  height: number;
};

function ReviewsChart({ bins, data, width, height }: BarsProps): JSX.Element {
  const binnedData: BinnedData[] = useMemo(() => {
    // Map bin -> number of occurances
    const map: { [index: number]: number } = {};
    data.forEach((d) => {
      if (map.hasOwnProperty(d)) map[d] += 1;
      else map[d] = 1;
    });
    // Fill in gaps with empty bins
    for (let i = Math.min(...bins); i <= Math.max(...bins); i++) {
      if (map[i] == undefined) map[i] = 0;
    }
    // Convert to BinnedData with percentages
    const binnedDataResult: BinnedData[] = [];
    for (const [key, value] of Object.entries(map))
      binnedDataResult.push({ bin: parseInt(key), frequency: (value / data.length) * 100 });
    // Sort
    return binnedDataResult.sort((a, b) => b.bin - a.bin);
  }, [data, bins]);

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, width],
        round: true,
        domain: [0, 100],
      }),
    [width],
  );
  const yScale = useMemo(
    () =>
      scaleBand<number>({
        range: [0, height],
        round: true,
        domain: [...bins].sort().reverse(),
        padding: 0.2,
      }),
    [height, bins],
  );

  return (
    <>
      <svg className="reviews-chart" width={width} height={height} aria-hidden>
        <Group>
          {binnedData.map((d) => {
            const barWidth = xScale(d.frequency) ?? 0;
            const barHeight = yScale.bandwidth();
            const barY = yScale(d.bin) ?? 0;
            return (
              <Group key={`bar-${d.bin}`}>
                <BarRounded x={0} y={barY} width={width} height={barHeight} fill="#F8F8F8" radius={8} all />
                <BarRounded x={0} y={barY} width={barWidth} height={barHeight} fill="#FFDC00" radius={8} all />
                <text
                  x={width}
                  y={barY}
                  dx={8}
                  dy={barHeight - 4}
                  fill="black"
                  fontSize={12}
                  style={{ fontWeight: 'bold' }}
                >
                  {`${Math.round(d.frequency)}%`}
                </text>
              </Group>
            );
          })}
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            labelOffset={0}
            tickLength={8}
            tickFormat={(value) => `${value} star`}
            stroke="black"
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 12,
              textAnchor: 'end',
              dy: '0.33em',
            })}
          />
        </Group>
      </svg>
      <table className="sr-only">
        <thead>
          <tr>
            <th>Stars</th>
            <th>Percent of Reviews</th>
          </tr>
        </thead>
        <tbody>
          {binnedData.map((d) => (
            <tr key={d.bin}>
              <td>{d.bin}</td>
              <td>{Math.round(d.frequency)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default ReviewsChart;
