import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Credentials, LoginResponse, MfaCredentials } from '../../types/types';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import Button from '../core/button/Button/Button';
import { formDataToObject, setPageTitle, storageAvailable } from '../../utils/functions';
import { useParams } from 'react-router-dom';
import { mfaLogin,mfaEmail } from '../../utils/requests';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../actions';
import { LOGIN_RETURN_PATH_KEY } from '../../utils/constants';
import { set } from 'lodash';
import { stringToBytes } from 'pdfjs-dist/types/src/shared/util';

function MfaVerificationPage(): JSX.Element {
  const location = useLocation();
  const mfaToken=  new URLSearchParams(location.search).get('mfaToken') ?? '';
  const emailMfa=  (new URLSearchParams(location.search).get('emailMfa') ?? '')==='true';
  const [verificationInvalid, setValidCode] = useState(true);
  const [codeEmailed, setCodeEmailed] = useState(false);
  const redirectPending = useRef(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => setPageTitle('Verify Login'), []);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement);
      const mfaCredentials = formDataToObject(formData) as MfaCredentials;
      mfaLogin(
        mfaCredentials,
        (loginResponse: LoginResponse) => {
          if(loginResponse.user){
            setValidCode(true);
            dispatch(setUser(loginResponse.user));
            const returnPath = storageAvailable('sessionStorage')
              ? window.sessionStorage.getItem(LOGIN_RETURN_PATH_KEY)
              : '/';
            if (loginResponse.user.forcePasswordChange) {
              navigate(`/reset_password`);
            } else if (returnPath && returnPath !== '/login') {
              if (storageAvailable('sessionStorage')) window.sessionStorage.clear();
              navigate(returnPath);
            }
            else {
              navigate('/course/dashboard');
            }
            redirectPending.current = true;
          }          
        },
        (error) => {
          switch (error.response?.status) {
            case 403:
              navigate('/login');
              return true;
            case 401:
            case 400:
              setValidCode(false);
              return true;
            default:
              return false;
          }
        },
      );
    },
    [dispatch, navigate],
  );

  const emailCode = useCallback(() => {    
    const mfaCredentials = { mfaToken } as MfaCredentials;
    mfaEmail(mfaCredentials, () => {setCodeEmailed(true);}, () => {/**/});
  }, []);

  return (
    <div className="page login-row">
      <img src={logoSrc} width="256" alt="Peerceptiv logo" />
      <div className="panel-white login-container">
        <h3>Enter MFA Code From Your App {emailMfa ?  ('or Email') :('')}</h3>
        {verificationInvalid ? null : (
          <p className="invalid-log" role="alert">
            Invalid Code. Please try again.
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <label className="sr-only" htmlFor="mfaCode">
            Verification Code from your app or email:
          </label>
          <input type="text" id="mfaCode" name="mfaCode" placeholder="Verification Code" autoComplete="one-time-code" required={true} />
          <input type="hidden" name="mfaToken" value={mfaToken} />
          <Button>
            <h1>Verify</h1>
          </Button>
        </form>
        
        {codeEmailed ?  (
          <p className="invalid-log" role="alert">
            Verification code was sent to your email.
          </p>
        ): emailMfa ? (
          <Button
            variant="secondary"
            onClick={emailCode}
          >
            Email Me A Verification Code
          </Button>
        ):null}        
        <hr />
        <Link to="/login" id='cancel-mfa'>Back to Login</Link>
      </div>
    </div>
  );
}

export default MfaVerificationPage;
