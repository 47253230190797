import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../../actions';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { RootState } from '../../../store';
import { UserUpdateRequest } from '../../../types/types';
import { formDataToObject, setPageTitle } from '../../../utils/functions';
import { editUser } from '../../../utils/requests';
import SingleForm from '../../core/input/Form/SingleForm';
import PasswordInput from '../PasswordInput';
const { Row, Col, FormGroup, InputGroup } = SingleForm;

function PasswordChangePage(): JSX.Element {
  useEffect(() => setPageTitle('Profile: Change Password'), []);

  const user = useSelector((state: RootState) => state.user);

  const oldPasswordInput = useRef<HTMLInputElement>(null);

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();

  const togglePassword = (passwordInputEl: HTMLInputElement) => {
    const togglePasswordButton = passwordInputEl.nextElementSibling;
    if (togglePasswordButton)
      if (passwordInputEl.type === 'password') {
        passwordInputEl.type = 'text';
        togglePasswordButton.textContent = 'visibility_off';
        togglePasswordButton.setAttribute('aria-label', 'Hide password.');
      } else {
        passwordInputEl.type = 'password';
        togglePasswordButton.textContent = 'visibility';
        togglePasswordButton.setAttribute(
          'aria-label',
          'Show password as plain text. Warning: this will display your password on the screen.',
        );
      }
  };

  const handleSubmit = useCallback(
    (formData: FormData, callback: () => void) => {
      const dataObject = formDataToObject(formData) as {
        oldPassword: string;
        password: string;
        repeatPassword: string;
      };
      if (dataObject.password === dataObject.repeatPassword) {
        setPasswordMismatch(false);
        if (dataObject.oldPassword !== dataObject.password) {
          setSamePassword(false);
          const reqData: UserUpdateRequest = {
            email: user.email,
            newPassword: dataObject.password,
            oldPassword: dataObject.oldPassword,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            role: user.role,
          };
          editUser(
            user.userId,
            reqData,
            (user) => {
              dispatch(setUser(user));
              setWrongPassword(false);
              modalDispatch(
                openModal({
                  heading: 'Password Changed',
                  label: 'Your password has been successfully changed.',
                  buttonText: 'Okay',
                  cancelHide: true,
                  onSubmit: () => navigate('/profile'),
                  onCancel: () => navigate('/profile'),
                }),
              );
            },
            () => {
              callback();
              setWrongPassword(true);
              return true;
            },
          );
        } else {
          setSamePassword(true);
        }
      } else {
        setPasswordMismatch(true);
      }
    },
    [dispatch, modalDispatch, navigate, user],
  );

  return (
    <SingleForm
      className="change-password-form"
      title="Change Password"
      submitText="Change"
      onSubmit={handleSubmit}
      noWrapper
    >
      {passwordMismatch ? (
        <p className="invalid-log" role="alert">
          Passwords do not match. Please try again.
        </p>
      ) : null}
      {samePassword ? (
        <p className="invalid-log" role="alert">
          Must choose a new password.
        </p>
      ) : null}
      {wrongPassword ? (
        <p className="invalid-log" role="alert">
          Incorrect password.
        </p>
      ) : null}
      <Row>
        <Col flexBasis="100%">
          <FormGroup>
            <InputGroup iconCode="" flexDirection="column">
              <label className="sr-only" htmlFor="oldPassword">
                Old Password:
              </label>
              <div className="password-wrapper">
                <input
                  ref={oldPasswordInput}
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Old Password"
                  required={true}
                  minLength={5}
                />
                <button
                  className="toggle-password material-icons"
                  type="button"
                  aria-label="Show password as plain text. Warning: this will display your password on the screen."
                  onClick={() => {
                    if (oldPasswordInput.current) togglePassword(oldPasswordInput.current);
                  }}
                >
                  visibility
                </button>
              </div>
              <PasswordInput reset passwordMismatch={passwordMismatch} setPasswordMismatch={setPasswordMismatch} />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </SingleForm>
  );
}

export default PasswordChangePage;
