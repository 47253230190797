import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../actions';
import { logout } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function Logout(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    logout(() => {
      dispatch(clearUser());
      navigate(`/`);
    });
  }, [navigate, dispatch]);

  return (
    <div className="page logout-row">
      <div className="panel-white logout-container">
        <LoadingSpinner size={160} position="static" />
        <h1>Logging out...</h1>
      </div>
    </div>
  );
}

export default Logout;
