import React, { useEffect, useMemo, useState } from 'react';
import { BenchmarkGrade } from '../../../types/types';
import Button from '../../core/button/Button/Button';
import Scatterplot, { PointsRange } from '../../core/display/Graph/Scatterplot';
import { ParentSize } from '@visx/responsive';
import { BenchmarkView } from './BenchmarkPage';
import { useLocation } from 'react-router-dom';
import { TEST_ENV } from '../../../utils/constants';

interface Props {
  benchmarkGrades: BenchmarkGrade[];
  onDataSelect: (data: PointsRange) => void;
  setView: (view: BenchmarkView) => void;
  unselectedSubmissions: BenchmarkGrade[];
}

function Graph({ benchmarkGrades, onDataSelect, setView, unselectedSubmissions }: Props): JSX.Element {
  const [parsedTeacherBenchmarkData, setParsedTeacherBenchmarkData] = useState<PointsRange[]>([]);
  const [parsedUnselectedData, setParsedUnselectedData] = useState<PointsRange[]>([]);
  const [domain, setDomain] = useState<[number, number]>([Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]);

  const location = useLocation();

  const parseGradeData = (grades: BenchmarkGrade[], dataCb: (data: PointsRange[]) => void) => {
    const parsedGrades: [number, number, number][] = [];
    let min = Number.MAX_SAFE_INTEGER,
      max = Number.MIN_SAFE_INTEGER;

    grades.forEach((grade) => {
      const { submissionGrade: x, benchmarkGrade: y } = grade;
      parsedGrades.push([x, y, 0]);
      min = Math.min(x, y, min);
      max = Math.max(x, y, max);
    });

    dataCb(parsedGrades);
    setDomain((prevDomain) => {
      const finalMin = Math.min(prevDomain[0], min);
      const finalMax = Math.max(prevDomain[1], max);
      return [finalMin, finalMax];
    });
  };

  useEffect(() => {
    parseGradeData(benchmarkGrades, setParsedTeacherBenchmarkData);
  }, [benchmarkGrades]);

  useEffect(() => {
    parseGradeData(unselectedSubmissions, setParsedUnselectedData);
  }, [unselectedSubmissions]);

  const allParsedData = useMemo(
    () => [...parsedTeacherBenchmarkData, ...parsedUnselectedData],
    [parsedTeacherBenchmarkData, parsedUnselectedData],
  );

  return (
    <div className="page benchmark-row" id="benchmark-page">
      <div id="graph-menu" className="panel">
        <h1 className="title">Grade Results</h1>
        <div id="graph-wrapper">
          {allParsedData.length > 0 && !TEST_ENV ? (
            <ParentSize>
              {({ width, height }) => (
                <Scatterplot
                  data={allParsedData}
                  dataGroups={[
                    { data: parsedTeacherBenchmarkData, color: 'white', label: 'Benchmarked Grades' },
                    { data: parsedUnselectedData, color: 'black', label: 'Curved Grades' },
                  ]}
                  xLabel="Peer Grade"
                  yLabel="Benchmark Grade"
                  width={width}
                  height={height}
                  xDomain={domain}
                  yDomain={domain}
                  onDataSelect={onDataSelect}
                />
              )}
            </ParentSize>
          ) : null}
        </div>

        <div className="ctrls">
          <Button variant="alt rad" onClick={() => setView('GRADING')}>
            Edit Benchmark
          </Button>

          <Button variant="rad" href={location.pathname.replace('/benchmark', '')} route>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Graph;
