import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewCommentWithName } from '../../types/types';
import { getReflectionReviewComment } from '../../utils/requests';
import _ from 'lodash';

interface Props {
  userId: string;
}

function StudentReflectionResults({ userId }: Props): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };
  const [reflectionCommentWithName, setReflectionCommentWithName] = useState<ReviewCommentWithName[]>([]);

  useEffect(() => {
    getReflectionReviewComment(assignmentId, userId, setReflectionCommentWithName);
  }, [assignmentId, userId]);

  const dataExists = !_.isEmpty(reflectionCommentWithName);
  const emptyClass = !dataExists ? ' empty' : '';
  return (
    <div className="results-container fadeIn">
      <div className={`panel-white peer-comments-card results-card-wide${emptyClass}`}>
        <div className="reflection-container">
          <h2 className="title">Reflection Comments</h2>
          <div className="comments-wrapper">
            {dataExists ? (
              <>
                {reflectionCommentWithName.map((reviewComment) => (
                  <Comment
                    key={`comment-${reviewComment.reviewComment.reviewCommentId}`}
                    name={reviewComment.commentName}
                    comment={reviewComment.reviewComment.comment}
                  />
                ))}
              </>
            ) : (
              <p style={{ textAlign: 'center' }}>
                <b>Comments Unavailable</b> <br /> User did not submit
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface CommentProps {
  comment: string;
  name: string;
}

function Comment({ comment, name }: CommentProps): JSX.Element {
  return (
    <blockquote className="peer-comment">
      <div className="name-label">
        <i>
          on <b>{name}</b>
        </i>
      </div>
      <div className="comment-label">{comment}</div>
    </blockquote>
  );
}

export default StudentReflectionResults;
