import React, { useCallback } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { deleteEval } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';

interface DeleteEvalButtonProps {
  peerEvaluationId: string;
  updateData: () => void;
}

function DeleteEvalButton({ peerEvaluationId, updateData }: DeleteEvalButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  const requestDeleteEval = useCallback(
    () =>
      deleteEval(peerEvaluationId, () => {
        updateData();
        modalDispatch(
          openModal({
            heading: 'Team Member Evaluation Deleted',
            inputType: 'none',
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
      }),
    [peerEvaluationId, updateData, modalDispatch],
  );

  return (
    <Button
      classOverride
      className="button-mini delete"
      onClick={() => {
        modalDispatch(
          openModal({
            heading: 'Delete Team Member Evaluation?',
            label: 'Are you sure you want to delete this team member evaluation?',
            buttonText: 'Delete',
            onConfirm: requestDeleteEval,
          }),
        );
      }}
      tooltip="Delete team member evaluation"
    >
      <Icon code="delete" ariaHidden />
    </Button>
  );
}

export default DeleteEvalButton;
