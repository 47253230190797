import React from 'react';
import { AssignmentRubric, Comment, Rating, RubricsTargetMap } from '../../../types/types';
import _ from 'lodash';

interface Props {
  rubricsMap: RubricsTargetMap;
}

function RubricPage({ rubricsMap }: Props): JSX.Element {
  return (
    <>
      {!_.isEmpty(rubricsMap) ? (
        <>
          <p>{`The following rubric items will be copied into the new assignment:`}</p>
          <ul id="rubric-list">
            {Object.keys(rubricsMap).map((target) => {
              const { items } = rubricsMap[target as keyof RubricsTargetMap] as AssignmentRubric;
              return (
                <li key={target}>
                  <b>
                    {target
                      .split('_')
                      .map((substr) => substr.charAt(0) + substr.slice(1).toLocaleLowerCase())
                      .join(' ')}{' '}
                    Rubric
                  </b>
                  <ul>
                    {items.length > 0 ? (
                      items.map((prompt, i) => {
                        let name = '';
                        let className = '';
                        if (prompt.hasOwnProperty('commentName')) {
                          name = (prompt as Comment).commentName;
                          className = 'comment';
                        } else if (prompt.hasOwnProperty('name')) {
                          name = (prompt as Rating).name;
                          className = 'rating';
                        }
                        return (
                          <li key={'prompt-' + i} className={className}>
                            {name}
                          </li>
                        );
                      })
                    ) : (
                      <li>This rubric is empty</li>
                    )}
                  </ul>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p>{`No rubric items will be copied, as the original rubric is empty.`}</p>
      )}

      <p>{`You can edit the rubric after copying the assignment.`}</p>
    </>
  );
}

export default RubricPage;
