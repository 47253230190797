import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logoSrc from '../../../assets/peerceptiv_logo_black_text.png';
import Button from '../../core/button/Button/Button';
import { Purchase } from '../../../types/types';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { createStudentPurchase, getPurchaseCheckoutToken } from '../../../utils/requests';
import Icon from '../../core/display/Icon';
import { useStripe } from '@stripe/react-stripe-js';
import { formatDollar } from '../../../utils/functions';

function StudentCourseAccessReviewOrder(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };

  const [purchase, setPurchase] = useState<Purchase | null>(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const navigate = useNavigate();

  useEffect(() => createStudentPurchase(courseId, setPurchase), [courseId]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (purchase) {
        setLoading(true);
        getPurchaseCheckoutToken(purchase.purchaseId, (data) => {
          setLoading(false);
          stripe?.redirectToCheckout({ sessionId: data.sessionId });
        });
      }
    },
    [purchase, stripe],
  );

  if (purchase) {
    return (
      <div id="student-course-access-review-order-page" className="page">
        <form className="panel" onSubmit={handleSubmit}>
          <img src={logoSrc} width="256" alt="Peerceptiv logo" />

          <Button id="back-btn" variant="rad alt low sm" onClick={() => navigate(-1)}>
            <Icon code="chevron_left" />
            Back
          </Button>

          <h1 className="title">Review Order</h1>
          <p>You are purchasing:</p>

          <div className="order-details">
            <table className="nice-table">
              <tbody>
                <tr>
                  <th>{purchase.itemName}</th>
                  <td>{formatDollar(purchase.unitPrice)}</td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>{`\u00d7 ${purchase.quantity}`}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>
                    <b>{formatDollar(purchase.price)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="tos-wrapper">
            <input type="checkbox" id="tos" required />
            <label htmlFor="tos">
              I Agree to the{' '}
              <b>
                <a href="https://peerceptiv.com/terms-of-service/" target="_blank" rel="noreferrer">
                  Terms of Service
                </a>
              </b>
            </label>
          </div>

          <Button className="confirm-btn" variant="rad">
            Checkout
            <Icon code="arrow_right_alt" ariaHidden />
          </Button>
        </form>
        {loading ? <LoadingSpinner /> : null}
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default StudentCourseAccessReviewOrder;
