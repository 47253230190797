import React, { useMemo } from 'react';
import { Group } from '@visx/group';
import { ViolinPlot, BoxPlot } from '@visx/stats';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Stats } from '@visx/mock-data/lib/generators/genStats';
import { withTooltip, Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { PatternLines } from '@visx/pattern';
import { AxisBottom } from '@visx/axis';
import { Bar, Circle } from '@visx/shape';

// accessors
const x = (d: Stats) => d.boxPlot.x;
const min = (d: Stats) => d.boxPlot.min;
const max = (d: Stats) => d.boxPlot.max;
const median = (d: Stats) => d.boxPlot.median;
const firstQuartile = (d: Stats) => d.boxPlot.firstQuartile;
const thirdQuartile = (d: Stats) => d.boxPlot.thirdQuartile;
const outliers = (d: Stats) => d.boxPlot.outliers;

interface TooltipData {
  name?: string;
  min?: number;
  median?: number;
  max?: number;
  firstQuartile?: number;
  thirdQuartile?: number;
}

export type StatsPlotProps = {
  data: number[];
  width: number;
  height: number;
  highlightedDatum?: number;
};

export default withTooltip<StatsPlotProps, TooltipData>(
  ({
    data,
    width,
    height,
    highlightedDatum,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    showTooltip,
    hideTooltip,
  }: StatsPlotProps & WithTooltipProvidedProps<TooltipData>) => {
    const processedData = useMemo(() => genStatsFromData(data), [data]);

    if (processedData === null) return <>Could not render graph</>;

    // scales
    const minXValue = useMemo(() => Math.min(...data, min(processedData)), [data, processedData]);
    const maxXValue = useMemo(() => Math.max(...data, max(processedData)), [data, processedData]);
    const xScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [16, width - 16],
          round: true,
          domain: [minXValue, maxXValue],
        }),
      [width, minXValue, maxXValue],
    );

    const yScale = useMemo(
      () =>
        scaleBand<string>({
          range: [0, height],
          round: true,
          domain: [x(processedData)],
          padding: 0.4,
        }),
      [height, processedData],
    );

    const boxWidth = yScale.bandwidth();
    const constrainedWidth = Math.min(40, boxWidth);

    return (
      <div style={{ position: 'relative' }}>
        <svg width={width} height={height}>
          <PatternLines
            id="hViolinLines"
            height={3}
            width={3}
            stroke="#d4d4d4"
            strokeWidth={1}
            orientation={['vertical']}
          />
          <Group top={height / 2 - 16}>
            <g>
              <ViolinPlot
                horizontal
                data={processedData.binData}
                stroke="#000000"
                top={0 - constrainedWidth / 2}
                width={constrainedWidth}
                valueScale={xScale}
                fill="url(#hViolinLines)"
              />
              <BoxPlot
                horizontal
                min={min(processedData)}
                max={max(processedData)}
                top={0 - constrainedWidth * 0.2}
                firstQuartile={firstQuartile(processedData)}
                thirdQuartile={thirdQuartile(processedData)}
                median={median(processedData)}
                boxWidth={constrainedWidth * 0.4}
                fill="#000000"
                fillOpacity={0.3}
                stroke="#000000"
                strokeWidth={2}
                valueScale={xScale}
                outliers={outliers(processedData)}
                minProps={{
                  onMouseOver: () => {
                    showTooltip({
                      tooltipLeft: xScale(min(processedData)) ?? 0 + 40,
                      tooltipTop: (yScale(x(processedData)) ?? 0) + constrainedWidth + 5,
                      tooltipData: {
                        min: min(processedData),
                        name: x(processedData),
                      },
                    });
                  },
                  onMouseLeave: () => {
                    hideTooltip();
                  },
                }}
                maxProps={{
                  onMouseOver: () => {
                    showTooltip({
                      tooltipLeft: xScale(max(processedData)) ?? 0 + 40,
                      tooltipTop: (yScale(x(processedData)) ?? 0) + constrainedWidth + 5,
                      tooltipData: {
                        max: max(processedData),
                        name: x(processedData),
                      },
                    });
                  },
                  onMouseLeave: () => {
                    hideTooltip();
                  },
                }}
                boxProps={{
                  onMouseOver: () => {
                    showTooltip({
                      tooltipLeft: xScale(median(processedData)) ?? 0 + 40,
                      tooltipTop: (yScale(x(processedData)) ?? 0) + constrainedWidth + 5,
                      tooltipData: {
                        ...processedData.boxPlot,
                        name: x(processedData),
                      },
                    });
                  },
                  onMouseLeave: () => {
                    hideTooltip();
                  },
                }}
                medianProps={{
                  style: {
                    stroke: 'white',
                  },
                  onMouseOver: () => {
                    showTooltip({
                      tooltipLeft: xScale(median(processedData)) ?? 0 + 40,
                      tooltipTop: (yScale(x(processedData)) ?? 0) + constrainedWidth + 5,
                      tooltipData: {
                        median: median(processedData),
                        name: x(processedData),
                      },
                    });
                  },
                  onMouseLeave: () => {
                    hideTooltip();
                  },
                }}
              />
              {highlightedDatum ? (
                <>
                  <text
                    x={xScale(highlightedDatum)}
                    y={32 - height / 2}
                    dx={-20}
                    fill="black"
                    fontSize={12}
                    style={{ fontWeight: 'bold' }}
                  >
                    Student
                  </text>
                  <Bar x={xScale(highlightedDatum)} y={36 - height / 2} width={1} height={height - 52} fill="#303030" />
                  <Circle cx={xScale(highlightedDatum)} cy={0} r={3} stroke="#000000" fill="#ffffff" />
                </>
              ) : null}
            </g>
            <AxisBottom
              top={height / 2 - 16}
              scale={xScale}
              label="Accuracy Correlation"
              tickLength={2}
              labelOffset={6}
            />
          </Group>
        </svg>

        {tooltipOpen && tooltipData && (
          <Tooltip
            top={tooltipTop}
            left={tooltipLeft}
            style={{ ...defaultTooltipStyles, backgroundColor: '#283238', color: 'white' }}
          >
            <div>
              <strong>{tooltipData.name}</strong>
            </div>
            <div style={{ marginTop: '5px', fontSize: '12px' }}>
              {tooltipData.max && <div>max: {tooltipData.max.toFixed(4)}</div>}
              {tooltipData.thirdQuartile && <div>third quartile: {tooltipData.thirdQuartile.toFixed(4)}</div>}
              {tooltipData.median && <div>median: {tooltipData.median.toFixed(4)}</div>}
              {tooltipData.firstQuartile && <div>first quartile: {tooltipData.firstQuartile.toFixed(4)}</div>}
              {tooltipData.min && <div>min: {tooltipData.min.toFixed(4)}</div>}
            </div>
          </Tooltip>
        )}
      </div>
    );
  },
);

const genStatsFromData = (data: number[]) => {
  data.sort(function (a, b) {
    return a - b;
  });
  const firstQuartile = data[Math.round(data.length / 4)];
  const thirdQuartile = data[Math.round((3 * data.length) / 4)];
  const IQR = thirdQuartile - firstQuartile;
  const min = firstQuartile - 1.5 * IQR;
  const max = thirdQuartile + 1.5 * IQR;
  const outliers = data.filter(function (p) {
    return p < min || p > max;
  });
  const binWidth = 2 * IQR * Math.pow(data.length - outliers.length, -1 / 3);
  const binNum = Math.round((max - min) / binWidth);
  const actualBinWidth = (max - min) / binNum;
  if (isNaN(binNum)) return null;
  const bins = new Array(binNum + 2).fill(0);
  const values = new Array(binNum + 2).fill(min);

  for (let ii = 1; ii <= binNum; ii += 1) {
    values[ii] += actualBinWidth * (ii - 0.5);
  }

  values[values.length - 1] = max;
  data
    .filter(function (p) {
      return p >= min && p <= max;
    })
    .forEach(function (p) {
      bins[Math.floor((p - min) / actualBinWidth) + 1] += 1;
    });
  const binData = values.map(function (v, index) {
    return {
      value: v,
      count: bins[index],
    };
  });
  const boxPlot = {
    x: 'Statistics',
    min: min,
    firstQuartile: firstQuartile,
    median: data[Math.round(data.length / 2)],
    thirdQuartile: thirdQuartile,
    max: max,
    outliers: outliers,
  };
  return {
    boxPlot: boxPlot,
    binData: binData,
  };
};
