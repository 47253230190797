import React, { useEffect } from 'react';
import { Assignment, AssignmentProgress, PhaseCode, ProgressStats } from '../../../types/types';
import PhaseMenu, { PhaseMenuDetails } from './PhaseMenu';

interface Props {
  assignment: Assignment;
  userProgress: AssignmentProgress;
  setPhaseDetails: (phase: PhaseCode, details: PhaseMenuDetails) => void;
}

function ReviewMenu({ assignment, userProgress, setPhaseDetails }: Props): JSX.Element {
  const rootPath = `/course/${assignment.courseId}/assignment/${assignment.assignmentId}`;
  const { selfReviewEnabled, progressStats, asyncEnabled, publicationTime, submissionDeadline } = assignment;
  const { status, requiredReviewsCount } = userProgress;
  const { reviewPhase, submissionPhase } = progressStats as ProgressStats;
  const requiredReviewsCompleted = userProgress.reviewsCompleted + (userProgress.selfReview?.complete === true ? 1 : 0);
  const dueDate = assignment.asyncEndDeadline ?? assignment.reviewDeadline ?? '';
  const locked = !assignment.liveMode && !asyncEnabled && status.reviewingUpcoming;
  const unlockDate = asyncEnabled ? publicationTime : submissionDeadline ?? '';

  useEffect(
    () =>
      setPhaseDetails('review', {
        complete: requiredReviewsCompleted >= requiredReviewsCount,
        active: asyncEnabled ? status.reviewPriority : reviewPhase,
        locked,
        deadline: dueDate,
      }),
    [
      asyncEnabled,
      dueDate,
      locked,
      requiredReviewsCompleted,
      requiredReviewsCount,
      reviewPhase,
      setPhaseDetails,
      status,
    ],
  );

  const getReviewTasks = () => {
    const reviewTasks: JSX.Element[] = [];
    let reviewNum = 0;

    // Create self-review button if applicable
    if (selfReviewEnabled && userProgress.submissionInfo) {
      const { selfReview } = userProgress;
      if (selfReview && userProgress.status.canEditReviews) {
        reviewTasks.push(
          <PhaseMenu.TaskEntry
            name="Self-Review"
            buttonText={!selfReview.complete ? 'Continue' : 'Edit'}
            complete={selfReview.complete}
            disabled={!reviewPhase}
            href={`${rootPath}/review/${selfReview.reviewId}`}
            key="review-btn-self"
          />,
        );
      } else if (selfReview && selfReview.complete) {
        reviewTasks.push(<PhaseMenu.TaskEntry name="Self-Review" buttonText="Complete" disabled complete />);
      } else if (!selfReview && userProgress.status.canDoAdditionalReviews) {
        reviewTasks.push(
          <PhaseMenu.TaskEntry
            name="Self-Review"
            buttonText="Start"
            href={`${rootPath}/review/new?selfReview=true`}
            key="review-btn-self"
          />,
        );
      } else if (!selfReview || !selfReview.complete) {
        reviewTasks.push(
          <PhaseMenu.TaskEntry name="Self-Review" buttonText="Incomplete" disabled incomplete key="review-btn-self" />,
        );
      }
    }

    // Create buttons for any completed reviews
    if (userProgress.completedReviews) {
      userProgress.completedReviews.forEach((review) => {
        if (reviewPhase && userProgress.status.canEditReviews)
          reviewTasks.push(
            <PhaseMenu.TaskEntry
              name={`Review ${review.displayName ? `of ${review.displayName}` : reviewNum + 1}`}
              buttonText="Edit"
              href={`${rootPath}/review/${review.reviewId}`}
              disabled={!reviewPhase}
              complete
              key={`review-btn-${reviewNum}`}
            />,
          );
        else
          reviewTasks.push(
            <PhaseMenu.TaskEntry
              name={`Review ${review.displayName ? `of ${review.displayName}` : reviewNum + 1}`}
              buttonText="Complete"
              disabled
              complete
              key={`review-btn-${reviewNum}`}
            />,
          );
        reviewNum++;
      });
    }

    // Create buttons for any incomplete reviews
    if (userProgress.incompleteReviews) {
      //sort the de-anonymized userName in alphabetical order
      userProgress.incompleteReviews.sort(function (a, b) {
        return (a.displayName ?? '').toUpperCase().localeCompare((b.displayName ?? '').toUpperCase());
      });

      userProgress.incompleteReviews.forEach((review) => {
        if (reviewPhase || (assignment.liveMode && submissionPhase)) {
          reviewTasks.push(
            <PhaseMenu.TaskEntry
              name={`Review ${review.displayName ? `of ${review.displayName}` : reviewNum + 1}`}
              buttonText="Continue"
              href={`${rootPath}/review/${review.reviewId}`}
              key={`review-btn-${reviewNum}`}
            />,
          );
        } else {
          reviewTasks.push(
            <PhaseMenu.TaskEntry
              name={`Review ${review.displayName ? `of ${review.displayName}` : reviewNum + 1}`}
              buttonText="Incomplete"
              disabled
              incomplete
              key={`review-btn-${reviewNum}`}
            />,
          );
          reviewNum++;
        }
      });
    }

    if (reviewPhase && reviewTasks.length < requiredReviewsCount && status.canDoAdditionalReviews) {
      let reviewNumber = 1;
      if (userProgress.completedReviews) reviewNumber += userProgress.completedReviews.length;
      const submissionLock =
        assignment.reviewingWithoutSubmission && !userProgress.status.hasSubmitted && reviewNumber === 1;
      const submissionLockParam = submissionLock ? '?submissionLock=true' : '';
      reviewTasks.push(
        <PhaseMenu.TaskEntry
          name={`Review ${reviewNum + 1}`}
          buttonText="Start"
          href={`${rootPath}/review/new${submissionLockParam}`}
          key={`new-review-btn`}
        />,
      );
    }

    return reviewTasks;
  };

  return (
    <PhaseMenu.SubMenu
      description={
        <>
          <b>Review Phase</b> - Review your peer&apos;s submission, rating its quality and providing helpful comments on
          how to improve.
        </>
      }
    >
      <PhaseMenu.Status
        phaseGoal={`Perform ${requiredReviewsCount} Reviews to complete this phase`}
        dueDate={dueDate}
        tasksCompleted={requiredReviewsCompleted}
        tasksToComplete={requiredReviewsCount}
        iconCode="rate_review"
      />
      <PhaseMenu.TaskList phase="review" status={status} locked={locked} unlockDate={unlockDate}>
        {getReviewTasks()}
      </PhaseMenu.TaskList>
    </PhaseMenu.SubMenu>
  );
}

export default ReviewMenu;
