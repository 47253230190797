import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { POST_Purchase, POST_SpecialPurchase, Purchase, SpecialPurchase } from '../../../types/types';
import {
  adminCreatePurchase,
  adminCreateSpecialPurchase,
  adminDeletePurchase,
  adminDeleteSpecialPurchase,
  adminGetPurchaseLog,
  adminGetSpecialPurchases,
  adminUpdateSpecialPurchase,
} from '../../../utils/requests';
import TabList from '../../core/layout/TabList/TabList';
import AdminPurchaseLog from './AdminPurchaseLog';
import AdminSpecialPurchases from './AdminSpecialPurchases';
import AdminStudentPurchaseCode from './AdminStudentPurchaseCode';
import moment from 'moment';

function AdminPurchasing(): JSX.Element {
  const [purchases, setPurchases] = useState<Purchase[] | undefined>(undefined);
  const [specialPurchases, setSpecialPurchases] = useState<SpecialPurchase[] | undefined>(undefined);
  const [updateKey, setUpdateKey] = useState(0);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  useEffect(() => {
    let searchFromDateTime = startDate ? startDate : '2000-01-01 00:00:00';
    let searchToDateTime = endDate ? endDate : '2050-12-30 00:00:00';
    adminGetPurchaseLog(searchFromDateTime, searchToDateTime, setPurchases);
    adminGetSpecialPurchases(searchFromDateTime, searchToDateTime, setSpecialPurchases);
  }, [endDate, startDate, updateKey]);

  const deletePurchase = useCallback((purchaseId: string) => adminDeletePurchase(purchaseId, updateData), [updateData]);

  const createPurchase = useCallback(
    (email: string, purchase: POST_Purchase) => adminCreatePurchase(email, purchase, updateData),
    [updateData],
  );

  const deleteSpecialPurchase = useCallback(
    (specialPurchaseId: string) => adminDeleteSpecialPurchase(specialPurchaseId, updateData),
    [updateData],
  );

  const createSpecialPurchase = useCallback(
    (specialPurchase: POST_SpecialPurchase) => adminCreateSpecialPurchase(specialPurchase, updateData),
    [updateData],
  );

  const updateSpecialPurchase = useCallback(
    (specialPurchaseId: string, specialPurchase: POST_SpecialPurchase) =>
      adminUpdateSpecialPurchase(specialPurchaseId, specialPurchase, updateData),
    [updateData],
  );

  const handleStartDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  const handleEndDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEndDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  return (
    <div id="admin-purchasing" className="page">
      <h1>Purchase Management</h1>
      <div id="purchase-search">
        <div>
          <label htmlFor="startDate">Start Date:</label>
          <input
            id="startDate"
            type="date"
            value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
            onChange={handleStartDateChange}
          />

          <label htmlFor="startDate">End Date:</label>
          <input
            id="endDate"
            type="date"
            value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <TabList
        label="Purchase Management Menu"
        tabs={
          <>
            <TabList.Tab id="purchase-log" controls="purchase-log-tab">
              Purchase Log
            </TabList.Tab>
            <TabList.Tab id="special-purchases" controls="special-purchases-tab">
              Special Purchases
            </TabList.Tab>
            <TabList.Tab id="student-purchase-code" controls="student-purchase-code-tab">
              Student Purchase Code
            </TabList.Tab>
          </>
        }
      >
        <TabList.TabPanel id="purchase-log-tab" labeledBy="purchase-log">
          <AdminPurchaseLog purchases={purchases} createPurchase={createPurchase} deletePurchase={deletePurchase} />
        </TabList.TabPanel>

        <TabList.TabPanel id="special-purchases-tab" labeledBy="special-purchases">
          <AdminSpecialPurchases
            specialPurchases={specialPurchases}
            createSpecialPurchase={createSpecialPurchase}
            deleteSpecialPurchase={deleteSpecialPurchase}
            updateSpecialPurchase={updateSpecialPurchase}
          />
        </TabList.TabPanel>

        <TabList.TabPanel id="student-purchase-code-tab" labeledBy="student-purchase-code">
          <AdminStudentPurchaseCode />
        </TabList.TabPanel>
      </TabList>
    </div>
  );
}

export default AdminPurchasing;
