import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { GridRadial } from '@visx/grid';
import { mod } from '../../../../utils/functions';
import xIcon from '../../../../assets/x.svg';

function genPolygonPoints(dataArray: DataPoint[], scale: (n: number) => number) {
  const step = (Math.PI * 2) / dataArray.length;
  const points: { x: number; y: number; style?: CSSProperties }[] = new Array(dataArray.length).fill({
    x: 0,
    y: 0,
  });
  const pointString: string = new Array(dataArray.length + 1).fill('').reduce((res, _, i) => {
    if (i > dataArray.length) return res;
    const xVal = scale(dataArray[i - 1].datum) * Math.sin(i * step);
    const yVal = scale(dataArray[i - 1].datum) * Math.cos(i * step);
    points[i - 1] = { x: xVal, y: yVal, style: dataArray[i - 1].style };
    res += `${xVal},${yVal} `;
    return res;
  });

  return { points, pointString };
}

type DataPoint = { datum: number; style?: CSSProperties };

export type RadarProps = {
  data: DataPoint[];
  radius: number;
  yDomain: number[];
};

function TargetGraph({ data, radius, yDomain }: RadarProps): JSX.Element {
  const svgRef = useRef<SVGSVGElement>(null);
  const dataKey = useMemo(() => data.map((point, i) => `${i}:${point.datum}`).toString(), [data]);

  useEffect(() => {
    if (svgRef.current) {
      const imgElems = svgRef.current.querySelectorAll('image');
      imgElems.forEach((elem) => {
        elem.classList.remove('arrow');
      });
      requestAnimationFrame(() => {
        imgElems.forEach((elem) => {
          elem.classList.add('arrow');
        });
      });
    }
  }, [svgRef, dataKey]);

  const yScale = scaleLinear<number>({
    range: [0, radius],
    domain: yDomain,
  });

  const polygonPoints = genPolygonPoints(data, (d) => yScale(d) ?? 0);

  return (
    <svg
      ref={svgRef}
      className="target-graph"
      width={radius * 2}
      height={radius * 2}
      overflow="visible"
      stroke="black"
      strokeWidth="2"
    >
      <rect fill="none" stroke="none" width={radius * 2} height={radius * 2} rx={14} />
      <Group top={radius} left={radius}>
        {[5, 4, 3, 2, 1].map((i) => (
          <GridRadial
            key={`grid-rad-${i}`}
            scale={yScale}
            outerRadius={radius * (i / 5)}
            numTicks={1}
            strokeWidth={1}
            fill={mod(i, 2) === 0 ? '#FFFFFF' : '#FF6A6A'}
            strokeOpacity={0}
          />
        ))}
        {polygonPoints.points.map((point, i) => (
          <image
            className="arrow"
            key={`x-${i}`}
            href={xIcon}
            x={point.x - 4}
            y={point.y - 4}
            style={{ ...point.style, animationDelay: `${i / 10}s` }}
          />
        ))}
      </Group>
    </svg>
  );
}

export default TargetGraph;
