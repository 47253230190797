import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { AccessPermission, Assignment } from '../../types/types';
import TeacherSubmissionRatingScores from './TeacherSubmissionRatingScores';
import TeacherGradesBreakdown from './TeacherGradesBreakdown';
import { getPhaseStatus } from '../../utils/functions';
import TeacherEvaluationRatingScores from './TeacherEvaluationRatingScores';
import ScrollingTabList from '../core/layout/TabList/ScrollingTabList';
import TeacherOverviewMenu from './TeacherOverviewMenu';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { getAccessPermission } from '../../utils/requests';
import TeacherGroupFormationResults from './TeacherGroupFormationResults';

interface Props {
  assignment: Assignment;
}

export interface TeacherResultsTabProps extends Props {
  hideTabId: (id: string) => void;
  updateKey: number;
  updateData: () => void;
  accessPermission: AccessPermission;
}

function TeacherResultPage({ assignment }: Props): JSX.Element {
  const phaseStatus = useMemo(() => getPhaseStatus(assignment), [assignment]);

  const [updateKey, setUpdateKey] = useState(0);
  const [hiddenTabIds, setHiddenTabIds] = useState<string[]>([]);
  const user = useSelector((state: RootState) => state.user);
  const course = useSelector((state: RootState) => state.course);
  const [accessPermission, setAccessPermission] = useState<AccessPermission>();

  const updateData = useCallback(() => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  const hideTabId = useCallback((id: string) => setHiddenTabIds((prevIds) => [...prevIds, id]), []);

  const { asyncEnabled, status, progressStats, completionGrading } = assignment;

  useEffect(() => {
    if (user.userId && course?.courseId) {
      getAccessPermission(course?.courseId, setAccessPermission);
    }
  }, [course?.courseId, user.userId]);
  const resultsReady = asyncEnabled
    ? status !== 'UNPUBLISHED'
    : status === 'NEEDS_GRADING' ||
      status === 'COMPLETE' ||
      (assignment.reflectionEnabled && progressStats?.reflectionPhase);

  if (resultsReady && accessPermission?.viewStudentGradePermission) {
  const tabProps = {
    assignment,
    updateKey,
    updateData,
    hideTabId,
    accessPermission,
  };
  return (
    <div className="page teacher-results-row">
      <div className="teacher-results-container">
        <ScrollingTabList
          label="Results Menu"
          tabs={
            <>
              <ScrollingTabList.Tab id="overview" controls="overview-tab">
                Overview
              </ScrollingTabList.Tab>
              {resultsReady && phaseStatus.review && !hiddenTabIds.includes('submission-scores') ? (
                <ScrollingTabList.Tab id="submission-scores" controls="submission-scores-tab">
                  Submission Scores
                </ScrollingTabList.Tab>
              ) : null}
              {resultsReady && phaseStatus.evaluate && !hiddenTabIds.includes('eval-scores') ? (
                <ScrollingTabList.Tab id="eval-scores" controls="eval-scores-tab">
                  Evaluation Scores
                </ScrollingTabList.Tab>
              ) : null}
              {resultsReady && phaseStatus.groupFormation ? (
                <ScrollingTabList.Tab id="group-formation" controls="group-formation-tab">
                  Group Formation
                </ScrollingTabList.Tab>
              ) : null}
              {resultsReady && !completionGrading && (asyncEnabled || !progressStats?.reflectionPhase) ? (
                <ScrollingTabList.Tab id="breakdown" controls="breakdown-tab">
                  Grades Breakdown
                </ScrollingTabList.Tab>
              ) : null}
            </>
          }
        >
          <ScrollingTabList.TabPanel title="Overview" id="overview-tab" labeledBy="overview">
            <TeacherOverviewMenu resultsReady={resultsReady} {...tabProps} />
            {!resultsReady ? (
              <div id="no-results-container" className="fadeIn">
                <div id="grades-unavailable-panel" className="panel-white">
                  <h1>Grades Unavailable</h1>
                  <p>Grades will be available once the assignment is fully completed by your class.</p>
                </div>
              </div>
            ) : null}
          </ScrollingTabList.TabPanel>

          {resultsReady &&
          (phaseStatus.review || assignment.instructorGradedOnly) &&
          !hiddenTabIds.includes('submission-scores') && accessPermission.viewRubricResultAndComments ? (
            <ScrollingTabList.TabPanel
              title="Submission Scores"
              id="submission-scores-tab"
              labeledBy="submission-scores"
            >
              <TeacherSubmissionRatingScores {...tabProps} />
            </ScrollingTabList.TabPanel>
          ) : null}

          {resultsReady && phaseStatus.evaluate && !hiddenTabIds.includes('eval-scores') && accessPermission.viewRubricResultAndComments? (
            <ScrollingTabList.TabPanel title="Evaluation Scores" id="eval-scores-tab" labeledBy="eval-scores">
              <TeacherEvaluationRatingScores {...tabProps} />
            </ScrollingTabList.TabPanel>
          ) : null}

          {resultsReady && phaseStatus.groupFormation ? (
            <ScrollingTabList.TabPanel title="Group Formation" id="group-formation-tab" labeledBy="group-formation">
              <TeacherGroupFormationResults {...tabProps} />
            </ScrollingTabList.TabPanel>
          ) : null}

          {resultsReady && accessPermission.viewRubricResultAndComments && !completionGrading && (asyncEnabled || !progressStats?.reflectionPhase) ? (
            <ScrollingTabList.TabPanel title="Grades Breakdown" id="breakdown-tab" labeledBy="breakdown">
              <TeacherGradesBreakdown {...tabProps} />
            </ScrollingTabList.TabPanel>
          ) : null}
        </ScrollingTabList>
      </div>
    </div>
  );
}

  if (!accessPermission?.viewStudentGradePermission) {
    return (
      <div className="page teacher-results-row">
        <div id="no-results-container" className="fadeIn">
          <div id="grades-unavailable-panel" className="panel-white">
            <h1>Grades Unavailable</h1>
            <p>Only instructors have access to this page.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page teacher-results-row">
      <div id="no-results-container" className="fadeIn">
        <div id="grades-unavailable-panel" className="panel-white">
          <h1>Grades Unavailable</h1>
          <p>Grades will be available once the assignment is fully completed by your class.</p>
        </div>
      </div>
    </div>
  );
}

export default TeacherResultPage;