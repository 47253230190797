import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';
import { Course } from '../../../types/types';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Grading1Props {
  benchmarkGradingEnabled: boolean;
  teacherReviewOverridesStudents: boolean;
}

function GradingPage1({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Grading1Props>): JSX.Element {
  const course = useSelector(selectCourse) as Course;

  const [benchmarkGradingEnabled, setBenchmarkGradingEnabled] = useState(assignmentSettings.benchmarkGradingEnabled);
  const [teacherReviewOverridesStudents, setTeacherReviewOverridesStudents] = useState(
    assignmentSettings.teacherReviewOverridesStudents,
  );

  useEffect(() => {
    updateSettings({
      benchmarkGradingEnabled,
      teacherReviewOverridesStudents,
    });
  }, [updateSettings, benchmarkGradingEnabled, teacherReviewOverridesStudents]);

  return (
    <>
      <h3>Instructor Involvement</h3>
      <div className="sub-section">
        {!course.asyncEnabled &&
        !assignmentSettings.peerEvaluationOnly &&
        !assignmentSettings.instructorUpload &&
        !assignmentSettings.groupFormationDeadline ? (
          <fieldset>
            <legend>Grading Style</legend>
            <div className="rad-radio-btn">
              <input
                id="curved"
                type="radio"
                name="benchmarkGradingEnabled"
                value="false"
                checked={!benchmarkGradingEnabled}
                onChange={(e) => setBenchmarkGradingEnabled(e.target.value === 'true')}
              />
              <label htmlFor="curved">Curved</label>
            </div>
            <div className="rad-radio-btn">
              <input
                id="benchmarked"
                type="radio"
                name="benchmarkGradingEnabled"
                value="true"
                checked={benchmarkGradingEnabled}
                onChange={(e) => setBenchmarkGradingEnabled(e.target.value === 'true')}
              />
              <label htmlFor="benchmarked">Benchmarked</label>
            </div>
            {!benchmarkGradingEnabled ? (
              <p>
                Curved grading uses the mean and standard deviation set by the instructor to generate the submission and
                reviewing grades.
              </p>
            ) : (
              <p>
                Benchmarking requires the instructor to grade ten student submissions. These benchmarks are used to
                determine the grades for the rest of the submissions.{' '}
                <a
                  href="https://peerceptiv.zendesk.com/hc/en-us/articles/360019272514-Step-By-Step-Guide-to-Benchmark-Grading"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </p>
            )}
          </fieldset>
        ) : null}

        {!assignmentSettings.peerEvaluationOnly &&
        !assignmentSettings.instructorUpload &&
        !assignmentSettings.groupFormationEnabled ? (
          <fieldset>
            <legend>Instructor Review Impact</legend>
            <div className="rad-radio-btn">
              <input
                id="regularReview"
                type="radio"
                name="teacherReviewOverridesStudents"
                value="false"
                checked={!teacherReviewOverridesStudents}
                onChange={(e) => setTeacherReviewOverridesStudents(e.target.value === 'true')}
              />
              <label htmlFor="regularReview">Regular Review</label>
            </div>
            <div className="rad-radio-btn">
              <input
                id="overrideReviews"
                type="radio"
                name="teacherReviewOverridesStudents"
                value="true"
                checked={teacherReviewOverridesStudents}
                onChange={(e) => setTeacherReviewOverridesStudents(e.target.value === 'true')}
              />
              <label htmlFor="overrideReviews">Override Student Reviews</label>
            </div>
          </fieldset>
        ) : null}
      </div>
    </>
  );
}

export default GradingPage1;
