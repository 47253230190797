import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { lightenDarkenColor, mod, outsideClick } from '../../../../utils/functions';
import FocusTrap from 'focus-trap-react';
import Icon from '../Icon';
import _ from 'lodash';

interface CalendarCell {
  type: 'past' | 'current' | 'today' | 'future';
  year: string;
  month: string;
  date: string;
}

export type CalendarRange = {
  color: string;
  low: string; // YYYY-MM-DD
  high: string; // YYYY-MM-DD
  label: string;
};

interface Props {
  className?: string;
  focusTrap?: boolean;
  onOutsideClick?: () => void;
  onSelect?: (arg0: string) => void;
  ranges?: CalendarRange[];
}

function Calendar({
  className,
  focusTrap = false,
  onOutsideClick = () => undefined,
  onSelect = () => undefined,
  ranges,
}: Props): JSX.Element {
  const todaysMoment = moment();
  const todaysDate = todaysMoment.date();
  const todaysMonth = todaysMoment.month();
  const todaysYear = todaysMoment.year();

  const [month, setMonth] = useState(todaysMonth);
  const [year, setYear] = useState(todaysYear);
  const [focusableCellDate, setFocusableCellDate] = useState(
    moment(`${year}-${month + 1}-01`, 'YYYY-M-DD').format('YYYY-MM-DD'),
  );

  const calendarEl = useRef<HTMLDivElement>(null);

  const currMoment = moment(`${year}-${month + 1}-01`, 'YYYY-M-DD');

  const lastMonth = mod(month - 1, 12) + 1;
  const nextMonth = ((month + 1) % 12) + 1;
  const lastMonthsYear = lastMonth === 12 ? year - 1 : year;
  const nextMonthsYear = nextMonth === 1 ? year + 1 : year;

  const dayOfWeekOfTheFirst = currMoment.startOf('month').day();
  const daysThisMonth = currMoment.daysInMonth();
  const daysLastMonth = moment()
    .month((currMoment.month() - 1) % 12)
    .daysInMonth();

  const calendarDays: CalendarCell[][] = [[], [], [], [], [], []];
  let row = 0;

  for (let i = dayOfWeekOfTheFirst; i > 0; i--) {
    calendarDays[row].push({
      type: 'past',
      year: lastMonthsYear + '',
      month: twoDigitString(lastMonth + ''),
      date: twoDigitString(daysLastMonth + 1 - i + ''),
    });
  }

  for (let day = 1; day <= daysThisMonth; day++) {
    if (calendarDays[row].length >= 7) row++;
    const type = todaysDate === day && month === todaysMonth && year === todaysYear ? 'today' : 'current';
    calendarDays[row].push({
      type: type,
      year: year + '',
      month: twoDigitString(month + 1 + ''),
      date: twoDigitString(day + ''),
    });
  }

  if (calendarDays[row].length >= 7 && row < calendarDays.length - 1) row++;

  let nextMonthDay = 1;
  while (calendarDays[row].length < 7) {
    calendarDays[row].push({
      type: 'future',
      year: nextMonthsYear + '',
      month: twoDigitString(nextMonth + ''),
      date: twoDigitString(nextMonthDay + ''),
    });
    nextMonthDay++;
    if (calendarDays[row].length >= 7 && row < calendarDays.length - 1) row++;
  }

  const goToNextMonth = () => {
    const prevMonth = month;
    setMonth((prev) => (prev + 1) % 12);
    if (prevMonth >= 11) setYear((prev) => prev + 1);
  };

  const goToPrevMonth = () => {
    const prevMonth = month;
    setMonth((prev) => mod(prev - 1, 12));
    if (prevMonth <= 0) setYear((prev) => prev - 1);
  };

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (calendarEl.current && outsideClick(e, [calendarEl.current])) {
        onOutsideClick();
      }
    };
    window.addEventListener('mousedown', handleMouseDown);
    return () => window.removeEventListener('mousedown', handleMouseDown);
  }, [onOutsideClick]);

  useEffect(() => {
    if (calendarEl.current && ranges) {
      const cells = Array.from(calendarEl.current.querySelectorAll('TD'));

      cells.forEach((cell) => {
        cell.setAttribute('style', '');
        cell.querySelector('.pattern1')?.setAttribute('style', '');
        cell.querySelector('.pattern2')?.setAttribute('style', '');
        const cellDate = cell.id.slice(5);
        const cellMoment = moment(cellDate);
        cell.setAttribute('aria-label', `${cellMoment.format('dddd D MMMM, YYYY')};`);
        ranges.forEach((range, i) => {
          if (cellMoment.isBetween(range.low, range.high) || cellDate === range.low || cellDate === range.high) {
            cell.setAttribute('style', `color: black;`);
            cell.setAttribute('aria-label', `${cell.getAttribute('aria-label')} ${range.label};`);
            cell.querySelector('.pattern1')?.setAttribute('style', getCalendarPattern(i, range.color));
          }
        });
      });

      for (let i = 0; i < ranges.length - 1; i++) {
        const firstId = ranges[i].high;
        const secondId = ranges[i + 1].low;
        if (firstId === secondId) {
          const cuspMoment = moment(firstId);
          const color1 = ranges[i].color;
          const color2 = ranges[i + 1].color;
          const label1 = ranges[i].label;
          const label2 = ranges[i + 1].label;
          const splitCell = document.getElementById(`cell-${firstId}`);
          if (splitCell) {
            splitCell.setAttribute(
              'style',
              `background: linear-gradient(135deg, ${color1} 50%, ${color2} 50%); color: black;`,
            );
            splitCell.setAttribute(
              'aria-label',
              `${cuspMoment.format('dddd D MMMM, YYYY')}; ${label1} Deadline; ${label2} Begins;`,
            );
            splitCell
              .querySelector('.pattern1')
              ?.setAttribute('style', getCalendarPattern(i, color1) + 'clip-path: polygon(0 0, 0 100%, 100% 0);');
            splitCell
              .querySelector('.pattern2')
              ?.setAttribute(
                'style',
                getCalendarPattern(i + 1, color2) + 'clip-path: polygon(100% 100%, 0 100%, 100% 0);',
              );
          }
        }
      }
    }
  }, [ranges, month, focusTrap]);

  useEffect(() => {
    const currFocusedMoment = moment(focusableCellDate);
    if (currFocusedMoment.format('YYYY-M') !== `${year}-${month + 1}`) {
      setFocusableCellDate(moment(`${year}-${month + 1}-01`, 'YYYY-M-DD').format('YYYY-MM-DD'));
    }
  }, [month, year, focusableCellDate]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    let shouldPreventDefault = true;

    const diffMonth = (date1: string, date2: string): boolean => {
      return moment(date1).format('M') !== moment(date2).format('M');
    };

    const cell = e.target as HTMLTableCellElement;
    const currDate = cell.getAttribute('data-date') ?? '';

    const key = e.key;

    let newDate = '',
      newCell = null,
      diffCb: () => void = () => undefined;
    switch (key) {
      case 'ArrowUp':
        newDate = moment(currDate).subtract(1, 'week').format('YYYY-MM-DD');
        diffCb = goToPrevMonth;
        break;
      case 'ArrowDown':
        newDate = moment(currDate).add(1, 'week').format('YYYY-MM-DD');
        diffCb = goToNextMonth;
        break;
      case 'ArrowLeft':
        newDate = moment(currDate).subtract(1, 'day').format('YYYY-MM-DD');
        diffCb = goToPrevMonth;
        break;
      case 'ArrowRight':
        newDate = moment(currDate).add(1, 'day').format('YYYY-MM-DD');
        diffCb = goToNextMonth;
        break;
      case 'Home':
        newDate = moment(currDate).startOf('month').format('YYYY-MM-DD');
        break;
      case 'End':
        newDate = moment(currDate).endOf('month').format('YYYY-MM-DD');
        break;
      case 'PageUp':
        newDate = moment(currDate).subtract(1, 'month').format('YYYY-MM-DD');
        diffCb = goToPrevMonth;
        break;
      case 'PageDown':
        newDate = moment(currDate).add(1, 'month').format('YYYY-MM-DD');
        diffCb = goToNextMonth;
        break;
      case ' ':
      case 'Enter':
        onSelect(currDate);
        break;
      case 'Tab':
        if (!focusTrap) shouldPreventDefault = false;
    }

    if (shouldPreventDefault) e.preventDefault();

    if (newDate !== '') {
      newCell = document.getElementById(`cell-${newDate}`);
      if (newCell === null || diffMonth(currDate, newDate)) diffCb();
      setFocusableCellDate(newDate);
    }
  };

  useEffect(() => {
    const cell = document.getElementById(`cell-${focusableCellDate}`);
    if (cell) cell.focus();
  }, [focusableCellDate]);

  return (
    <div
      ref={calendarEl}
      className={'calendar' + (className ? ` ${className}` : '')}
      role="dialog"
      tabIndex={0}
      aria-label="Calendar"
      onKeyDown={(e) => {
        if (e.key === 'Escape') onOutsideClick();
      }}
    >
      <FocusController focusTrap={focusTrap}>
        <div>
          <div className="ctrls" role="menubar">
            <button
              aria-label="Previous Month"
              className="button-mini"
              type="button"
              role="menuitem"
              onClick={goToPrevMonth}
            >
              <Icon code="chevron_left" ariaHidden />
            </button>
            <span>{currMoment.format('MMM YYYY')}</span>
            <button
              aria-label="Next Month"
              className="button-mini"
              type="button"
              role="menuitem"
              onClick={goToNextMonth}
            >
              <Icon code="chevron_right" ariaHidden />
            </button>
          </div>
          <table role="grid" onKeyDown={handleKeyDown}>
            <thead>
              <tr role="row">
                <th className="sr-only" aria-label="Week Number" role="columnheader">
                  <span aria-hidden>Week</span>
                </th>
                <th aria-label="Sunday" role="columnheader">
                  <span aria-hidden>Sun</span>
                </th>
                <th aria-label="Monday" role="columnheader">
                  <span aria-hidden>Mon</span>
                </th>
                <th aria-label="Tuesday" role="columnheader">
                  <span aria-hidden>Tue</span>
                </th>
                <th aria-label="Wednesday" role="columnheader">
                  <span aria-hidden>Wed</span>
                </th>
                <th aria-label="Thursday" role="columnheader">
                  <span aria-hidden>Thu</span>
                </th>
                <th aria-label="Friday" role="columnheader">
                  <span aria-hidden>Fri</span>
                </th>
                <th aria-label="Saturday" role="columnheader">
                  <span aria-hidden>Sat</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {calendarDays.map((week, i) => {
                return (
                  <tr key={`cal-row-${i}`} role="row">
                    <th className="sr-only" role="rowheader">
                      {moment(`${week[0].year}-${week[0].month}-${week[0].date}`).format('w')}
                    </th>
                    {week.map((day, j) => {
                      const date = `${day.year}-${day.month}-${day.date}`;
                      return (
                        <td
                          tabIndex={focusableCellDate === date ? 0 : -1}
                          aria-selected={focusableCellDate === date ? true : undefined}
                          key={`cal-day-${j}`}
                          id={`cell-${date}`}
                          className={day.type}
                          aria-current={day.type === 'today' ? true : undefined}
                          // aria-label={moment(date).format('dddd D MMMM, YYYY')}
                          data-date={date}
                          onClick={() => {
                            onSelect(date);
                          }}
                        >
                          <span>{day.date}</span>
                          <div className="pattern1" />
                          <div className="pattern2" />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </FocusController>
    </div>
  );
}

interface FocusControllerProps {
  children: React.ReactNode;
  focusTrap: boolean;
}

function FocusController({ children, focusTrap }: FocusControllerProps): JSX.Element {
  if (focusTrap) return <FocusTrap>{children}</FocusTrap>;
  return <>{children}</>;
}

const twoDigitString = (str: string): string => {
  return str.length < 2 ? '0' + str : str;
};

const patternGetters: ((color: string) => string)[] = [
  (color: string) => {
    const lighterColor = lightenDarkenColor(color, 20);
    return `background: linear-gradient(0deg, ${lighterColor} 50%, ${color} 50%); background-size: 8px 8px;`;
  },
  (color: string) => {
    const lighterColor = lightenDarkenColor(color, 20);
    return `background: repeating-linear-gradient(45deg, ${color}, ${color} 4px, ${lighterColor} 4px, ${lighterColor} 8px);`;
  },
  (color: string) => {
    const lighterColor = lightenDarkenColor(color, 20);
    return `background: linear-gradient(90deg, ${lighterColor} 50%, ${color} 50%); background-size: 8px 8px;`;
  },
  (color: string) => {
    const lighterColor = lightenDarkenColor(color, 20);
    return `background: repeating-linear-gradient(-45deg, ${color}, ${color} 4px, ${lighterColor} 4px, ${lighterColor} 8px);`;
  },
  (color: string) => {
    const lighterColor = lightenDarkenColor(color, 20);
    return `background: repeating-linear-gradient(-45deg, ${color}, ${color} 4px, ${lighterColor} 4px, ${lighterColor} 8px);`;
  },
];

export const getCalendarPattern = (i: number, color: string) => {
  return patternGetters[mod(i, patternGetters.length)](color);
};

export const getCalendarPatternCssProps = (i: number, color: string) => {
  const style: React.CSSProperties = {};
  const styleString = patternGetters[mod(i, patternGetters.length)](color);
  styleString.split(';').forEach((styleLine) => {
    const parts = styleLine.split(': ');
    if (parts.length === 2) {
      const styleAttribute = parts[0];
      style[_.camelCase(styleAttribute) as 'background' | 'backgroundSize'] = parts[1];
    }
  });
  return style;
};

export default Calendar;
