import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Assignment } from '../../../types/types';
import Calendar, { CalendarRange } from './Calendar/Calendar';

interface Props {
  assignment: Assignment;
  timezone: string;
}

function AssignmentCalendar({ assignment, timezone }: Props): JSX.Element {
  const timezoneConvert = useCallback((date: string) => moment(date).tz(timezone).format('YYYY-MM-DD'), [timezone]);

  const deadlines = useMemo(() => {
    const deadlines = [];
    deadlines.push({
      date: timezoneConvert(assignment.publicationTime),
      title: 'Publication Date',
      color: '#B8DCFF',
    });
    if (assignment.groupFormationDeadline !== null)
      deadlines.push({
        date: timezoneConvert(assignment.groupFormationDeadline),
        title: 'Group Formation Phase',
        color: '#7878F1',
      });
    if (assignment.submissionDeadline !== null)
      deadlines.push({
        date: timezoneConvert(assignment.submissionDeadline),
        title: 'Submission Phase',
        color: '#7878F1',
      });
    if (assignment.reviewDeadline !== null)
      deadlines.push({
        date: timezoneConvert(assignment.reviewDeadline),
        title: 'Review Phase',
        color: '#E676E3',
      });
    if (assignment.feedbackDeadline !== null)
      deadlines.push({
        date: timezoneConvert(assignment.feedbackDeadline),
        title: 'Feedback Phase',
        color: '#E4C445',
      });
    if (assignment.peerEvaluationDeadline !== null)
      deadlines.push({
        date: timezoneConvert(assignment.peerEvaluationDeadline),
        title: 'Evaluation Phase',
        color: '#55C92D',
      });
    if (assignment.asyncEndDeadline !== null && assignment.asyncEndDeadline !== undefined)
      deadlines.push({
        date: timezoneConvert(assignment.asyncEndDeadline),
        title: 'Async Assignment',
        color: '#7878F1',
      });
    return deadlines;
  }, [assignment, timezoneConvert]);

  const ranges: CalendarRange[] = useMemo(() => {
    return deadlines.length === 1
      ? [{ color: deadlines[0].color, low: deadlines[0].date, high: deadlines[0].date, label: deadlines[0].title }]
      : deadlines.slice(1).map((deadline, i) => ({
          color: deadline.color,
          low: deadlines[i].date,
          high: deadline.date,
          label: deadline.title,
        }));
  }, [deadlines]);

  return <Calendar ranges={ranges} />;
}

export default AssignmentCalendar;
