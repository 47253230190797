import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { Course } from '../../types/types';
import { getCourseTemplates } from '../../utils/requests';
import Icon from '../core/display/Icon';
import QueryTable, {  Column } from '../core/display/QueryTable/QueryTable';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../core/button/Dropdown/Dropdown';

function CourseTemplates(): JSX.Element {
  const navigate = useNavigate();

  const tableColumns = useMemo<Column<Course>[]>(
    () => [
      { Header: 'Course Name', accessor: 'courseName', className: 'left-align' },
      {
        Header: 'Created By',
        accessor: 'user',
        className: 'left-align',
        Cell: (course: Course) => (course.user?.admin ? 'Peerceptiv' : course.user?.name),
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (course: Course) => moment(course.createdAt).format('MMM DD, YYYY'),
      },
      {
        Header: 'Type',
        accessor: 'asyncEnabled',
        className: 'center-align',
        Cell: function render(course: Course) {
          if (course.asyncEnabled) return <Icon className="async-icon" code="timer_off" label="Asynchronous" tooltip />;
          return <Icon className="sync-icon" code="update" label="Synchronous" tooltip />;
        },
      },
    ],
    [],
  );

  const handleRowSelect = useCallback(
    (course: Course) => navigate(`/course/${course.courseId}/assignments`),
    [navigate],
  );

  return (
    <section className="home-body" id="course-menu">
      <h1 className="sr-only">Course Templates</h1>
      <QueryTable
        title="Course Templates"
        id="course-list-table"
        ctrlsInsert={
          <div className="col-2">
            <Dropdown
              className="peer-button button-rad button-low"
              id="new-course-template-dropdown"
              buttonContent="New Template"
              iconCode="arrow_drop_down"
              align="left"
              top="100%"
            >
              <Dropdown.Link href="/course/templates/new" route>
                Create Template
              </Dropdown.Link>
              <Dropdown.Link href="/course/templates/course-copy-list" route>
                Copy From Course
              </Dropdown.Link>
            </Dropdown>
          </div>
        }
        columns={tableColumns}
        queryRequest={getCourseTemplates}
        onRowSelect={handleRowSelect}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsWrapperClassName: 'col-2',
          tableClassName: 'home-body',
        }}
      />
    </section>
  );
}

export default CourseTemplates;
