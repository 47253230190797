import React, { useRef } from 'react';
import _ from 'lodash';

/**
 * Source: https://kittygiraudel.com/2021/04/05/an-accessible-toggle/
 */

interface Props {
  checked?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  hideLabel?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

function Toggle({ checked, children, disabled, hideLabel=false, onChange }: Props): JSX.Element {
  const id = useRef(_.uniqueId('searchbar-'));

  return (
    <label className="Toggle" htmlFor={`toggle-${id.current}`}>
      <input
        type="checkbox"
        name="toggle"
        id={`toggle-${id}`}
        className="Toggle__input"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <span className="Toggle__display">
        <svg
          aria-hidden="true"
          focusable="false"
          className="Toggle__icon Toggle__icon--checkmark"
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.08471 10.6237L2.29164 6.83059L1 8.11313L6.08471 13.1978L17 2.28255L15.7175 1L6.08471 10.6237Z"
            fill="currentcolor"
            stroke="currentcolor"
          />
        </svg>
        <svg
          aria-hidden="true"
          focusable="false"
          className="Toggle__icon Toggle__icon--cross"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.167 0L6.5 4.667L1.833 0L0 1.833L4.667 6.5L0 11.167L1.833 13L6.5 8.333L11.167 13L13 11.167L8.333 6.5L13 1.833L11.167 0Z"
            fill="currentcolor"
          />
        </svg>
      </span>
      <span className={hideLabel? "sr-only":undefined}>{children}</span>
    </label>
  );
}

export default Toggle;
