import React, { useState, useCallback, useEffect, useRef } from 'react';
import { LoginResponse, MfaCredentials, MfaEnrollment } from '../../types/types';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import Button from '../core/button/Button/Button';
import { getPropsFromQuery, formDataToObject, setPageTitle, storageAvailable, copyTextToClipboard } from '../../utils/functions';
import { useParams } from 'react-router-dom';
import { mfaEnrollCode, mfaVerify } from '../../utils/requests';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../actions';
import { LOGIN_RETURN_PATH_KEY } from '../../utils/constants';
import { RootState } from '../../store';

function MfaEnrollmentPage(): JSX.Element {
  const { token } = useParams() as { token?: string };
  const [validCode, setValidCode] = useState(true);
  const [mfaSecret, setMfaSecret] = useState('');
  const redirectPending = useRef(false);
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => setPageTitle('Enable MFA'), []);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement);
      const mfaCredentials = formDataToObject(formData) as MfaCredentials;
      mfaVerify(
        mfaCredentials,
        (loginResponse: LoginResponse) => {
          if(loginResponse.user){
            setValidCode(true);
            dispatch(setUser(loginResponse.user));
            const returnPath = storageAvailable('sessionStorage')
              ? window.sessionStorage.getItem(LOGIN_RETURN_PATH_KEY)
              : '/';
            if (loginResponse.user.forcePasswordChange) {
              navigate(`/reset_password`);
            } else if (returnPath && returnPath !== '/login') {
              if (storageAvailable('sessionStorage')) window.sessionStorage.clear();
              navigate(returnPath);
            }
            else {
              navigate('/profile/details');
            }
            redirectPending.current = true;
          }          
        },
        (error) => {
          switch (error.response?.status) {
            case 403:
              navigate('/');
              return true;
            case 401:
            case 400:
              setValidCode(false);
              return true;
            default:
              return false;
          }
        },
      );
    },
    [dispatch, navigate],
  );

  const cancelEnrollment = useCallback(() => {
    if(user.requireMfaEnrollment)
      navigate('/logout');
    else
      navigate('/profile/manage-mfa')

  }, [navigate]);

  const enrollMfa = async () => {
    mfaEnrollCode((mfaEnrollment: MfaEnrollment) => {
      if(mfaEnrollment){
        const secretDisplay = document.getElementById('mfa-secret-copy-display') as HTMLInputElement;
        const qrCodeImage = document.getElementById('mfa-qr-code-image') as HTMLImageElement;
      
        if (secretDisplay && qrCodeImage ) {
          secretDisplay.value = mfaEnrollment.mfaSecret?? '';
          qrCodeImage.src = mfaEnrollment.qrCodeUrl?? '';
          setMfaSecret(mfaEnrollment.mfaSecret?? '');
        }
      }
    });
  };
  
  useEffect(() => {
    enrollMfa();
  }, []);

  return (
    <div className="page login-row" id='mfa-enrollment-page'>
      <img src={logoSrc} width="256" alt="Peerceptiv logo" />
      <div className="panel-white login-container">
        <h3 className='prompt-text'>Scan This QR Code With Your MFA App</h3>
        <p className='prompt-text'>Peerceptiv recommends <a href='https://authy.com'>Authy</a></p>
        <form onSubmit={handleSubmit}>
          <label className="sr-only" htmlFor="mfaCode">
            Verification Code from your app:
          </label>
          <img id='mfa-qr-code-image' alt="TOTP QR Code" className='static-image'/>
          <p className='prompt-text'>Or Copy and Paste This Key</p>
          <div className="code-wrapper" id='mfa-copy-code-wrapper'>
            <input type="text" id="mfa-secret-copy-display" name="mfa-secret" placeholder="Secret" readOnly />
            <Button className="button-mini" type="button" aria-expanded="false" tooltip="Copy Code" classOverride onClick={() => copyTextToClipboard(mfaSecret)}>
              <i className="material-icons ">content_copy</i>
            </Button>
          </div>
          <hr />
          <p className='prompt-text'>Enter a code from your app to verify setup:</p>
          <input type="text" id="mfaCode" name="mfaCode" placeholder="Verification Code" autoComplete="one-time-code" required={true} />
          {validCode ? null : (
            <p className="invalid-log" role="alert">
              Invalid Code. Please try again.
            </p>
          )}
          <Button>
            <h1>Verify</h1>
          </Button>
        </form>
        <Button
           variant="secondary"
           onClick={cancelEnrollment}
         >
           Cancel
        </Button>
      </div>
    </div>
  );
}

export default MfaEnrollmentPage;
