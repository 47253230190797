import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Button from '../core/button/Button/Button';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

interface Props {
  requestRejectGroupInvite: (assignmentId: string, groupInviteId: string, callback?: () => void) => void;
}

function InviteReject({ requestRejectGroupInvite }: Props): JSX.Element {
  const { courseId, assignmentId, groupInviteId } = useParams() as {
    courseId: string;
    assignmentId: string;
    groupInviteId: string;
  };
  const returnPath = `/course/${courseId}/assignment/${assignmentId}/groups/my-group`;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    requestRejectGroupInvite(assignmentId, groupInviteId, () => {
      setSuccess(true);
      setLoading(false);
    });
  }, [assignmentId, groupInviteId, requestRejectGroupInvite]);

  if (!loading) {
    if (success) navigate(`${returnPath}?status=reject`);
    else
      return (
        <div>
          <h1>Something Went Wrong</h1>
          <p>Failed to reject group invite</p>
          <Button href={returnPath} route>
            Go to Groups Page
          </Button>
        </div>
      );
  }
  return <LoadingSpinner />;
}

export default InviteReject;
