import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { AccessPermission, AssignmentProgress, User } from '../../../types/types';
import { getAccessPermission, getUserAssignmentProgress } from '../../../utils/requests';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import TabList from '../../core/layout/TabList/TabList';
import Avatar from '../../core/display/Avatar/Avatar';
import Button from '../../core/button/Button/Button';
import { getPhaseStatus, PhaseStatus, setPageTitle } from '../../../utils/functions';
import ExpandedStudentRow from '../table_menus/ExpandedStudentRow';
import Icon from '../../core/display/Icon';
import JumpButton from '../../core/button/JumpButton';
import SubmissionDetails from './SubmissionDetails';
import ReviewsReceivedDetails from './ReviewsReceivedDetails';
import ReviewsSentDetails from './ReviewsSentDetails';
import EvalsReceivedDetails from './EvalsReceivedDetails';
import EvalsSentDetails from './EvalsSentDetails';
import GradeDetails from './GradeDetails';
import StudentReflectionResults from '../../results/StudentReflectionResults';
import GroupDetails from './GroupDetails';

export interface StudentDetailsTabProps {
  assignmentProgress: AssignmentProgress;
  phaseStatus: PhaseStatus;
  updateData: () => void;
  updateKey: number;
  accessPermission?: AccessPermission;
}

function StudentDetailsPage(): JSX.Element {
  useEffect(() => setPageTitle('Student Details'), []);
  const { assignmentId, userId } = useParams() as { courseId: string; assignmentId: string; userId: string };
  const [assignmentProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const [updateKey, setUpdateKey] = useState(0);
  const [accessPermission, setAccessPermission] = useState<AccessPermission>();
  const course = useSelector((state: RootState) => state.course);
  const user = useSelector((state: RootState) => state.user);

  const phaseStatus = useMemo(
    () => (assignmentProgress ? getPhaseStatus(assignmentProgress.assignment) : null),
    [assignmentProgress],
  );

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  useEffect(
    () => getUserAssignmentProgress(assignmentId, userId, setAssignmentProgress),
    [updateKey, assignmentId, userId],
  );

  useEffect(() => {
    if (user.userId && course?.courseId) {
      getAccessPermission(course?.courseId, setAccessPermission);
    }
  }, [course?.courseId, user.userId]);

  if (user.courseRole === 'STUDENT') {
    window.location.replace('/');
    return <LoadingSpinner />;
  }

  if (assignmentProgress && phaseStatus) {
    const { user: student, assignment, status } = assignmentProgress;
    const tabProps: StudentDetailsTabProps = {
      assignmentProgress: assignmentProgress,
      updateKey: updateKey,
      updateData: updateData,
      phaseStatus: phaseStatus,
      accessPermission: accessPermission,
    };

    return (
      <div id="student-details-page" className="page">
        <div id="student-card" className="panel-sm panel-white">
          <Avatar user={student} size={64} />
          <div className="info-wrapper">
            <h1>{student.name}</h1>
            <a href={`mailto:${student.email}`}>{student.email}</a>
          </div>
        </div>

        <Button id="back-btn" variant="rad alt" onClick={() => window.history.back()}>
          <Icon code="chevron_left" ariaHidden />
          Back
        </Button>

        <TabList
          label="Roster Menu"
          tabs={
            <>
              <TabList.Tab id="overview" controls="overview-tab">
                Overview
              </TabList.Tab>
              {status.hasResult && !assignment.completionGrading && accessPermission?.viewStudentGradePermission ? (
                <TabList.Tab id="grades" controls="grades-tab">
                  Grades
                </TabList.Tab>
              ) : null}
              {assignment.groupsEnabled ? (
                <TabList.Tab id="group" controls="group-tab">
                  Group
                </TabList.Tab>
              ) : null}
              {phaseStatus.submission ? (
                <TabList.Tab id="submission" controls="submission-tab">
                  Submission
                </TabList.Tab>
              ) : null}
              {(phaseStatus.review || assignment.instructorGradedOnly) &&
              accessPermission?.viewRubricResultAndComments ? (
                <>
                  {!assignment.instructorUpload ? (
                    <TabList.Tab id="reviews-received" controls="reviews-received-tab">
                      Reviews Received
                    </TabList.Tab>
                  ) : null}

                  {!assignment.instructorGradedOnly ? (
                    <TabList.Tab id="reviews-sent" controls="reviews-sent-tab">
                      Reviews Sent
                    </TabList.Tab>
                  ) : null}
                </>
              ) : null}
              {phaseStatus.evaluate &&
              accessPermission?.viewRubricResultAndComments ? (
                <>
                  <TabList.Tab id="evals-received" controls="evals-received-tab">
                    Team Member Evaluations Received
                  </TabList.Tab>
                  <TabList.Tab id="evals-sent" controls="evals-sent-tab">
                    Team Member Evaluations Sent
                  </TabList.Tab>
                </>
              ) : null}

              {phaseStatus.reflection&&
              accessPermission?.viewRubricResultAndComments ? (
                <>
                  <TabList.Tab id="reflection" controls="reflection-tab">
                    Reflection
                  </TabList.Tab>
                </>
              ) : null}
            </>
          }
        >
          <TabList.TabPanel id="overview-tab" labeledBy="overview">
            <div id="overview-card" className="panel-sm panel-white">
              <ExpandedStudentRow {...tabProps} />
            </div>
          </TabList.TabPanel>

          {status.hasResult && !assignment.completionGrading && accessPermission?.viewStudentGradePermission ? (
            <TabList.TabPanel id="grades-tab" labeledBy="grades">
              <GradeDetails {...tabProps} />
            </TabList.TabPanel>
          ) : null}

          {assignment.groupsEnabled ? (
            <TabList.TabPanel id="group-tab" labeledBy="group">
              <GroupDetails {...tabProps} />
            </TabList.TabPanel>
          ) : null}

          {phaseStatus.submission ? (
            <TabList.TabPanel id="submission-tab" labeledBy="submission">
              <SubmissionDetails {...tabProps} />
            </TabList.TabPanel>
          ) : null}

          {phaseStatus.review && accessPermission?.viewRubricResultAndComments ? (
            <>
              <TabList.TabPanel id="reviews-received-tab" labeledBy="reviews-received">
                <ReviewsReceivedDetails {...tabProps} />
              </TabList.TabPanel>

              <TabList.TabPanel id="reviews-sent-tab" labeledBy="reviews-sent">
                <ReviewsSentDetails {...tabProps} />
              </TabList.TabPanel>
            </>
          ) : null}

          {phaseStatus.evaluate &&
          accessPermission?.viewRubricResultAndComments ? (
            <>
              <TabList.TabPanel id="evals-received-tab" labeledBy="evals-received">
                <EvalsReceivedDetails {...tabProps} />
              </TabList.TabPanel>

              <TabList.TabPanel id="evals-sent-tab" labeledBy="evals-sent">
                <EvalsSentDetails {...tabProps} />
              </TabList.TabPanel>
            </>
          ) : null}

          {phaseStatus.reflection &&
          accessPermission?.viewRubricResultAndComments ? (
            <>
              <TabList.TabPanel id="reflection-tab" labeledBy="reflection">
                <div className="reflection-container">
                  <StudentReflectionResults {...tabProps} userId={student.userId} />
                </div>
              </TabList.TabPanel>
            </>
          ) : null}
        </TabList>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export const getCompletionStatus = (complete: boolean): JSX.Element =>
  complete ? (
    <span className="complete-status">
      <span>Complete</span> <Icon code="done" ariaHidden />
    </span>
  ) : (
    <span className="incomplete-status">
      <span>Incomplete</span> <Icon code="close" ariaHidden />
    </span>
  );

export const getJumpButtons = (targetId: string, text: string): JSX.Element => (
  <>
    <JumpButton type="focus-child" targetId={targetId} invisible>
      {text}
    </JumpButton>
    <JumpButton type="scroll" variant="rad low sm" targetId={targetId} ariaHidden>
      {text}
    </JumpButton>
  </>
);

export default StudentDetailsPage;
