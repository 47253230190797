import React, { useState, useEffect } from 'react';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Review2Props {
  allowBonusReviews: boolean;
  reviewingWithoutSubmission: boolean;
  selfReviewEnabled: boolean;
}

function ReviewPage2({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Review2Props>): JSX.Element {
  const [allowBonusReviews, setAllowBonusReviews] = useState(assignmentSettings.allowBonusReviews);
  const [reviewingWithoutSubmission, setReviewWithoutSubmission] = useState(
    assignmentSettings.reviewingWithoutSubmission,
  );
  const [selfReviewEnabled, setSelfReviewEnabled] = useState(assignmentSettings.selfReviewEnabled);

  useEffect(() => {
    updateSettings({ allowBonusReviews, reviewingWithoutSubmission, selfReviewEnabled });
  }, [updateSettings, allowBonusReviews, reviewingWithoutSubmission, selfReviewEnabled]);

  return (
    <>
      <p>Advanced - If unsure, leave these settings as default.</p>
      {!assignmentSettings.liveMode? (
        <div>
        <input
          id="allowBonusReviews"
          type="checkbox"
          name="allowBonusReviews"
          checked={allowBonusReviews}
          onChange={(e) => {
            setAllowBonusReviews(e.target.checked);
          }}
        />
        <label htmlFor="allowBonusReviews">Allow extra, bonus reviews</label>
      </div>

      ):null}
      <div>
        <input
          id="reviewingWithoutSubmission"
          type="checkbox"
          name="reviewingWithoutSubmission"
          checked={reviewingWithoutSubmission}
          onChange={(e) => {
            setReviewWithoutSubmission(e.target.checked);
          }}
        />
        <label htmlFor="reviewingWithoutSubmission">Allow students who haven&apos;t submitted to peer review</label>
      </div>

      <div>
        <input
          id="selfReviewEnabled"
          type="checkbox"
          name="selfReviewEnabled"
          checked={selfReviewEnabled}
          onChange={(e) => {
            setSelfReviewEnabled(e.target.checked);
          }}
        />
        <label htmlFor="selfReviewEnabled">Require students perform a self-review</label>
      </div>
    </>
  );
}

export default ReviewPage2;
