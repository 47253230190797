import React, { useRef, useEffect } from 'react';

interface Props {
  passwordMismatch: boolean;
  reset?: boolean;
  setPasswordMismatch: React.Dispatch<React.SetStateAction<boolean>>;
}

function PasswordInput({ passwordMismatch, reset = false, setPasswordMismatch }: Props): JSX.Element {
  const passwordInputEl1 = useRef<HTMLInputElement>(null);
  const passwordInputEl2 = useRef<HTMLInputElement>(null);

  const passwordOnChange = () => {
    if (passwordInputEl1.current && passwordInputEl2.current) {
      const pwd1 = passwordInputEl1.current.value;
      const pwd2 = passwordInputEl2.current.value;
      if (pwd1 === pwd2) setPasswordMismatch(false);
    }
  };

  const checkPasswordMatch = () => {
    if (passwordInputEl1.current && passwordInputEl2.current) {
      const pwd1 = passwordInputEl1.current.value;
      const pwd2 = passwordInputEl2.current.value;
      if (pwd1 === pwd2) {
        setPasswordMismatch(false);
      } else if (pwd1 !== '' && pwd2 !== '') {
        setPasswordMismatch(true);
      }
    }
  };

  useEffect(() => {
    if (passwordInputEl1.current && passwordInputEl2.current) {
      if (passwordMismatch) {
        passwordInputEl1.current.style.borderColor = 'red';
        passwordInputEl2.current.style.borderColor = 'red';
      } else {
        passwordInputEl1.current.style.borderColor = '';
        passwordInputEl2.current.style.borderColor = '';
      }
    }
  }, [passwordMismatch]);

  const togglePassword = (passwordInputEl: HTMLInputElement) => {
    const togglePasswordButton = passwordInputEl.nextElementSibling;
    if (togglePasswordButton)
      if (passwordInputEl.type === 'password') {
        passwordInputEl.type = 'text';
        togglePasswordButton.textContent = 'visibility_off';
        togglePasswordButton.setAttribute('aria-label', 'Hide password.');
      } else {
        passwordInputEl.type = 'password';
        togglePasswordButton.textContent = 'visibility';
        togglePasswordButton.setAttribute(
          'aria-label',
          'Show password as plain text. Warning: this will display your password on the screen.',
        );
      }
  };

  return (
    <>
      <label className="sr-only" htmlFor="new-password">
        {reset ? 'New Password:' : 'Password:'}
      </label>
      <div className="password-wrapper">
        <input
          ref={passwordInputEl1}
          type="password"
          id="new-password"
          name="password"
          placeholder={reset ? 'New Password' : 'Password'}
          autoComplete="new-password"
          required={true}
          onBlur={checkPasswordMatch}
          onChange={passwordOnChange}
          minLength={8}
          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
          aria-describedby="password-constraints"
        />
        <button
          className="toggle-password material-icons"
          type="button"
          aria-label="Show password as plain text. Warning: this will display your password on the screen."
          onClick={() => {
            if (passwordInputEl1.current) togglePassword(passwordInputEl1.current);
          }}
        >
          visibility
        </button>
        <div className="sr-only" id="password-constraints">
          Eight or more characters with a mix of letters, numbers and symbols.
        </div>
      </div>
      <label className="sr-only" htmlFor="repeatPassword">
        {reset ? 'Confirm New Password:' : 'Confirm Password:'}
      </label>
      <div className="password-wrapper">
        <input
          ref={passwordInputEl2}
          type="password"
          id="repeatPassword"
          name="repeatPassword"
          placeholder={reset ? 'Re-type New Password' : 'Re-type Password'}
          required={true}
          onBlur={checkPasswordMatch}
          onChange={passwordOnChange}
          minLength={5}
        />
        <button
          className="toggle-password material-icons"
          type="button"
          aria-label="Show password as plain text. Warning: this will display your password on the screen."
          onClick={() => {
            if (passwordInputEl2.current) togglePassword(passwordInputEl2.current);
          }}
        >
          visibility
        </button>
      </div>
    </>
  );
}

export default PasswordInput;
