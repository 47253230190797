import React, { useState, useEffect, useCallback } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { AccessPermission, CourseUser } from '../../types/types';
import Table from '../core/display/Table/Table';
import ExpandedRosterRow from './table_menus/ExpandedRosterRow';
import { ColumnDef } from '@tanstack/react-table';
import { capitalizeWords } from '../../utils/functions';

interface Props {
  instructorData: CourseUser[];
  showAddModal: () => void;
  updateData: () => void;
  accessPermission?: AccessPermission;
}

type InstructorRosterData = {
  courseId: string;
  email: string;
  role: string;
  name: string;
  userId: string;
};

function InstructorsTable({ instructorData, showAddModal, updateData, accessPermission }: Props): JSX.Element {
  const [tableData, setTableData] = useState<InstructorRosterData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<InstructorRosterData>[]>([]);

  const { modalDispatch } = useModalContext();

  const handleRowSelect = useCallback(
    (userId: string, courseId: string) => {
      modalDispatch(
        openModal({
          heading: 'Instructor Details',
          headingHide: true,
          inputType: 'none',
          form: false,
          closeButton: true,
          noActionButtons: true,
          children: (
            <ExpandedRosterRow
              userId={userId}
              courseId={courseId}
              enableRemove={instructorData.length >= 2}
              updateData={updateData}
              accessPermission={accessPermission}
            />
          ),
        }),
      );
    },
    [accessPermission, instructorData.length, modalDispatch, updateData],
  );

  useEffect(() => {
    const columns: ColumnDef<InstructorRosterData>[] = [
      { header: 'Name', accessorKey: 'name', meta: { className: 'left-align' } },
      { header: 'Role', accessorKey: 'role', meta: { className: 'left-align' }},
      { header: 'Email', accessorKey: 'email', meta: { className: 'left-align' } },
    ];
    const dataTable: InstructorRosterData[] = [];
    instructorData.forEach((instructor: CourseUser) => {
      const newRow: InstructorRosterData = {
        name: instructor.user.sortableName,
        email: instructor.user.email,
        role: capitalizeWords(instructor.role),
        userId: instructor.userId,
        courseId: instructor.course.courseId as string,
      };

      dataTable.push(newRow);
    });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [instructorData]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="name"
      title="Instructor List"
      headingLevel={1}
      id="instructor-roster-card"
      informOfRow={(row) => handleRowSelect(row.original.userId, row.original.courseId)}
      onAdd={showAddModal}
    />
  );
}

export default InstructorsTable;
