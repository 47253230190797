import React from 'react'
import Sidebar from '../../core/layout/Sidebar/Sidebar';

function AdminPurchasingSidebar():JSX.Element{
    return (
      <Sidebar
        title={<span id="assignment-lbl">Admin Purchasing</span>}
        subtitle={''}
        titleHref={``}
        menuLabel="Admin Purchasing Menu"
      >
        <Sidebar.Link href={`/admin/purchasing/management`} iconCode="assignment">
          Contracts
        </Sidebar.Link>
        <Sidebar.Link href={`/admin/purchasing/log`} iconCode="content_paste">
          Purchase Logs
        </Sidebar.Link>
      </Sidebar>
    );
}

export default AdminPurchasingSidebar