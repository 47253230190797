import React from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';

interface Props {
  instructorGradedOnly: boolean;
  setInstructorGradedOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReviewSetup({ instructorGradedOnly, setInstructorGradedOnly }: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>
          Review
          <HelpTag type="modal" heading="Review Phase" margin="0 1rem" emphasize>
            <p>
              In the second phase of a Peerceptiv peer assessment, students and/or instructors <i>review</i> the
              submissions using a rubric. Assignments reviewing options are for peer reviews, where students and
              instructors can review or instructor-graded only, where only the instructor reviews. Instructor-graded
              only assignments are rare and only used for final drafts or optional assignments.
            </p>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="col-inputs">
          <legend>How are grades generated?</legend>
          <div className="rad-radio-btn">
            <input
              id="reviewGraded"
              type="radio"
              name="instructorGradedOnly"
              value="false"
              checked={!instructorGradedOnly}
              onChange={(e) => setInstructorGradedOnly(e.target.value === 'true')}
            />
            <label htmlFor="reviewGraded">Peer Reviews</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="teacherGraded"
              type="radio"
              name="instructorGradedOnly"
              value="true"
              checked={instructorGradedOnly}
              onChange={(e) => setInstructorGradedOnly(e.target.value === 'true')}
            />
            <label htmlFor="teacherGraded">Instructor-Graded Only</label>
          </div>
        </fieldset>
      </Form.Body>
    </>
  );
}

export default ReviewSetup;
