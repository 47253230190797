import React from 'react';
import { Assignment } from '../../types/types';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import { Type, State } from './SubmissionPage';

interface Props {
  allowFileType: boolean;
  allowLinkType: boolean;
  allowTextType: boolean;
  assignment: Assignment;
  requestStateChangeInSequence: (arg0: number) => void;
  setSubmissionType: React.Dispatch<React.SetStateAction<string | null>>;
}

function ChooseMenu({
  allowFileType,
  allowLinkType,
  allowTextType,
  assignment,
  requestStateChangeInSequence,
  setSubmissionType,
}: Props): JSX.Element {
  return (
    <div className="submission-menu" key="choose-menu">
      <h1>Choose a Submission Type</h1>
      <p>
        Please select which type of submission you would like to hand in. The following submission types have been
        allowed for this assignment by your instructor:
      </p>
      <div
        className="flex-row choose-btn-row"
        onClick={(e: React.MouseEvent) => {
          const targetEl = e.target as HTMLElement;
          if (targetEl.tagName === 'BUTTON') requestStateChangeInSequence(State.SUBMIT);
        }}
      >
        {allowFileType ? (
          <Button variant="rad" onClick={() => setSubmissionType(Type.FILE)}>
            <Icon code="file_upload" ariaHidden /> File Upload
          </Button>
        ) : (
          ''
        )}
        {allowLinkType ? (
          <Button variant="rad" onClick={() => setSubmissionType(Type.LINK)}>
            <Icon code="link" ariaHidden /> Submit Link
          </Button>
        ) : (
          ''
        )}
        {allowTextType ? (
          <Button variant="rad" onClick={() => setSubmissionType(Type.TEXT)}>
            <Icon code="create" ariaHidden /> Submit Text
          </Button>
        ) : (
          ''
        )}
        {assignment.liveMode ? (
          <Button variant="rad" onClick={() => setSubmissionType(Type.PRESENTATION)}>
            <Icon code="view_cozy" ariaHidden /> In-class Presentation
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default ChooseMenu;
