import React, { useCallback } from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { deleteReview } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';

interface DeleteReviewButtonProps {
  reviewId: string;
  updateData: () => void;
}

function DeleteReviewButton({ reviewId, updateData }: DeleteReviewButtonProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  const requestDeleteReview = useCallback(
    () =>
      deleteReview(reviewId, () => {
        updateData();
        modalDispatch(
          openModal({
            heading: 'Review Deleted',
            inputType: 'none',
            buttonText: 'Continue',
            cancelHide: true,
          }),
        );
      }),
    [reviewId, updateData, modalDispatch],
  );

  return (
    <Button
      classOverride
      className="button-mini delete"
      onClick={() => {
        modalDispatch(
          openModal({
            heading: 'Delete Review?',
            label: 'Are you sure you want to delete this review?',
            buttonText: 'Delete',
            onConfirm: requestDeleteReview,
          }),
        );
      }}
      tooltip="Delete review"
      ariaLabel="Delete review"
    >
      <Icon code="delete" ariaHidden />
    </Button>
  );
}

export default DeleteReviewButton;
