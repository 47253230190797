import { PayloadAction } from '@reduxjs/toolkit';
import { Assignment } from '../types/types';
import { CLEAR_ASSIGNMENT, SET_ASSIGNMENT } from '../utils/constants';

const assignmentReducer = (state = null as Assignment | null, action: PayloadAction<Assignment>): Assignment | null => {
  switch (action.type) {
    case SET_ASSIGNMENT:
      return action.payload;
    case CLEAR_ASSIGNMENT:
      return action.payload;
    default:
      return state;
  }
};

export default assignmentReducer;
