import React, { useState, useEffect } from 'react';
import HelpTag from '../../core/display/HelpTag';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface EvaluationProps {
  memberEvalEnabled: boolean;
  groupEvalEnabled: boolean;
  instructorEvalEnabled: boolean;
  leaderEvalEnabled: boolean;
  selfEvalEnabled: boolean;
  showEvaluatorIdentities: boolean;
  usePeerEvalRatingsForGrades: boolean;
}

function EvaluationPage({
  assignmentSettings,
  updateSettings,
}: AssignmentWizardPageProps<EvaluationProps>): JSX.Element {
  const [memberEvalEnabled, setMemberEvalEnabled] = useState(assignmentSettings.memberEvalEnabled);
  const [groupEvalEnabled, setGroupEvalEnabled] = useState(assignmentSettings.groupEvalEnabled);
  const [instructorEvalEnabled, setInstructorEvalEnabled] = useState(assignmentSettings.instructorEvalEnabled);
  const [leaderEvalEnabled, setLeaderEvalEnabled] = useState(assignmentSettings.leaderEvalEnabled);
  const [selfEvalEnabled, setSelfEvalEnabled] = useState(assignmentSettings.selfEvalEnabled);
  const [usePeerEvalRatingsForGrades, setUsePeerEvalRatingsForGrades] = useState(
    assignmentSettings.usePeerEvalRatingsForGrades,
  );
  const [showEvaluatorIdentities, setShowEvaluatorIdentities] = useState(assignmentSettings.showEvaluatorIdentities);

  useEffect(() => {
    updateSettings({
      memberEvalEnabled,
      groupEvalEnabled,
      instructorEvalEnabled,
      leaderEvalEnabled,
      selfEvalEnabled,
      usePeerEvalRatingsForGrades,
      showEvaluatorIdentities,
    });
  }, [
    updateSettings,
    groupEvalEnabled,
    instructorEvalEnabled,
    leaderEvalEnabled,
    selfEvalEnabled,
    usePeerEvalRatingsForGrades,
    showEvaluatorIdentities,
    memberEvalEnabled,
  ]);

  useEffect(() => {
    if (!memberEvalEnabled && !groupEvalEnabled && !leaderEvalEnabled && !instructorEvalEnabled) {
      setMemberEvalEnabled(true);
    }
  }, [groupEvalEnabled, memberEvalEnabled, instructorEvalEnabled, leaderEvalEnabled]);

  return (
    <>
      <div className="sub-section">
        <p>
          Team Member Evaluation asks students to rate and comment on the performance of their group members. The
          following options are in addition to a standard team member evaluation assignment.
        </p>
        <p>
          If you do not enable &quot;<i>Use peer evaluation ratings for grades</i>&quot; then the grades will be based
          on student completion of the peer evaluations.
        </p>
        <p>Choose which evaluation options to enable:</p>

        <div>
          <input
            id="memberEvalEnabled"
            name="memberEvalEnabled"
            type="checkbox"
            checked={memberEvalEnabled}
            onChange={(e) => setMemberEvalEnabled(e.target.checked)}
            disabled={!groupEvalEnabled && !leaderEvalEnabled && !instructorEvalEnabled}
          />
          <label htmlFor="memberEvalEnabled">Require students to evaluate their group&apos;s peers (Default)</label>
          <HelpTag>This can be toggled off, but only if another evaluation type below is selected.</HelpTag>
        </div>

        <div>
          <input
            id="groupEvalEnabled"
            name="groupEvalEnabled"
            type="checkbox"
            checked={groupEvalEnabled}
            onChange={(e) => {
              setGroupEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="groupEvalEnabled">Require students to evaluate the group as a whole</label>
          <HelpTag>This asks group members to evaluate the effectiveness of their group.</HelpTag>
        </div>

        <div>
          <input
            id="leaderEvalEnabled"
            name="leaderEvalEnabled"
            type="checkbox"
            checked={leaderEvalEnabled}
            onChange={(e) => {
              setLeaderEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="leaderEvalEnabled">Require students to evaluate the group leader</label>
          <HelpTag>Group leaders must be indicated for this assignment.</HelpTag>
        </div>

        <div>
          <input
            id="instructorEvalEnabled"
            name="instructorEvalEnabled"
            type="checkbox"
            checked={instructorEvalEnabled}
            onChange={(e) => {
              setInstructorEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="instructorEvalEnabled">Require students to evaluate the instructors</label>
        </div>

        <div>
          <input
            id="selfEvalEnabled"
            name="selfEvalEnabled"
            type="checkbox"
            checked={selfEvalEnabled}
            onChange={(e) => {
              setSelfEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="selfEvalEnabled">Require students perform a self-evaluation</label>
          <HelpTag>Student self-ratings are not counted toward the overall rating received.</HelpTag>
        </div>

        <div>
          <input
            id="usePeerEvalRatingsForGrades"
            name="usePeerEvalRatingsForGrades"
            type="checkbox"
            checked={usePeerEvalRatingsForGrades}
            onChange={(e) => {
              setUsePeerEvalRatingsForGrades(e.target.checked);
            }}
          />
          <label htmlFor="usePeerEvalRatingsForGrades">Use team member evaluation ratings for grades</label>
          <HelpTag>
            This bases the grade on the overall average students receive if they completed the evaluation.
          </HelpTag>
        </div>

        <div>
          <input
            id="showEvaluatorIdentities"
            name="showEvaluatorIdentities"
            type="checkbox"
            checked={showEvaluatorIdentities}
            onChange={(e) => {
              setShowEvaluatorIdentities(e.target.checked);
            }}
          />
          <label htmlFor="showEvaluatorIdentities">Turn off evaluator anonymity</label>
          <HelpTag>This will show the names of evaluators and their provided scores/comments.</HelpTag>
        </div>
      </div>
    </>
  );
}

export default EvaluationPage;
