import React, { useState, useRef, useCallback, useEffect } from 'react';
import { handleKeySelect } from '../../../../utils/functions';
import Icon from '../../display/Icon';

interface AddButtonProps {
  children: React.ReactNode;
  focus?: boolean;
  iconCode?: string;
}

function AddButton({ children, focus, iconCode }: AddButtonProps): JSX.Element {
  const [show, setShow] = useState(false);

  const overlayRef = useRef<HTMLDivElement>(null);
  const actionsContainerRef = useRef<HTMLDivElement>(null);

  const cancelClass = show ? ' button-cancel' : '';
  const focusClass = focus && !show ? ' focus pointer-animation-top' : '';

  const handleActionSelect = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const target = e.target as Element;
      if (target.tagName === 'BUTTON') setShow(false);
    }
  };

  const handleSelect = useCallback((e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
    requestAnimationFrame(() => (actionsContainerRef.current?.firstElementChild as HTMLElement | null)?.focus());
  }, []);

  const handleDeselect = useCallback((e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  }, []);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setShow(false);
    };

    window.addEventListener('keydown', handleEscape);

    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  return (
    <>
      <div
        className="shade-overlay"
        ref={overlayRef}
        onClick={(e) => {
          if (e.target === overlayRef.current || e.target === actionsContainerRef.current) setShow(false);
        }}
        style={{ display: show ? undefined : 'none' }}
      >
        <div className="actions-container" ref={actionsContainerRef} onClick={handleActionSelect}>
          {children}
        </div>
      </div>
      <button
        className={'add-button' + cancelClass + focusClass}
        onClick={show ? handleDeselect : handleSelect}
        onKeyDown={(e) => handleKeySelect(e, show ? handleDeselect : handleSelect)}
      >
        <Icon code={iconCode && !show ? iconCode : 'add'} />
      </button>
    </>
  );
}

interface ActionButtonProps {
  action: () => void;
  children: React.ReactNode;
  iconCode: string;
}

function ActionButton({ action, children, iconCode }: ActionButtonProps) {
  return (
    <button className="action-button-wrapper" onClick={action}>
      <div className="action-lbl" onClick={(e) => e.preventDefault()}>
        {children}
      </div>
      <Icon code={iconCode} ariaHidden />
    </button>
  );
}

AddButton.ActionButton = ActionButton;

export default AddButton;
