import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFeedbackTable } from '../../actions';
import CoreForm from '../core/input/Form/Form';
import TextBox from '../core/input/TextBox/TextBox';
import { RatingLevel } from '../../types/types';
import { RootState } from '../../store';
import { DEFAULT_ASSIGNMENT } from '../../utils/constants';
import StarRatingInput from '../core/input/StarRatingInput/StarRatingInput';

interface Props {
  commentId: string;
  feedbackPrompt: string | null;
  feedbackRatings: RatingLevel[];
  save: () => void;
}

function FormPage({ commentId, feedbackPrompt, feedbackRatings, save }: Props): JSX.Element {
  const ratingDescriptions = useMemo(() => feedbackRatings.map((rating) => rating.levelDescription), [feedbackRatings]);

  // Redux:
  const dispatch = useDispatch();
  const feedbackTable = useSelector((state: RootState) => state.feedbackTable);

  const textboxHandleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement>) => {
      const comment = e.target.value;
      dispatch(
        updateFeedbackTable({
          id: commentId,
          data: {
            comment: comment,
          },
        }),
      );
      save();
    },
    [dispatch, commentId, save],
  );

  const handleRatingChange = useCallback(
    (score: number) => {
      dispatch(
        updateFeedbackTable({
          id: commentId,
          data: {
            score,
          },
        }),
      );
      save();
    },
    [dispatch, commentId, save],
  );

  // Get initial comment, if any
  const initialComment = useMemo(() => {
    let initComment;
    if (feedbackTable[commentId] && feedbackTable[commentId].comment) initComment = feedbackTable[commentId].comment;
    return initComment;
  }, [commentId, feedbackTable]);

  // Get initial score, if any
  const initialScore = useMemo(() => {
    let initScore;
    if (feedbackTable[commentId]) initScore = feedbackTable[commentId].score;
    return initScore;
  }, [commentId, feedbackTable]);

  return (
    <>
      <CoreForm.Body>
        <StarRatingInput
          legendText="How helpful are these comments?"
          ratingDescriptions={ratingDescriptions}
          numStars={5}
          defaultValue={initialScore}
          onChange={handleRatingChange}
          required
        />
        <TextBox
          key={`textbox-${commentId}`}
          placeholder={`Provide feedback on your peer's helpfulness.`}
          commentId={commentId}
          labelText={feedbackPrompt ?? DEFAULT_ASSIGNMENT.feedbackPrompt}
          required={true}
          onChange={textboxHandleChange}
          onBlur={textboxHandleChange}
          defaultValue={initialComment}
        />
      </CoreForm.Body>
    </>
  );
}

export default FormPage;
