import React, { useEffect, useMemo, useState } from 'react';
import Form from '../core/input/Form/Form';
import { AssignmentProgress, User } from '../../types/types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/selectors';
import _ from 'lodash';
import LiveEvalScoreboard from './LiveEvalScoreboard';

type PointAttribution = {
  peer: User;
  points: number;
};

interface Props {
  userProgress: AssignmentProgress;
}

function AttributePoints({ userProgress }: Props): JSX.Element {
  const [pointAttribution, setPointAttribution] = useState<PointAttribution[] | null>(null);

  const user = useSelector(selectUser);

  useEffect(() => {
    const peers = userProgress.group?.groupMembers
      .map((member) => member.user)
      .filter((peer) => peer.userId !== user.userId)
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
    if (peers) setPointAttribution(peers.map((peer) => ({ peer, points: 0 })));
  }, [userProgress, user]);

  const formPages = useMemo(
    () =>
      pointAttribution
        ? [
            <EditorFormPage
              key="page-1"
              pointAttribution={pointAttribution}
              setPointAttribution={setPointAttribution}
            />,
            <ConfirmFormPage
              key="page-2"
              pointAttribution={pointAttribution}
              setPointAttribution={setPointAttribution}
            />,
          ]
        : [],
    [pointAttribution, setPointAttribution],
  );

  return <Form id="eval-attribute-points-form" pages={formPages} save={() => undefined} onSubmit={() => undefined} />;
}

interface FormPageProps {
  pointAttribution: PointAttribution[];
  setPointAttribution: React.Dispatch<React.SetStateAction<PointAttribution[] | null>>;
}

function EditorFormPage({ pointAttribution, setPointAttribution }: FormPageProps): JSX.Element {
  const sum = pointAttribution.map((attr) => attr.points).reduce((partialSum, a) => partialSum + a, 0);
  return (
    <>
      <Form.Header>
        <Form.Title>Team Member Performance</Form.Title>
        <Form.Description size="sm">
          Distribute points among your team members according to the amount of work they contributed to the project. You
          have a total of 100 points to distribute. You must distribute all 100 points.
        </Form.Description>
      </Form.Header>
      <Form.Body>
        <div>Points Allocated: {sum}/100</div>
        {pointAttribution.map((attribution, i) => {
          const { peer, points } = attribution;
          const id = `points-${peer.userId}`;
          return (
            <div key={peer.userId}>
              <label htmlFor={id}>{peer.name}</label>
              <input
                id={id}
                type="number"
                value={points}
                onChange={(e) => {
                  setPointAttribution((prevAttribution) => {
                    const newAttribution = _.cloneDeep(prevAttribution);
                    if (newAttribution) {
                      const newPoints = Math.min(Math.max(parseInt(e.target.value), 0), 100);
                      const sum = newAttribution
                        .map((attr, j) => (i === j ? newPoints : attr.points))
                        .reduce((partialSum, a) => partialSum + a, 0);
                      if (sum <= 100) newAttribution[i].points = newPoints;
                    }
                    return newAttribution;
                  });
                }}
              />
            </div>
          );
        })}
      </Form.Body>
    </>
  );
}

function ConfirmFormPage({ pointAttribution }: FormPageProps): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>Review & Submit</Form.Title>
        <Form.Description size="sm">
          Please review the team member rankings below. These rankings were determined by your point distribution. Once
          you feel your evaluation is accurate, click &quot;Submit&quot; to finish.
        </Form.Description>
      </Form.Header>
      <Form.Body>
        {pointAttribution
          .sort((a, b) => {
            if (a.points < b.points) return 1;
            else if (a.points > b.points) return -1;
            return 0;
          })
          .map((attr, i) => {
            const { peer, points } = attr;
            return (
              <LiveEvalScoreboard.LiveScoreboardEntry
                key={peer.userId}
                rank={i + 1}
                score={points}
                user={peer}
                current={false}
                end
                title="MEMBER"
              />
            );
          })}
      </Form.Body>
    </>
  );
}

export default AttributePoints;
