import React, { useState, useEffect } from 'react';
import { AccessPermission, Assignment, AssignmentProgress } from '../../types/types';
import { getCourseProgress, getCourseAssignments, getAccessPermission } from '../../utils/requests';
import ProgressTable from './ProgressTable';
import { useParams } from 'react-router';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { setPageTitle } from '../../utils/functions';

function CourseProgressPage(): JSX.Element {
  useEffect(() => setPageTitle('Course Progress'), []);

  const { courseId } = useParams() as { courseId: string; assignmentId: string };

  const [courseProgress, setCourseProgress] = useState<AssignmentProgress[] | null>(null);
  const [courseAssignments, setCourseAssignments] = useState<Assignment[]>([]);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<'all' | string>('all');
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment | null>(null);
  const [selectedProgress, setSelectedProgress] = useState<AssignmentProgress[]>([]);
  const [accessPermission, setAccessPermission] = useState<AccessPermission | undefined>(undefined);

  useEffect(() => {
    getCourseProgress(courseId, setCourseProgress);
    getCourseAssignments(courseId, setCourseAssignments);
    getAccessPermission(courseId, setAccessPermission);
  }, [courseId]);

  useEffect(() => {
    if (selectedAssignmentId === 'all') setSelectedAssignment(null);
    else
      courseAssignments.forEach((assignment) => {
        if (assignment.assignmentId === selectedAssignmentId) setSelectedAssignment(assignment);
      });
  }, [selectedAssignmentId, courseAssignments]);

  useEffect(() => {
    if (courseProgress) {
      if (selectedAssignmentId === 'all') setSelectedProgress(courseProgress);
      else
        setSelectedProgress(
          courseProgress.filter((progress) => progress.assignment.assignmentId === selectedAssignmentId),
        );
    }
  }, [courseProgress, selectedAssignmentId]);

  if (courseProgress && selectedProgress) {
    return (
      <div className="page teacher-results-row">
        <div className="roster-container">
          <section className="assignment-select-wrapper">
            <label htmlFor="assignment-select">Select an Assignment:</label>
            <select
              id="assignment-select"
              value={selectedAssignmentId}
              onChange={(e: React.ChangeEvent) => setSelectedAssignmentId((e.target as HTMLSelectElement).value)}
            >
              <option value="all">All</option>
              {courseAssignments.map((assignment) => {
                return (
                  <option key={`option-${assignment.assignmentId}`} value={assignment.assignmentId}>
                    {assignment.assignmentName}
                  </option>
                );
              })}
            </select>
          </section>
          <section>
            <ProgressTable
              assignment={selectedAssignment ?? undefined}
              progressData={selectedProgress}
              includeAssignmentNames={selectedAssignmentId === 'all'}
              showAllColumns={selectedAssignmentId === 'all'}
              accessPermission={accessPermission}
            />
          </section>
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default CourseProgressPage;
