import React from 'react';
import PhaseCard from './PhaseCard';
import Button from '../../core/button/Button/Button';
import { PhaseCardProps } from './StudentDashboardOld';

function EvaluateCard({ rootPath, userProgress }: PhaseCardProps): JSX.Element {
  const { assignment } = userProgress;
  const { canEvaluate } = userProgress.status;

  return (
    <PhaseCard type="EVALUATE" role="STUDENT" asyncEnabled={assignment.asyncEnabled} userProgress={userProgress}>
      <Button href={canEvaluate ? `${rootPath}/evaluate` : undefined} route disabled={!canEvaluate}>
        Evaluate
      </Button>
      <p>
        {userProgress.countEvaluationsComplete}
        {userProgress.evaluationCount > 0 ? `/${userProgress.evaluationCount}` : null} Evaluations Complete
      </p>
    </PhaseCard>
  );
}

export default EvaluateCard;
