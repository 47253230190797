import _ from 'lodash';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import SimpleBarCore from 'simplebar-core';
import { Notification as NotificationType } from '../../../../types/types';
import { NUM_NOTIFICATIONS_KEY } from '../../../../utils/constants';
import {
  deleteNotification,
  getNotifications,
  getNotificationsMetaData,
  markAllNotificationsAsRead,
  markNotificationRead,
} from '../../../../utils/requests';
import Button from '../../button/Button/Button';
import Icon from '../../display/Icon';
import Notification from '../../display/Notification/Notification';
import FilterTab from '../FilterTab/FilterTab';
import { storageAvailable } from '../../../../utils/functions';

interface Props {
  dedicatedPage?: boolean;
  limit?: number;
  setNumNotifications?: (arg0: number) => void;
}

function NotificationsMenu({
  dedicatedPage = false,
  limit = 10,
  setNumNotifications = () => undefined,
}: Props): JSX.Element {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [maxLoad, setMaxLoad] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const SCROLL_BUFFER = 48;
  const filterUnread = useMemo(() => filterList.includes('Unread'), [filterList]);

  const scrollBarEl = useRef<SimpleBarCore | null>(null);

  const updateNumUnread = useCallback(
    (numUnreadNotifs: number) => {
      setNumNotifications(numUnreadNotifs);
      if (storageAvailable('sessionStorage'))
        window.sessionStorage.setItem(NUM_NOTIFICATIONS_KEY, numUnreadNotifs + '');
      document.title = `${numUnreadNotifs > 0 ? `(${numUnreadNotifs}) ` : ''}${document.title.replace(
        /(\(\d+\)\s)/,
        '',
      )}`;
    },
    [setNumNotifications],
  );

  const markAllAsRead = useCallback(() => markAllNotificationsAsRead(setNotifications), []);

  const markAsRead = useCallback(
    (notificationId: string) =>
      markNotificationRead(notificationId, (updatedNotif) => {
        setNotifications((prevNotifs) => {
          const i = prevNotifs.findIndex((notif) => notif.notificationId === updatedNotif.notificationId);
          const newNotifs = _.cloneDeep(prevNotifs);
          if (i >= 0 && i < newNotifs.length) newNotifs[i] = updatedNotif;
          return newNotifs;
        });
      }),
    [],
  );

  const dismissNotification = useCallback(
    (notificationId: string) =>
      deleteNotification(notificationId, () =>
        setNotifications((prevNotifs) => prevNotifs.filter((notif) => notif.notificationId !== notificationId)),
      ),
    [],
  );

  useEffect(
    () => getNotificationsMetaData((metaData) => updateNumUnread(metaData.unSeenNotificationsCount)),
    [notifications, updateNumUnread],
  );

  useEffect(() => {
    setMaxLoad(false);
    setNotifications([]);
    setPageNum(0);
  }, [filterUnread]);

  useEffect(() => {
    if (pageNum === 0 && notifications.length === 0) setLoading(true);
  }, [pageNum, notifications]);

  useEffect(() => {
    if (
      loading &&
      !maxLoad &&
      ((pageNum === 0 && notifications.length === 0) || (pageNum > 0 && notifications.length > 0))
    )
      getNotifications(limit, pageNum, filterUnread === true ? false : null, (query) => {
        setNotifications((prevNotifs) => [...prevNotifs, ...query.results]);
        setLoading(false);
        if (query.finalPageNum === pageNum) setMaxLoad(true);
      });
  }, [loading, maxLoad, pageNum, filterUnread, limit, notifications.length]);

  const triggerLoadMore = useCallback(() => {
    if (!loading && !maxLoad) {
      setPageNum((prevNum) => prevNum + 1);
      setLoading(true);
    }
  }, [loading, maxLoad]);

  const handleScroll = useCallback(
    (e: Event) => {
      const wrapper = e.target as HTMLElement;
      if (wrapper && wrapper.scrollTop >= wrapper.scrollHeight - wrapper.offsetHeight - SCROLL_BUFFER)
        triggerLoadMore();
    },
    [triggerLoadMore],
  );

  useEffect(() => {
    const scrollElem = scrollBarEl.current?.getScrollElement();
    if (scrollElem && scrollElem.getAttribute('event-added') !== 'true') {
      scrollElem.addEventListener('scroll', handleScroll);
      scrollElem.setAttribute('event-added', 'true');
    }
  }, [handleScroll]);

  return (
    <>
      <div className="ctrls">
        <FilterTab label="Show:" hideLabel setFilterList={setFilterList}>
          <FilterTab.Button id="btn-all" type="radio" name="notifications-filters" defaultChecked={true}>
            All
          </FilterTab.Button>
          <FilterTab.Button id="btn-unread" type="radio" name="notifications-filters">
            Unread
          </FilterTab.Button>
        </FilterTab>
        <Button variant="link low xs" onClick={markAllAsRead}>
          Mark all as read
        </Button>
      </div>
      <SimpleBar ref={scrollBarEl} className="notifications-wrapper">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <Notification
              key={notification.notificationId}
              {...notification}
              markAsRead={() => markAsRead(notification.notificationId)}
              dismiss={dedicatedPage ? () => dismissNotification(notification.notificationId) : undefined}
            />
          ))
        ) : (
          <div className="no-notifs">
            <Icon code="notifications_off" ariaHidden />
            <span>No {filterList.includes('Unread') ? 'unread notifications' : 'notifications'} to show</span>
          </div>
        )}
        {!maxLoad && !loading ? (
          <Button id="load-more" variant="rad low xs" onClick={triggerLoadMore}>
            Load more
          </Button>
        ) : null}
      </SimpleBar>
      <Link id="notifs-page-link" to="/notifications">
        See all notifications
      </Link>
    </>
  );
}

export default NotificationsMenu;
