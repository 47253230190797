import React from 'react';
import TabList from '../core/layout/TabList/TabList';
import CourseStats from './CourseStats';
import InstitutionStatistics from './InstitutionStatistics';

function AdminStats(): JSX.Element {
  return (
    <div className="page home-page" id="stats">
      <TabList
        label="Stats Menu"
        tabs={
          <>
            <TabList.Tab id="courses" controls="courses-tab">
              Courses
            </TabList.Tab>
            <TabList.Tab id="institutions" controls="institution-tab">
              Institutions
            </TabList.Tab>
          </>
        }
      >
        <TabList.TabPanel id="institution-tab" labeledBy="institutions">
          <InstitutionStatistics />
        </TabList.TabPanel>
        <TabList.TabPanel id="courses-tab" labeledBy="courses">
          <CourseStats />
        </TabList.TabPanel>
      </TabList>
    </div>
  );
}

export default AdminStats;
