import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../core/button/Button/Button';
import { ApprovedDomain } from '../../../types/types';
import {
  addApprovedDomain,
  changeDomainMode,
  deleteApprovedDomain,
  getApprovedDomains,
  updateSeatCap,
} from '../../../utils/requests';
import Table from '../../core/display/Table/Table';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import TableInput from '../../core/input/TableInput/TableInput';
import Dropdown from '../../core/button/Dropdown/Dropdown';
import { formDataToObjectParsed } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';

type TableData = {
  domain: string;
  domainOn: string;
  seatsCap: number | string;
  seatsUsed: number | string;
  allowlist: ApprovedDomain;
};

interface Props {
  updateKey: number;
  updateData: () => void;
}

function ApprovedDomainsMenu({ updateKey, updateData }: Props): JSX.Element {
  const [allowlist, setAllowlist] = useState<ApprovedDomain[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);
  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    getApprovedDomains(setAllowlist);
  }, [updateKey]);

  const openAddAllowlistDialogue = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Add Allowlist Domains',
          label: "Enter the allowed domain's details below and submit to add it to the table:",
          buttonText: 'Add',
          form: false,
          closeButton: true,
          noActionButtons: true,
          justifyModal: 'flex-start',
          children: <AddAllowlistDialogue updateData={updateData} />,
        }),
      ),
    [modalDispatch, updateData],
  );

  const handleDelete = useCallback(
    (allowlist: ApprovedDomain) => {
      modalDispatch(
        openModal({
          heading: 'Allowed Domain',
          label: 'Are you sure you want to delete this domain listing (Not retroactive for existing seats)?',
          buttonText: 'Delete',
          padding: '3rem',
          onConfirm: () => deleteApprovedDomain(allowlist.approvedDomainId, setAllowlist),
        }),
      );
    },
    [modalDispatch],
  );

  const handleTurnOff = useCallback(
    (allowlist: ApprovedDomain) => {
      modalDispatch(
        openModal({
          heading: 'Allowed Domain',
          label: `Are you sure you want to ${allowlist.enabled ? 'turn off ' : 'turn on '} global seats for this domain?`,
          buttonText: 'Confirm',
          padding: '3rem',
          onConfirm: () => changeDomainMode(allowlist.approvedDomainId, updateData),
        }),
      );
    },
    [modalDispatch, updateData],
  );

  const handleEditSeatCap = useCallback(
    (allowlist: ApprovedDomain) =>
      modalDispatch(
        openModal({
          heading: `Update the Seat Cap for ${allowlist.domain}`,
          closeButton: true,
          cancelHide: true,
          buttonText: 'Update',
          form: true,
          onSubmit: (seatCap) => {
            const data = formDataToObjectParsed(seatCap) as {
              seats: string;
            };
            const seats = data.seats;
            updateSeatCap(allowlist.approvedDomainId, seats, updateData);
          },
          children: <UpdateSeatCap />,
        }),
      ),
    [modalDispatch, updateData],
  );

  useMemo(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Domain', accessorKey: 'domain', meta:{className: 'left-align' }} ,
      { header: 'Mode', accessorKey: 'domainOn', meta:{className: 'left-align' }},
      { header: 'Seats Cap', accessorKey: 'seatsCap', meta:{className: 'left-align' }},
      { header: 'Seats Used', accessorKey: 'seatsUsed', meta:{className: 'left-align' }},
      {
        header: '',
        accessorKey: 'allowlist',
        cell: (cell)=>{
          const allowlist = cell.getValue() as ApprovedDomain;

          return (
            <Dropdown className="button-mini options-btn" iconCode="more_horiz" align="right">
              <>
                <Dropdown.Link href="#" onClick={() => handleEditSeatCap(allowlist)}>
                  Edit Seat Cap
                </Dropdown.Link>
                <Dropdown.Link href="#" onClick={() => handleTurnOff(allowlist)}>
                  {allowlist.enabled ? 'Turn Off' : 'Turn On'}
                </Dropdown.Link>
                <Dropdown.Link href="#" onClick={() => handleDelete(allowlist)}>
                  Delete
                </Dropdown.Link>
              </>
            </Dropdown>
          );
        },
        meta:{className: 'center-align options-cell'},
      },
    ];

    const dataTable: TableData[] = [];
    allowlist.forEach((allowlist: ApprovedDomain) => {
      const newRow: TableData = {
        domain: allowlist.domain,
        domainOn: allowlist.enabled ? 'On' : 'Off',
        seatsCap: allowlist.seatsCap ? allowlist.seatsCap : 'unlimited',
        seatsUsed: allowlist.seatsUsed ? allowlist.seatsUsed : 'None',
        allowlist: allowlist,
      };

      dataTable.push(newRow);
    });
    setTableData(dataTable);
    setTableColumns(columns);
  }, [handleDelete, handleEditSeatCap, handleTurnOff, allowlist]);

  return (
    <>
      <Button id="allowlist-btn" onClick={openAddAllowlistDialogue}>
        {' '}
        New Approved Domain
      </Button>
      <Table
        columns={tableColumns}
        data={tableData}
        sortBy="domain"
        noWrapper
        headingLevel={1}
        id="domain-card"
        informOfRow={(row) => navigate(`/domain/${row.original.allowlist.approvedDomainId}/seats`)}
        title="Allowed Domains"
      />
    </>
  );
}

type Domain = { domainName: string; seatsCap: string };

const tableInputHeaders = [
  { title: 'Domain', accessor: 'domain' },
  { title: 'Seats Cap', accessor: 'seatsCap' },
];

interface AddAllowlistProps {
  updateData: () => void;
}

function AddAllowlistDialogue({ updateData }: AddAllowlistProps): JSX.Element {
  const [domains, setDomains] = useState<Domain[]>([]);
  const { modalDispatch } = useModalContext();

  function handleDomainChange(updatedDomains: Domain[]) {
    setDomains(updatedDomains);
  }

  return (
    <form
      onSubmit={(e) => {
        const validDomains = domains.filter((domain) => domain.domainName !== '');
        e.preventDefault();
        addApprovedDomain(validDomains, () => {
          const numDomain = validDomains.length;
          const plural = numDomain !== 1;
          modalDispatch(
            openModal({
              heading: `Domain${plural ? 's' : ''} Added`,
              label: `${numDomain} domain${plural ? 's have' : ' has'} been added to the list.`,
              inputType: 'none',
              buttonText: 'Continue',
              cancelHide: true,
            }),
          );
          updateData();
        });
      }}
    >
      <TableInput<Domain> headers={tableInputHeaders} onChange={handleDomainChange} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
}

function UpdateSeatCap(): JSX.Element {
  return (
    <div id="create-purchase-modal-content">
      <div className="input-wrapper">
        <label htmlFor="seats">Seats:</label>
        <input id="seats" name="seats" type="number" placeholder="#" min={1} required />
      </div>
    </div>
  );
}

export default ApprovedDomainsMenu;
