import { PayloadAction } from '@reduxjs/toolkit';
import { CommentTable, CommentData, RatingTable, FeedbackTable, User, Assignment, Course } from '../types/types';
import {
  CLEAR_REVIEW_DATA,
  SET_COMMENT_TABLE,
  UPDATE_COMMENT_TABLE,
  SET_RATING_TABLE,
  UPDATE_RATING_TABLE,
  UPDATE_PREVIOUS_TABLES,
  SET_SAVE_TIMESTAMP,
  SET_FEEDBACK_TABLE,
  UPDATE_FEEDBACK_TABLE,
  SET_USER,
  CLEAR_USER,
  SET_ASSIGNMENT,
  CLEAR_ASSIGNMENT,
  SET_COURSE,
  CLEAR_COURSE,
  NEW_UPDATE_KEY,
  CLEAR_COURSE_USER,
  LOG_ERROR,
} from '../utils/constants';
import { ErrorObj } from '../utils/requests';

export const clearReviewData = (): PayloadAction<null> => {
  return {
    type: CLEAR_REVIEW_DATA,
    payload: null,
  };
};

export const setCommentTable = (commentTable: CommentTable): PayloadAction<CommentTable> => {
  return {
    payload: commentTable,
    type: SET_COMMENT_TABLE,
  };
};

export const updateCommentTable = (commentData: CommentData): PayloadAction<CommentData> => {
  return {
    type: UPDATE_COMMENT_TABLE,
    payload: commentData,
  };
};

export const setRatingTable = (ratingTable: RatingTable): PayloadAction<RatingTable> => {
  return {
    type: SET_RATING_TABLE,
    payload: ratingTable,
  };
};

export type RatingUpdateData = {
  ratingId: string;
  score: number;
};

export const updateRatingTable = (ratingTable: RatingUpdateData): PayloadAction<RatingUpdateData> => {
  return {
    type: UPDATE_RATING_TABLE,
    payload: ratingTable,
  };
};

export type Tables = { commentTable?: CommentTable; ratingTable?: RatingTable; feedbackTable?: FeedbackTable };

export const updatePreviousTables = (tables: Tables): PayloadAction<Tables> => {
  return {
    type: UPDATE_PREVIOUS_TABLES,
    payload: tables,
  };
};

export const setSaveTimestamp = (saveTimestamp: string): PayloadAction<string> => {
  return {
    type: SET_SAVE_TIMESTAMP,
    payload: saveTimestamp,
  };
};

export const setFeedbackTable = (feedbackTable: FeedbackTable): PayloadAction<FeedbackTable> => {
  return {
    type: SET_FEEDBACK_TABLE,
    payload: feedbackTable,
  };
};

export type FeedbackUpdateData = {
  id: string;
  data: {
    score?: number;
    comment?: string;
  };
};

export const updateFeedbackTable = (feedbackUpdateData: FeedbackUpdateData): PayloadAction<FeedbackUpdateData> => {
  return {
    type: UPDATE_FEEDBACK_TABLE,
    payload: feedbackUpdateData,
  };
};

export const setUser = (user: User): PayloadAction<User> => {
  return {
    payload: user,
    type: SET_USER,
  };
};

export const clearUser = (): PayloadAction<User> => {
  return {
    payload: {
      userId: '',
      purchasingEnabled: false,
      email: '',
      pseudonym: '',
      role: 'STUDENT',
      admin: false,
      enabled: false,
      sortableName: '',
      accountNonLocked: false,
      accountNonExpired: false,
      activeAccount: false,
      createdAt: '',
      credentialsNonExpired: false,
      forcePasswordChange: false,
      username: '',
      authorities: [],
      groupSyncEnabled: false,
      lastLoginTime: null,
      courseRole: null,
      courseRoleCourseId: null,
      courseStudentPurchaseRequired: null,
      schoolAdmin: false,
      sendAllNotifications: false,
      sendDeadlineNotifications: false,
      institutionId:'',
      videoStreamingEnabled: false,
      ltiUser: false,
    } as User,
    type: CLEAR_USER,
  };
};

export const clearCourseUser = (): PayloadAction<null> => {
  return {
    payload: null,
    type: CLEAR_COURSE_USER,
  };
};

export const setReduxAssignment = (assignment: Assignment): PayloadAction<Assignment> => {
  return {
    payload: assignment,
    type: SET_ASSIGNMENT,
  };
};

export const clearAssignment = (): PayloadAction<null> => {
  return {
    payload: null,
    type: CLEAR_ASSIGNMENT,
  };
};

export const setReduxCourse = (course: Course): PayloadAction<Course> => {
  return {
    payload: course,
    type: SET_COURSE,
  };
};

export const clearCourse = (): PayloadAction<null> => {
  return {
    payload: null,
    type: CLEAR_COURSE,
  };
};

export const newUpdateKey = (): PayloadAction<number> => {
  return {
    payload: Date.now(),
    type: NEW_UPDATE_KEY,
  };
};

export const logError = (error: ErrorObj): PayloadAction<ErrorObj[]> => {
  return {
    payload: [error],
    type: LOG_ERROR,
  };
};
