import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewByItem } from '../../../types/types';
import { getResetReviewsByUserInAssignment, getReviewItemsOfUser } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import JumpButton from '../../core/button/JumpButton';
import DeleteReviewButton from './DeleteReviewButton';
import RatingDetailsTable from './RatingDetailsTable';
import { getCompletionStatus, getJumpButtons, StudentDetailsTabProps } from './StudentDetailsPage';
import ReviewDialogue from '../../core/display/ReviewDialogue/ReviewDialogue';
import Accordion from '../../core/layout/Accordion/Accordion';
import AlertBar from '../../core/display/AlertBar';

function ReviewsSentDetails({ assignmentProgress, updateData, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId, userId } = useParams() as { courseId: string; assignmentId: string; userId: string };

  const [reviewsSent, setReviewSent] = useState<ReviewByItem[] | null>(null);
  const [resetReviews, setResetReviews] = useState<ReviewByItem[] | null>(null);

  useEffect(() => {
    getReviewItemsOfUser(assignmentId, userId, setReviewSent);
    if (assignmentProgress.assignment.asyncEnabled)
      getResetReviewsByUserInAssignment(userId, assignmentId, setResetReviews);
  }, [updateKey, assignmentProgress, assignmentId, userId]);

  return (
    <>
      {reviewsSent && reviewsSent.length > 0 ? (
        <>
          <div id="submitters-table" className="panel-sm panel-white">
            <h2 className="title" tabIndex={-1}>
              Submitters
            </h2>
            <table>
              <tbody>
                {reviewsSent.map((review) => (
                  <tr key={`row-${review.reviewId}`}>
                    <th>
                      <b>{review.submissionUser.name}</b>
                    </th>
                    <td>{getCompletionStatus(review.complete)}</td>
                    <td>{getJumpButtons(`review-sent-${review.reviewId}`, 'Jump to Review')}</td>
                    <td>
                      <DeleteReviewButton reviewId={review.reviewId} updateData={updateData} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {reviewsSent.map((reviewByItem) => (
            <ReviewSent key={reviewByItem.reviewId} reviewByItem={reviewByItem} />
          ))}
        </>
      ) : (
        <div className="panel-sm panel-white no-progress-message">
          <h2 className="title">No Reviews Sent Yet</h2>
          <div>
            <p>
              <b>{assignmentProgress.user.name}</b> has not yet sent peer reviews for this assignment.
            </p>
            <p>More details will be available once they have sent peer reviews.</p>
          </div>
        </div>
      )}

      {resetReviews && resetReviews.length > 0 ? (
        <Accordion className="reset-reviews-accordion" name="Reset Reviews">
          <AlertBar backgroundColor="#c1daf7" id="template-disclaimer">
            These are reviews which were nullified by the student resubmitting and restarting the assignment.
          </AlertBar>
          {resetReviews.map((reviewByItem) => (
            <ReviewSent key={reviewByItem.reviewId} reviewByItem={reviewByItem} />
          ))}
        </Accordion>
      ) : null}
    </>
  );
}

function ReviewSent({ reviewByItem }: { reviewByItem: ReviewByItem }): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  return (
    <div
      key={`review-sent-${reviewByItem.reviewId}`}
      id={`review-sent-${reviewByItem.reviewId}`}
      className="panel-sm panel-white review-wrapper"
    >
      <JumpButton type="focus-child" targetId="submitters-table" invisible>
        Back to submitters table
      </JumpButton>
      <h2 className="title">Submitter: {reviewByItem.submissionUser.name}</h2>
      <div className="status-wrapper">
        <span className="target">Peer Review</span> {getCompletionStatus(reviewByItem.complete)}
        <Button
          variant="rad low sm"
          href={`/course/${courseId}/assignment/${assignmentId}/submission/${reviewByItem.submissionId}`}
          route
        >
          Open Submission
        </Button>
      </div>

      {reviewByItem.comments.length > 0 ? (
        <>
          <h3>Review Comments</h3>
          {reviewByItem.comments.map((commentItem) => (
            <section key={`dialogue-${commentItem.comment.commentId}`} className="dialogue-container">
              <h4>
                on &quot;<i>{commentItem.comment.commentName}</i>&quot;
              </h4>
              <ReviewDialogue commentWithReviewComments={commentItem} teacherView />
            </section>
          ))}
        </>
      ) : null}

      {reviewByItem.ratings.length > 0 ? (
        <RatingDetailsTable heading="Review Ratings" reviewableByItem={reviewByItem} />
      ) : null}
    </div>
  );
}

export default ReviewsSentDetails;
