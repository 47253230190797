import React, { useMemo, useRef } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { SemesterData } from '../../../../types/types';
import { GridColumns } from '@visx/grid';
import _ from 'lodash';
import JumpButton from '../../button/JumpButton';

export type Props = {
  data: SemesterData[];
  height: number;
  width: number;
  xLabel: string;
};

function SemesterBarChart({ data, height, width, xLabel }: Props): JSX.Element {
  const id = useRef(_.uniqueId());

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, width],
        round: true,
        domain: [0, Math.max(...data.map((d) => d.count))],
      }),
    [width, data],
  );
  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, height],
        round: true,
        domain: data.map((d) => `${d.season} ${d.year}`),
        padding: 0.2,
      }),
    [height, data],
  );

  return (
    <>
      <svg className="semester-bar-chart" width={width} height={height} aria-hidden>
        <Group>
          {data.map((d, i) => {
            const barWidth = xScale(d.count) ?? 0;
            const barHeight = yScale.bandwidth();
            const barY = yScale(`${d.season} ${d.year}`) ?? 0;
            return (
              <BarRounded
                key={`bar-${d.season} ${d.year}`}
                x={0}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={i < data.length - 1 ? '#59A9DE' : '#F5BA43'}
                radius={4}
                all
              />
            );
          })}
          <GridColumns
            scale={xScale}
            width={width}
            height={height}
            strokeDasharray="3 2"
            stroke="#BBBBBB"
            strokeWidth={1}
            numTicks={2}
          />
          <AxisLeft
            hideAxisLine
            hideTicks
            scale={yScale}
            labelOffset={0}
            tickLength={8}
            stroke="black"
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 12,
              textAnchor: 'end',
              dy: '0.33em',
            })}
          />
          <AxisBottom
            hideAxisLine
            hideTicks
            scale={xScale}
            labelOffset={6}
            tickLength={0}
            stroke="#BBBBBB"
            tickFormat={(t) => `${Math.floor(t.valueOf())}`}
            numTicks={2}
            top={height}
            label={xLabel}
            tickLabelProps={() => ({
              fill: '#BBBBBB',
              fontSize: 10,
              textAnchor: 'middle',
            })}
          />
        </Group>
      </svg>

      <div className="accessible-table sr-only">
        <p>Semester Data</p>
        <JumpButton id={`pre-table-btn-${id.current}`} targetId={`post-table-btn-${id.current}`} type="focus">
          Skip to after table
        </JumpButton>
        <table>
          <thead>
            <tr>
              <th>Semester</th>
              <th>{xLabel}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((semesterDatum) => (
              <tr key={`${semesterDatum.season}-${semesterDatum.year}`}>
                <td>
                  {semesterDatum.season} {semesterDatum.year}
                </td>
                <td>{semesterDatum.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <JumpButton id={`post-table-btn-${id.current}`} targetId={`pre-table-btn-${id.current}`} type="focus">
          Skip to before table
        </JumpButton>
      </div>
    </>
  );
}

export default SemesterBarChart;
