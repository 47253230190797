import React, { useState, useEffect } from 'react';
import { RatingLevel } from '../../../types/types';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface FeedbackProps {
  feedbackPrompt: string;
  feedbackRatings: RatingLevel[];
}

function FeedbackPage({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<FeedbackProps>): JSX.Element {
  const levels = new Array(5).fill('');
  assignmentSettings.feedbackRatings.forEach((level) => {
    const score = level.score;
    if (score >= 0 && score <= levels.length) levels[score - 1] = level.levelDescription;
  });

  const [prompt, setPrompt] = useState(assignmentSettings.feedbackPrompt);
  const [rating5, setRating5] = useState(levels[4]);
  const [rating4, setRating4] = useState(levels[3]);
  const [rating3, setRating3] = useState(levels[2]);
  const [rating2, setRating2] = useState(levels[1]);
  const [rating1, setRating1] = useState(levels[0]);

  const [editPrompt, setEditPrompt] = useState(false);
  const [editRatings, setEditRatings] = useState(false);

  useEffect(() => {
    updateSettings({
      feedbackPrompt: prompt,
      feedbackRatings: [
        { score: 1, levelDescription: rating1 },
        { score: 2, levelDescription: rating2 },
        { score: 3, levelDescription: rating3 },
        { score: 4, levelDescription: rating4 },
        { score: 5, levelDescription: rating5 },
      ],
    });
  }, [updateSettings, prompt, rating1, rating2, rating3, rating4, rating5]);

  return (
    <>
      <h3>
        Use the standard helpfulness criteria or customize how your students evaluate the helpfulness of their
        peers&apos; comments.
      </h3>
      <div className="feedback-interface">
        <div className="title-button-wrapper">
          <label htmlFor="feedbackPrompt">Feedback Prompt</label>
          <Button
            variant={`rad${editPrompt ? '' : ' alt'}`}
            type="button"
            onClick={() => {
              setEditPrompt((prevState) => !prevState);
              requestAnimationFrame(() => document.getElementById('feedbackPrompt')?.focus());
            }}
          >
            <span className="text">{editPrompt ? 'Save Changes' : 'Edit'}</span>
            <span className="icon">
              <Icon code={editPrompt ? 'save' : 'edit'} />
            </span>
          </Button>
        </div>

        {editPrompt ? (
          <textarea id="feedbackPrompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} required />
        ) : (
          <p>{prompt}</p>
        )}
        <div className="title-button-wrapper">
          <h3>Feedback Ratings</h3>
          <Button
            variant={`rad${editRatings ? '' : ' alt'}`}
            type="button"
            onClick={() => {
              setEditRatings((prevState) => !prevState);
              requestAnimationFrame(() => document.getElementById('5-rating')?.focus());
            }}
          >
            <span className="text">{editRatings ? 'Save Changes' : 'Edit'}</span>
            <span className="icon">
              <Icon code={editRatings ? 'save' : 'edit'} />
            </span>
          </Button>
        </div>
        <div className="rating-wrapper">
          <label htmlFor="5-rating">5/5</label>
          {editRatings ? (
            <input
              id="5-rating"
              type="text"
              value={rating5}
              onChange={(e) => {
                setRating5(e.target.value);
              }}
              required={true}
              disabled={!editRatings}
            />
          ) : (
            <p>{rating5}</p>
          )}
        </div>
        <div className="rating-wrapper">
          <label htmlFor="4-rating">4/5</label>
          {editRatings ? (
            <input
              id="4-rating"
              type="text"
              value={rating4}
              onChange={(e) => {
                setRating4(e.target.value);
              }}
              required={true}
            />
          ) : (
            <p>{rating4}</p>
          )}
        </div>
        <div className="rating-wrapper">
          <label htmlFor="3-rating">3/5</label>
          {editRatings ? (
            <input
              id="3-rating"
              type="text"
              value={rating3}
              onChange={(e) => {
                setRating3(e.target.value);
              }}
              required={true}
              disabled={!editRatings}
            />
          ) : (
            <p>{rating3}</p>
          )}
        </div>
        <div className="rating-wrapper">
          <label htmlFor="2-rating">2/5</label>
          {editRatings ? (
            <input
              id="2-rating"
              type="text"
              value={rating2}
              onChange={(e) => {
                setRating2(e.target.value);
              }}
              required={true}
              disabled={!editRatings}
            />
          ) : (
            <p>{rating2}</p>
          )}
        </div>
        <div className="rating-wrapper">
          <label htmlFor="1-rating">1/5</label>
          {editRatings ? (
            <input
              id="1-rating"
              type="text"
              value={rating1}
              onChange={(e) => {
                setRating1(e.target.value);
              }}
              required={true}
              disabled={!editRatings}
            />
          ) : (
            <p>{rating1}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default FeedbackPage;
