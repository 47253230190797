import React, { useCallback, useMemo } from 'react';
import Form from '../core/input/Form/Form';
import TextBox from '../core/input/TextBox/TextBox';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommentTable } from '../../actions';
import { RootState } from '../../store';
import CommentTutorial from '../tutorial/CommentTutorial';
import RichReader from '../core/display/RichReader';

interface Props {
  commentId: string;
  commentName: string;
  commentPrompt: string;
  maximumComments: number;
  minimumComments: number;
  onInvalid?: () => void;
  saveReflection: () => void;
}

function FormCommentPage({ commentId, commentName, commentPrompt, onInvalid, saveReflection }: Props): JSX.Element {
  // Redux:
  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);

  const handleChange = useCallback(
    (e: React.ChangeEvent) => {
      const comment = (e.target as HTMLInputElement).value;
      dispatch(
        updateCommentTable({
          commentId: commentId,
          commentNumber: 0,
          comment: comment,
          pinDrop: null,
        }),
      );
      saveReflection();
    },
    [commentId, dispatch, saveReflection],
  );

  const comment = useMemo(() => {
    let initComment = '';
    if (commentTable[commentId] && commentTable[commentId][0]) {
      initComment = commentTable[commentId][0].comment;
    }
    return initComment;
  }, [commentId, commentTable]);

  return (
    <>
      <Form.Header>
        <Form.Title>{commentName}</Form.Title>
      </Form.Header>
      <RichReader content={commentPrompt} />
      <Form.Body>
        <TextBox
          key={commentId}
          commentId={commentId}
          labelText={''}
          required
          defaultValue={comment}
          onChange={handleChange}
          onBlur={handleChange}
          onInvalid={onInvalid}
        />
      </Form.Body>
      <CommentTutorial />
    </>
  );
}

export default FormCommentPage;
