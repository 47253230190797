import React from 'react';
import { Assignment, Course, User } from '../../../../types/types';
import { getAssignmentTypeText } from '../../../../utils/functions';
import Sidebar from './Sidebar';

interface Props {
  assignment: Assignment;
  course: Course;
  user: User;
}

function TeacherSidebar({ assignment, course }: Props): JSX.Element {
  const { courseId } = course;
  const { assignmentId } = assignment;

  return (
    <Sidebar
      title={<span id="assignment-lbl">{assignment.assignmentName}</span>}
      subtitle={getAssignmentTypeText(assignment)}
      titleHref={`/course/${courseId}/assignment/${assignmentId}/dashboard`}
      menuLabel="Assignment Menu"
    >
      <Sidebar.Link href={`/course/${courseId}/assignment/${assignmentId}/dashboard`} iconCode="assignment">
        Dashboard
      </Sidebar.Link>
      <Sidebar.Link href={`/course/${courseId}/assignment/${assignmentId}/rubric`} iconCode="content_paste">
        Rubric
      </Sidebar.Link>
      {assignment.template ? null : (
        <>
          {assignment.groupsEnabled ? (
            <Sidebar.Link href={`/course/${courseId}/assignment/${assignmentId}/groups`} iconCode="people">
              Groups
            </Sidebar.Link>
          ) : null}
          {assignment.reviewingCirclesEnabled ? (
            <Sidebar.Link href={`/course/${courseId}/assignment/${assignmentId}/circles`} iconCode="hub">
              Review Circles
            </Sidebar.Link>
          ) : null}
          <Sidebar.Link href={`/course/${courseId}/assignment/${assignmentId}/progress`} iconCode="update">
            Progress
          </Sidebar.Link>
          <Sidebar.Link href={`/course/${courseId}/assignment/${assignmentId}/results`} iconCode="grade">
            Results
          </Sidebar.Link>
        </>
      )}

      <Sidebar.Link href={`/course/${courseId}/assignments`} iconCode="exit_to_app">
        Exit to Course Assignment List
      </Sidebar.Link>
    </Sidebar>
  );
}

export default TeacherSidebar;
