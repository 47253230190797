import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StudentDetailsTabProps } from './StudentDetailsPage';
import Accordion from '../../core/layout/Accordion/Accordion';
import Icon from '../../core/display/Icon';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../../store/selectors';
import Avatar from '../../core/display/Avatar/Avatar';
import { GroupFormationResponse, GroupFormationSurvey } from '../../../types/types';
import { getGroupFormationSurvey, getStudentGroupFormationResponse } from '../../../utils/requests';
import {
  GroupFormationFormData,
  generateFormDataFromGroupFormationSurveyAndResponse,
} from '../../groups/GroupFormation';
import BasicScheduler, { BasicSchedule } from '../../core/input/Scheduler/BasicScheduler';
import { getReadableFromType } from '../../groups/GroupFormationSurveyEditor';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../core/layout/Tooltip/Tooltip';

function GroupDetails({ assignmentProgress }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const assignment = useSelector(selectAssignment);

  const [groupCollapsed, setGroupCollapsed] = useState(true);
  const [survey, setSurvey] = useState<GroupFormationSurvey | null>(null);
  const [surveyResponse, setSurveyResponse] = useState<GroupFormationResponse | null>(null);
  const [groupFormationParsedData, setGroupFormationParsedData] = useState<GroupFormationFormData | null>(null);

  useEffect(() => {
    if (assignment?.groupFormationEnabled && assignmentProgress) {
      getGroupFormationSurvey(assignmentId, setSurvey);
      getStudentGroupFormationResponse(assignmentId, assignmentProgress.user.userId, setSurveyResponse);
    }
  }, [assignment, assignmentId, assignmentProgress]);

  useEffect(() => {
    if (survey && surveyResponse) {
      setGroupFormationParsedData(generateFormDataFromGroupFormationSurveyAndResponse(survey, surveyResponse));
    }
  }, [survey, surveyResponse]);

  const { group, user } = assignmentProgress;
  return (
    <div id="group-roster-details-page">
      <h2>Group</h2>

      {group ? (
        <div id="group-container">
          <Accordion
            id={`group-${group.groupId}`}
            className="group-accordion"
            name={group.groupName}
            collapsedValue={groupCollapsed}
            onToggle={setGroupCollapsed}
            contentPadding="0.5rem"
            headingContent={
              <>
                <span className="group-size-indicator" role="img" aria-label={`${group.groupMembers.length} Members`}>
                  <Icon code="person" ariaHidden />
                  <span>{group.groupMembers.length}</span>
                </span>
                {assignment?.allowStudentGroupManagement ? (
                  <span>{group.openAccess ? 'Open' : 'Invite-Only'}</span>
                ) : null}
              </>
            }
          >
            <ul aria-label="Group Members">
              {(group.groupMembers || [])
                .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
                .map((member) => (
                  <li key={`member-${member.groupMemberId}`}>
                    <div className="member-entry">
                      <Avatar user={member.user} size={40} />
                      <span className="entry-name">
                        {member.groupLeader && assignment?.enableGroupLeaders ? (
                          <Tooltip>
                            <TooltipTrigger
                              tag="span"
                              className="leader-icon"
                              role="img"
                              tabIndex={0}
                              ariaLabel="Group Leader"
                            >
                              <Icon code="stars" label="Group Leader" />
                            </TooltipTrigger>
                            <TooltipContent>Group Leader</TooltipContent>
                          </Tooltip>
                        ) : null}
                        <span>{member.user.name}</span>
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>
        </div>
      ) : (
        <p>
          <i>{user.name ?? 'User'} is not yet in a group.</i>
        </p>
      )}
      {assignment?.groupFormationEnabled ? (
        <>
          <h2>Group Formation Survey Response</h2>
          {survey && groupFormationParsedData ? (
            survey.prompts.map((prompt) => {
              const currData = groupFormationParsedData[prompt.surveyPromptId];
              return (
                <>
                  <h3>{getReadableFromType(prompt.type)}</h3>

                  {(() => {
                    switch (prompt.type) {
                      case 'SCHEDULE':
                        return <BasicScheduler initSchedule={currData as BasicSchedule[]} readOnly />;
                      case 'RANKED_CHOICE':
                        return (
                          <>
                            <p>
                              <b>Prompt:</b> {prompt.description}
                            </p>
                            <p>
                              <b>Answer:</b>
                              <ol>
                                {(currData as string[]).map((option) => (
                                  <li key={option}>{option}</li>
                                ))}
                              </ol>
                            </p>
                          </>
                        );
                      case 'CHOOSE_ONE':
                        return (
                          <>
                            <p>
                              <b>Prompt:</b> {prompt.description}
                            </p>
                            <p>
                              <b>Answer:</b> {prompt.options[currData as number]}
                            </p>
                          </>
                        );
                    }
                  })()}
                </>
              );
            })
          ) : (
            <p>
              <i>{user.name ?? 'User'} has not yet completed the group formation survey.</i>
            </p>
          )}
        </>
      ) : null}
    </div>
  );
}

export default GroupDetails;
