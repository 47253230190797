import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Assignment } from '../../types/types';
import { getUserCopyableAssignments } from '../../utils/requests';
import { getAssignmentTypeText, handleKeySelect, setPageTitle } from '../../utils/functions';
import { CoreSettings } from './AssignmentInitialSetup';
import SearchBar from '../core/input/SearchBar/SearchBar';
import Icon from '../core/display/Icon';

export interface AssignmentCopyProps extends CoreSettings {
  assignmentName: string;
  assignmentDescription: string | null;
}

function AssignmentCopyList(): JSX.Element {
  useEffect(() => setPageTitle('Assignment Copy List'), []);

  const courseId = (useParams() as { courseId: string }).courseId;

  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [searchValue, setSearchValue] = useState(''); // Raw Data

  const filteredAssignments = useMemo(
    () =>
      assignments.filter(
        (assignment) => assignment.assignmentName.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) !== -1,
      ),
    [assignments, searchValue],
  );

  const navigate = useNavigate();

  useEffect(() => getUserCopyableAssignments(courseId, setAssignments), [courseId]);

  const redirect = useCallback((path: string) => navigate(`/course/${courseId}${path}`), [courseId, navigate]);

  return (
    <div className="page assignment-row">
      <div className="panel-white assignment-copy-menu">
        <h1>Copy a Past Assignment</h1>
        <h2>Select an assignment to copy</h2>

        <div className="ctrls">
          <SearchBar
            placeholder="Search Assignments"
            value={searchValue}
            setValue={setSearchValue}
            resultsLength={filteredAssignments.length}
          />

          <p>
            {searchValue !== '' ? (
              <>
                Showing results for &apos;<i id="search-query">{searchValue}</i>&apos;:
              </>
            ) : (
              ''
            )}
          </p>
        </div>

        <ul>
          {filteredAssignments.map((assignment, i) => (
            <AssignmentCard key={'assignment-' + i} assignment={assignment} redirect={redirect} />
          ))}
        </ul>
      </div>
    </div>
  );
}

interface AssignmentCardProps {
  assignment: Assignment;
  redirect: (path: string) => void;
}

function AssignmentCard({ assignment, redirect }: AssignmentCardProps): JSX.Element {
  const submissionText = getAssignmentTypeText(assignment);

  const publicationMoment = moment(assignment.publicationTime);
  if (assignment.course) publicationMoment.tz(assignment.course.timeZone);
  const date = publicationMoment.format('MM/DD/YYYY');

  const handleSelect = () => redirect(`/assignment/setup/wizard/copy/${assignment.assignmentId}`);

  return (
    <li
      className="assignment-card copy-card"
      role="button"
      onClick={handleSelect}
      onKeyDown={(e) => handleKeySelect(e, handleSelect)}
      tabIndex={0}
    >
      <div className="assignment-icon">{assignment.favorite ? <Icon code="star" label="Favorite" /> : null}</div>

      <div className="main-info">
        <h3>{assignment.assignmentName}</h3>
        <p>{submissionText}</p>
        <p>
          <i>
            <span className="extra-text">Published on </span>
            {date}
          </i>
        </p>
      </div>
      <div className="course-info">
        <h4>
          <Icon code="class" />
          {assignment.course?.courseName}
        </h4>
      </div>
    </li>
  );
}

export default AssignmentCopyList;
