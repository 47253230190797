import React, { useEffect } from 'react';
import { Assignment, AssignmentProgress, PhaseCode, ProgressStats } from '../../../types/types';
import PhaseMenu, { PhaseMenuDetails } from './PhaseMenu';
import moment from 'moment';

interface Props {
  assignment: Assignment;
  userProgress: AssignmentProgress;
  setPhaseDetails: (phase: PhaseCode, details: PhaseMenuDetails) => void;
}

function FeedbackMenu({ assignment, userProgress, setPhaseDetails }: Props): JSX.Element {
  const rootPath = `/course/${assignment.courseId}/assignment/${assignment.assignmentId}`;
  const { progressStats, asyncEnabled, publicationTime, reviewDeadline } = assignment;
  const { status, feedbackCompleted, feedbackRequiredCount } = userProgress;
  const { feedbackPhase } = progressStats as ProgressStats;
  const dueDate = assignment.asyncEndDeadline ?? assignment.feedbackDeadline ?? '';
  const locked = !asyncEnabled && status.feedbackUpcoming;
  const unlockDate = asyncEnabled ? publicationTime : reviewDeadline ?? '';

  useEffect(
    () =>
      setPhaseDetails('feedback', {
        complete: feedbackCompleted >= feedbackRequiredCount,
        active: asyncEnabled ? status.feedbackPriority : feedbackPhase,
        locked,
        deadline: dueDate,
      }),
    [asyncEnabled, dueDate, feedbackCompleted, feedbackPhase, feedbackRequiredCount, locked, setPhaseDetails, status],
  );

  const getFeedbackTasks = () => {
    const feedbackTasks: JSX.Element[] = [];
    let feedbackNum = 0;

    // Create buttons for any completed feedback
    if (userProgress.reviewsGivenFeedback) {
      userProgress.reviewsGivenFeedback.forEach((review) => {
        if (feedbackPhase && status.canCompleteFeedback && assignment.asyncEnabled === false)
          feedbackTasks.push(
            <PhaseMenu.TaskEntry
              name={`Feedback ${feedbackNum + 1}`}
              buttonText="Edit"
              disabled={!feedbackPhase}
              href={`${rootPath}/review/${review.reviewId}/feedback?feedbackCompleted=${feedbackCompleted}`}
              complete
              key={`feedback-btn-${feedbackNum}`}
            />,
          );
        else
          feedbackTasks.push(
            <PhaseMenu.TaskEntry
              name={`Feedback ${feedbackNum + 1}`}
              buttonText="Complete"
              disabled
              complete
              key={`feedback-btn-${feedbackNum}`}
            />,
          );
        feedbackNum++;
      });
    }

    // Create buttons for any incomplete feedback
    if (userProgress.reviewsRequiringFeedback) {
      userProgress.reviewsRequiringFeedback.forEach((review) => {
        if (feedbackPhase && status.canCompleteFeedback)
          feedbackTasks.push(
            <PhaseMenu.TaskEntry
              name={`Feedback ${feedbackNum + 1}`}
              buttonText="Start"
              href={`${rootPath}/review/${review.reviewId}/feedback?feedbackCompleted=${feedbackCompleted}`}
              key={`feedback-btn-${feedbackNum}`}
            />,
          );
        else
          feedbackTasks.push(
            <PhaseMenu.TaskEntry
              name={`Feedback ${feedbackNum + 1}`}
              buttonText="Incomplete"
              disabled
              incomplete
              key={`feedback-btn-${feedbackNum}`}
            />,
          );
        feedbackNum++;
      });
    }

    if (assignment.asyncEnabled && status.canCompleteFeedback)
      while (feedbackTasks.length < assignment.numberOfReviewers) {
        feedbackTasks.push(
          <PhaseMenu.TaskEntry
            name={`Feedback ${feedbackNum + 1}`}
            buttonText="Awaiting Incoming Review"
            disabled
            key={`feedback-btn-${feedbackNum}`}
          />,
        );
        feedbackNum++;
      }

    return feedbackTasks;
  };

  const getNoReviewsReceivedText = () => {
    if (assignment.submissionDeadline !== null && moment().isAfter(assignment.submissionDeadline)) {
      if (status.hasSubmitted)
        return (
          <p>
            Feedback action unavailable <br /> (No reviews received)
          </p>
        );
      else
        return (
          <p>
            Feedback action unavailable <br /> {status.canLateSubmit ? '(Submission required)' : '(Missed submission)'}
          </p>
        );
    }
    return <p>Awaiting reviews...</p>;
  };

  return (
    <PhaseMenu.SubMenu
      description={
        <>
          <b>Feedback Phase</b> - Respond to your reviewers about the quality and helpfulness of their reviewing
          comments.
        </>
      }
    >
      <PhaseMenu.Status
        phaseGoal={`Provide Feedback for ${feedbackRequiredCount} reviews to complete this phase`}
        dueDate={dueDate}
        tasksCompleted={feedbackCompleted}
        tasksToComplete={feedbackRequiredCount}
        iconCode="question_answer"
      />
      <PhaseMenu.TaskList phase="feedback" status={status} locked={locked} unlockDate={unlockDate}>
        {userProgress.reviewsReceived < 1 ? getNoReviewsReceivedText() : null}
        {!status.feedbackUpcoming ? getFeedbackTasks() : null}
      </PhaseMenu.TaskList>
    </PhaseMenu.SubMenu>
  );
}

export default FeedbackMenu;
