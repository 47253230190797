import React from 'react';

interface Props {
  commentIdToCommentMap: { [index: string]: string };
}

function EvalCommentsPreview({ commentIdToCommentMap }: Props): JSX.Element {
  return (
    <div className="comment-preview-container">
      {Object.keys(commentIdToCommentMap).map((key) => (
        <blockquote className="right" key={key}>
          {commentIdToCommentMap[key]}
        </blockquote>
      ))}
    </div>
  );
}

export default EvalCommentsPreview;
