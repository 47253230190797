import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store';
import TutorialModal from './TutorialModal';

interface Props {
  resourcesAvailable: boolean;
}

function StudentResultsTutorial({ resourcesAvailable }: Props): JSX.Element {
  const user = useSelector((state: RootState) => state.user);
  const userId = user.actingAs ? 'null' : user.userId;
  const courseId = user.actingAs ? 'null' : user.courseRoleCourseId;
  const { assignmentId } = useParams() as { assignmentId: string };

  return (
    <TutorialModal
      id={`student-results-tutorial-for-${userId}-in-${courseId}-${assignmentId}`}
      heading="Your results are ready!"
    >
      <div id="student-results-tutorial">
        <p>Here are a few highlights to focus on:</p>
        <ol>
          <li>The grades section shows how you did on each part of the assignment</li>
          <li>Click on &quot;MORE&quot; under each rating prompt to read the rubric descriptions</li>
          {resourcesAvailable ? (
            <li>
              Check the &quot;Resources&quot; card to see if your instructor has any suggested additional learning
              materials for you based on your results
            </li>
          ) : (
            <li>Check the &quot;Peer Comments&quot; card to see all of the peer comments about your submission</li>
          )}
        </ol>
      </div>
    </TutorialModal>
  );
}

export default StudentResultsTutorial;
