import React, { useMemo } from 'react';
import Icon from '../Icon';

interface Props {
  score: number;
  max: number;
  starSize: number;
  showScore?: boolean;
}

function StarRating({ score, max, starSize, showScore = false }: Props): JSX.Element {
  const starValues = useMemo(() => {
    const result = new Array(max).fill(0);
    let scoreLeft = Math.min(score, max);
    for (let i = 0; i < result.length && scoreLeft > 0; i++) {
      let currValue = 0;
      if (scoreLeft >= 1) {
        currValue = 1;
        scoreLeft -= 1;
      } else {
        currValue = scoreLeft;
        scoreLeft = 0;
      }
      result[i] = currValue;
    }
    return result;
  }, [score, max]);

  return (
    <div
      className="star-rating"
      aria-label={`${score.toFixed(1)} stars out of ${max}`}
      style={{ height: `${starSize}px` }}
    >
      {showScore ? <span className="score">{score.toFixed(1)}</span> : null}
      <div className="stars" style={{ height: `${starSize}px` }}>
        {starValues.map((value, i) => (
          <Star key={`star-${i}`} percent={value * 100} size={starSize} />
        ))}
      </div>
    </div>
  );
}

function Star({ percent, size }: { percent: number; size: number }): JSX.Element {
  return (
    <div
      className="star"
      style={{ width: `${size}px`, height: `${size}px`, display: 'inline-block', position: 'relative' }}
    >
      <Icon
        code="star"
        color="#99A4AD"
        style={{ position: 'absolute', fontSize: `${size}px`, lineHeight: `${size}px` }}
        ariaHidden
      />
      <Icon
        code="star"
        color="#FFDC00"
        style={{
          position: 'absolute',
          fontSize: `${size}px`,
          lineHeight: `${size}px`,
          clipPath: `polygon(0 0, ${percent}% 0, ${percent}% 100%, 0 100%)`,
        }}
        ariaHidden
      />
    </div>
  );
}

export default StarRating;
