import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AssignmentLineItemPair, LineItem } from '../../types/types';
import {
  connectAssignmentAndLineItem,
  deleteAllLineItems,
  disconnectAssignmentAndLineItem,
  editLineItemMaxScore,
  getLineItemAssignmentPairs,
  getUnconnectedLineItems,
  newLineItems,
} from '../../utils/requests';
import { useParams } from 'react-router-dom';
import Table from '../core/display/Table/Table';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { ColumnDef } from '@tanstack/react-table';

function LtiManagementPage(): JSX.Element {
  const { courseId } = useParams() as { courseId: string };
  const [assignmentLineItemPairs, setLineItemAssignmentPairs] = useState<AssignmentLineItemPair[]>([]);
  const [unconnectedLineItems, setUnconnectedLineItems] = useState<LineItem[]>([]);
  const [selectedLineItemId, setSelectedLineitemId] = useState<{ [key: string]: string }>({});
  const [editMaxScore, setEditMaxScore] = useState<{ [key: string]: boolean }>({});
  const [maxScores, setMaxScores] = useState<{ [key: string]: number }>({});
  const { modalDispatch } = useModalContext();
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    getLineItemAssignmentPairs(courseId, setLineItemAssignmentPairs);
    getUnconnectedLineItems(courseId, setUnconnectedLineItems);
  }, [courseId, updateKey]);

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  const deleteAllLineItemHandler = useCallback(
      () => {
      modalDispatch(
        openModal({
          heading: 'Delete Line Items',
          label: `Are you sure you want to delete all grade book connections?`,
          buttonText: 'Delete',
          padding: '3rem',
          onConfirm: () => deleteAllLineItems(courseId, updateData)
        }),
      );
    },  [courseId, modalDispatch, updateData],
  ); 

  const initializeMaxScores = (pairs: AssignmentLineItemPair[]) => {
    const initialMaxScores = pairs.reduce((acc, pair) => {
      if (pair.lineItem) {
        acc[pair.assignmentId] = pair.lineItem.scoreMaximum;
      }
      return acc;
    }, {} as { [key: string]: number });
    setMaxScores(initialMaxScores);
  };

  useEffect(() => {
    initializeMaxScores(assignmentLineItemPairs);
    const initialSelectedLineItemId = assignmentLineItemPairs.reduce((acc, pair) => {
      acc[pair.assignmentId] = acc[pair.assignmentId] || '';
      return acc;
    }, {} as { [key: string]: string });
    setSelectedLineitemId(initialSelectedLineItemId);
  }, [assignmentLineItemPairs]);

  const handleSelectedLineItemChange = useCallback((event: ChangeEvent<HTMLSelectElement>, assignmentId: string) => {
    const newSelectedLineItem = event.target.value;
    setSelectedLineitemId((prevSelectedLineItems) => ({
      ...prevSelectedLineItems,
      [assignmentId]: newSelectedLineItem,
    }));
  }, []);

  const handleMaxScoreChange = useCallback((event: ChangeEvent<HTMLInputElement>, assignmentId: string) => {
    const newMaxScore = event.target.value === '' ? 0 : parseFloat(event.target.value);
    setMaxScores((prevMaxScore) => ({
      ...prevMaxScore,
      [assignmentId]: newMaxScore,
    }));
  }, []);

  const handleStateOfEditMaxScore = useCallback((assignmentId: string, isEditing: boolean) => {
    setEditMaxScore((prevState) => ({
      ...prevState,
      [assignmentId]: isEditing,
    }));
  }, []);

  const renderSelectOptions = useCallback(
    (assignmentId: string) => {
      return (
        <select
          name="Select a Grade Book Line Item"
          value={selectedLineItemId[assignmentId]}
          onChange={(event) => handleSelectedLineItemChange(event, assignmentId)}
          id={assignmentId}
        >
          <option value="">Select a Grade Book Line Item</option>
          {Array.isArray(unconnectedLineItems) &&
            unconnectedLineItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          <option value="new">NEW GRADE BOOK ITEM</option>
        </select>
      );
    },
    [selectedLineItemId, unconnectedLineItems, handleSelectedLineItemChange],
  );

  const tableColumns: ColumnDef<AssignmentLineItemPair>[] = useMemo(
    () => [
      { header: 'Assignment', accessorKey: 'assignmentName', meta:{className: 'left-align' }},
      { header: 'Results Sent', accessorKey: 'resultSent', meta:{className: 'left-align' }},
      {
        header: 'LMS Grade Book Item',
        accessorKey: 'assignmentId',
        cell: ({row})=>{
          const lineItem = row.original.lineItem;
          const assignmentId = row.original.assignmentId;
          return <div>{lineItem ? lineItem.label : <div>{renderSelectOptions(assignmentId)}</div>}</div>;
        },
        meta:{className: 'center-align'},
      },
      {
        header: 'Grade Book Max Score',
        cell: ({row}) => {
          const lineItem = row.original.lineItem;
          const assignmentId = row.original.assignmentId;
          return lineItem ? (
            <div>
              {editMaxScore[assignmentId] ? (
                <label>
                  MaxScore:{' '}
                  <input
                    type="text"
                    value={maxScores[assignmentId]}
                    onChange={(event) => handleMaxScoreChange(event, assignmentId)}
                    onBlur={() => {
                      lineItem.scoreMaximum = maxScores[assignmentId];
                      editLineItemMaxScore(assignmentId, lineItem, updateData);
                      handleStateOfEditMaxScore(assignmentId, false);
                    }}
                    autoFocus={true}
                  />
                </label>
              ) : (
                <button
                  id="maxScore-btn"
                  onClick={() => {
                    handleStateOfEditMaxScore(assignmentId, true);
                  }}
                >
                  {lineItem.scoreMaximum} <Icon code="edit" />
                </button>
              )}
            </div>
          ) : null;
        },
        accessorKey: 'maxScore',
        meta:{className: 'left-align' },
      },
      {
        header: 'Connect',
        cell: ({ row })=> {
          const lineItem = row.original.lineItem;
          const assignmentId = row.original.assignmentId;
          const handleConnect = () => {
            if (selectedLineItemId[assignmentId]) {
              if (selectedLineItemId[assignmentId] === 'new') {
                newLineItems(assignmentId, updateData);
              } else {
                connectAssignmentAndLineItem(assignmentId, selectedLineItemId[assignmentId], updateData);
              }
            }
          };
          const handleDisconnect = () => {
            if (assignmentId) {
              disconnectAssignmentAndLineItem(assignmentId, updateData);
            }
          };
          return lineItem ? (
            <Button onClick={handleDisconnect}>Disconnect</Button>
          ) : (
            <Button onClick={handleConnect} disabled={!selectedLineItemId[assignmentId]}>
              Connect
            </Button>
          );
        },
      },
    ],
    [selectedLineItemId, renderSelectOptions, editMaxScore, maxScores, handleMaxScoreChange, updateData, handleStateOfEditMaxScore],
  );

  return (
    <div className="lti-connection-container">
      <div className="lti-connection-instruction">
        This page allows you to connect or disconnect Peerceptiv assignments with grade book line items in the LMS and
        set the point value. If your grades do not pass back or your assignment link does not work, check the
        connections and make sure there are no duplicates. Any Peerceptiv assignment with student data in it (or an
        assignment which will be completed) should be connected and have the correct grade book max score associated
        with it. Contact Peerceptiv Support at
        <a href="https://peerceptiv.zendesk.com" target="_blank" rel="noopener noreferrer">
          {'  '}
          peerceptiv.zendesk.com
        </a>
        {'  '}if you need further assistance.
      </div>
      <div id="delete-btn">
        <Button type="button" variant="rad low" onClick={deleteAllLineItemHandler}>
          Delete All Grade Book Items
        </Button>
      </div>
      <Table
        columns={tableColumns}
        data={assignmentLineItemPairs}
        sortBy="name"
        title="LTI Connection Management"
        headingLevel={1}
        id="instructor-lti-connection-card"
        noWrapper
      />
    </div>
  );
}

export default LtiManagementPage;
