import React, { useState, useEffect, useCallback } from 'react';
import { AssignmentProgress, Group } from '../../../types/types';
import { useParams } from 'react-router';
import { deanonymize, getDeanonymizedList } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';

interface Props {
  progress: AssignmentProgress[];
}

function SubmitterUsersList({ progress }: Props): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };
  const [deanonymizedList, setDeanonymizedList] = useState<string[]>([]);
  const [groupIdList, setGroupIdList] = useState<string[]>([]);
  const [groupList, setGroupList] = useState<Group[]>([]);
  const [updateKey, setUpdateKey] = useState(0);
  const [groupIdToSubmissionIdMap, setGroupIdSubmissionIdMap] = useState(new Map());

  const updateData = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  useEffect(() => {
    getDeanonymizedList(assignmentId, setDeanonymizedList);
  }, [assignmentId, updateKey]);

  const checkInDeanonymizedList = (Id: string): boolean => {
    return deanonymizedList.includes(Id);
  };

  useEffect(() => {
    progress.forEach((item) => {
      if (item.group != null) {
        setGroupIdList((prevGroupIdList) => {
          const groupIdArray = Array.from(prevGroupIdList);
          const groupId = item.group?.groupId ?? '';
          if (!groupIdArray.includes(groupId)) {
            groupIdArray.push(groupId);
          }
          return groupIdArray;
        });
        setGroupList((prevGroupList) => {
          if (item.group !== null && !prevGroupList.some((group) => group.groupId === item.group?.groupId)) {
            return [...prevGroupList, item.group];
          }
          return prevGroupList;
        });
        setGroupIdSubmissionIdMap((prevMap) => {
          const newMap = new Map(prevMap);
          newMap.set(item.group?.groupId, item.submissionInfo?.submissionId);
          return newMap;
        });
      }
    });
  }, [progress]);

  if (groupIdList.length > 0) {
    return (
      <ul className="deanonymize-list">
        {groupList.map((item) => (
          <>
            {groupIdToSubmissionIdMap.get(item.groupId) != undefined ? (
              <li key={item.groupId}>
                <div className="submitter-name-btn">
                  <div className="submitter-name">{item.groupName}</div>
                  <div>
                    <Button
                      disabled={checkInDeanonymizedList(item.groupId)}
                      onClick={() => {
                        deanonymize(assignmentId, groupIdToSubmissionIdMap.get(item.groupId), updateData);
                      }}
                    >
                      De-anonymize
                    </Button>
                  </div>
                </div>
              </li>
            ) : (
              <></>
            )}
          </>
        ))}
      </ul>
    );
  } else {
    return (
      <ul className="deanonymize-list">
        {progress && progress.length > 0 ? (
          progress.map((item) => (
            <>
              {item.assignment.assignmentId != undefined && item.submissionId != null ? (
                <li key={item.user.userId}>
                  <div className="submitter-name-btn">
                    <div className="submitter-name">{item.user.name}</div>
                    <div>
                      <Button
                        disabled={item.user.name != null && checkInDeanonymizedList(item.user.userId)}
                        onClick={() => {
                          deanonymize(item.assignment.assignmentId, item.submissionId, updateData);
                        }}
                      >
                        De-anonymize
                      </Button>
                    </div>
                  </div>
                </li>
              ) : (
                <></>
              )}
            </>
          ))
        ) : (
          <></>
        )}
      </ul>
    );
  }
}

export default SubmitterUsersList;
