import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { Course } from '../../types/types';
import { getCopyableCourses, getCourseTemplates } from '../../utils/requests';
import Icon from '../core/display/Icon';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';
import Button from '../core/button/Button/Button';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import { stringArrayIncludes } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';

interface Props {
  onRowSelect: (rowDatum: Course) => void;
  updateKey?: number | undefined;
}

function CourseCopyTable({ onRowSelect }: Props): JSX.Element {
  const [filterList, setFilterList] = useState<string[]>([]);

  const queryRequest = useMemo(
    () => (stringArrayIncludes(filterList, 'Templates') ? getCourseTemplates : getCopyableCourses),
    [filterList],
  );

  const navigate = useNavigate();

  const tableColumns = useMemo<Column<Course>[]>(
    () => [
      {
        Header: 'Course Name',
        accessor: 'courseName',
        className: 'left-align',
        Cell: (course: Course) => course.courseName,
      },
      {
        Header: 'Created By',
        accessor: 'user',
        className: 'left-align',
        Cell: (course: Course) => (course.user?.admin ? 'Peerceptiv' : course.user?.name),
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (course: Course) => moment(course.createdAt).format('MMM DD, YYYY'),
      },
      {
        Header: 'Roster',
        accessor: 'rosterStudentCount',
        className: 'center-align',
        Cell: function render(course: Course) {
          return (
            <span>
              <Icon code="person" ariaHidden />
              {course.rosterStudentCount ?? 0}
            </span>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'asyncEnabled',
        className: 'center-align',
        Cell: function render(course: Course) {
          if (course.asyncEnabled) return <Icon className="async-icon" code="timer_off" label="Asynchronous" tooltip />;
          return <Icon className="sync-icon" code="update" label="Synchronous" tooltip />;
        },
      },
      {
        Header: 'View Course',
        accessor: 'courseId',
        className: 'center-align',
        Cell: function render(course: Course) {
          return (
            <Button
              variant="low sm"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/course/${course.courseId}/assignments`);
              }}
              tooltip="View Course"
            >
              <Icon code="login" label="View Course" />
            </Button>
          );
        },
        notSortable: true,
      },
    ],
    [navigate],
  );

  return (
    <QueryTable
      title="Courses"
      id="course-list-table"
      ctrlsInsert={
        <div className="col-2">
          <FilterTab label="Course/Template Select" setFilterList={setFilterList} hideLabel>
            <FilterTab.Button type="radio" name="course-template-filters" defaultChecked>
              Courses
            </FilterTab.Button>
            <FilterTab.Button type="radio" name="course-template-filters">
              Templates
            </FilterTab.Button>
          </FilterTab>
        </div>
      }
      columns={tableColumns}
      queryRequest={queryRequest}
      onRowSelect={onRowSelect}
      classNames={{
        ctrlsClassName: 'ctrls-row',
        ctrlsWrapperClassName: 'col-2',
        tableClassName: 'home-body',
      }}
    />
  );
}

export default CourseCopyTable;
