import React from 'react';
import HelpTag from '../../core/display/HelpTag';
import Form from '../../core/input/Form/Form';

interface Props {
  feedbackEnabled: boolean;
  setFeedbackEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

function FeedbackSetup({ feedbackEnabled, setFeedbackEnabled }: Props): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>
          Feedback
          <HelpTag type="modal" heading="Feedback Phase" margin="0 1rem" emphasize>
            <p>
              In the third phase of a Peerceptiv assignment, the submitter responds to the reviewers about the quality
              and helpfulness of their reviewing comments. A feedback phase is strongly encouraged because this keeps
              the reviewers accountable for leaving thoughtful reviews. However, assignments will still run smoothly if
              this phase is omitted.
            </p>
          </HelpTag>
        </Form.Title>
      </Form.Header>
      <Form.Body>
        <fieldset className="row-inputs">
          <legend>Include feedback phase?</legend>
          <div className="rad-radio-btn">
            <input
              id="feedbackOn"
              type="radio"
              name="feedbackEnabled"
              value="true"
              checked={feedbackEnabled}
              onChange={(e) => setFeedbackEnabled(e.target.value === 'true')}
            />
            <label htmlFor="feedbackOn">Yes</label>
          </div>
          <div className="rad-radio-btn">
            <input
              id="feedbackOff"
              type="radio"
              name="feedbackEnabled"
              value="false"
              checked={!feedbackEnabled}
              onChange={(e) => setFeedbackEnabled(e.target.value === 'true')}
            />
            <label htmlFor="feedbackOff">No</label>
          </div>
        </fieldset>
        <p>
          <b>We strongly suggest including a feedback phase.</b>
        </p>
      </Form.Body>
    </>
  );
}

export default FeedbackSetup;
