import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../actions';
import Button from '../../core/button/Button/Button';
import { User } from '../../../types/types';
import { mfaDisable } from '../../../utils/requests';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { useNavigate } from 'react-router-dom';

interface Props {
  user: User;
}

function ManageMfa({ user }: Props): JSX.Element {
  const { modalDispatch } = useModalContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const disableMfa = useCallback(
    () =>
      modalDispatch(
        openModal({
          heading: 'Disable MFA?',
          children: (
            <p>
              Are you sure you want to remove MFA from your account?
            </p>
          ),
          onConfirm: () =>
            mfaDisable(user.userId, (user) => {
                dispatch(setUser(user));
              },
            ),
        }),
      ),
    [navigate,modalDispatch],
  );

  return (
    <div>
      <p>MFA is {user.mfaEnabled ? 'enabled' : 'disabled'}.</p>
      {user.mfaRequired ? (
        <p>Institution rules prevent you from managing MFA currently.</p>
      ) : user.mfaEnabled ?(
        <Button className='peer-button' onClick={disableMfa}>
          Disable MFA
        </Button>
      ) : (
        <Link className='peer-button' to="/mfa/enroll">
          Enable MFA
        </Link>      
      )}
    </div>
  );
}
export default ManageMfa;
