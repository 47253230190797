import React from 'react';
import Icon from '../../display/Icon';
import Button from '../Button/Button';

interface Props {
  download?: string;
  href?: string;
  iconCode: string;
  id?: string;
  label: string;
  onClick?: () => void;
}

function OptionButton({ download, href, iconCode, id, label, onClick }: Props): JSX.Element {
  return (
    <Button
      id={id}
      className="option-btn"
      classOverride
      type={!href ? 'button' : undefined}
      href={href}
      download={download}
      onClick={onClick}
      tooltip={label}
    >
      <Icon code={iconCode} label={label} />
    </Button>
  );
}

export default React.memo(OptionButton);
