import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Group, User } from '../../types/types';
import { getPropsFromQuery } from '../../utils/functions';
import Button from '../core/button/Button/Button';
import Dropdown from '../core/button/Dropdown/Dropdown';
import Avatar from '../core/display/Avatar/Avatar';
import Icon from '../core/display/Icon';
import { selectAssignment, selectUser } from '../../store/selectors';

interface Props {
  assignmentId: string;
  canSelfManageGroup: boolean;
  courseId: string;
  myGroup: Group;
  requestDemoteGroupLeader: (assignmentId: string, groupId: string, userId: string) => void;
  requestPromoteUserToGroupLeader: (assignmentId: string, groupId: string, userId: string) => void;
  requestRemoveUserFromGroup: (user: User, group: Group) => void;
  requestSetGroupOpenAccess: (assignmentId: string, groupId: string, access: 'open' | 'closed') => void;
  saveGroup: (arg0: Group) => void;
}

function StudentGroupsMyGroup({
  assignmentId,
  canSelfManageGroup,
  courseId,
  myGroup,
  requestDemoteGroupLeader,
  requestPromoteUserToGroupLeader,
  requestRemoveUserFromGroup,
  requestSetGroupOpenAccess,
  saveGroup,
}: Props): JSX.Element {
  const query: { status?: 'accept' | 'reject' } = getPropsFromQuery(new URLSearchParams(useLocation().search));

  const basePath = `/course/${courseId}/assignment/${assignmentId}/groups`;
  const [editName, setEditName] = useState(false);

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  const { modalDispatch } = useModalContext();

  const imLeader = useMemo(
    () =>
      assignment?.enableGroupLeaders &&
      myGroup &&
      myGroup.groupMembers.some((member) => member.user.userId === user.userId && member.groupLeader),
    [assignment, myGroup, user],
  );

  useEffect(() => {
    if (query.status !== undefined) {
      let heading = '',
        label = '';
      switch (query.status) {
        case 'accept':
          heading = 'Join Request Accepted';
          label = 'Student is now in your group.';
          break;
        case 'reject':
          heading = 'Join Request Rejected';
          label = `Student's request to join your group has been denied.`;
          break;
      }

      modalDispatch(
        openModal({
          heading: heading,
          label: label,
          buttonText: 'Okay',
          cancelHide: true,
        }),
      );
    }
  }, [modalDispatch, query]);

  const onNameChange = useCallback(
    (newName: string) => {
      if (myGroup && myGroup.groupName !== newName) saveGroup({ ...myGroup, groupName: newName });
    },
    [saveGroup, myGroup],
  );

  return (
    <div id="my-group">
      {myGroup ? (
        <>
          <div id="heading-bar">
            {canSelfManageGroup && imLeader ? (
              editName ? (
                <div>
                  <label>
                    Name:{' '}
                    <input
                      type="text"
                      value={myGroup.groupName}
                      onChange={(e) => {
                        onNameChange(e.target.value);
                      }}
                      onBlur={() => {
                        setEditName(false);
                      }}
                      autoFocus={true}
                    />
                  </label>
                </div>
              ) : (
                <button id="group-name-edit" onClick={() => setEditName(true)}>
                  <h2>
                    {myGroup.groupName} <Icon code="edit" label="Edit name" />
                  </h2>
                </button>
              )
            ) : (
              <h2>{myGroup.groupName}</h2>
            )}

            <span className="group-size-indicator" role="img" aria-label={`${myGroup.groupMembers.length} Members`}>
              <Icon code="person" ariaHidden />
              <span>{myGroup.groupMembers.length}</span>
            </span>

            {canSelfManageGroup && assignment?.enableGroupLeaders ? (
              <select
                value={myGroup.openAccess ? 'open' : 'closed'}
                onChange={(e) =>
                  requestSetGroupOpenAccess(assignmentId, myGroup.groupId, e.target.value as 'open' | 'closed')
                }
                disabled={!imLeader}
              >
                <option value="open">Open</option>
                <option value="closed">Invite-Only</option>
              </select>
            ) : null}
          </div>

          <div id="group-container">
            <ul aria-label="My Group Members">
              {myGroup.groupMembers
                .sort((a, b) => {
                  if (a.user.userId === user.userId) return -1;
                  if (b.user.userId === user.userId) return 1;
                  return (a.user.name || '').localeCompare(b.user.name || '');
                })
                .map((member) => {
                  const currUser = member.user;
                  const isSelf = currUser.userId === user.userId;

                  return (
                    <li key={`member-${member.groupMemberId}`}>
                      <div className="member-entry">
                        <Avatar user={currUser} size={40} anonymous={!isSelf && assignment?.anonymousGroups} />

                        <span className="entry-name">
                          {member.groupLeader && assignment?.enableGroupLeaders ? (
                            <span className="leader-icon">
                              <Icon code="stars" label="Group Leader" tooltip />
                            </span>
                          ) : null}
                          {isSelf ? (
                            <span>
                              <b>{currUser.name}</b>
                              <span className="badge">You</span>
                            </span>
                          ) : (
                            <span>{assignment?.anonymousGroups ? 'Anonymous' : currUser.name}</span>
                          )}
                        </span>

                        {canSelfManageGroup ? (
                          <Dropdown iconCode="expand_more" align="left" disabled={!isSelf && !imLeader}>
                            {isSelf || imLeader ? (
                              <Dropdown.Link
                                onClick={() => {
                                  modalDispatch(
                                    openModal({
                                      heading: isSelf ? 'Leave Group' : 'Remove From Group',
                                      label: isSelf
                                        ? `Are you sure you want to leave group "${myGroup.groupName}"?`
                                        : `Are you sure you want to remove "${currUser.name}" from your group?`,
                                      inputType: 'none',
                                      buttonText: isSelf ? 'Leave' : 'Remove',
                                      onConfirm: () => {
                                        requestRemoveUserFromGroup(currUser, myGroup);
                                      },
                                    }),
                                  );
                                }}
                              >
                                {isSelf ? 'Leave' : 'Remove'}
                              </Dropdown.Link>
                            ) : null}

                            {imLeader && isSelf && myGroup.groupMembers.length > 1 ? (
                              <Dropdown.Link
                                onClick={() => {
                                  modalDispatch(
                                    openModal({
                                      heading: `Revoke Leadership?`,
                                      label: `Are you sure you want to revoke leadership?"`,
                                      inputType: 'none',
                                      buttonText: 'Revoke',
                                      onConfirm: () => {
                                        requestDemoteGroupLeader(assignmentId, myGroup.groupId, currUser.userId);
                                      },
                                    }),
                                  );
                                }}
                              >
                                Revoke Leadership
                              </Dropdown.Link>
                            ) : null}
                            {imLeader && !isSelf ? (
                              <Dropdown.Link
                                onClick={() => {
                                  modalDispatch(
                                    openModal({
                                      heading: `Promote ${currUser.firstName}?`,
                                      label: `Are you sure you want to promote "${currUser.name}"? This will revoke your leadership."`,
                                      inputType: 'none',
                                      buttonText: 'Promote',
                                      onConfirm: () => {
                                        requestPromoteUserToGroupLeader(assignmentId, myGroup.groupId, currUser.userId);
                                      },
                                    }),
                                  );
                                }}
                              >
                                Promote to Leader
                              </Dropdown.Link>
                            ) : null}
                          </Dropdown>
                        ) : null}
                      </div>
                    </li>
                  );
                })}

              {myGroup.pendingMembers.map((member) => (
                <li key={`member-${member.groupMemberId}`}>
                  <div className="member-entry pending">
                    <Avatar user={member.user} size={40} />
                    <span className="name">{member.user.name}</span>

                    {member.requestToJoin ? (
                      <>
                        <span className="badge">Requested</span>
                        {canSelfManageGroup && imLeader ? (
                          <div className="request-ctrls">
                            <Button
                              variant="rad low sm"
                              href={`${basePath}/invite/${member.groupInvite?.groupInviteId}/accept`}
                              route
                            >
                              Accept
                            </Button>
                            <Button
                              variant="rad low sm alt"
                              href={`${basePath}/invite/${member.groupInvite?.groupInviteId}/reject`}
                              route
                            >
                              Reject
                            </Button>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <span className="badge">Pending</span>
                        {canSelfManageGroup && imLeader ? (
                          <Dropdown iconCode="expand_more" align="left">
                            <Dropdown.Link
                              onClick={() => {
                                requestRemoveUserFromGroup(member.user, myGroup);
                              }}
                            >
                              Cancel Invite
                            </Dropdown.Link>
                          </Dropdown>
                        ) : null}
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>

            {canSelfManageGroup && !assignment?.anonymousGroups ? (
              <Button className="add-members-btn" href={`${basePath}/student-list`} route>
                <Icon code="add" ariaHidden />
                <span>Invite a New Member</span>
              </Button>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default StudentGroupsMyGroup;
