import React, { useCallback, useEffect } from 'react';
import { formDataToObject, setPageTitle } from '../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { forgotPassword } from '../../utils/requests';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import SingleForm from '../core/input/Form/SingleForm';

function ForgotPasswordPage(): JSX.Element {
  useEffect(() => setPageTitle('Forgot Password'), []);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const handleSubmit = useCallback(
    (formData: FormData, callback: () => void) => {
      const email = (formDataToObject(formData) as { email: string }).email;
      forgotPassword(
        email,
        () =>
          modalDispatch(
            openModal({
              heading: 'Email Verification Sent',
              label:
                'If an account matching that email was found a password reset email with further instructions will be sent.',
              buttonText: 'Okay',
              noCancel: true,
              onConfirm: () => navigate('/login'),
            }),
          ),
        callback,
      );
    },
    [modalDispatch, navigate],
  );

  return (
    <>
      <div className="page login-row">
        <img src={logoSrc} width="256" alt="Peerceptiv logo" />
        <SingleForm
          className="forgot-password-form"
          title="Forgot Password"
          submitText="Request Password Reset"
          onSubmit={handleSubmit}
          backButton
        >
          <SingleForm.Row>
            <SingleForm.Col flexBasis="100%">
              <SingleForm.FormGroup>
                <SingleForm.InputGroup iconCode="email" flexDirection="column">
                  <label className="sr-only" htmlFor="email">
                    Email:
                  </label>
                  <input type="email" id="email" name="email" placeholder="Email" required={true} />
                </SingleForm.InputGroup>
              </SingleForm.FormGroup>
            </SingleForm.Col>
          </SingleForm.Row>
        </SingleForm>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
