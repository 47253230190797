import React, { useState } from 'react';
import AlertBar from '../core/display/AlertBar';
import Button from '../core/button/Button/Button';
import { storageAvailable } from '../../utils/functions';

function StorageDetector(): JSX.Element {
  const [dismissed, setDismissed] = useState(false);

  if ((storageAvailable('localStorage') && storageAvailable('sessionStorage')) || dismissed) return <></>;
  return (
    <AlertBar>
      Please enable cookies and site data. Until then, you will likely experience issues using Peerceptiv.{' '}
      <Button variant="xs" onClick={() => setDismissed(true)}>
        Dismiss
      </Button>
    </AlertBar>
  );
}

export default StorageDetector;
