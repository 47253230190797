import React, { useEffect, useMemo, useState } from 'react';
import { RosterPermission } from '../../../types/types';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';
import { getRolePermissions, updateRosterPermission } from '../../../utils/requests';
import Toggle from '../../core/input/Toggle/Toggle';
import Table from '../../core/display/Table/Table';
import { CellProps } from 'react-table';
import { ColumnDef } from '@tanstack/react-table';

function GraderPermission(): JSX.Element {
  const [graderRosterPermission, setGraderRosterPermission] = useState<RosterPermission[]>([]);
  const course = useSelector(selectCourse);

  useEffect(() => {
    if (course?.courseId) {
      getRolePermissions(course?.courseId,'GRADER', setGraderRosterPermission);
    }
  }, [course?.courseId]);

  const tableColumns = useMemo<ColumnDef<RosterPermission>[]>(
    () => [
      { header: 'Access', accessorKey: 'permissionDescription', meta:{className: 'left-align' }},
      {
        header: 'Permission',
        accessorKey: 'accessPermission',
        cell: ({ cell, row }) => {
          const permission: string = row.original.permission.toString();
          const value: boolean = cell.getValue() as boolean;
          return (
            <Toggle
              checked={value}
              hideLabel
              onChange={() => {
                if (course && course.courseId) {
                  updateRosterPermission(course.courseId, 'GRADER', permission, setGraderRosterPermission);
                }
              }}
            >
              Modify Grader Permission
            </Toggle>
          );
        },
        meta:{className: 'left-align' },
      },
    ],
    [course],
  );

  return <Table columns={tableColumns} data={graderRosterPermission} title="Grader Permissions" sortBy="permissionDescription" />;
}

export default GraderPermission;
