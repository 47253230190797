import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { BenchmarkGrade, Rubric } from '../../../types/types';
import { focusSiteContent, mod } from '../../../utils/functions';
import ProgressRing from '../../core/display/Progress/ProgressRing';
import ReviewSubmission from '../../core/display/Submission/ReviewSubmission';
import TabList from '../../core/layout/TabList/TabList';
import Button from '../../core/button/Button/Button';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { BenchmarkView } from './BenchmarkPage';
import ReadOnlyRubric from '../../rubric/ReadOnlyRubric';
import { SCREEN_WIDTH_TABLET } from '../../../utils/constants';

interface Props {
  benchmarkGrades: BenchmarkGrade[];
  initPageNum?: number;
  progress: number;
  rubric: Rubric;
  save: (grades: BenchmarkGrade[]) => void;
  setBenchmarkGrades: React.Dispatch<React.SetStateAction<BenchmarkGrade[] | null>>;
  setView: (view: BenchmarkView) => void;
}

function GradingForm({
  benchmarkGrades,
  initPageNum = 0,
  progress,
  rubric,
  save,
  setBenchmarkGrades,
  setView,
}: Props): JSX.Element {
  const [pageNum, setPageNum] = useState(initPageNum);
  const [submissionCollapsed, setSubmissionCollapsed] = useState(false);
  const [changeId, setChangeId] = useState(0);
  const [lastSaveChangeId, setLastSaveChangeId] = useState(0);
  const [onMobile, setOnMobile] = useState(window.innerWidth < SCREEN_WIDTH_TABLET);
  const complete: boolean = progress === 100;

  useEffect(() => {
    if (benchmarkGrades.length > 0 && changeId > lastSaveChangeId) {
      save(benchmarkGrades);
      setLastSaveChangeId(changeId);
    }
  }, [changeId, lastSaveChangeId, benchmarkGrades, save]);

  const changePage = useCallback(
    (diff: number) => {
      setPageNum((prevPageNum) => mod(prevPageNum + diff, benchmarkGrades.length));
    },
    [benchmarkGrades.length],
  );

  const handleProgressChange = useCallback(
    (value: number) => {
      setBenchmarkGrades((prevGrades) => {
        const newGrades = _.cloneDeep(prevGrades);
        if (newGrades) newGrades[pageNum].benchmarkGrade = value;
        return newGrades;
      });
      setChangeId((prevId) => prevId + 1);
    },
    [pageNum, setBenchmarkGrades],
  );

  useEffect(() => {
    const handleResize = () => {
      const currMobileState = window.innerWidth < SCREEN_WIDTH_TABLET;
      if (onMobile !== currMobileState) setOnMobile(currMobileState);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [onMobile]);

  const layoutClass = submissionCollapsed || onMobile ? 'benchmark-column' : 'benchmark-row';

  if (pageNum < benchmarkGrades.length)
    return (
      <div className={`page ${layoutClass}`} id="benchmark-page">
        {pageNum < benchmarkGrades.length ? (
          <ReviewSubmission
            submitter={benchmarkGrades[pageNum].authorPseudonym}
            submissionInfo={benchmarkGrades[pageNum].submission}
            onChange={(state) => {
              setSubmissionCollapsed(state.collapsed);
            }}
          />
        ) : null}
        <div id="benchmark-menu">
          <h1>Benchmark Grade</h1>
          <TabList
            label="Benchmark Menu"
            mini
            tabs={
              <>
                <TabList.Tab id="grade" controls="grade-tab">
                  Grade
                </TabList.Tab>
                <TabList.Tab id="rubric" controls="rubric-tab">
                  Rubric
                </TabList.Tab>
                <TabList.Tab id="help" controls="help-tab">
                  Help
                </TabList.Tab>
              </>
            }
          >
            <TabList.TabPanel id="grade-tab" labeledBy="grade">
              <div id="grader-wrapper">
                <p>Choose a grade for the current submission:</p>
                <ProgressRing
                  input
                  size="lg"
                  progress={Math.round(benchmarkGrades[pageNum].benchmarkGrade)}
                  setProgress={handleProgressChange}
                  radius={60}
                  strokeWidth={12}
                  padding={8}
                />
              </div>
              <div className="ctrls">
                <div id="mid-ctrl">
                  {complete && (
                    <Button id="finish-btn" variant="green" onClick={() => setView('GRAPH')}>
                      <div id="finish-txt">Finish</div>
                      <span id="progress">{progress}% Complete</span>
                    </Button>
                  )}
                  <div className="non-linear-pagination">
                    {benchmarkGrades.map((grade, i) => {
                      const isCurrent = i === pageNum;
                      const currClass = isCurrent ? 'current' : '';
                      if (grade.needsGrading)
                        return (
                          <div
                            className={`page-icon ${isCurrent ? 'incomplete-' + currClass : ''}`}
                            key={`page-icon-${i}`}
                          />
                        );
                      return <div className={`page-icon complete ${currClass}`} key={`page-icon-${i}`} />;
                    })}
                  </div>
                  {!complete && <span id="progress">{progress}% Complete</span>}
                </div>
                <Button className="back-btn" variant="rad alt" onClick={() => focusSiteContent(() => changePage(-1))}>
                  Back
                </Button>
                <Button className="next-btn" variant="rad" onClick={() => focusSiteContent(() => changePage(1))}>
                  Next
                </Button>
              </div>
            </TabList.TabPanel>
            <TabList.TabPanel id="rubric-tab" labeledBy="rubric">
              <ReadOnlyRubric prompts={rubric} />
            </TabList.TabPanel>
            <TabList.TabPanel id="help-tab" labeledBy="help">
              <p>
                <b>{benchmarkGrades.length} student submissions</b> have been randomly chosen. Assign a grade to each
                one. You can move to the next or prior submissions at any time. You can also change the grades you
                assign. The grades for these {benchmarkGrades.length} submissions will determine the grades for the rest
                of the submissions.
              </p>
            </TabList.TabPanel>
          </TabList>
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

export default GradingForm;
