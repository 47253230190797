import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../utils/functions';
import { ErrorObj } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import Accordion from '../core/layout/Accordion/Accordion';

interface Props {
  errors: ErrorObj[];
}

function ErrorLogPage({ errors }: Props): JSX.Element {
  useEffect(() => setPageTitle('Error Log'), []);

  const navigate = useNavigate();

  return (
    <div id="error-log-page" className="page">
      <Button id="back-btn" onClick={() => navigate(-1)}>
        Back
      </Button>
      <h1>Request Error Log:</h1>
      <div id="error-log-container">
        {errors.map((error, i) => (
          <Accordion key={`error-${i}`} name={error.id}>
            <Accordion.Row>
              <Accordion.Col>
                <p>{error.message}</p>
                <pre>
                  <code>{JSON.stringify(error.error, null, 2)}</code>
                </pre>
              </Accordion.Col>
            </Accordion.Row>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default ErrorLogPage;
