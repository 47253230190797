import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Comment, CommentTable } from '../../types/types';
import ReportButton from '../auth/ReportButton';
import Button from '../core/button/Button/Button';
import RichReader from '../core/display/RichReader';
import Dropdown from '../core/button/Dropdown/Dropdown';
import { renderPinDropLabel, seekComment, usePinDropContext } from '../../contexts/PinDropContext';

interface ReviewSubmissionProps {
  comment: Comment;
  pageNum: number;
  reviewCommentTable: CommentTable | null;
}

function FeedbackReview({ comment, pageNum, reviewCommentTable }: ReviewSubmissionProps): JSX.Element {
  return (
    <div className="feedback-review-window">
      <MainRow comment={comment} />
      <div className="review-display-wrapper">
        <ReviewDisplay commentId={comment.commentId} reviewCommentTable={reviewCommentTable} pageNum={pageNum} />
      </div>
    </div>
  );
}

interface MainRowProps {
  comment: Comment;
}

function MainRow({ comment }: MainRowProps): JSX.Element {
  const { modalDispatch } = useModalContext();

  return (
    <div className="main-row">
      <div className="details">
        <p className="comment-name">
          on <b>{comment.commentName}</b>
        </p>
        <Button
          className="prompt-btn"
          classOverride
          onClick={() =>
            modalDispatch(
              openModal({
                heading: 'Prompt Description:',
                closeButton: true,
                noActionButtons: true,
                padding: '4rem',
                maxWidth: '640px',
                children: <RichReader key={comment.commentId} content={comment.commentPrompt} />,
              }),
            )
          }
        >
          View Prompt
        </Button>
      </div>
    </div>
  );
}

interface ReviewDisplayProps {
  commentId: string;
  pageNum: number;
  reviewCommentTable: CommentTable | null;
}

function ReviewDisplay({ commentId, reviewCommentTable }: ReviewDisplayProps): JSX.Element {
  const { pinDropDispatch, pinDropContextState } = usePinDropContext();

  const { reviewId } = useParams() as { reviewId: string };

  const [comments, setComments] = useState<JSX.Element[]>([]);

  const parseCommentsFromTable = useCallback(() => {
    const animationDelayFromId = (id: string): string => {
      const parsedId = parseInt(id);
      if (isNaN(parsedId)) return '0s';
      return parsedId * 0.1 + 's';
    };
    const comments = [] as JSX.Element[];
    if (reviewCommentTable && reviewCommentTable.hasOwnProperty(commentId)) {
      for (const [id, commentEntry] of Object.entries(reviewCommentTable[commentId])) {
        const comment = commentEntry.comment;
        if (comment !== '')
          comments.push(
            <div className="review-comment-dialogue-wrapper" key={commentId + '-comment-' + id}>
              <blockquote className="review-comment received" style={{ animationDelay: animationDelayFromId(id) }}>
                {comment}
              </blockquote>
              <div className="ctrls">
                {commentEntry.pinDrop ? (
                  <Button
                    className="pin-drop"
                    classOverride
                    onClick={() => pinDropDispatch(seekComment(commentId, parseInt(id)))}
                    tooltip={
                      commentEntry.pinDrop.altText
                        ? `"${commentEntry.pinDrop.altText}"`
                        : commentEntry.pinDrop.pageNum != -1
                        ? 'View Pin'
                        : commentEntry.pinDrop.timestamp != -1
                        ? 'Play From Timestamp'
                        : undefined
                    }
                    disabled={commentEntry.pinDrop.pageNum === -1 && commentEntry.pinDrop.timestamp === -1}
                    ariaLabel={
                      commentEntry.pinDrop.altText
                        ? `Comment Pin Description: "${commentEntry.pinDrop.altText}"`
                        : undefined
                    }
                  >
                    {renderPinDropLabel(commentId, parseInt(id), commentEntry.pinDrop, pinDropContextState)}
                  </Button>
                ) : null}
                <Dropdown className="button-mini" iconCode="more_vert" align="right">
                  <ReportButton className="report-btn" buttonText="Report" reviewId={reviewId} />
                </Dropdown>
              </div>
            </div>,
          );
      }
    }
    setComments(comments);
  }, [commentId, pinDropContextState, pinDropDispatch, reviewCommentTable, reviewId]);

  useEffect(() => {
    if (commentId) parseCommentsFromTable();
  }, [commentId, parseCommentsFromTable]);

  return <div className="comment-quotes-wrapper">{comments}</div>;
}

export default FeedbackReview;
