import React from 'react';
import Button from '../../core/button/Button/Button';
import { BenchmarkView } from './BenchmarkPage';

interface Props {
  numSubmissions?: number;
  setView: (view: BenchmarkView) => void;
}

function Tutorial({ numSubmissions = 10, setView }: Props): JSX.Element {
  return (
    <div className="page benchmark-row" id="benchmark-page">
      <div className="panel" id="tutorial">
        <h1>Instructor Benchmark Grading</h1>
        <h2 className="title">How It Works</h2>

        <p>
          <b>{numSubmissions} student submissions</b> have been randomly chosen. Assign a grade to each one. You can
          move to the next or prior submissions at any time. You can also change the grades you assign. The grades for
          these {numSubmissions} submissions will determine the grades for the rest of the submissions.
        </p>

        <Button variant="rad" onClick={() => setView('GRADING')}>
          Start
        </Button>
      </div>
    </div>
  );
}

export default Tutorial;
