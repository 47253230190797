import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import JumpButton from '../../button/JumpButton';
import _ from 'lodash';

interface Props {
  bins: number;
  data: number[];
  height: number;
  width: number;
}

type HistogramDatum = {
  domain: number[];
  count: number;
};

function Histogram({ bins, data, height, width }: Props): JSX.Element {
  const id = useRef(_.uniqueId());
  const margin = { top: 16, right: 16, bottom: 48, left: 40 };
  const innerWidth = width - margin.left - margin.right;
  const padding = 1;

  const [histogramData, setHistogramData] = useState<HistogramDatum[]>([]);

  useEffect(() => {
    const aggregatedData: HistogramDatum[] = [];

    // Setup bins
    const binSize = 100 / bins;
    for (let i = 0; i < bins; i++) aggregatedData.push({ domain: [i * binSize, (i + 1) * binSize], count: 0 });

    // Count per bin inclusion
    data.forEach((datum) => {
      const index = Math.min(Math.floor(datum / binSize), bins - 1);
      aggregatedData[index].count++;
    });

    setHistogramData(aggregatedData);
  }, [data, bins]);

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, 100],
        range: [0, xMax],
        round: true,
      }),
    [xMax],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...histogramData.map((d) => d.count))],
        range: [yMax, 0],
        round: true,
      }),
    [yMax, histogramData],
  );

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<HistogramDatum | null>({
    // initial tooltip state
    tooltipOpen: true,
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: null,
  });

  // event handlers
  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGRectElement>, datum: HistogramDatum) => {
      const coords = localPoint(event.target as HTMLElement, event);
      if (coords)
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: coords.y,
          tooltipData: datum,
        });
    },
    [showTooltip],
  );

  useEffect(() => hideTooltip(), [hideTooltip]);

  return (
    <>
      <JumpButton invisible id={`pre-graph-btn-${id.current}`} targetId={`post-graph-btn-${id.current}`} type="focus">
        Skip to after graph
      </JumpButton>
      <svg width={width} height={height} aria-description="Grade Distribution Histogram">
        <rect width={width} height={height} fill="none" />
        <Group top={margin.top} left={margin.left}>
          {histogramData.map((d, i) => {
            const barWidth = innerWidth / bins - padding * 2;
            const barHeight = yMax - (yScale(d.count) ?? 0);
            const barX = (innerWidth / bins) * i + padding;
            const barY = yMax - barHeight;
            return (
              <Bar
                key={`bar-${d.domain}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#589FCF"
                rx="8"
                onPointerMove={(e) => {
                  handlePointerMove(e, d);
                }}
                onPointerOut={hideTooltip}
                tabIndex={d.count > 0 ? 0 : undefined}
                aria-label={
                  d.count > 0 ? `${d.count} Students within ${d.domain[0]}-${d.domain[1]}% Grade Range` : undefined
                }
              />
            );
          })}
          <AxisBottom
            numTicks={histogramData.length}
            top={yMax}
            scale={xScale}
            label="Grade"
            hideTicks
            stroke="#D1D1D1"
            labelOffset={16}
          />
          <AxisLeft
            scale={yScale}
            label="Number of Students"
            hideTicks
            stroke="#D1D1D1"
            tickFormat={(val) => (Number.isInteger(val) ? val + '' : '')}
            labelOffset={24}
          />
        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          key={Math.random()} // needed for bounds to update correctly
          left={tooltipLeft}
          top={tooltipTop}
        >
          {tooltipData ? (
            <div className="tooltip">
              <p>
                <b>{tooltipData.count} Students</b> within <br />
                <b>
                  {tooltipData.domain.length > 1 ? `${tooltipData.domain[0]}-${tooltipData.domain[1]}%` : null}
                </b>{' '}
                Grade Range
              </p>
            </div>
          ) : null}
        </TooltipWithBounds>
      )}
      <JumpButton invisible id={`post-graph-btn-${id.current}`} targetId={`pre-graph-btn-${id.current}`} type="focus">
        Skip to before graph
      </JumpButton>
    </>
  );
}

export default Histogram;
