import React, { useState, useEffect } from 'react';
import { changeNumberInputWithBounds } from '../../../utils/functions';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Grading4Props {
  dailyLatePenalty: number;
  preventLateSubmissions: boolean;
  lateSubmissionPenaltiesEnabled: boolean;
}

function GradingPage4({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Grading4Props>): JSX.Element {
  const [preventLateSubmissions, setPreventLateSubmissions] = useState(assignmentSettings.preventLateSubmissions);
  const [lateSubmissionPenaltiesEnabled, setLateSubmissionPenaltiesEnabled] = useState(
    assignmentSettings.lateSubmissionPenaltiesEnabled,
  );
  const [dailyLatePenalty, setDailyLatePenalty] = useState(assignmentSettings.dailyLatePenalty);

  useEffect(() => {
    updateSettings({
      dailyLatePenalty,
      preventLateSubmissions,
      lateSubmissionPenaltiesEnabled,
    });
  }, [updateSettings, dailyLatePenalty, preventLateSubmissions, lateSubmissionPenaltiesEnabled]);

  return (
    <>
      <h3>Late Submission</h3>
      <div className="sub-section">
        <div>
          {!assignmentSettings.peerEvaluationOnly && !assignmentSettings.instructorUpload ? (
            <fieldset className="row-inputs">
              <legend>Allow late submissions?</legend>
              <div className="rad-radio-btn">
                <input
                  id="preventLateSubmissionsOff"
                  type="radio"
                  name="preventLateSubmissions"
                  value="false"
                  checked={!preventLateSubmissions}
                  onChange={(e) => setPreventLateSubmissions(e.target.value === 'true')}
                />
                <label htmlFor="preventLateSubmissionsOff">Yes</label>
              </div>
              <div className="rad-radio-btn">
                <input
                  id="preventLateSubmissionsOn"
                  type="radio"
                  name="preventLateSubmissions"
                  value="true"
                  checked={preventLateSubmissions}
                  onChange={(e) => setPreventLateSubmissions(e.target.value === 'true')}
                />
                <label htmlFor="preventLateSubmissionsOn">No</label>
              </div>
            </fieldset>
          ) : null}

          {!preventLateSubmissions ? (
            <fieldset>
              <legend>Apply late penalties?</legend>
              <div className="rad-radio-btn">
                <input
                  id="lateSubmissionPenaltiesEnabledOn"
                  type="radio"
                  name="lateSubmissionPenaltiesEnabled"
                  value="true"
                  checked={lateSubmissionPenaltiesEnabled}
                  onChange={(e) => setLateSubmissionPenaltiesEnabled(e.target.value === 'true')}
                  tabIndex={preventLateSubmissions ? undefined : -1}
                />
                <label htmlFor="lateSubmissionPenaltiesEnabledOn">Yes</label>
              </div>
              <div className="rad-radio-btn">
                <input
                  id="lateSubmissionPenaltiesEnabledOff"
                  type="radio"
                  name="lateSubmissionPenaltiesEnabled"
                  value="false"
                  checked={!lateSubmissionPenaltiesEnabled}
                  onChange={(e) => setLateSubmissionPenaltiesEnabled(e.target.value === 'true')}
                  tabIndex={preventLateSubmissions ? undefined : -1}
                />
                <label htmlFor="lateSubmissionPenaltiesEnabledOff">No</label>
              </div>
            </fieldset>
          ) : null}

          {!preventLateSubmissions && lateSubmissionPenaltiesEnabled ? (
            <>
              <div>
                <div>
                  <label htmlFor="dailyLatePenalty">Late Penalty</label>
                </div>
                <span>
                  <input
                    id="dailyLatePenalty"
                    name="dailyLatePenalty"
                    type="number"
                    min="0"
                    max="100"
                    value={dailyLatePenalty}
                    onChange={(e) => {
                      changeNumberInputWithBounds(e.target.value, 0, 100, setDailyLatePenalty);
                    }}
                  />{' '}
                  point penalty per day
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default GradingPage4;
