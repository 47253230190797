import React, { useState, useCallback } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Assignment } from '../../types/types';
import { confirmSubmission, postTextSubmission, updateTextSubmission } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import OptionButton from '../core/button/OptionButton/OptionButton';
import Submission from '../core/display/Submission/Submission';
import RichEditor from '../core/editor/RichEditor/RichEditor';
import { State } from './SubmissionPage';

interface Props {
  allowEdit: boolean;
  assignmentId: string;
  assignment: Assignment;
  asyncEnabled: boolean;
  canReset?: boolean;
  onDelete: () => void;
  requestSequenceBreak: (arg0: number) => void;
  requestStateChangeInSequence: (arg0: number) => void;
  returnToDashboard: () => void;
  setSubmissionIdAndCleanLocalstorage: (arg0: string) => void;
  submissionId: string;
  submissionState: number;
  textSubmission?: string;
  submitterId: string;
}

function TextMenu({
  allowEdit,
  assignmentId,
  onDelete,
  requestSequenceBreak,
  requestStateChangeInSequence,
  returnToDashboard,
  setSubmissionIdAndCleanLocalstorage,
  submissionId,
  submissionState,
  textSubmission,
  submitterId,
}: Props): JSX.Element {
  const [text, setText] = useState(textSubmission);
  const [textIsEmpty, setTextIsEmpty] = useState(true);
  const { modalDispatch } = useModalContext();

  const onInvalid = useCallback(
    (heading: string, label: string) => {
      modalDispatch(
        openModal({
          heading,
          label,
          cancelHide: true,
        }),
      );
    },
    [modalDispatch],
  );

  switch (submissionState) {
    case State.SUBMIT:
      return (
        <div className="submission-menu" key="text-submit" id="submit-menu" tabIndex={-1}>
          <h1>Submit Text</h1>
          <p>Enter the text you would like to submit for the assignment.</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // Post file to server
              if (textIsEmpty) {
                onInvalid('Empty Submission', 'Submission text cannot be blank');
              } else {
                const formData = new FormData();
                if (text) formData.append('textSubmission', text);
                if (submissionId === '')
                  postTextSubmission(assignmentId, submitterId, formData, setSubmissionIdAndCleanLocalstorage, () => {
                    requestStateChangeInSequence(State.CONFIRM);
                  });
                else
                  updateTextSubmission(submissionId, formData, setSubmissionIdAndCleanLocalstorage, () => {
                    requestStateChangeInSequence(State.CONFIRM);
                  });
              }
            }}
          >
            <RichEditor initContent={text} onChange={setText} isEmpty={setTextIsEmpty} />
            <div className="buttons-wrapper">
              <Button
                variant="rad alt"
                type="button"
                onClick={() => {
                  requestSequenceBreak(State.CHOOSE);
                }}
              >
                Back
              </Button>
              <Button variant="rad" type="submit">
                Continue
              </Button>
            </div>
          </form>
        </div>
      );
    case State.CONFIRM:
      return (
        <div className="submission-menu" key="text-confirm" id="confirm-menu" tabIndex={-1}>
          <h1>Confirm &amp; Submit</h1>
          <p>Please check that your submission appears correctly and then press Confirm &amp; Submit.</p>
          <Submission
            key="text-confirm"
            submissionId={submissionId}
            flexDirection="column-reverse"
            textMenu={() => (
              <div className="view-ctrls">
                <Button
                  variant="rad alt"
                  type="button"
                  onClick={() => {
                    requestSequenceBreak(State.SUBMIT);
                  }}
                >
                  Edit
                </Button>

                <Button
                  variant="rad"
                  onClick={() => {
                    confirmSubmission(submissionId);
                    requestStateChangeInSequence(State.FINISH);
                  }}
                >
                  Confirm &amp; Submit
                </Button>
              </div>
            )}
          />
        </div>
      );
    case State.FINISH:
      return (
        <div className="submission-menu" key="text-finish" id="finish-menu" tabIndex={-1}>
          <h1>Submission Successful</h1>
          <p>Your assignment has been successfully submitted.</p>
          <Button variant="rad" onClick={returnToDashboard}>
            Done
          </Button>
        </div>
      );
    case State.VIEW:
      return (
        <div className="submission-menu" key="text-view" id="view-menu" tabIndex={-1}>
          <h1>View Submission</h1>
          <p>
            Below is your current submission for your assignment.{' '}
            {allowEdit ? 'Here you may access, replace, or delete your submission.' : ''}
          </p>
          <Submission
            key="text-view"
            submissionId={submissionId}
            flexDirection="column-reverse"
            textMenu={
              allowEdit
                ? () => (
                    <div className="view-ctrls flex-row">
                      <OptionButton
                        id="delete-sub-btn"
                        onClick={() => onDelete()}
                        label="Delete submission"
                        iconCode="delete"
                      />
                      <Button
                        variant="rad"
                        onClick={() => {
                          requestSequenceBreak(State.SUBMIT);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )
                : undefined
            }
          />
        </div>
      );
    default:
      return <></>;
  }
}

export default TextMenu;
