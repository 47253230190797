import React, { useState, useEffect, useCallback } from 'react';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { AccessPermission, Course, CourseUser } from '../../types/types';
import Table from '../core/display/Table/Table';
import ExpandedRosterRow from './table_menus/ExpandedRosterRow';
import { ColumnDef } from '@tanstack/react-table';

interface Props {
  course: Course;
  showAddModal: () => void;
  studentData: CourseUser[];
  updateData: () => void;
  accessPermission?: AccessPermission;
}

type TableData = {
  courseId: string;
  email: string;
  name: string;
  paid: 'Yes' | 'No';
  pseudo: string;
  userId: string;
};

function StudentsTable({ course, showAddModal, studentData, updateData, accessPermission }: Props): JSX.Element {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);

  const { modalDispatch } = useModalContext();

  const handleRowSelect = useCallback(
    (userId: string, courseId: string) =>
      modalDispatch(
        openModal({
          heading: 'Student Details',
          headingHide: true,
          form: false,
          closeButton: true,
          noActionButtons: true,
          children: (
            <ExpandedRosterRow
              userId={userId}
              courseId={courseId}
              studentPurchaseEnabled = {course.studentPurchaseEnabled} updateData={updateData}
              accessPermission={accessPermission}
            />
          ),
        }),
      ),
    [course.studentPurchaseEnabled, accessPermission, modalDispatch, updateData],
  );

  useEffect(() => {
    const columns: ColumnDef<TableData>[] = [
      { header: 'Name', accessorKey: 'name', meta: { className: 'left-align' } },
      { header: 'Pseudonym', accessorKey: 'pseudo', meta: { className: 'left-align' } },
      { header: 'Email', accessorKey: 'email', meta: { className: 'left-align' } },
    ];
    if (course.studentPurchaseEnabled) columns.push({ header: 'Paid', accessorKey: 'paid' });
    const dataTable: TableData[] = [];
    studentData.forEach((student: CourseUser) => {
      const newRow: TableData = {
        name: student.user.sortableName,
        pseudo: student.user.pseudonym,
        email: student.user.email,
        paid: student.studentPurchaseRequired ? 'No' : 'Yes',
        userId: student.userId,
        courseId: student.course.courseId as string,
      };

      dataTable.push(newRow);
    });

    setTableData(dataTable);
    setTableColumns(columns);
  }, [studentData, course]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="name"
      title="Student Roster"
      headingLevel={1}
      id="student-roster-card"
      informOfRow={(row) => handleRowSelect(row.original.userId, row.original.courseId)}
      onAdd={showAddModal}
    />
  );
}

export default StudentsTable;
