import React, { useState, useEffect } from 'react';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface AdvancedProps {
  convertSubmissionsToPdf: boolean;
  emailNotificationsEnabled: boolean;
}

function AdvancedPage({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<AdvancedProps>): JSX.Element {
  const [convertSubmissionsToPdf, setConvertSubmissionsToPdf] = useState<boolean>(
    assignmentSettings.convertSubmissionsToPdf,
  );
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState<boolean>(
    assignmentSettings.emailNotificationsEnabled,
  );

  useEffect(() => {
    updateSettings({ convertSubmissionsToPdf, emailNotificationsEnabled });
  }, [updateSettings, convertSubmissionsToPdf, emailNotificationsEnabled]);

  return (
    <div className="sub-section">
      <h3>If unsure, leave these settings as default.</h3>
      <div className="sub-section">
        {!assignmentSettings.peerEvaluationOnly && !assignmentSettings.groupFormationOnly ? (
          <div>
            <input
              id="convertSubmissionsToPdf"
              name="convertSubmissionsToPdf"
              type="checkbox"
              checked={convertSubmissionsToPdf}
              onChange={(e) => {
                setConvertSubmissionsToPdf(e.target.checked);
              }}
            />
            <label htmlFor="convertSubmissionsToPdf">Convert document submissions to PDF</label>
          </div>
        ) : null}

        <div>
          <input
            id="emailNotificationsEnabled"
            name="emailNotificationsEnabled"
            type="checkbox"
            checked={emailNotificationsEnabled}
            onChange={(e) => {
              setEmailNotificationsEnabled(e.target.checked);
            }}
          />
          <label htmlFor="emailNotificationsEnabled">Send email notifications</label>
        </div>
      </div>
    </div>
  );
}

export default AdvancedPage;
