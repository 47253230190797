import React, { useCallback, useMemo} from 'react';
import { CourseRating } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';
import moment from 'moment';
import Icon from '../core/display/Icon';
import { getCourseRating } from '../../utils/requests';

function CourseRatings(): JSX.Element {
  const navigate = useNavigate();

  const handleSelect = useCallback(
    (courseRating: CourseRating) => navigate(`/rubrics/course/${courseRating.course.courseId}/ratings`),
    [navigate],
  );

  const tableColumns = useMemo<Column<CourseRating>[]>(
    () => [
      {
        Header: 'Course Name',
        accessor: 'course',
        Cell: (courseRating: CourseRating) => courseRating.course.courseName,
        customSort: 'courseName',
      },

      {
        Header: 'Created By',
        accessor: 'course',
        className: 'left-align',
        Cell: (courseRating: CourseRating) => {
          const { course } = courseRating;
          if (course.user && course.user.name) return course.user.name;
          return '';
        },
      },
      {
        Header: 'Created At',
        accessor: 'course',
        Cell: (courseRating: CourseRating) => moment(courseRating.course.createdAt).format('MMM DD, YYYY'),
        customSort: 'createdAt',
      },
      {
        Header: 'Roster',
        accessor: 'course',
        Cell: function render(courseRating: CourseRating) {
          return (
            <span>
              <Icon code="person" ariaHidden />
              {courseRating.course.rosterStudentCount ?? 0}
            </span>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'course',
        className: 'center-align',
        Cell: function render(courseRating: CourseRating) {
          return courseRating.course.asyncEnabled ? 'Async' : 'Sync';
        },
        customSort: 'asyncEnabled',
      },
      {
        Header: 'Ratings',
        accessor: 'ratingCount',
        className: 'center-align',
        Cell: (courseRating: CourseRating) => courseRating.ratingCount,
        customSort: 'ratingCount',
      },
      {
        Header: 'Tags',
        accessor: 'tagCount',
        className: 'center-align',
        Cell: (courseRating: CourseRating) => courseRating.tagCount,
        customSort: 'tagCount',
      },
    ],
    [],
  );

  return (
    <div className="home-body" id="course-menu">
      <QueryTable
        id="course-list-table"
        columns={tableColumns}
        types={[
          { buttonText: `Active`, type: 'ACTIVE' },
          { buttonText: `Archived`, type: 'ARCHIVED' },
          { buttonText: `All`, type: null },
        ]}
        queryRequest={getCourseRating}
        onRowSelect={handleSelect}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsTypesClassName: 'col-3',
          ctrlsWrapperClassName: 'col-3',
          tableClassName: 'home-body',
        }}
        itemViewDefault={false}
        itemContainerClassName="item-container"
        hideLimitSelect
        defaultPageSize={10}
        title="Courses"
      />
    </div>
  );
}

export default CourseRatings;
