import React, { useRef, useEffect, useState } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import { VideoPin, deactivatePinning, unseekComment, usePinDropContext } from '../../../contexts/PinDropContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { updateCommentTable } from '../../../actions';

interface Props {
  options: {
    aspectRatio?: string;
    autoplay?: boolean;
    controls?: boolean;
    responsive?: boolean;
    fluid?: boolean;
    fill?: boolean;
    sources: { src: string; type: string }[];
  };
}

function VideoJS({ options }: Props): JSX.Element {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);

  // PinDrop Context:
  const { pinDropContextState, pinDropDispatch } = usePinDropContext();

  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);

  const [pinUpdateKey, setPinUpdateKey] = useState(0);
  const pinUpdateStore = useRef<{
    timestamp: number;
    commentId: string;
    commentNumber: number;
  } | null>(null);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current?.appendChild(videoElement);

      playerRef.current = videojs(videoElement, options);

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    const { commentId, commentNumber } = pinDropContextState;
    if (playerRef.current && commentId && commentNumber) {
      const pinDrop =
        commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber)
          ? commentTable[commentId][commentNumber].pinDrop
          : null;
      if (!pinDrop) {
        const timestamp = playerRef.current.currentTime() ?? 0;
        pinUpdateStore.current = { timestamp, commentId, commentNumber };
        pinDropDispatch(deactivatePinning());
        setPinUpdateKey((prevKey) => prevKey + 1);
      }
    }
  }, [commentTable, pinDropContextState, pinDropDispatch]);

  /**
   * This implementation manually defers this Redux dispatch to its own Component update.
   * Otherwise dispatch takes priority and blocks the PinDropContext update.
   */
  useEffect(() => {
    if (pinUpdateStore.current) {
      const { commentId, commentNumber, timestamp } = pinUpdateStore.current;
      const comment =
        commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber)
          ? commentTable[commentId][commentNumber].comment
          : '';
      dispatch(
        updateCommentTable({
          commentId: commentId,
          commentNumber: commentNumber,
          comment,
          pinDrop: { timestamp, pageNum: -1, xPosition: -1, yPosition: -1 },
        }),
      );
      pinUpdateStore.current = null;
    }
  }, [commentTable, dispatch, pinUpdateKey]);

  useEffect(() => {
    const { seekComment, commentId, commentNumber } = pinDropContextState;
    if (seekComment && commentId && commentNumber && playerRef.current) {
      const pinDrop =
        commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber)
          ? (commentTable[commentId][commentNumber].pinDrop as VideoPin)
          : null;
      if (pinDrop && pinDrop.timestamp >= 0) {
        playerRef.current.play();
        playerRef.current.currentTime(pinDrop.timestamp);
      }
      pinDropDispatch(unseekComment());
    }
  }, [commentTable, pinDropContextState, pinDropDispatch]);

  return <div className="video-player" data-vjs-player ref={videoRef} />;
}

export default VideoJS;
