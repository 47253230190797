import React, { useState, useEffect } from 'react';
import { AssignmentWizardPageProps } from '../AssignmentWizard';
import { changeNumberInputWithBounds, conditionallyPlural, mod } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';
import { Course } from '../../../types/types';

export interface GroupFormationProps {
  groupFormationGroupNum: number;
}

function GroupFormationPage({
  assignmentSettings,
  updateSettings,
}: AssignmentWizardPageProps<GroupFormationProps>): JSX.Element {
  const course = useSelector(selectCourse) as Course;
  const rosterSize = course.rosterStudentCount ?? 0;

  const [groupFormationGroupNum, setGroupFormationGroupNum] = useState(assignmentSettings.groupFormationGroupNum);
  const [tab, setTab] = useState<'SIZE' | 'NUMBER'>('SIZE');
  const [maxUsersPerGroup, setMaxUsersPerGroup] = useState(
    calcGroupSizeFromGroupNum(groupFormationGroupNum ?? 4, rosterSize),
  );

  useEffect(() => {
    updateSettings({ groupFormationGroupNum });
  }, [updateSettings, groupFormationGroupNum]);

  const remainder = mod(rosterSize, groupFormationGroupNum ?? 1);
  const studentsPerGroup = Math.floor(rosterSize / (groupFormationGroupNum ?? 1));

  return (
    <div id="group-formation-wizard-page">
      <div className="modal-tabs">
        <span
          className={tab === 'SIZE' ? 'selected' : ''}
          role="button"
          onClick={() => {
            setTab('SIZE');
          }}
        >
          Group Size
        </span>
        <span
          className={tab === 'NUMBER' ? 'selected' : ''}
          role="button"
          onClick={() => {
            setTab('NUMBER');
          }}
        >
          Group Number
        </span>
      </div>

      {tab === 'SIZE' ? (
        <div className="modal-tab">
          <label htmlFor="maxUsersPerGroup">Max students per Group:</label>
          <input
            type="number"
            id="maxUsersPerGroup"
            name="maxUsersPerGroup"
            min={1}
            max={100}
            value={maxUsersPerGroup}
            onChange={(e) =>
              changeNumberInputWithBounds(e.target.value, 2, 100, (newMaxUsersPerGroup: number) => {
                setMaxUsersPerGroup(newMaxUsersPerGroup);
                const newGroupFormationGroupNum = calcGroupNumFromGroupSize(newMaxUsersPerGroup, rosterSize);
                setGroupFormationGroupNum(newGroupFormationGroupNum);
              })
            }
          />
        </div>
      ) : null}

      {tab === 'NUMBER' ? (
        <div className="modal-tab">
          <label htmlFor="groupFormationGroupNum">Number of Groups:</label>
          <input
            id="groupFormationGroupNum"
            type="number"
            min={1}
            max={100}
            value={groupFormationGroupNum}
            onChange={(e) =>
              changeNumberInputWithBounds(e.target.value, 1, rosterSize, (newGroupFormationGroupNum: number) => {
                const minGroupSize = Math.floor(rosterSize / newGroupFormationGroupNum);
                const newMaxUsersPerGroup = calcGroupSizeFromGroupNum(newGroupFormationGroupNum, rosterSize);
                if (minGroupSize > 1 && newMaxUsersPerGroup < 100) {
                  setGroupFormationGroupNum(newGroupFormationGroupNum);
                  setMaxUsersPerGroup(newMaxUsersPerGroup);
                }
              })
            }
          />
        </div>
      ) : null}

      <table className="preview">
        <thead>
          <tr>
            <th>These settings will generate:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {conditionallyPlural((groupFormationGroupNum ?? 1) - remainder, 'Group')} of {studentsPerGroup}
            </td>
          </tr>
          {remainder > 0 ? (
            <tr>
              <td>
                {conditionallyPlural(remainder, 'Group')} of {studentsPerGroup + 1}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}

const calcGroupNumFromGroupSize = (groupSize: number, n: number) => {
  let groupNum: number;
  for (groupNum = 1; groupNum <= n; groupNum++) {
    const remainder = mod(n, groupNum);
    const studentsPerGroup = Math.floor(n / groupNum);
    const numMinGroups = groupNum - remainder;
    const numMaxGroups = remainder;
    const maxSize = numMaxGroups > 0 ? studentsPerGroup + 1 : 0;
    const minSize = studentsPerGroup;
    const k = numMinGroups + numMaxGroups;
    if (Math.max(maxSize, minSize) === groupSize || Math.max(maxSize, minSize) < groupSize) return k;
  }
  return -1;
};

const calcGroupSizeFromGroupNum = (groupNum: number, n: number) => {
  const newRemainder = mod(n, groupNum);
  const newStudentsPerGroup = Math.floor(n / groupNum);
  return newRemainder > 0 ? newStudentsPerGroup + 1 : newStudentsPerGroup;
};

export default GroupFormationPage;
