import React from 'react';
import Button from '../core/button/Button/Button';

function NewCourseMenu(): JSX.Element {
  return (
    <div id="new-course-menu-wrapper">
      <div className="panel-white" id="new-course-menu">
        <h1>Choose a course creation method</h1>
        <section className="button-menu">
          <Button id="new-btn" variant="rad low" href="/course/dashboard/new" route>
            Create a New Course
          </Button>
          <Button id="copy-btn" variant="rad low" href="/course/dashboard/course-copy-list" route>
            Copy an Existing Course
          </Button>
        </section>
      </div>
    </div>
  );
}

export default NewCourseMenu;
